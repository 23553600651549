import './dicionario.css';

import React,{ useState, useContext, useEffect} from 'react';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'

import { toast } from 'react-toastify'
import { FilterMatchMode } from 'primereact/api';
import { MenuItem, TextField } from '@mui/material';


import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import useForm from '../../hooks/useForm';
import LoadingIcons from 'react-loading-icons'
import DataGridCustom from '../../Components/DataGrid/DataGridCustom'
import Box from '@mui/material/Box'

function CadastroDicionario() {
    
    const [dataForm, handleInputChange, clear] = useForm({
        id: '',
        empresa: '',
        tipo1: '', 
        tipo2: '', 
        tipo3: '', 
        tipo4: '', 
        tipo5: '',
        tipo6: '', 
        tipo7: '',
        tipo8: '', 
        tipo9: '',   
        tipo10: ''
    });

    const { user, isHumburguerActive } = useContext(AuthContext);
    const { getMomentos,
        getDicionary,
        alterDicionary,
        saveDicionary,
        deleteDicionary,
        dicionary,
        getEmpresas, 
        empresas,
        getEmpresasUserAdm,
        empresaUserAdm, formConteudo
    } = useContext(CrudContext);
    
    const [dicionary2, setDicionary2] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [newProductsDialog, setNewProductsDialog] = useState(false);
    const [productDialog, setProductDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false);
    const [isEdit, setIsEdit] = useState(false)

    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const [empresaGrid, setEmpresaGrid] = useState([])

    useEffect(() => {
        getMomentos();
        initFilters1();
        getDicionary()
        getEmpresas()
        listEmpresa()
        getEmpresasUserAdm(user)
    }, [])

    useEffect(() => {
        setDicionary2(dicionary)
    }, [dicionary])

    useEffect(()=>{ 
        if(formConteudo){
            setSelectedCustomers(formConteudo)
        }        
      },[formConteudo])

    const listEmpresa = () => {
        let list = []
        empresas?.forEach((item)=>{
            list.push({
                label: item.name,
                value: item.id
            })
        })

        setEmpresaGrid(list)
    }

    // --------------------------------------------------------------------------------------
    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveProduct(e)}
            />
        </React.Fragment>
    );

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setNewProductsDialog(!newProductsDialog)
    };

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

        });
        setGlobalFilterValue1('');
    }

    const saveProduct = (e) => {
        setSubmitted(true);
        handleSaveDicionary(e)
        setNewProductsDialog(!newProductsDialog)
    }

    // ---------------------------------------------------------------------------------------


    async function handleSaveDicionary(e) {
        e.preventDefault();
        if (dataForm) {
            await saveDicionary(dataForm);
            clear()
        }
        else {
            toast.error('Preencha todos os campos')
        }
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const openNew = () => {
        setNewProductsDialog(true)
        clear()    
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProduct = () => {
        deleteDicionary(selectedCustomers);
        setDeleteProductsDialog(false);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );
        
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />
                <Button label="Editar" icon="pi pi-check" className="p-button-alert" onClick={() => confirmeEditar()} disabled={selectedCustomers?.length === 1 ? false : true} />
            </React.Fragment>
        )
    }

    const confirmeEditar = () => {
        setIsEdit(!isEdit)
        const filterDicionario = dicionary2.filter( item => item.id === selectedCustomers[0])[0]
        const filterEmpresa = empresaUserAdm.filter( item => item.empresa === filterDicionario.empresa)[0]
        dataForm.id = selectedCustomers[0]
        dataForm.empresa = filterEmpresa.empresa
        dataForm.tipo1 = filterDicionario.tipo1
        dataForm.tipo2 = filterDicionario.tipo2
        dataForm.tipo3 = filterDicionario.tipo3
        dataForm.tipo4 = filterDicionario.tipo4
        dataForm.tipo5 = filterDicionario.tipo5
        dataForm.tipo6 = filterDicionario.tipo6
        dataForm.tipo7 = filterDicionario.tipo7
        dataForm.tipo8 = filterDicionario.tipo8
        dataForm.tipo9 = filterDicionario.tipo9      
        dataForm.tipo10 = filterDicionario.tipo10      
        // setDataItemEdit()
    }

    const columns = [
        {field: 'id', headerName: 'id', width: 50 },
        {field: 'empresa', headerName: 'Base Cliente', width: 250 },
        {field: 'tipo1', headerName: 'Tipo 1', width: 200 },
        {field: 'tipo2', headerName: 'Tipo 2', width: 200 },
        {field: 'tipo3', headerName: 'Tipo 3', width: 250 },
        {field: 'tipo4', headerName: 'Tipo 4', width: 250 },
        {field: 'tipo5', headerName: 'Tipo 5', width: 200 },
        {field: 'tipo6', headerName: 'Tipo 6', width: 250 },
        {field: 'tipo7', headerName: 'Tipo 7', width: 250 },
        {field: 'tipo8', headerName: 'Tipo 8', width: 250 },
        {field: 'tipo9', headerName: 'Tipo 9', width: 250 },
        {field: 'tipo10', headerName: 'Tipo 10', width: 250 },
      ]

      const productDialogFooterAlter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialogAlter()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => {
                    alterDicionary(dataForm)                                      
                    setIsEdit(!isEdit)
                }}
            />
        </React.Fragment>
    );

    
    const hideDialogAlter= ()=>{
        setIsEdit(!isEdit)
    }

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                {/* <Title nome="Cadastro de Dicionário">
                </Title> */}

                <div className="container-profile">
                    {/* ------------------------------------------ */}
                    {newProductsDialog &&
                        <Dialog
                            visible={newProductsDialog}
                            style={{ width: "900px" }}
                            header="Novo registro"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooter}
                            onHide={hideDialog}
                        >
                            
                                <div className="field-dicionary">

                                    <TextField
                                        id="cad-dicionario-empresa"
                                        select
                                        label="Base cliente"
                                        value={dataForm.empresa}
                                        name="empresa"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                        >
                                        {empresaUserAdm?.map(option => (
                                            <MenuItem key={option.id} value={option.empresa}>
                                                {option.empresa}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 1"
                                        value={dataForm.tipo1}
                                        name="tipo1"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 2"
                                        value={dataForm.tipo2}
                                        name="tipo2"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 3"
                                        value={dataForm.tipo3}
                                        name="tipo3"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 4"
                                        value={dataForm.tipo4}
                                        name="tipo4"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />

                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 5"
                                        value={dataForm.tipo5}
                                        name="tipo5"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />

                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 6"
                                        value={dataForm.tipo6}
                                        name="tipo6"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 7"
                                        value={dataForm.tipo7}
                                        name="tipo7"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 8"
                                        value={dataForm.tipo8}
                                        name="tipo8"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 9"
                                        value={dataForm.tipo9}
                                        name="tipo9"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 10"
                                        value={dataForm.tipo10}
                                        name="tipo10"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />      
                                </div>
          
                            
                        </Dialog>
                    }
 {isEdit &&
                        <Dialog
                            visible={isEdit}
                            style={{ width: "900px" }}
                            header="Editar registro"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooterAlter}
                            onHide={hideDialogAlter}
                        >
                            
                                <div className="field-dicionary">

                                    <TextField
                                        id="cad-dicionario-empresa"
                                        select
                                        label="Base cliente"
                                        value={dataForm.empresa}
                                        name="empresa"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                        >
                                        {empresaUserAdm?.map(option => (
                                            <MenuItem key={option.id} value={option.empresa}>
                                                {option.empresa}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 1"
                                        value={dataForm.tipo1}
                                        name="tipo1"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 2"
                                        value={dataForm.tipo2}
                                        name="tipo2"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 3"
                                        value={dataForm.tipo3}
                                        name="tipo3"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 4"
                                        value={dataForm.tipo4}
                                        name="tipo4"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />

                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 5"
                                        value={dataForm.tipo5}
                                        name="tipo5"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />

                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 6"
                                        value={dataForm.tipo6}
                                        name="tipo6"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 7"
                                        value={dataForm.tipo7}
                                        name="tipo7"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 8"
                                        value={dataForm.tipo8}
                                        name="tipo8"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 9"
                                        value={dataForm.tipo9}
                                        name="tipo9"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="outlined-select-currency"
                                        label="Tipo 10"
                                        value={dataForm.tipo10}
                                        name="tipo10"
                                        onChange={(e) => handleInputChange(e)}
                                        margin="normal"
                                        variant="outlined"
                                    />      
                                </div>
          
                            
                        </Dialog>
                    }

                    {dicionary2 &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                            <Box sx={{ height: 800, width: '100%' }}>
                                <DataGridCustom 
                                    column={columns} 
                                    rows={dicionary2}                
                                />
                            </Box>    
                        </div>
                    }

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {deleteProductsDialog && <span>Tem certeza que gostaria de deletar os dicionários <b>selecionados</b>?</span>}
                        </div>
                    </Dialog>

                </div>
            </div >
        </div >
    )
}


export default CadastroDicionario;