import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/auth'
import CardComponent from '../../Components/CardHigienizacao';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'
import { Grid } from '@mui/material';
import LoadingIcons from 'react-loading-icons'

import './styled.css'
import { ImportacaoContext } from '../../contexts/importacao';
const Pacotes = () => {

  const { isHumburguerActive, user } = useContext(AuthContext);
  const { load } = useContext(ImportacaoContext)
  const { higienizacaoAllProcess, higienizacaoProcess, higienizacaoAllProcessAdmin } = useContext(CrudContext);
  const [dicionario, setdicionario] = useState(null);
  const [itens, setItens] = useState()

  useEffect(() => {
    higienizacaoAllProcessAdmin()
    // if (user.tipoUsuario === 'Administrador Global') {
    //   higienizacaoAllProcessAdmin();
    // } else {
    //   higienizacaoAllProcess(user.uid);
    // }
  }, [])


  useEffect(() => {

    const _capa = higienizacaoProcess.map((process) => {
      return {
        monitoramentoAtivo: process.isMonitored ? process.isMonitored : 'Não',
        ...process,
      };
    })

    setdicionario(_capa)

    const result = Object.values(
      _capa.reduce((accumulator, { baseCliente }) => {
        accumulator[baseCliente] = accumulator[baseCliente] || { nomeCliente: baseCliente, quantidade: 0 };
        accumulator[baseCliente].quantidade++;
        return accumulator;
      }, {})
    );

    setItens(result)

  }, [higienizacaoProcess])


  return (
    <div className="App">
      <Header />
      <div className={`content${isHumburguerActive ? '-active' : ''}`}>
        {load ?
          <div className='icon-loading'>
            <LoadingIcons.SpinningCircles stroke="#5499e9" />
          </div> : <>
            <Grid className='grid-component' columnSpacing={1} container justifyContent={'space-evenly'}>

              {itens &&
                itens.map((filterItens, index) => {
                  return <CardComponent key={index} quantidade={filterItens.quantidade} baseCliente={filterItens.nomeCliente} todos={dicionario} />
                })
              }

            </Grid>
          </>
        }
      </div>
    </div>
  );
}

export default Pacotes;