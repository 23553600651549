import React, { useEffect } from 'react';

import { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'

import { toast } from 'react-toastify'

import { FiPlusCircle } from 'react-icons/fi'
import { FilterMatchMode } from 'primereact/api';
import LoadingIcons from 'react-loading-icons'

import { MenuItem, TextField } from '@mui/material';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';
import './advogado.css';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import Box from '@mui/material/Box'
import DataGridCustom from '../../Components/DataGrid/DataGridCustom'

import { v4 as uuidv4 } from 'uuid'

function CadastroAdvogado() {
    const { 
        isHumburguerActive, 
    } = useContext(AuthContext);

    const { 
        getEmpresas, 
        saveAdvogado, 
        advogados, 
        getAdvogado, 
        deleteAdvogado, 
        alterAdvogado,
        getOffice,
        office, 
        load, formConteudo
    } = useContext(CrudContext);
    
    const [office2, setOffice2] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [newProductsDialog, setNewProductsDialog] = useState(false);
    // const [novaEmpresa, setNovaEmpresa] = useState();
    const [novoOffice, setNovoOffice] = useState();

    const [submitted, setSubmitted] = useState(false);
    const [nomeCompleto, setNomeCompleto] = useState('')
    const [oabData, setOabData] = useState([])
    const [oabGridAdd, setOabGridAdd] = useState('')
    const [selectOab, setSelecOab] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [advogadoId, setAdvogadoId] = useState('')

    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');

    const [tipo, setTipo] = useState([{ label: "Reclamante", value: "Reclamante" }, { label: "Reclamada", value: "Reclamada" }])
    const [_tipo, _setTipo] = useState()


    useEffect(() => {
        getEmpresas();
        getAdvogado();
        initFilters1();
        getOffice()
    }, [])


    useEffect(() => {
        setOffice2(office)
    }, [office])

    useEffect(() => {
        let data = advogados.filter((item) => item.id === advogadoId)
        setOabData(data[0]?.oab)
        setNomeCompleto(data[0]?.name)
    }, [advogadoId])

    useEffect(()=>{ 
        if(formConteudo){
            setSelectedCustomers(formConteudo)
        }        
      },[formConteudo])
    // --------------------------------------------------------------------------------------
    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveAdvogados(e)}
            />
        </React.Fragment>
    );

    const editDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialogEdit()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => updateAdvogado(e)}
            />
        </React.Fragment>
    );

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

        });
        setGlobalFilterValue1('');
    }

    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                {/* <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} /> */}
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Pesquisar" />
                </span>
            </div>
        )
    }

    const header1 = renderHeader1();

    const hideDialogEdit = () => {
        setSubmitted(false);
        setIsEdit(false)
    };

    const hideDialog = () => {
        setSubmitted(false);
        setNewProductsDialog(!newProductsDialog)
    };

    const updateAdvogado = () => {
        setSubmitted(true);
        let payload = {
            id: selectedCustomers[0],
            name: nomeCompleto,
            oab: oabData,
            oabList: '',
            tipo: _tipo,
            office: novoOffice
        }

        alterAdvogado(payload)
        setNomeCompleto(nomeCompleto)
        _setTipo(_tipo)
        setIsEdit(false)

    }

    const saveAdvogados = (e) => {
        setSubmitted(true);
        handleSaveAdvogado(e)
        setNewProductsDialog(!newProductsDialog)
    }

    // ---------------------------------------------------------------------------------------


    async function handleSaveAdvogado(e) {
        e.preventDefault();
        setSubmitted(true);
        if (oabData.length === 0 || !nomeCompleto || !_tipo) {
            return toast.error('Preencha todos os campos')
        }

        if (oabData.length > 0 && nomeCompleto && _tipo) {
            let payload = {
                name: nomeCompleto,
                oab: oabData,
                tipo: _tipo,
                office: novoOffice
            }

            await saveAdvogado(payload);

            setNomeCompleto('');
            setOabData([])
            setSelecOab([])
            setNewProductsDialog(!newProductsDialog)
        }
    }

    const editRegistroGrid = (options) => {
        if (options.rowData.oab === "") {
            setOabData(oabData.filter((i) => i.id != options.rowData.id))
        }

        if (options.rowData.oab != "") {
            let oabFindIndex = options.props.value.findIndex((i) => i.id === options.rowData.id)
            oabData[oabFindIndex].oab = options.value
        }
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const confirmeEditar = () => {
        setIsEdit(true)


        let data = advogados.filter((item) => item.id === selectedCustomers[0])

        setNomeCompleto(selectedCustomers[0] ? data[0].name : data[0].name)
        setOabData(selectedCustomers[0] ? data[0].oab : data[0].oab)
        setAdvogadoId(data[0].id)
        _setTipo(selectedCustomers[0] ? data[0].tipo : data[0].tipo)
        setNovoOffice(selectedCustomers[0] ? data[0].office : data[0].office)
    }

    const openNew = () => {
        setNewProductsDialog(true)
        setIsEdit(false)
        setNomeCompleto()
        setOabData([])
        _setTipo()
        setNovoOffice()
    }

    const rightToolbarTemplate = () => {
        if (selectedCustomers?.length > 1) {
            setIsEdit(false)
        }

        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />
                <Button label="Editar" icon="pi pi-check" className="p-button-alert" onClick={() => confirmeEditar()} disabled={selectedCustomers?.length === 1 ? false : true} />
            </React.Fragment>
        )
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProduct = () => {
        deleteAdvogado(selectedCustomers);
        setDeleteProductsDialog(false);

    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    const popUpAddOABGrid = () => {

        if (!oabGridAdd) {
            return toast.error("É necessário informar o número da OAB")
        }
        let newOab = [...oabData]
        newOab.push({
            id: uuidv4(),
            oab: oabGridAdd
        })
        setOabData(newOab)
        setOabGridAdd('')
    }

    const popUpAddOABGridEdit = () => {

        if (!oabGridAdd) {
            return toast.error("É necessário informar o número da OAB")
        }
        let newOab = [...oabData]
        newOab.push({
            id: uuidv4(),
            oab: oabGridAdd
        })
        setOabData(newOab)
        setOabGridAdd('')
    }

    const onRowEditComplete1 = (e) => {
        // let _products2 = [...pedidos2];
        let { newData, index } = e;
        newData.oab = selectOab
        //Necessário fazer o metodo de update aqui-----------------------------------------------------------------------
        alterAdvogado(newData);
    }

    const columns = [
        {field: 'id', headerName: 'id', width: 50 },
        {field: 'name', headerName: 'Nome Completo', width: 250 },
        {field: 'tipo', headerName: 'Tipo', width: 250 },
        {field: 'office', headerName: 'Escritório', width: 250 },
        {field: 'oabList', headerName: 'OAB', width: 250 }
      ]

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                {/* <Title nome="Cadastro de Advogado">
                </Title> */}

                <div className="container-profile">
                    {newProductsDialog &&
                        <Dialog
                            visible={newProductsDialog}
                            style={{ width: "450px" }}
                            header="Novo registro"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name-advogado"
                                    label="Nome completo"
                                    value={nomeCompleto}
                                    onChange={(e) => setNomeCompleto(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    id="outlined-name-list"
                                    select
                                    label="Tipo"
                                    value={_tipo}
                                    onChange={(e) => _setTipo(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {tipo?.map(option => (
                                        <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className="field"> 
                                <TextField
                                    id="outlined-name-list"
                                    select
                                    label="Escritório"
                                    value={novoOffice}
                                    onChange={(e)=> setNovoOffice(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {office2?.map(option => (
                                    <MenuItem key={option.id} value={option.description}>
                                        {option.description}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className="field-label">
                                <TextField
                                    id="outlined-name"
                                    label="OAB"
                                    value={oabGridAdd}
                                    onChange={(e) => setOabGridAdd(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <div className="field-icon">
                                    <FiPlusCircle id='icon-oab-add' className='icon-oab-add' size={25} onClick={() => popUpAddOABGrid()} />
                                </div>


                            </div>
                            <DataTable value={oabData} dataKey="id" responsiveLayout="scroll"
                                showGridlines
                                selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)}>
                                <Column field="oab" header="OAB" editor={(options) => editRegistroGrid(options)} style={{ width: '100%' }}></Column>
                            </DataTable>

                        </Dialog>
                    }


                    {isEdit &&
                        <Dialog
                            visible={isEdit}
                            style={{ width: "450px" }}
                            header="Editar registro"
                            modal
                            className="card p-fluid"
                            footer={editDialogFooter}
                            onHide={hideDialogEdit}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name-advogado"
                                    label="Nome completo"
                                    value={nomeCompleto}
                                    onChange={(e) => setNomeCompleto(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    id="outlined-name-list"
                                    select
                                    label="Tipo"
                                    value={_tipo}
                                    onChange={(e) => _setTipo(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {tipo?.map(option => (
                                        <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className="field"> 
                                <TextField
                                    id="outlined-name-list"
                                    select
                                    label="Escritório"
                                    value={novoOffice}
                                    onChange={(e)=> setNovoOffice(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {office2?.map(option => (
                                    <MenuItem key={option.id} value={option.description}>
                                        {option.description}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            </div>


                            <div className="field-label">
                                <TextField
                                    id="outlined-name"
                                    label="OAB"
                                    value={oabGridAdd}
                                    onChange={(e) => setOabGridAdd(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                                <div className="field-icon">
                                    <FiPlusCircle id='icon-oab-add' className='icon-oab-add' size={25} onClick={() => popUpAddOABGridEdit()} />
                                </div>


                            </div>
                            <DataTable value={oabData} dataKey="id" responsiveLayout="scroll"
                                showGridlines
                                selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)}>
                                <Column field="oab" header="OAB" editor={(options) => editRegistroGrid(options)} style={{ width: '100%' }}></Column>
                            </DataTable>

                        </Dialog>
                    }


                    {advogados &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={() => rightToolbarTemplate()}></Toolbar>
                            <Box sx={{ height: 800, width: '100%' }}>
                                <DataGridCustom 
                                    column={columns} 
                                    rows={advogados}                
                                />
                            </Box>
                        </div>
                    }

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {deleteProductsDialog && <span>Tem certeza que gostaria de deletar os advogados <b>selecionados</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div >
        </div >
    )
}


export default CadastroAdvogado;