import './pedidos.css';

import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'
import { toast } from 'react-toastify'
import { FilterMatchMode } from 'primereact/api';
import { MenuItem, TextField } from '@mui/material';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import LoadingIcons from 'react-loading-icons'
import Box from '@mui/material/Box'
import DataGridCustom from '../../Components/DataGrid/DataGridCustom'

function CadastroAtividade() {

    const { user, isHumburguerActive } = useContext(AuthContext);
    const {
        getAtividades,
        atividades,
        getFuncao,
        funcao,
        deleteAtividades,
        getFuncaoDepartamento,
        funcaoDepartamento,
        alterAtividades,
        saveAtividades,formConteudo
    } = useContext(CrudContext);
    const [Funcao, setFuncao] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [newProductsDialog, setNewProductsDialog] = useState(false);
    const [filtroFuncao, setFiltroFuncao] = useState(null);
    const [isEdit, setIsEdit] = useState(false)
    const [novaFuncao, setNovaFuncao] = useState();
    const [departamento, setDepartamento] = useState();
    const [efuncao, setEfuncao] = useState();

    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');

    const [productDialog, setProductDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false);
    const [_atividades, _setAtividades] = useState([])

    useEffect(() => {
        getFuncao();
        getAtividades();
        getFuncaoDepartamento()
        initFilters1();
        getFuncaoDepartamento();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setFuncao(funcao)
        setFiltroFuncao(funcao)
    }, [funcao])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(()=>{ 
        if(formConteudo){
            setSelectedCustomers(formConteudo)
        }        
      },[formConteudo])

      useEffect(()=>{
        _setAtividades(atividades)
      },[atividades])
    // --------------------------------------------------------------------------------------
    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveProduct(e)}
            />
        </React.Fragment>
    );

    const productDialogFooterAlter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => {
                    alterAtividades({
                        _id: selectedCustomers[0],
                        Name: novaFuncao,
                        Department: departamento,
                        Function:  efuncao 
                    });  
                    
                    setIsEdit(!isEdit)
                }}
            />
        </React.Fragment>
    );

    const hideDialogAlter= ()=>{
        setIsEdit(!isEdit)
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setNewProductsDialog(!newProductsDialog)
    };

    const saveProduct = (e) => {
        setSubmitted(true);
        handleSavePedido(e)
        setNewProductsDialog(!newProductsDialog)
    }

    // ---------------------------------------------------------------------------------------

    async function handleSavePedido(e) {
        e.preventDefault();
        if (novaFuncao) {
            const funcaoDep = []
            funcaoDepartamento.filter(item => {
                if (item.label == departamento) {
                    funcaoDep.push({
                        id: item._id, Descricao: item.label
                    })
                }
            })
            const payload = {
                Name: novaFuncao,
                Department: departamento,
                Function: efuncao
            }

            saveAtividades(payload);
            setNovaFuncao('');
            setDepartamento('');
        }
        else {
            toast.error('Preencha todos os campos')
        }
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const openNew = () => {
        setNewProductsDialog(true)
        setEfuncao()
        setDepartamento()
        setNovaFuncao()
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProduct = () => {
        deleteAtividades(selectedCustomers);
        setDeleteProductsDialog(false);
    }

    const clearFilter1 = () => {
        initFilters1();
    }

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

        });
        setGlobalFilterValue1('');
    }

    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                {/* <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} /> */}
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Pesquisar" />
                </span>
            </div>
        )
    }

    const header1 = renderHeader1();

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    // const rightToolbarTemplate = () => {
    //     return (
    //         <React.Fragment>
    //             <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
    //             <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />
    //         </React.Fragment>
    //     )
    // }

    const confirmeEditar = () => {
        setIsEdit(!isEdit)

        const filterAtividades = _atividades.filter( item => item._id === selectedCustomers[0])[0]
        setEfuncao(filterAtividades.Function)
        setDepartamento(filterAtividades.Department)
        setNovaFuncao(filterAtividades.Name)
        // setDataItemEdit()
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />
                <Button label="Editar" icon="pi pi-check" className="p-button-alert" onClick={() => confirmeEditar()} disabled={selectedCustomers?.length === 1 ? false : true} />
            </React.Fragment>
        )
    }

    const statusEditor = (options) => {
        return (
            <Dropdown value={options.value} options={funcaoDepartamento} optionLabel="label" optionValue="value"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Selecione Tipo Verba"
                itemTemplate={(option) => {
                    return <span className={`product-badge status-${option.value.toLowerCase()}`}>{option.label}</span>
                }} />
        );
    }

    function filterFuncao(depto) {
        let _filter = [...funcao]
        let filtrado = _filter.filter(item => item.Departamento === depto)
        setFiltroFuncao(filtrado)
    }

    const getDepartamentoLabel = (status) => {
        return status;
    }

    const getFuncaoLabel = (status) => {
        return status;
    }

    const departamentoBodyTemplate = (rowData) => {
        return getDepartamentoLabel(rowData.Department);
    }

    const funcaoBodyTemplate = (rowData) => {
        return getFuncaoLabel(rowData.Function);
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const departamentoEditor = (options) => {
        return (
            <Dropdown value={options.value} options={funcaoDepartamento} optionLabel="label" optionValue="value"
                onChange={(e) => {
                    options.editorCallback(e.value)
                    filterFuncao(e.value)
                }} placeholder="Departamento"
                itemTemplate={(option) => {
                    return <span className={`product-badge status-${option.value.toLowerCase()}`}>{option.label}</span>
                }} />
        );
    }

    const funcaoEditor = (options) => {
        return (
            <Dropdown value={options.value} options={filtroFuncao} optionLabel="label" optionValue="value"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Selecione Tipo Usuario"
                itemTemplate={(option) => {
                    return <span className={`product-badge status-${option.value.toLowerCase()}`}>{option.label}</span>
                }} />
        );
    }

    const onRowEditComplete1 = (e) => {
        let _products2 = [...Funcao];
        let { newData, index } = e;

        _products2[index] = newData;
        alterAtividades({
            _id: newData._id,
            Name: newData.Name,
            Department: newData.Department,
            Function: newData.Function
        });

        setFuncao(_products2);
    }

    //CRIAR REGRA PARA EDITAR NA GRID IGUAL DO PROCESSOS editable: true
    const columns = [
        {field: 'id', headerName: 'id', width: 50 },
        {field: 'Name', headerName: 'Nome', width: 250 },
        {field: 'Department', headerName: 'Departamento', width: 250 },
        {field: 'Function', headerName: 'Função', width: 250 }
      ]

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                {/* <Title nome="Cadastro de Pedido">
                </Title> */}

                <div className="container-profile">
                    {/* ---------------------------------------------------- */}
                    {newProductsDialog &&
                        <Dialog
                            visible={newProductsDialog}
                            style={{ width: "450px" }}
                            header="Nova atividade"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Nome"
                                    value={novaFuncao}
                                    onChange={(e) => setNovaFuncao(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            {funcaoDepartamento &&
                                <div className="form-box">
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Departamento"
                                        fullWidth
                                        value={departamento}
                                        onChange={(e) => {
                                            setDepartamento(e.target.value)
                                            filterFuncao(e.target.value)
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        {funcaoDepartamento.map(option => (
                                            <MenuItem key={option.label} value={option.label}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            }

                            {filtroFuncao &&
                                <div className="form-box">
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Função"
                                        fullWidth
                                        value={efuncao}
                                        onChange={(e) => {
                                            setEfuncao(e.target.value)
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        {
                                            filtroFuncao.map(option => (
                                                <MenuItem key={option.Descricao} value={option.Descricao}>
                                                    {option.Descricao}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </div>
                            }
                        </Dialog>
                    }

                    {isEdit &&
                        <Dialog
                            visible={isEdit}
                            style={{ width: "450px" }}
                            header="Editar ativiade"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooterAlter}
                            onHide={hideDialogAlter}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Nome"
                                    value={novaFuncao}
                                    onChange={(e) => setNovaFuncao(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            {funcaoDepartamento &&
                                <div className="form-box">
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Departamento"
                                        fullWidth
                                        value={departamento}
                                        onChange={(e) => {
                                            setDepartamento(e.target.value)
                                            filterFuncao(e.target.value)
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        {funcaoDepartamento.map(option => (
                                            <MenuItem key={option.value} value={option.label}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            }

                            {filtroFuncao &&
                                <div className="form-box">
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Função"
                                        fullWidth
                                        value={efuncao}
                                        onChange={(e) => {
                                            setEfuncao(e.target.value)
                                        }}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        {
                                            filtroFuncao.map(option => (
                                                <MenuItem key={option.Descricao} value={option.Descricao}>
                                                    {option.Descricao}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </div>
                            }
                        </Dialog>
                    }
                    {/* ---------------------------------------------------- */}
                    {_atividades &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                            <Box sx={{ height: 800, width: '100%' }}>
                                <DataGridCustom 
                                    column={columns} 
                                    rows={_atividades}                
                                />
                            </Box>
                        </div>
                    }

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {deleteProductsDialog && <span>Tem certeza que gostaria de deletar os pedidos <b>selecionados</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div >
        </div >
    )
}


export default CadastroAtividade;