import {
    DataGridPro,
    ptBR
} from '@mui/x-data-grid-pro';
import { useContext, useState } from 'react';
import { CrudContext } from '../../contexts/crud';

const DataGridCustom = ({ column, rows }) => {
    const { setFormConteudo } = useContext(CrudContext)
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false })
    const [pageSize, setPageSize] = useState(10);
    const [selectedCustomers, setSelectedCustomers] = useState([]);


    const alterColumnVisibility = (model) => {
        setColumnVisibilityModel(model);
    }

    const handleSaveClick = (e) =>{       
        setFormConteudo({ [e.field]: e.value, id: e.id })        
    }

    return(<>
        <DataGridPro id="data-Grid-Pro" className='data-Grid-Pro'
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            columns={column}
            rows={rows}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
                alterColumnVisibility(newModel)
            }
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 100]}
            pageSize={pageSize}
            pagination
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(newSelectionModel) => {
                setSelectedCustomers(newSelectionModel);
                setFormConteudo(newSelectionModel)
            }}
            onCellEditCommit={handleSaveClick}
            getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd' }
            
        />
    </>)
}


export default DataGridCustom