import './styled.css'
import { FaDownload } from 'react-icons/fa'
import React, { useState, useContext, useEffect, useRef } from 'react';
import { toast } from 'react-toastify'
import moment from 'moment';
import * as XLSX from 'xlsx';

const CardHigienizacaoComponent = ({ quantidade, baseCliente, todos }) => {

    const exportExcel = () => {
        if (todos.length > 0) {
            let itemExcel = []

            const filtered = todos.filter(item => item.baseCliente === baseCliente)

            filtered.map(item => {
                itemExcel.push({
                    numero: item.numero,
                    processoID: item.processoID,
                    baseCliente: item.baseCliente,
                    instancia: item.instancia,
                    nome_autor: item.nome_autor,
                    reclamada: item.reclamada,
                    juiz: item.juiz,
                    partes: item.partes,
                    area: item.area,
                    distribuicaoData: item.distribuicaoData ? moment(item.distribuicaoData).format('DD/MM/YYYY HH:MM') : '',
                    distribuicaoTipo: item.distribuicaoTipo,
                    processo_digital: item.processo_digital,
                    tribunal: item.tribunal,
                    sistema: item.sistema,
                    vara: item.vara,
                    orgao_julgador: item.orgao_julgador,
                    comarca_cnj: item.comarca_cnj,
                    foro_cnj: item.foro_cnj,
                    uf: item.uf,
                    valor: item.valor,
                    assunto_extra: item.assunto_extra,
                    natureza: item.natureza,
                    data_audiencia: item.data_audiencia ? moment(item.data_audiencia).format('DD/MM/YYYY HH:MM') : '',
                    local_audiencia: item.local_audiencia,
                    tipo_audiencia: item.tipo_audiencia,
                    todos_advogados: item.todos_advogados,
                    advogados_parte_ativa: item.advogados_parte_ativa,
                    oab_advogado_parte_ativa: item.oab_advogado_parte_ativa,
                    estado_advogado_parte_ativa: item.estado_advogado_parte_ativa,
                    advogados_parte_passiva: item.advogados_parte_passiva,
                    oab_advogado_parte_passiva: item.oab_advogado_parte_passiva,
                    estado_advogado_parte_passiva: item.estado_advogado_parte_passiva,
                    ultima_mov_juiz: item.ultima_mov_juiz,
                    total_movs: item.total_movs,
                    primeiro_andamento_mais_recente: item.primeiro_andamento_mais_recente,
                    segundo_andamento_mais_recente: item.segundo_andamento_mais_recente,
                    terceiro_andamento_mais_recente: item.terceiro_andamento_mais_recente,
                    quarto_andamento_mais_recente: item.quarto_andamento_mais_recente,
                    quinto_andamento_mais_recente: item.quinto_andamento_mais_recente,
                    sexto_andamento_mais_recente: item.sexto_andamento_mais_recente,
                    setimo_andamento_mais_recente: item.setimo_andamento_mais_recente,
                    oitavo_andamento_mais_recente: item.oitavo_andamento_mais_recente,
                    nono_andamento_mais_recente: item.nono_andamento_mais_recente,
                    decimo_andamento_mais_recente: item.decimo_andamento_mais_recente,
                    situacao: item.situacao,
                    citado: item.citado,
                    penhorado: item.penhorado,
                    suspenso: item.suspenso,
                    acordo: item.acordo,
                    sentenca: item.sentenca,
                    texto_sentenca: item.texto_sentenca,
                    sentencaData: item.sentencaData ? moment(item.sentencaData).format('DD/MM/YYYY HH:MM') : '',
                    classificacao_sentenca: item.classificacao_sentenca,
                    cumprimento_de_sentenca: item.cumprimento_de_sentenca,
                    recurso: item.recurso,
                    transitado_julg: item.transitado_julg,
                    arquivado: item.arquivado,
                    data_arquivamento: item.data_arquivamento
                })
            })

            import('xlsx').then(xlsx => {
                const headers = {
                    numero: 'Codigo do processo',
                    processoID: 'processoID',
                    baseCliente: 'Base Cliente',
                    instancia: 'instancia',
                    nome_autor: 'Nome autor',
                    reclamada: 'Reclamada',
                    juiz: 'Relator/Juiz',
                    partes: 'Todas partes',
                    area: 'Area',
                    distribuicaoData: 'Data distribuição',
                    distribuicaoTipo: 'Tipo distribuicao',
                    processo_digital: 'Processo digital',
                    tribunal: 'Tribunal',
                    sistema: 'Sistema',
                    vara: 'Vara',
                    orgao_julgador: 'Orgao Julgador',
                    comarca_cnj: 'Comarca CNJ',
                    foro_cnj: 'Foro CNJ',
                    uf: 'UF',
                    valor: 'Valor',
                    assunto_extra: 'Assunto extra',
                    natureza: 'Natureza',
                    data_audiencia: 'Data Audiência',
                    local_audiencia: 'Local Audiência',
                    tipo_audiencia: 'Tipo Audiência',
                    todos_advogados: 'Todos advogados',
                    advogados_parte_ativa: 'Advogados (parte ativa)',
                    oab_advogado_parte_ativa: 'OAB advogado (parte ativa)',
                    estado_advogado_parte_ativa: 'Estado advogado (parte ativa)',
                    advogados_parte_passiva: 'Advogados (parte passiva)',
                    oab_advogado_parte_passiva: 'OAB advogado (parte passiva)',
                    estado_advogado_parte_passiva: 'Estado advogado (parte passiva)',
                    ultima_mov_juiz: 'Ultima mov. juiz',
                    total_movs: 'Total movs.',
                    primeiro_andamento_mais_recente: '1o andamento mais recente',
                    segundo_andamento_mais_recente: '2o andamento mais recente',
                    terceiro_andamento_mais_recente: '3o andamento mais recente',
                    quarto_andamento_mais_recente: '4o andamento mais recente',
                    quinto_andamento_mais_recente: '5o andamento mais recente',
                    sexto_andamento_mais_recente: '6o andamento mais recente',
                    setimo_andamento_mais_recente: '7o andamento mais recente',
                    oitavo_andamento_mais_recente: '8o andamento mais recente',
                    nono_andamento_mais_recente: '9o andamento mais recente',
                    decimo_andamento_mais_recente: '10o andamento mais recente',
                    situacao: 'Situação',
                    citado: 'Citado',
                    penhorado: 'Penhorado',
                    suspenso: 'Suspenso',
                    acordo: 'Acordo',
                    sentenca: 'Sentença',
                    texto_sentenca: 'Data Sentença',
                    sentencaData: 'Texto Sentença',
                    classificacao_sentenca: 'Classificação sentença',
                    cumprimento_de_sentenca: 'Cumprimento de sentença',
                    recurso: 'Recurso',
                    transitado_julg: 'Transitado julg.',
                    arquivado: 'Arquivado',
                    data_arquivamento: 'Data arquivamento',
                };

                itemExcel.unshift(headers); // if custom header, then make sure first row of data is custom header 
                const worksheet = XLSX.utils.json_to_sheet(itemExcel, { skipHeader: true });
                //  worksheet = xlsx.utils.json_to_sheet(productsFiltered);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'processos');
            });
        } else {
            toast.error('Selecione ao menos um item para exportar');
        }

    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                const date = new Date();
                let formatedDay = date.toLocaleDateString();
                module.default.saveAs(data, 'Higienizacao_' + formatedDay + EXCEL_EXTENSION);
            }
        });
    }

    return (<>
        <div className='card-component'>
            <div className='card-info'>
                <span>{baseCliente}</span>
                <p className="m-0"> {quantidade}</p>
            </div>
            <div className='card-button'>
                <FaDownload size={30} onClick={exportExcel} />
            </div>
        </div>
    </>)
}

export default CardHigienizacaoComponent