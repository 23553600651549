import { toast } from 'react-toastify'
import { isExcel } from '.';
import { v4 as uuidv4 } from 'uuid'

export const modelo = {
    revelia: {
        columnName: "Revelia?",
        fieldName: "revelia",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Sim - Outra reclamada', value: 'Sim - Outra reclamada' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' },
        ],
    },

    reclamanteOuvidaAud: {
        columnName: "Reclamante foi ouvido em audiência?",
        fieldName: "reclamanteOuvidaAud",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    testemunhaReclamada: {
        columnName: "Testemunha reclamada?",
        fieldName: "testemunhaReclamada",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    testemunhaReclamante: {
        columnName: "Testemunha reclamante?",
        fieldName: "testemunhaReclamante",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    houvePericia: {
        columnName: "Houve perícia?",
        fieldName: "houvePericia",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    resultadoDaPericiaInsalubridade: {
        columnName: "Resultado da perícia Insalubridade?",
        fieldName: "resultadoInsalubridade",
        lista: [
            { label: 'Há insalubridade', value: 'Há insalubridade' },
            { label: 'Não há insalubridade', value: 'Não há insalubridade' }
        ]
    },

    agenteInsalubre: {
        columnName: "Agente Insalubre",
        fieldName: "agenteInsalubre",
        lista: [
            { label: 'Biológicos', value: 'Biológicos' },
            { label: 'Físicos', value: 'Físicos' },
            { label: 'Químicos', value: 'Químicos' }
        ]
    },

    graudaInsalubridade: {
        columnName: "Grau da Insalubridade",
        fieldName: "graudaInsalubridade",
        lista: [
            { label: '10%', value: '10%' },
            { label: '20%', value: '20%' },
            { label: '40%', value: '40%' }
        ]
    },


    resultadoPericulosidade: {
        columnName: "Resultado Periculosidade",
        fieldName: "resultadoPericulosidade",
        lista: [
            { label: 'Há periculosidade', value: 'Há periculosidade' },
            { label: 'Não há periculosidade', value: 'Não há periculosidade' }
        ]
    },

    agentePericulosidade: {
        columnName: "# Agente Periculosidade",
        fieldName: "agentePericulosidade",
        lista: [
            { label: 'Explosivos', value: 'Explosivos' },
            { label: 'Radiações ionizantes ou substâncias radioativas', value: 'Radiações ionizantes ou substâncias radioativas' },
            { label: 'Exposição a roubos ou outras violências físicas', value: 'Exposição a roubos ou outras violências físicas' },
            { label: 'Energia elétrica', value: 'Energia elétrica' },
            { label: 'Uso de motocicleta', value: 'Uso de motocicleta' }
        ]
    },

    resultadoDoenca: {
        columnName: "Resultado Doença?",
        fieldName: "resultadoDoenca",
        lista: [
            { label: 'Há nexo causal', value: 'Há nexo causal' },
            { label: 'Há nexo concausal', value: 'Há nexo concausal' },
            { label: 'Não há nexo causal', value: 'Não há nexo causal' }
        ]
    },

    resultadoAcidente: {
        columnName: "Resultado Acidente",
        fieldName: "resultadoAcidente",
        lista: [
            { label: 'Reconhecido o acidente de trabalho', value: 'Reconhecido o acidente de trabalho' },
            { label: 'Não reconhecido o acidente de trabalho', value: 'Não reconhecido o acidente de trabalho' }
        ]
    },

    houveReformanoAcordaodeRO: {
        columnName: "Houve reforma no Acórdão de RO?",
        fieldName: "houveReformanoAcordaodeRO",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' }
        ]
    },

    acordaoReformouparaImprocedencia: {
        columnName: "Acórdão reformou para improcedência?",
        fieldName: "acordaoReformouparaImprocedencia",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    houveReformaNoAcordaoRR: {
        columnName: "Houve reforma no acórdão RR? Se sim, qual",
        fieldName: "houveReformaNoAcordaoRR",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' },
            { label: 'Acordo homologado antes do momento', value: 'Acordo homologado antes do momento' }
        ]
    },

    rrAdmitido: {
        columnName: "RR Admitido?",
        fieldName: "rrAdmitido",
        lista: [
            { label: 'Admitido', value: 'Admitido' },
            { label: 'Não admitido', value: 'Não admitido' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' }
        ]
    },

    deQuemFoiCalcHomologado: {
        columnName: "De quem foi o calc Homologado",
        fieldName: "deQuemFoiCalcHomologado",
        lista: [
            { label: 'Reclamada', value: 'Reclamada' },
            { label: 'Reclamante', value: 'Reclamante' },
            { label: 'Perito', value: 'Perito' },
            { label: 'Vara/Secretaria', value: 'Vara/Secretaria' }
        ]
    },

    status: {
        columnName: "Status",
        fieldName: "Status",
        lista: [
            { label: 'Ativo', value: 'Ativo' },
            { label: 'Baixado', value: 'Baixado' },
            { label: 'Encerrado', value: 'Encerrado' },
            { label: 'Suspenso', value: 'Suspenso' }
        ]
    },


    fase: {
        columnName: "Fase",
        fieldName: "Fase",
        lista: [
            { label: 'Conhecimento', value: 'Conhecimento' },
            { label: 'Recursal', value: 'Recursal' },
            { label: 'Execução', value: 'Execução' },
            { label: 'todas', value: 'todas' }
        ]
    },
}

export const modeloTresData = {
    revelia: {
        columnName: "Revelia?",
        fieldName: "revelia",
        lista: [
            { label: 'Sim', value: 'Sim' },
            { label: 'Não', value: 'Não' },
            { label: 'Sim - Outra reclamada', value: 'Sim - Outra reclamada' },
            { label: 'Momento não alcançado', value: 'Momento não alcançado' },
        ],
    },
}

export const tipoArquivo = [
    { label: "Cálculo", value: "Cálculo" },
    { label: "Cópia", value: "Cópia" },
    { label: "Base de Provisão", value: "Base de Provisão" },
    { label: "Plano de Projeto", value: "Plano de Projeto" },
    { label: "Outros", value: "Outros" }
]

export const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});

export function dataStatus(dados) {

    let dataPizza = null;
    let dadosagrupados = null;

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.status]) {
                acumulador[dado.status] = [];
            }
            acumulador[dado.status].push(dado)
            return acumulador;
        }, {})
    }

    dataPizza = {
        options3: {
            labels: ['Ativo', 'Baixado', 'Encerrado', 'Suspenso'],
            dataLabels: {
                enabled: true,
                formatter: function (val, opt) {
                    return val.toFixed(2) + "%" + " " + "(" + opt.w.config.series[opt.seriesIndex] + ")"
                },
            },
            title: {
                text: 'Status Processual',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            },
            colors: ['#203864', '#B4C7E7', '#8FAADC', '#4472C4']
        },
        series3: [dadosagrupados.Ativo?.length ? dadosagrupados.Ativo.length : 0,
        dadosagrupados.Baixado?.length ? dadosagrupados.Baixado.length : 0,
        dadosagrupados.Encerrado?.length ? dadosagrupados.Encerrado.length : 0,
        dadosagrupados.Suspenso?.length ? dadosagrupados.Suspenso.length : 0]
    }

    return dataPizza;
}

export function dataFase(dados) {

    let dataDonnut = null;
    let dadosagrupados = null;

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.fase]) {
                acumulador[dado.fase] = [];
            }
            acumulador[dado.fase].push(dado)
            return acumulador;
        }, {})
    }



    dataDonnut = {
        options3: {
            labels: ['Conhecimento', 'Recursal', 'Execução'],
            colors: ['#203864', '#4472C4', '#B4C7E7'],
            dataLabels: {
                enabled: true,
                formatter: function (val, opt) {
                    return val.toFixed(2) + "%" + " " + "(" + opt.w.config.series[opt.seriesIndex] + ")"
                },
            },
            title: {
                text: 'Fase Processual',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            }
        },
        series3: [dadosagrupados['1 - Conhecimento']?.length ? dadosagrupados['1 - Conhecimento'].length : 0,
        dadosagrupados['2 - Recursal']?.length ? dadosagrupados['2 - Recursal'].length : 0,
        dadosagrupados['3 - Execução']?.length ? dadosagrupados['3 - Execução'].length : 0]
    }

    return dataDonnut;
}

export function dataAgressoresValor(dados) {

    let dataQtdAgressores = null;
    let dadosagrupados = null;
    const categories = [];
    let _data1 = [];

    let _data2 = [];
    let categoria2 = [];

    for (let dado of dados) {
        if (!categories.includes(dado.advogadoReverso_oab)) {
            categories.push(dado.advogadoReverso_oab);
        }
    }

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.advogadoReverso_oab]) {
                acumulador[dado.advogadoReverso_oab] = [];
            }
            acumulador[dado.advogadoReverso_oab].push(dado)
            return acumulador;
        }, {})
    }

    categories.forEach(cat => {
        let _valor = 0;
        dadosagrupados[cat].forEach(dado => {
            _valor = Number(_valor) + Number(dado.riscoDCliente)
        })

        _data1.push({ cat: cat, valor: _valor });
    })

    let nData = [..._data1.sort(function (a, b) {
        return b.valor - a.valor;
    })
    ]

    nData.forEach((item) => {
        categoria2.push(item.cat)
    })

    nData.forEach((item) => {
        _data2.push(item.valor)
    })

    let _topQuinzeCategoria = [...categoria2]
    let _topQuinze = [..._data2]

    if (_data2.length > 14) {
        _topQuinzeCategoria = _topQuinzeCategoria.slice(0, 15)
        _topQuinze = _topQuinze.slice(0, 15)
    }

    dataQtdAgressores = {
        options3: {
            chart: {
                id: 'apexchart-example'
            },
            title: {
                text: 'Advogados Agressores por Valor',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            },
            colors: ['#203864'],
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'bottom'
                    }
                },
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                    colors: ['#fff']
                },
                formatter: function (val, opt) {
                    return currencyFormatter.format(val);
                },
                offsetX: 0,
                dropShadow: {
                    enabled: true
                }
            },
            xaxis: {
                categories: _topQuinzeCategoria,
                labels: {
                    show: false,
                }
            }
        },
        series3: [{
            name: 'Valor da Causa',
            data: _topQuinze
        }]
    }

    return dataQtdAgressores;
}

export function dataAgressoresQuantidade(dados) {
    let dataValorAgressores = null;
    let dadosagrupados = null;
    const categories = [];
    let _data1 = [];

    let _data2 = [];
    let categoria2 = [];

    for (let dado of dados) {
        if (!categories.includes(dado.advogadoReverso_oab)) {
            categories.push(dado.advogadoReverso_oab);
        }
    }

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.advogadoReverso_oab]) {
                acumulador[dado.advogadoReverso_oab] = [];
            }
            acumulador[dado.advogadoReverso_oab].push(dado)
            return acumulador;
        }, {})
    }

    categories.forEach(cat => {
        _data1.push({ cat: cat, valor: dadosagrupados[cat].length });
    })


    let nData = [..._data1.sort(function (a, b) {
        return b.valor - a.valor;
    })
    ]

    nData.forEach((item) => {
        categoria2.push(item.cat)
    })

    nData.forEach((item) => {
        _data2.push(item.valor)
    })

    let _topQuinzeCategoria = [...categoria2]
    let _topQuinze = [..._data2]

    if (_data2.length > 14) {
        _topQuinzeCategoria = _topQuinzeCategoria.slice(0, 15)
        _topQuinze = _topQuinze.slice(0, 15)
    }

    dataValorAgressores = {
        options3: {
            chart: {
                id: 'apexchart-example'
            },
            title: {
                text: 'Advogados Agressores por Quantidade',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            },
            colors: ['#203864'],
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            xaxis: {
                categories: _topQuinzeCategoria,
                labels: {
                    show: false,
                }
            }
        },
        series3: [{
            name: 'Quantidade',
            data: _topQuinze
        }]
    }

    return dataValorAgressores;
}

export function dataEcQuantidade(dados) {
    let dataValorAgressores = null;
    let dadosagrupados = null;
    const categories = [];
    let _data1 = [];
    let _data2 = [];
    let categoria2 = [];


    for (let dado of dados) {
        if (!categories.includes(dado.ecBase)) {
            categories.push(dado.ecBase);
        }
    }

    const arraySemNulos = categories.filter((elemento) => elemento !== null);

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.ecBase]) {
                acumulador[dado.ecBase] = [];
            }
            acumulador[dado.ecBase].push(dado)
            return acumulador;
        }, {})
    }

    arraySemNulos.forEach(cat => {
        _data1.push({ cat: cat, valor: dadosagrupados[cat].length });
    })

    let nData = [..._data1.sort(function (a, b) {
        return b.valor - a.valor;
    })
    ]

    nData.forEach((item) => {
        categoria2.push(item.cat)
    })

    nData.forEach((item) => {
        _data2.push(item.valor)
    })

    let _topQuinzeCategoria = [...categoria2]
    let _topQuinze = [..._data2]

    if (_data2.length > 14) {
        _topQuinzeCategoria = _topQuinzeCategoria.slice(0, 15)
        _topQuinze = _topQuinze.slice(0, 15)
    }

    dataValorAgressores = {
        options3: {
            chart: {
                id: 'apexchart-example'
            },
            colors: ['#203864'],
            title: {
                text: 'Escritorio de Base por Quantidade',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            xaxis: {
                categories: _topQuinzeCategoria,
                labels: {
                    show: false,
                }
            }
        },
        series3: [{
            name: 'Quantidade',
            data: _topQuinze
        }]
    }

    return dataValorAgressores;
}

export function dataEcValor(dados) {

    let dataValorEC = null;
    let dadosagrupados = null;
    const categories = [];
    let _data1 = [];

    let _data2 = [];
    let categoria2 = [];

    for (let dado of dados) {
        if (!categories.includes(dado.ecBase)) {
            categories.push(dado.ecBase);
        }
    }

    const arraySemNulos = categories.filter((elemento) => elemento !== null);

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.ecBase]) {
                acumulador[dado.ecBase] = [];
            }
            acumulador[dado.ecBase].push(dado)
            return acumulador;
        }, {})
    }

    arraySemNulos.forEach(cat => {
        let _valor = 0;
        dadosagrupados[cat].forEach(dado => {
            _valor = Number(_valor) + Number(dado.riscoDCliente)
        })
        _data1.push({ cat: cat, valor: _valor });
    })

    let nData = [..._data1.sort(function (a, b) {
        return b.valor - a.valor;
    })
    ]

    nData.forEach((item) => {
        categoria2.push(item.cat)
    })

    nData.forEach((item) => {
        _data2.push(item.valor)
    })

    let _topQuinzeCategoria = [...categoria2]
    let _topQuinze = [..._data2]

    if (_data2.length > 14) {
        _topQuinzeCategoria = _topQuinzeCategoria.slice(0, 15)
        _topQuinze = _topQuinze.slice(0, 15)
    }

    dataValorEC = {
        options3: {
            chart: {
                id: 'apexchart-example'
            },
            colors: ['#203864'],
            title: {
                text: 'Escritorio de Base por Valor',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'bottom'
                    }
                },
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                    colors: ['#fff']
                },
                formatter: function (val, opt) {
                    return currencyFormatter.format(val);
                },
                offsetX: 0,
                dropShadow: {
                    enabled: true
                }
            },
            xaxis: {
                categories: _topQuinzeCategoria,
                labels: {
                    show: false,
                }
            }
        },
        series3: [{
            name: 'Valor da Causa',
            data: _topQuinze
        }]
    }

    return dataValorEC;
}

export function dataUF(dados) {

    let dataQtdUF = null;
    let dadosagrupados = null;
    const categories = [];
    const _data1 = [];

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.uf]) {
                acumulador[dado.uf] = [];
            }
            acumulador[dado.uf].push(dado)
            return acumulador;
        }, {})
    }

    for (let dado of dados) {
        if (!categories.includes(dado.uf)) {
            categories.push(dado.uf);
        }
    }

    categories.forEach(cat => {
        let _valor = 0;
        dadosagrupados[cat].forEach(dado => {
            _valor = _valor + 1
        })
        _data1.push({
            x: cat,
            y: _valor
        });
    })

    _data1.sort(function (a, b) {
        return b.y - a.y;
    })

    let _topQuinzeCategoria = [...categories]
    let _topQuinze = [..._data1]

    if (_data1.length > 14) {
        _topQuinzeCategoria = _topQuinzeCategoria.slice(0, 15)
        _topQuinze = _topQuinze.slice(0, 15)
    }

    dataQtdUF = {
        options3: {
            chart: {
                id: 'Processos_por_UF'
            },
            colors: ['#203864'],
            title: {
                text: 'Processos por UF',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'bottom'
                    }
                },
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                    colors: ['#fff']
                },
                offsetX: 0,
                dropShadow: {
                    enabled: true
                }
            },
        },
        series3: [{
            name: 'Valor da Causa',
            data: _topQuinze
        }]
    }

    return dataQtdUF;
}

export function dataComarca(dados) {
    let dataQtdCidade = null;
    let dadosagrupados = null;
    const categories = [];
    const _data1 = [];

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.cidade]) {
                acumulador[dado.cidade] = [];
            }
            acumulador[dado.cidade].push(dado)
            return acumulador;
        }, {})
    }

    for (let dado of dados) {
        if (!categories.includes(dado.cidade)) {
            categories.push(dado.cidade);
        }
    }

    categories.forEach(cat => {
        let _valor = 0;
        dadosagrupados[cat].forEach(dado => {
            _valor = _valor + 1
        })
        _data1.push({
            x: cat,
            y: _valor
        });
    })

    _data1.sort(function (a, b) {
        return b.y - a.y;
    })

    let _topQuinzeCategoria = [...categories]
    let _topQuinze = [..._data1]

    if (_data1.length > 14) {
        _topQuinzeCategoria = _topQuinzeCategoria.slice(0, 15)
        _topQuinze = _topQuinze.slice(0, 15)
    }

    dataQtdCidade = {
        options3: {
            chart: {
                id: 'Processos_por_UF'
            },
            colors: ['#203864'],
            title: {
                text: 'Processos por Comarca',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'bottom'
                    }
                },
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                    colors: ['#fff']
                },
                offsetX: 0,
                dropShadow: {
                    enabled: true
                }
            },
        },
        series3: [{
            name: 'Valor da Causa',
            data: _topQuinze
        }]
    }

    return dataQtdCidade;
}


export function dataTimeLineConhecimento(dados) {

    let dataTimeLine = null;
    let dadosagrupados = null;
    let dadosagrupadosTotal = null;
    const categories = [];
    let _data1 = [];
    let _data2 = [];

    for (let dado of dados) {
        if (!categories.includes(dado.momento)) {
            categories.push(dado.momento);
        }
    }

    categories.sort()

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.momento]) {
                acumulador[dado.momento] = [];
            }
            acumulador[dado.momento].push(dado)
            return acumulador;
        }, {})
    }

    categories.forEach(cat => {
        let _valor = 0;
        dadosagrupados[cat].forEach(dado => {
            _valor = Number(_valor) + Number(dado.riscoDCliente)
        })
        _data1.push(calcValorDivididoPorMil(_valor));
    })

    categories.forEach(cat => {
        let _valor = 0;
        dadosagrupados[cat].forEach(dado => {
            _valor = _valor + 1
        })
        _data2.push(_valor.toFixed(0));
    })
    // let tamanho = Math.max.apply(null, _data1)
    dataTimeLine = {
        options3: {
            chart: {
                id: "basic-bar",
                toolbar: {
                    show: true
                },
                type: 'line',
            },
            title: {
                text: 'Timeline Conhecimento',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            },
            legend: {
                position: 'top'
            },
            stroke: {
                width: [4, 0, 0]
            },
            xaxis: {
                categories: categories,
                labels: {
                    show: true,
                    rotate: -50,
                    rotateAlways: true,
                    hideOverlappingLabels: true,
                    minHeight: 180,
                    maxHeight: 400,
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                },
            },
            colors: ['#203864', '#95b3d7'],
            yaxis: [{
                title: {
                    text: '$ Envolvido - Provavel (/1000)',
                },
            }, {
                opposite: true,
                title: {
                    text: '# Proc'
                }
            }]
        },
        series3: [{
            name: "$ Envolvido - Próvavel (/1000)",
            type: "line",
            data: _data1
        },
        {
            name: "# Proc.",
            type: "column",
            data: _data2
        }]
    }

    return dataTimeLine;
}

const calcValorDivididoPorMil = (valor) => {
    const _valor = valor / 1000
    return _valor.toFixed(0)
}


export function dataTimeLineRecursal(dados) {

    let dataTimeLine = null;
    let dadosagrupados = null;
    let dadosagrupadosTotal = null;
    const categories = [];
    let _data1 = [];
    let _data2 = [];

    for (let dado of dados) {
        if (!categories.includes(dado.momento)) {
            categories.push(dado.momento);
        }
    }

    categories.sort()

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.momento]) {
                acumulador[dado.momento] = [];
            }
            acumulador[dado.momento].push(dado)
            return acumulador;
        }, {})
    }

    categories.forEach(cat => {
        let _valor = 0;
        dadosagrupados[cat].forEach(dado => {
            _valor = Number(_valor) + Number(dado.riscoDCliente)
        })
        _data1.push(calcValorDivididoPorMil(_valor));
    })

    categories.forEach(cat => {
        let _valor = 0;
        dadosagrupados[cat].forEach(dado => {
            _valor = _valor + 1
        })
        _data2.push(_valor.toFixed(0));
    })
    // let tamanho = Math.max.apply(null, _data1)
    dataTimeLine = {
        options3: {
            chart: {
                id: "basic-bar",
                toolbar: {
                    show: true
                },
                type: 'line',
            },
            title: {
                text: 'Timeline Recursal',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            },
            legend: {
                position: 'top'
            },
            stroke: {
                width: [4, 0, 0]
            },
            xaxis: {
                categories: categories,
                labels: {
                    show: true,
                    rotate: -45,
                    rotateAlways: true,
                    hideOverlappingLabels: true,
                    minHeight: 180,
                    maxHeight: 400,
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                },
            },
            colors: ['#203864', '#376092'],
            yaxis: [{
                title: {
                    text: '$ Envolvido - Provavel (/1000)',
                },
            }, {
                opposite: true,
                title: {
                    text: '# Proc'
                }
            }]
        },
        series3: [{
            name: "$ Envolvido - Próvavel (/1000)",
            type: "line",
            data: _data1
        },
        {
            name: "# Proc.",
            type: "column",
            data: _data2
        }]
    }

    return dataTimeLine;
}

export function dataTimeLineExecucao(dados) {

    let dataTimeLine = null;
    let dadosagrupados = null;
    let dadosagrupadosTotal = null;
    const categories = [];
    let _data1 = [];
    let _data2 = [];

    for (let dado of dados) {
        if (!categories.includes(dado.momento)) {
            categories.push(dado.momento);
        }
    }

    categories.sort()

    if (dados) {
        dadosagrupados = dados.reduce(function (acumulador, dado) {
            if (!acumulador[dado.momento]) {
                acumulador[dado.momento] = [];
            }
            acumulador[dado.momento].push(dado)
            return acumulador;
        }, {})
    }

    categories.forEach(cat => {
        let _valor = 0;
        dadosagrupados[cat].forEach(dado => {
            _valor = Number(_valor) + Number(dado.riscoDCliente)
        })
        _data1.push(calcValorDivididoPorMil(_valor));
    })

    categories.forEach(cat => {
        let _valor = 0;
        dadosagrupados[cat].forEach(dado => {
            _valor = _valor + 1
        })
        _data2.push(_valor.toFixed(0));
    })
    // let tamanho = Math.max.apply(null, _data1)
    dataTimeLine = {
        options3: {
            chart: {
                id: "basic-bar",
                toolbar: {
                    show: true
                },
                type: 'line',
            },
            title: {
                text: 'Timeline Execução',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#263238'
                },
            },
            legend: {
                position: 'top'
            },
            stroke: {
                width: [4, 0, 0]
            },
            xaxis: {
                categories: categories,
                labels: {
                    show: true,
                    rotate: -45,
                    rotateAlways: true,
                    hideOverlappingLabels: false,
                    minHeight: 180,
                    maxHeight: 400,
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                },
            },
            colors: ['#203864', '#8064a2'],
            yaxis: [{
                title: {
                    text: '$ Envolvido - Provavel (/1000)',
                },
            }, {
                opposite: true,
                title: {
                    text: '# Proc'
                }
            }]
        },
        series3: [{
            name: "$ Envolvido - Próvavel (/1000)",
            type: "line",
            data: _data1
        },
        {
            name: "# Proc.",
            type: "column",
            data: _data2
        }]
    }

    return dataTimeLine;
}

export function filtrarDados(baseCliente, status, uf, fase, advogado, dados) {


    var dadosfiltrados = dados

    function retornabaseCliente(value) {
        if (baseCliente === 'Todas') {
            return value;
        }
        else {
            if (value.baseCliente === baseCliente)
                return value;
        }
    }

    function retornaStatus(value) {
        if (status === 'Todas') {
            return value;
        }
        else {
            if (value.status === status)
                return value;
        }
    }

    function retornaUF(value) {
        if (uf === 'Todas') {
            return value;
        }
        else {
            if (value.uf === uf)
                return value;
        }
    }

    function retornaFase(value) {
        if (fase === 'Todas') {
            return value;
        }
        else {
            if (value.fase === fase)
                return value;
        }
    }

    function retornaAdvogado(value) {
        if (advogado === 'Todas' || advogado.label === 'Todas' || advogado.label === '') {
            return value;
        }
        else {
            if (value.advogado === advogado.label)
                return value;
        }
    }

    dadosfiltrados = dados.filter(retornabaseCliente);
    dadosfiltrados = dadosfiltrados.filter(retornaStatus);
    dadosfiltrados = dadosfiltrados.filter(retornaUF);
    dadosfiltrados = dadosfiltrados.filter(retornaFase);
    dadosfiltrados = dadosfiltrados.filter(retornaAdvogado);

    if (dadosfiltrados.length <= 0) {
        toast.error('Não foram encontrados dados com os filtros selecionados')
    }
    else {
        toast.success('Dados filtrados')
    }

    return dadosfiltrados;

}


export function dataTabelaStatus(dados) {

    let dadosagrupados = null;

    if (dados) {
        dadosagrupados = dados.reduce((acc, item) => {
            if (!acc[item.status]) {
                acc[item.status] = 1;
            } else {
                acc[item.status] += 1;
            }
            return acc;
        }, {});
    }

    const tabela = Object.keys(dadosagrupados).map((status) => ({
        id: uuidv4(),
        status,
        quantidade: dadosagrupados[status]
      }));


    return tabela;
}

export function dataTabelaStatusInterno(dados) {

    let dadosagrupados = null;

    if (dados) {
        dadosagrupados = dados.reduce((acc, item) => {
            if (!acc[item.status_pre]) {
                acc[item.status_pre] = 1;
            } else {
                acc[item.status_pre] += 1;
            }
            return acc;
        }, {});
    }

    const tabela = Object.keys(dadosagrupados).map((status) => ({
        id: uuidv4(),
        status,
        quantidade: dadosagrupados[status]
      }));


    return tabela;
}

export const uflist = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
];
