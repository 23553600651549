import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/auth'
import { ImportacaoContext } from '../../contexts/importacao'
import { CrudContext } from '../../contexts/crud';
import Chart from 'react-apexcharts'
import {
  dataStatus,
  dataFase,
  dataAgressoresQuantidade,
  dataAgressoresValor,
  dataEcQuantidade,
  dataEcValor,
  dataUF,
  dataComarca,
  dataTimeLineConhecimento,
  dataTimeLineRecursal,
  dataTimeLineExecucao,
  filtrarDados,
  uflist
} from '../../utils/data'
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { MenuItem } from '@mui/material';
import { TextField, Autocomplete } from '@mui/material'
import LoadingIcons from 'react-loading-icons'

import Header from '../../Components/Header'

import 'react-toastify/dist/ReactToastify.css';

import './dashboard.css'

function DashPadrao() {

  const { user, isHumburguerActive } = useContext(AuthContext);
  const { getImportacao, modeloUm, load, setLoad } = useContext(ImportacaoContext);
  const { getEmpresas, empresas, getAdvogado, advogadosList } = useContext(CrudContext);

  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState(null);
  const [data4, setData4] = useState(null);
  const [data5, setData5] = useState(null);
  const [data6, setData6] = useState(null);
  const [data7, setData7] = useState(null);
  const [data8, setData8] = useState(null);
  const [data9, setData9] = useState(null);
  const [data10, setData10] = useState(null);
  const [data11, setData11] = useState(null);
  const [controle, setControle] = useState(false);
  const [empresa, setEmpresa] = useState('Todas');
  const [status, setStatus] = useState('Todas');
  const [uf, setUf] = useState('Todas');
  const [fase, setFase] = useState('Todas');
  const [advogado, setAdvogado] = useState('Todas');
  // const [loading, setLoading] = useState(true)

  const [filterEmpresaAdm, setFilterEmpresaAdm] = useState(null)

  useEffect(() => {
    getImportacao();
    setControle(true);
    getEmpresas();
    getAdvogado();
  }, [])

  useEffect(() => {
    let _dados = modeloUm;
    const modeloUmConhecimento = modeloUm.filter(item => item.fase === "1 - Conhecimento")
    const modeloUmRecursal = modeloUm.filter(item => item.fase === "2 - Recursal")
    const modeloUmExecucao = modeloUm.filter(item => item.fase === "3 - Execução")

    setFilterEmpresaAdm(user?.tipoUsuario === "Cliente" ? user.cliente : empresas);

    if (_dados.length > 0 && controle) {
      setData1(dataStatus(modeloUm));
      setData2(dataFase(modeloUm));
      setData4(dataAgressoresQuantidade(modeloUm));
      setData5(dataAgressoresValor(modeloUm));
      setData6(dataEcQuantidade(modeloUm));
      setData7(dataEcValor(modeloUm));
      setData8(dataUF(modeloUm));
      setData9(dataComarca(modeloUm));
      setData3(dataTimeLineConhecimento(modeloUmConhecimento));
      setData10(dataTimeLineRecursal(modeloUmRecursal))
      setData11(dataTimeLineExecucao(modeloUmExecucao))
      setControle(false);
    }


  }, [modeloUm])

  function handledEmpresa(e) {
    setEmpresa(e.target.value);
  }

  function handledStatus(e) {
    setStatus(e.target.value);
  }

  function handledUF(e) {
    setUf(e.target.value);
  }

  function handledFase(e) {
    setFase(e.target.value);
  }

  function handledAdvogado(e) {
    setAdvogado(e.target.value);
  }


  function recebeFiltro() {

    let dadosFiltrados = filtrarDados(empresa, status, uf, fase, advogado, modeloUm);
    const modeloUmConhecimento = dadosFiltrados.filter(item => item.fase === "1 - Conhecimento")
    const modeloUmRecursal = dadosFiltrados.filter(item => item.fase === "2 - Recursal")
    const modeloUmExecucao = dadosFiltrados.filter(item => item.fase === "3 - Execução")

    if (dadosFiltrados.length > 0) {
      setData1(dataStatus(dadosFiltrados));
      setData2(dataFase(dadosFiltrados));
      setData4(dataAgressoresQuantidade(dadosFiltrados));
      setData5(dataAgressoresValor(dadosFiltrados));
      setData6(dataEcQuantidade(dadosFiltrados));
      setData7(dataEcValor(dadosFiltrados));
      setData8(dataUF(dadosFiltrados));
      setData9(dataComarca(dadosFiltrados));
      setData3(dataTimeLineConhecimento(modeloUmConhecimento));
      setData10(dataTimeLineRecursal(modeloUmRecursal));
      setData11(dataTimeLineExecucao(modeloUmExecucao));

    }
  }

  const leftToolbarTemplate = () => {
    return (
      <>
        {filterEmpresaAdm &&
          <TextField
            id="outlined-select-currency-empresa"
            select
            label="Base cliente"
            value={empresa}
            onChange={(e) => { handledEmpresa(e) }}
            margin="normal"
            variant="outlined"
            size="small"
          >
            {user.tipoUsuario !== 'Cliente' &&
              <MenuItem key={999} value={'Todas'}>
                Todas
              </MenuItem>
            }
            {filterEmpresaAdm?.map(option => (
              <MenuItem key={option.id} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        }

        <TextField
          id="outlined-select-currency-empresa"
          select
          label="Status"
          value={status}
          onChange={(e) => { handledStatus(e) }}
          margin="normal"
          variant="outlined"
          size="small"
        >
          <MenuItem key={999} value={'Todas'}>
            Todas
          </MenuItem>
          <MenuItem key={1} value={'Ativo'}>
            Ativo
          </MenuItem>
          <MenuItem key={2} value={'Baixado'}>
            Baixado
          </MenuItem>
          <MenuItem key={3} value={'Encerrado'}>
            Encerrado
          </MenuItem>
          <MenuItem key={4} value={'Suspenso'}>
            Suspenso
          </MenuItem>
        </TextField>

        <TextField
          id="outlined-select-currency-empresa"
          select
          label="UF"
          value={uf}
          onChange={(e) => { handledUF(e) }}
          margin="normal"
          variant="outlined"
          size="small"
        >
          <MenuItem key={999} value={'Todas'}>
            Todas
          </MenuItem>
          {uflist?.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="outlined-select-currency-empresa"
          select
          label="Fase"
          value={fase}
          onChange={(e) => { handledFase(e) }}
          margin="normal"
          variant="outlined"
          size="small"
        >
          <MenuItem key={999} value={'Todas'}>
            Todas
          </MenuItem>
          <MenuItem key={1} value={'1 -Conhecimento'}>
            1 - Conhecimento
          </MenuItem>
          <MenuItem key={2} value={'2 - Recursal'}>
            2 - Recursal
          </MenuItem>
          <MenuItem key={3} value={'3 - Execução'}>
            3 - Execução
          </MenuItem>
        </TextField>

        {/* <Autocomplete
          value={advogado}
          disablePortal
          options={advogadosList}
          id="size-small-standard"
          onChange={(event, newValue) => {
            setAdvogado(newValue);
          }}
          size="small"
          sx={{ width: 250, mt: 1 }}
          renderInput={(params) => <TextField {...params} variant="standard" label="Advogado" />}
        /> */}

        <Button label="Filtrar" icon="pi pi-filter-fill" className="p-button-filter" onClick={recebeFiltro} />
      </>

    )
  }


  return (
    <div className="App">
      <Header />
      <div className={`content${isHumburguerActive ? '-active' : ''}`}>
        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

        {load ?
          <div className='icon-loading'>
            <LoadingIcons.SpinningCircles stroke="#5499e9" />
          </div>
          : <>

            <div className='two-lines'>
              <div className='two-lines-item'>
                <div className='one-lines-item'>
                  {data1 &&
                    <Chart options={data1.options3} series={data1.series3} type="donut" width={'100%'} height={400} />
                  }
                </div>
              </div>
              <div className='two-lines-item'>
                <div className='one-lines-item'>
                  {data2 &&
                    <Chart options={data2.options3} series={data2.series3} type="pie" width={'100%'} height={400} />
                  }
                </div>
              </div>
            </div>

            <div className='three-lines'>
              {data3 &&
                <div className='three-lines-item'>
                  <Chart
                    options={data3.options3}
                    series={data3.series3}
                    type="line"
                    width={'100%'} height={600}
                  />
                </div>
              }

              {data10 &&
                <div className='three-lines-item'>
                  <Chart
                    options={data10.options3}
                    series={data10.series3}
                    type="line"
                    width={'100%'} height={600}
                  />
                </div>
              }

              {data11 &&
                <div className='three-lines-item'>
                  <Chart
                    options={data11.options3}
                    series={data11.series3}
                    type="line"
                    width={'100%'} height={600}
                  />
                </div>
              }
            </div>

            <div className='two-lines'>
              <div className='two-lines-item'>

                <div className='one-lines-item'>
                  {data4 &&
                    <Chart options={data4.options3} series={data4.series3} type="bar" width={'100%'} height={400} />
                  }
                </div>
              </div>
              <div className='two-lines-item'>

                <div className='one-lines-item'>
                  {data5 &&
                    <Chart options={data5.options3} series={data5.series3} type="bar" width={'100%'} height={400} />
                  }
                </div>
              </div>
            </div>
            <div className='two-lines'>
              <div className='two-lines-item'>

                <div className='one-lines-item'>
                  {data6 &&
                    <Chart options={data6.options3} series={data6.series3} type="bar" width={'100%'} height={400} />
                  }
                </div>
              </div>
              <div className='two-lines-item'>

                <div className='one-lines-item'>
                  {data7 &&
                    <Chart options={data7.options3} series={data7.series3} type="bar" width={'100%'} height={400} />
                  }
                </div>
              </div>
            </div>
            <div className='two-lines'>
              <div className='two-lines-item'>

                <div className='one-lines-item'>
                  {data8 &&
                    <Chart options={data8.options3} series={data8.series3} type="bar" width={'100%'} height={400} />
                  }
                </div>
              </div>
              <div className='two-lines-item'>

                <div className='one-lines-item'>
                  {data9 &&
                    <Chart options={data9.options3} series={data9.series3} type="bar" width={'100%'} height={400} />
                  }
                </div>
              </div>
            </div>

          </>}

      </div>
    </div>
  );
}
export default DashPadrao;
