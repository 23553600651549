import './escritorio.css';

import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'
import { toast } from 'react-toastify'
import { FilterMatchMode } from 'primereact/api';
import { TextField, MenuItem, FormControl, InputLabel, Select, OutlinedInput } from '@mui/material';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import Box from '@mui/material/Box'
import DataGridCustom from '../../Components/DataGrid/DataGridCustom'

function CadastroDepartamento() {

    const { isHumburguerActive, usuarios, getAllUsers } = useContext(AuthContext);
    const {
        saveDepartamento,
        deleteDepartamento,
        getDepartamento,
        departamento,
        alterDepartamento, formConteudo
    } = useContext(CrudContext);

    const [dicionario, setdicionario] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [newProductsDialog, setNewProductsDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [_departamento, _setDepartamento] = useState();
    const [fase, setFase] = useState();

    const [productDialog, setProductDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false);

    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const [lideres, setLideres] = useState([]);

    useEffect(() => {
        initFilters1();
        getDepartamento()
        getAllUsers(1);
    }, [])

    useEffect(() => {
        setdicionario(departamento)
    }, [departamento])

    useEffect(() => {
        setdicionario(departamento)
    }, [])
    // --------------------------------------------------------------------------------------
    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveProduct(e)}
            />
        </React.Fragment>
    );

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setNewProductsDialog(!newProductsDialog)
    };

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

        });
        setGlobalFilterValue1('');
    }

    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                {/* <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} /> */}
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Pesquisar" />
                </span>
            </div>
        )
    }

    const header1 = renderHeader1();

    const saveProduct = (e) => {
        setSubmitted(true);
        handlesaveDepartamento(e)
        setNewProductsDialog(!newProductsDialog)
    }

    // ---------------------------------------------------------------------------------------


    async function handlesaveDepartamento(e) {
        e.preventDefault();
        if (_departamento) {
            await saveDepartamento(_departamento, lideres);
            _setDepartamento('');
            setFase('');
        }
        else {
            toast.error('Preencha todos os campos')
        }
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const openNew = () => {
        setNewProductsDialog(true)
        _setDepartamento()
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProduct = () => {
        deleteDepartamento(selectedCustomers);

        setDeleteProductsDialog(false);
    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />
                <Button label="Editar" icon="pi pi-check" className="p-button-alert" onClick={() => confirmeEditar()} disabled={selectedCustomers?.length === 1 ? false : true} />
            </React.Fragment>
        )
    }

    const confirmeEditar = () => {
        setIsEdit(!isEdit)
        const filterDepartamento = dicionario.filter(item => item._id === selectedCustomers[0])[0]
        _setDepartamento(filterDepartamento.Descricao)
        // setDataItemEdit()
    }


    //CRIAR REGRA PARA EDITAR NA GRID IGUAL DO PROCESSOS editable: true
    const columns = [
        { field: 'id', headerName: 'id', width: 50 },
        { field: 'Descricao', headerName: 'Descrição', width: 250 },
        { field: 'Lideres', headerName: 'Lideres', width: 250 }
    ]

    const productDialogFooterAlter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialogAlter()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => {
                    alterDepartamento({ id: selectedCustomers[0], Descricao: _departamento })

                    setIsEdit(!isEdit)
                }}
            />
        </React.Fragment>
    );


    const hideDialogAlter = () => {
        setIsEdit(!isEdit)
    }


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setLideres(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                {/* <Title nome="Cadastro de Escritório">
                </Title> */}

                <div className="container-profile">
                    {/* ------------------------------------------ */}
                    {newProductsDialog &&
                        <Dialog
                            visible={newProductsDialog}
                            style={{ width: "450px" }}
                            header="Novo registro"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Descrição"
                                    value={_departamento}
                                    onChange={(e) => _setDepartamento(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="field">
                                <FormControl fullWidth margin="normal" variant="outlined">
                                    <InputLabel id="multiple-lider-label">Lider</InputLabel>
                                    <Select
                                        labelId="multiple-lider-label"
                                        id="multiple-lider"
                                        multiple
                                        value={lideres}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Lider" />}
                                        renderValue={(selected) => selected.map((value) => usuarios.find(option => option.key === value).nome).join(', ')}
                                    >
                                        {usuarios.map((option) => (
                                            <MenuItem key={option.key} value={option.key}>
                                                {option.nome}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Dialog>
                    }

                    {isEdit &&
                        <Dialog
                            visible={isEdit}
                            style={{ width: "450px" }}
                            header="Editar departamento"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooterAlter}
                            onHide={hideDialogAlter}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Descrição"
                                    value={_departamento}
                                    onChange={(e) => _setDepartamento(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="field">
                                <FormControl fullWidth margin="normal" variant="outlined">
                                    <InputLabel id="multiple-lider-label">Lider</InputLabel>
                                    <Select
                                        labelId="multiple-lider-label"
                                        id="multiple-lider"
                                        multiple
                                        value={lideres}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Lider" />}
                                        renderValue={(selected) => selected.map((value) => usuarios.find(option => option.key === value).nome).join(', ')}
                                    >
                                        {usuarios.map((option) => (
                                            <MenuItem key={option.key} value={option.key}>
                                                {option.nome}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Dialog>
                    }


                    {dicionario &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                            <Box sx={{ height: 800, width: '100%' }}>
                                <DataGridCustom
                                    column={columns}
                                    rows={dicionario}
                                />
                            </Box>
                        </div>
                    }

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {deleteProductsDialog && <span>Tem certeza que gostaria de deletar os escritórios <b>selecionados</b>?</span>}
                        </div>
                    </Dialog>

                </div>
            </div >
        </div >
    )
}


export default CadastroDepartamento;