import React, { useState, useContext, useEffect } from 'react'
import './signup.css'

import { AuthContext } from '../../contexts/auth'
import { CrudContext } from '../../contexts/crud'
import { UserType } from '../../services/list'
import { FilterMatchMode } from 'primereact/api';
import { MenuItem, TextField } from '@mui/material'
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';
import Header from '../../Components/Header'
import LoadingIcons from 'react-loading-icons'

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { toast } from 'react-toastify'
import { bodyTemplateLabel, bodyTemplateValue, statusEditorLabel } from '../../utils/index'
import Box from '@mui/material/Box'
import DataGridCustom from '../../Components/DataGrid/DataGridCustom'

function SignUp() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [efuncao, setEfuncao] = useState('');
  const [password, setPassword] = useState('123mudar');
  const [isHidden, setIsHidden] = useState(true)
  const { signUp, loadingAuth, usuarios, updateUser, getAllUsers, isHumburguerActive, removeUser, ativarUser, user } = useContext(AuthContext);
  const { getEmpresas, empresas, funcaoDepartamento, getFuncaoDepartamento, getFuncao, funcao, setFuncao, formConteudo } = useContext(CrudContext);
  const [tipoUsuario, setTipoUsuario] = useState({});
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [ativarProductsDialog, setAtivarProductsDialog] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);
  const [filteredCompanies, setFilteredCompanies] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState(null);
  const [opcaoAtivo, setOpcaoAtivo] = useState(1);
  const [selCompanies, setSeledCompanies] = useState(null);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState('');
  const [newProductsDialog, setNewProductsDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [filtroFuncao, setFiltroFuncao] = useState(null);
  const [isEdit, setIsEdit] = useState(false)

  const [empresa, setEmpresa] = useState()
  const [listaTipoUsuario, setListaTipoUsuario] = useState([])

  const searchCompany = (event) => {
    setTimeout(() => {
      let _filteredCompanies;
      if (!event.query.trim().length) {
        _filteredCompanies = [...empresas];
      }
      else {
        _filteredCompanies = empresas.filter((country) => {
          return country.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }

      setFilteredCompanies(_filteredCompanies);
    }, 250);
  }

  useEffect(() => {
    getEmpresas();
    getAllUsers(1);
    initFilters1();
    getFuncaoDepartamento();
    getFuncao();
    setListaTipoUsuario(UserType)
  }, [])


  useEffect(() => {
    setFiltroFuncao(funcao)
  }, [funcao])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{ 
    if(formConteudo){
        setSelectedCustomers(formConteudo)
    }        
  },[formConteudo])
  // --------------------------------------------------------------------------------------
  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => hideDialog()}
      />
      <Button
        label="Salvar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={(e) => saveProduct(e)}
      />
    </React.Fragment>
  );

  const handledChange = (e) => {
    setOpcaoAtivo(e.target.value);

    getAllUsers(e.target.value);
  }

  const hideDialog = () => {
    setSubmitted(false);
    // setProductDialog(false);
    setNewProductsDialog(!newProductsDialog)
  };

  const saveProduct = (e) => {
    setSubmitted(true);
    handledSubmit(e)
    setNewProductsDialog(!newProductsDialog)

    setTimeout(() => {
      getAllUsers(1);
    }, 1000);
  }

  // ---------------------------------------------------------------------------------------
  const openNew = () => {
    setNewProductsDialog(true)
    setNome()
    setEfuncao()
    setTipoUsuario()
    setDepartamento()
    setEmail()
    setEmpresa()
  }

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  }

  const confirmAtivarSelected = () => {
    setAtivarProductsDialog(true);
  }

  const hideAtivarProductDialog = () => {
    setAtivarProductsDialog(false);
  }

  const hideDeleteProductDialog = () => {
    setDeleteProductsDialog(false);
  }

  const deleteProduct = () => {
    selectedCustomers.forEach(id => {
      removeUser(id)
    })
    toast.success('Inativado com sucesso')
    setDeleteProductsDialog(false);
  }

  const AtivarUsuario = () => {
    selectedCustomers.forEach(id => {
      ativarUser(id)
    })

    toast.success('Ativado com sucesso')

    setAtivarProductsDialog(false);
  }

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
      <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
    </React.Fragment>
  );



  const AtivaUsuarioDialogFooter = (
    <React.Fragment>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideAtivarProductDialog} />
      <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={AtivarUsuario} />
    </React.Fragment>
  );

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
        {opcaoAtivo == 1 && <Button label="Inativar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />}
        {opcaoAtivo == 0 && <Button label="Ativar" icon="pi pi-check" className="p-button-alert" onClick={confirmAtivarSelected} disabled={!selectedCustomers || !selectedCustomers.length} />}
        {opcaoAtivo == 1 &&  <Button label="Editar" icon="pi pi-check" className="p-button-alert" onClick={() => confirmeEditar()} disabled={selectedCustomers?.length === 1 ? false : true} /> }
        {/* <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} /> */}
      </React.Fragment>
    )
  }

  const confirmeEditar = () => {
    setIsEdit(!isEdit)
    const filterUsuarios = usuarios.filter( item => item.id === selectedCustomers[0])[0]
    const user = JSON.parse(localStorage.getItem("SistemaUser"))

    setNome(filterUsuarios.nome)
    setEfuncao(filterUsuarios.funcao)
    setTipoUsuario(filterUsuarios.tipoUsuario)
    setDepartamento(filterUsuarios.departamento)
    setEmail(filterUsuarios.email)
    setEmpresa(filterUsuarios.empresa[0])
  }


const productDialogFooterAlter = (
    <React.Fragment>
        <Button
            label="Cancelar"
            icon="pi pi-times"
            className="p-button-text"
            onClick={() => hideDialogAlter()}
        />
        <Button
            label="Salvar"
            icon="pi pi-check"
            className="p-button-text"
            onClick={(e) => {
              updateUser({  
                key: selectedCustomers[0],
                nome: nome,
                tipoUsuario: tipoUsuario,
                departamento: departamento,
                funcao: efuncao,
                cliente: empresa,
                email: email,
                token: user.token
              });                                    
                setIsEdit(!isEdit)
            }}
        />
    </React.Fragment>
);


const hideDialogAlter= ()=>{
    setIsEdit(!isEdit)
}

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="demo-simple-select-helper-label">Tipo</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={opcaoAtivo}
            label="Tipo"
            onChange={handledChange}
          >
            <MenuItem value={1}>Ativo</MenuItem>
            <MenuItem value={0}>Inativo</MenuItem>
          </Select>
        </FormControl>
      </React.Fragment>
    )
  }

  function filterFuncao(depto) {
    let _filter = [...funcao]
    let filtrado = _filter.filter(item => item.Departamento === depto)
    setFiltroFuncao(filtrado)
  }

  function handledSubmit(e) {
    e.preventDefault();

    if (nome === '' || email === '') {
      return toast.error("Nome, Email e Tipo de usuário obrigatório")
    }

    if (nome !== '' && email !== '') {

      let data = {
        email: email,
        name: nome,
        password: '123mudar',
        confirmPassword: '123mudar',
        userType: tipoUsuario,
        userDepartment: departamento,
        userRole: efuncao,
        company: selectedCompanies,
        avatarUrl: null
      };

      signUp(data);
    }

    setNome('');
    setEmail('');
    setPassword('');
    setEmpresa('')
    setTipoUsuario(0)
    setSelectedCompanies([]);
    setIsHidden(true)
  }


  const initFilters1 = () => {
    setFilters1({
      'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

    });
    setGlobalFilterValue1('');
  }

    const widthColumn = 150
    const columns = [
        {field: 'id', headerName: 'id', width: widthColumn },
        {field: 'nome', headerName: 'Nome', width: widthColumn },
        {field: 'email', headerName: 'Email', width: widthColumn },
        {field: 'cliente', headerName: 'Base Cliente', width: widthColumn },
        {field: 'tipoUsuario', headerName: 'Tipo Usuário', width: widthColumn },
        {field: 'departamento', headerName: 'Departamento', width: 200 },
        {field: 'funcao', headerName: 'Função', width: 250 }
      ]
  return (
    <>
      <Header />
      <div className={`content${isHumburguerActive ? '-active' : ''}`}>

        <div className="form-cadastro">
          {newProductsDialog &&
            <Dialog
              visible={newProductsDialog}
              style={{ width: "450px" }}
              header="Novo usuário"
              modal
              className="card p-fluid"
              footer={productDialogFooter}
              onHide={hideDialog}
            >
              <div className="field">
                <TextField
                  id="outlined-name"
                  label="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  margin="normal"
                  variant="outlined"
                />
              </div>

              <div className="field">
                <TextField
                  id="outlined-email"
                  label="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  type={'email'}
                />
              </div>
              <div className="field">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Tipo de usuário"
                  value={tipoUsuario}
                  onChange={(e) => {
                    if (e.target.value === 'Cliente') {
                      setIsHidden(false)
                    } else (
                      setIsHidden(true)
                    )
                    setTipoUsuario(e.target.value)
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {UserType.map(option => (
                    <MenuItem key={option.value} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="field">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Departamento"
                  fullWidth
                  value={departamento}
                  onChange={(e) => {
                    setDepartamento(e.target.value)
                    filterFuncao(e.target.value)
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {funcaoDepartamento.map(option => (
                    <MenuItem key={option.value} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="field">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Função"
                  fullWidth
                  value={efuncao}
                  onChange={(e) => {
                    setEfuncao(e.target.value)
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {
                    filtroFuncao.map(option => (
                      <MenuItem key={option.Descricao} value={option.Descricao}>
                        {option.Descricao}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>

              {!isHidden &&
                <div className="field autocomplet" id='field-autocomplete'>
                  <span className='label-base-client'>Base cliente</span>
                  <AutoComplete
                    margin="normal"
                    variant="outlined"
                    value={selectedCompanies}
                    suggestions={filteredCompanies}
                    completeMethod={searchCompany}
                    field="name"
                    multiple
                    onChange={(e) => setSelectedCompanies(e.value)}
                    aria-label="Countries"
                  />
                </div>}

            </Dialog>
          }
          {isEdit &&
            <Dialog
              visible={isEdit}
              style={{ width: "450px" }}
              header="Editar usuário"
              modal
              className="card p-fluid"
              footer={productDialogFooterAlter}
              onHide={hideDialogAlter}
            >
              <div className="field">
                <TextField
                  id="outlined-name"
                  label="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  margin="normal"
                  variant="outlined"
                />
              </div>

              <div className="field">
                <TextField
                  id="outlined-email"
                  label="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  type={'email'}
                />
              </div>
              <div className="field">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Tipo de usuário"
                  value={tipoUsuario}
                  onChange={(e) => {
                    if (e.target.value === 'Cliente') {
                      setIsHidden(false)
                    } else (
                      setIsHidden(true)
                    )
                    setTipoUsuario(e.target.value)
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {UserType.map(option => (
                    <MenuItem key={option.label} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="field">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Departamento"
                  fullWidth
                  value={departamento}
                  onChange={(e) => {
                    setDepartamento(e.target.value)
                    filterFuncao(e.target.value)
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {funcaoDepartamento.map(option => (
                    <MenuItem key={option.value} value={option.label}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="field">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Função"
                  fullWidth
                  value={efuncao}
                  onChange={(e) => {
                    setEfuncao(e.target.value)
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {
                    filtroFuncao.map(option => (
                      <MenuItem key={option.Descricao} value={option.Descricao}>
                        {option.Descricao}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>

              {!isHidden &&
                <div className="field autocomplet" id='field-autocomplete'>
                  <span className='label-base-client'>Base cliente</span>
                  <AutoComplete
                    margin="normal"
                    variant="outlined"
                    value={selectedCompanies}
                    suggestions={filteredCompanies}
                    completeMethod={searchCompany}
                    field="name"
                    multiple
                    onChange={(e) => setSelectedCompanies(e.value)}
                    aria-label="Countries"
                  />
                </div>}

            </Dialog>
          }

          <div className="card p-fluid">
            <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            {usuarios &&
              <Box sx={{ height: 800, width: '100%' }}>
              <DataGridCustom 
                  column={columns} 
                  rows={usuarios}                
              />
              </Box>
            }
            <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
              <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {deleteProductsDialog && <span>Tem certeza que gostaria de inativar os usuarios <b>selecionados</b>?</span>}
              </div>
            </Dialog>

            <Dialog visible={ativarProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={AtivaUsuarioDialogFooter} onHide={hideAtivarProductDialog}>
              <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                {ativarProductsDialog && <span>Tem certeza que gostaria de ativar os usuarios <b>selecionados</b>?</span>}
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
