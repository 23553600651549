import { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import './forgot.css'
import { AuthContext } from '../../contexts/auth'
import logo from '../../assets/logotipo@6x-8.png'

function EsqueceuSenha() {

  const [email, setEmail] = useState('');
  const { forgot, load, errorEmail } = useContext(AuthContext);

  const navegate = useNavigate();

  async function handledSubmit(e) {
    e.preventDefault();

    if(email === ''){
      return toast.error('É necessário informar um e-mail');
    }

    if(email){
      await forgot(email);
      setEmail('');
    
        if(!errorEmail){
            setTimeout(() => { navegate('/'); }, 10)  
        }          
    }
  }

  return (
    <div className="container-center">
      <div className="login">
        <div className="logo-area">
          <img src={logo} alt="logo sistema" />
        </div>

        <form className='form-login-info' onSubmit={handledSubmit}>
          <h1>Esqueci minha senha</h1>
          
          <div className='wrap-input100 validate-input'>
            <input className="input100 has-val" placeholder='email@site.com.br' type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          
          <button type="submit" >{load ? 'Preparando tudo...' : 'Enviar'}</button>

          <Link className='forgot' to="/">Já tem uma conta? Entre</Link>

        </form>

       
      </div>
    </div>
  );
}

export default EsqueceuSenha;
