import { useContext } from 'react';
import './style.css';
import avatar from '../../assets/avatar.png'

import { AuthContext } from '../../contexts/auth'
import { Link } from 'react-router-dom'
import { FiFolder, FiArchive, FiEye, FiEdit2, FiUserPlus, FiBriefcase, FiPlusCircle, FiPackage, FiFile, FiBarChart2, FiBarChart, FiSliders, FiSettings, FiCheckSquare, FiLogOut, FiFeather, FiDroplet, FiDatabase }
    from 'react-icons/fi'
import { GiLightBulb } from 'react-icons/gi'

import IMGFolder from '../../assets/menuLateral/Folder.png'
import IMGLogo from '../../assets/menuLateral/simbolo@6x-8.png'
import IMGHamburguer from '../../assets/menuLateral/Hamburguer.png'
import IMGPactLogo from '../../assets/menuLateral/logotipo@6x-8.png'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



function Header() {

    const { user, signOut,
        isHumburguerActive, setIsHumburguerActive,
        isModel, setIsModel,
        isConfig, setIsConfig,
        isSelected, setIsSelected,
        isDash,
        setIsDash,
        showConfig,
        setShowConfig,
        isProject,
        setIsProject,
        isHigienizacao,
        setIsHigienizacao
    } = useContext(AuthContext);

    function isMenuSelected(index) {
        setIsSelected(index)
    }


    return (<>
        <div className={`sidebar ${isHumburguerActive ? 'sidebar-active' : ''}`}>

            <div className={`sidebar-logo`}>
                <Link to={'/dashboardPadrao'}>
                    {!isHumburguerActive ? <img className='pact-logo-close' src={IMGLogo} /> : <img className='pact-logo' src={IMGPactLogo} />}
                </Link>
            </div>

            <div
                className={`sidebar-icons${isHumburguerActive ? '-active' : ''} ${isSelected === 11 ? 'selected' : ''}`}
                onClick={() => {
                    setIsHumburguerActive(!isHumburguerActive);
                    setIsConfig(false);
                    setIsModel(false);
                    setIsSelected(11)
                    setIsDash(false)
                }
                }
            >
                <img
                    id={`humburguer${isHumburguerActive ? '-active' : ''}`}
                    className='humburguer icons-size'
                    src={IMGHamburguer}
                />
                <span style={{ display: `${isHumburguerActive ? '' : 'none'}` }} onClick={() => setIsHumburguerActive(!isHumburguerActive)}>Menu</span>
            </div>


            {/* Menu DashBoard OCULTO*/}
            {/* <Link
                to={''}
                className={`sidebar-icons${isHumburguerActive ? '-active' : ''} ${isSelected === 1 ? 'selected' : ''}`}
                onClick={() => {
                    setIsDash(!isDash);
                    setIsSelected(1)
                    setIsHumburguerActive(true)
                }}
            >
                <FiBarChart2 color='#FFF' className={'icons-size'} onClick={() => setIsHumburguerActive(true)} />
                <span style={{ display: `${isHumburguerActive ? '' : 'none'}` }} > Visão Geral  <KeyboardArrowDownIcon style={{ color: 'white' }} /></span>
            </Link> */}

            {isDash &&
                <>
                    <Link to="/dashboardPadrao" className={`sub-menu-itens${isHumburguerActive ? '-active' : ''} ${isSelected === 19 ? 'selected' : ''}`} onClick={() => {
                        setIsHumburguerActive(!isHumburguerActive);
                        setIsDash(false);
                        setIsProject(false);
                        setIsHigienizacao(false);
                        isMenuSelected(19)
                    }}><FiBarChart color="FFF" className={'icons-size'} /><span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Dashboard</span></Link>

                </>
            }


            {/* Menu Projetos */}
            <Link
                to={''}
                className={`sidebar-icons${isHumburguerActive ? '-active' : ''} ${isSelected === 25 ? 'selected' : ''}`}
                onClick={() => {
                    setIsHigienizacao(!isHigienizacao);
                    setIsSelected(25)
                    setIsHumburguerActive(true)
                }}
            >
                <FiFeather color='#FFF' className={'icons-size'} onClick={() => setIsHumburguerActive(true)} />
                <span style={{ display: `${isHumburguerActive ? '' : 'none'}` }} > Higienização  <KeyboardArrowDownIcon style={{ color: 'white' }} /></span>
            </Link>

            {isHigienizacao &&
                <>

                    <Link to="/higienizacao" className={`sub-menu-itens${isHumburguerActive ? '-active' : ''} ${isSelected === 22 ? 'selected' : ''}`} onClick={() => {
                        setIsHumburguerActive(!isHumburguerActive);
                        setIsDash(false);
                        setIsProject(false);
                        setIsHigienizacao(false);
                        isMenuSelected(22)
                    }}><FiDroplet color="FFF" className={'icons-size'} /><span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Saneamento</span></Link>

                    <Link to="/pacotes" className={`sub-menu-itens${isHumburguerActive ? '-active' : ''} ${isSelected === 26 ? 'selected' : ''}`} onClick={() => {
                        setIsHumburguerActive(!isHumburguerActive);
                        setIsDash(false);
                        setIsProject(false);
                        setIsHigienizacao(false);
                        isMenuSelected(26)
                    }}><FiDatabase color="FFF" className={'icons-size'} /><span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Pacotes</span></Link>

                    <Link to="/monitoramento" className={`sub-menu-itens${isHumburguerActive ? '-active' : ''} ${isSelected === 27 ? 'selected' : ''}`} onClick={() => {
                        setIsHumburguerActive(!isHumburguerActive);
                        setIsDash(false);
                        setIsProject(false);
                        setIsHigienizacao(false);
                        isMenuSelected(27)
                    }}><FiEye color="FFF" className={'icons-size'} /><span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Monitoramento</span></Link>
                </>
            }

            {/* <Link
                to="/tabelas"
                className={`sidebar-icons${isHumburguerActive ? '-active' : ''} ${isSelected === 24 ? 'selected' : ''}`}
                onClick={() => {
                    setIsHumburguerActive(true)
                    setIsSelected(24)
                }}>
                <FiBookOpen id={`file${isHumburguerActive ? '-active' : ''}`} className={`file${isHumburguerActive ? '-active' : ''} icons-size`} color="FFF" />
                <span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Tabelas</span>
            </Link> */}

            {/* Menu Projetos */}
            <Link
                to={''}
                className={`sidebar-icons${isHumburguerActive ? '-active' : ''} ${isSelected === 23 ? 'selected' : ''}`}
                onClick={() => {
                    setIsProject(!isProject);
                    setIsSelected(23)
                    setIsHumburguerActive(true)
                }}
            >
                <FiArchive color='#FFF' className={'icons-size'} onClick={() => setIsHumburguerActive(true)} />
                <span style={{ display: `${isHumburguerActive ? '' : 'none'}` }} > GED  <KeyboardArrowDownIcon style={{ color: 'white' }} /></span>
            </Link>

            {isProject &&
                <>

                    {/* <Link to="/dashboardCutomizado" className={`sub-menu-itens${isHumburguerActive ? '-active' : ''} ${isSelected === 20 ? 'selected' : ''}`} onClick={() => {
                        setIsHumburguerActive(!isHumburguerActive);
                        setIsDash(false);
                        setIsProject(false);
                        setIsHigienizacao(false);
                        isMenuSelected(20)
                    }}><FiSliders color="FFF" className={'icons-size'} /><span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Status Report</span></Link> */}

                    {/* Menu Processos */}

                    {/* <Link to="/processos" className={`sub-menu-itens${isHumburguerActive ? '-active' : ''} ${isSelected === 13 ? 'selected' : ''}`} onClick={() => {
                        setIsHumburguerActive(!isHumburguerActive);
                        setIsDash(false);
                        setIsProject(false);
                        setIsHigienizacao(false);
                        isMenuSelected(13)
                    }}><FiFolder color="FFF" className={'icons-size'} /><span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Processos</span></Link> */}

                    <Link to="/arquivos" className={`sub-menu-itens${isHumburguerActive ? '-active' : ''} ${isSelected === 2 ? 'selected' : ''}`} onClick={() => {
                        setIsHumburguerActive(!isHumburguerActive);
                        setIsDash(false);
                        setIsProject(false);
                        setIsHigienizacao(false);
                        isMenuSelected(2)
                    }}><FiFile color="FFF" className={'icons-size'} /><span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Arquivos de Projetos</span></Link>

                    {/* <Link to="/insights" className={`sub-menu-itens${isHumburguerActive ? '-active' : ''} ${isSelected === 21 ? 'selected' : ''}`} onClick={() => {
                        setIsHumburguerActive(!isHumburguerActive);
                        setIsDash(false);
                        setIsProject(false);
                        setIsHigienizacao(false);
                        isMenuSelected(21)
                    }}><GiLightBulb color="FFF" className={'icons-size'} /><span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Insights</span></Link> */}

                    {/* <Link to="/manutencaoFiltros" className={`sub-menu-itens${isHumburguerActive ? '-active' : ''} ${isSelected === 16 ? 'selected' : ''}`} onClick={() => {
                        setIsHumburguerActive(!isHumburguerActive);
                        setIsDash(false);
                        setIsProject(false);
                        setIsHigienizacao(false);
                        isMenuSelected(16)
                    }}><FiEdit2 color="FFF" className={'icons-size'} /><span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Filtros</span></Link> */}

                </>
            }



            {/* <Link
                        to="/processos"
                        className={`sidebar-icons${isHumburguerActive ? '-active' : ''} ${isSelected === 13 ? 'selected' : ''}`}
                        onClick={() => {
                            setIsHumburguerActive(true)
                            setIsSelected(13)
                        }}>
                        <img src={IMGFolder} id={`file${isHumburguerActive ? '-active' : ''}`} className={`file${isHumburguerActive ? '-active' : ''} icons-size`} color="FFF" />
                        <span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Processos</span>
                    </Link> */}

            {/* <Link to="/dashboardCutomizado" className={`sub-menu-itens${isHumburguerActive ? '-active' : ''} ${isSelected === 20 ? 'selected' : ''}`} onClick={() => {
                        setIsHumburguerActive(!isHumburguerActive);
                        setIsDash(false);
                        isMenuSelected(20)
                    }}><FiSliders color="FFF" className={'icons-size'} /><span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Customizado</span></Link> */}
            {/* <Link
                to="/todos"
                className={`sidebar-icons${isHumburguerActive ? '-active' : ''} ${isSelected === 22 ? 'selected' : ''}`}
                onClick={() => {
                    setIsHumburguerActive(true)
                    setIsSelected(22)
                }}>
                <FiCheckSquare id={`file${isHumburguerActive ? '-active' : ''}`} className={`file${isHumburguerActive ? '-active' : ''} icons-size`} color="FFF" />

                <span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Legal Ops</span>
            </Link> */}

            {/* <Link
                        to="/insights"
                        className={`sidebar-icons${isHumburguerActive ? '-active' : ''} ${isSelected === 21 ? 'selected' : ''}`}
                        onClick={() => {
                            setIsHumburguerActive(true)
                            setIsSelected(21)
                        }}>
                        {/* <img src={IMGFolder} id={`file${isHumburguerActive ? '-active' : ''}`} className={`file${isHumburguerActive ? '-active' : ''} icons-size`} color="FFF" /> */}
            {/* <GiLightBulb id={`file${isHumburguerActive ? '-active' : ''}`} className={`file${isHumburguerActive ? '-active' : ''} icons-size`} color="FFF" />
                        <span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Insights</span>
                    </Link> */}


            {/* <Link
                        to="/manutencaoFiltros"
                        className={`sidebar-icons${isHumburguerActive ? '-active' : ''} ${isSelected === 16 ? 'selected' : ''}`}
                        onClick={() => {
                            setIsHumburguerActive(true)
                            setIsSelected(16)
                        }}> */}
            {/* <FiEdit2 id={`file${isHumburguerActive ? '-active' : ''}`} className={`file${isHumburguerActive ? '-active' : ''} icons-size`} color="FFF" />

                        <span style={{ display: `${isHumburguerActive ? '' : 'none'}` }}>Filtros</span>
                    </Link> */}

        </div>



        <div className='header'>
            <a href="/meuperfil"><span>{user.nome ? user.nome : ''}</span></a>
            <div>
                <img src={user.avatarUrl ? user.avatarUrl : avatar} alt="" />
            </div>


            <div className='menu-configuracoes'>
                <FiSettings fontSize={'25px'} style={{ color: 'white' }} onClick={() => setShowConfig(!showConfig)} />
            </div>

            {showConfig ?
                (<div className='menu-configuracoes-active'>
                    <span>Configurações</span>
                    {user.tipoUsuario !== "Cliente" ? <>
                        <Link to="/usuarios" onClick={() => { setShowConfig(false); setIsSelected(0) }}><FiUserPlus color="#5499e9" className={'icons-size'} /><span>Cadastro de Usuário</span></Link>
                        <Link to="/empresas" onClick={() => { setShowConfig(false); setIsSelected(0) }}><FiBriefcase color="#5499e9" className={'icons-size'} /><span>Cadastro de Base Cliente</span></Link>
                        <Link to="/momentos" onClick={() => { setShowConfig(false); setIsSelected(0) }}><FiPlusCircle color="#5499e9" className={'icons-size'} /><span>Cadastro de Momento</span></Link>
                        <Link to="/pedidos" onClick={() => { setShowConfig(false); setIsSelected(0) }}><FiPackage color="#5499e9" className={'icons-size'} /><span>Cadastro de Pedidos</span></Link>
                        <Link to="/advogado" onClick={() => { setShowConfig(false); setIsSelected(0) }}><FiPlusCircle color="#5499e9" className={'icons-size'} /><span>Cadastro de Advogado</span></Link>
                        <Link to="/escritorio" onClick={() => { setShowConfig(false); setIsSelected(0) }}><FiBriefcase color="#5499e9" className={'icons-size'} /><span>Cadastro de Escritório</span></Link>
                        <Link to="/dicionario" onClick={() => { setShowConfig(false); setIsSelected(0) }}><FiPlusCircle color="#5499e9" className={'icons-size'} /><span>Cadastro de Dicionário</span></Link>
                        <Link to="/departamento" onClick={() => { setShowConfig(false); setIsSelected(0) }}><FiPlusCircle color="#5499e9" className={'icons-size'} /><span>Cadastro de Departamento</span></Link>
                        <Link to="/funcao" onClick={() => { setShowConfig(false); setIsSelected(0) }}><FiPlusCircle color="#5499e9" className={'icons-size'} /><span>Cadastro de Função</span></Link>
                        <Link to="/atividades" onClick={() => { setShowConfig(false); setIsSelected(0) }}><FiPlusCircle color="#5499e9" className={'icons-size'} /><span>Colunas Atividades</span></Link>
                        <Link to="/reports" onClick={() => { setShowConfig(false); setIsSelected(0) }}><FiUserPlus color="#5499e9" className={'icons-size'} /><span>Logs Higienização</span></Link>
                    </> : <></>
                    }
                    <Link to="" onClick={() => { signOut() }}><FiLogOut color="#5499e9" className={'icons-size'} /><span>Sair</span></Link>
                </div>)
                : ''
            }

        </div>
    </>
    )
}

export default Header;