import React, { useState, useContext, useEffect, useRef } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'


import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';
import './index.css'

import { ImportacaoContext } from '../../contexts/importacao';

import DataGridCustom from "../../Components/DataGrid/DataGridCustom";
import Box from '@mui/material/Box';

const Relatorio = () => {

    const { load } = useContext(ImportacaoContext);
    const { isHumburguerActive, user } = useContext(AuthContext);
    const { getReports, reports } = useContext(CrudContext);
    const [dicionario, setdicionario] = useState(null);

    useEffect(() => {
        getReports();
    }, [])

    const columns = [
        { field: 'idReport', headerName: 'ID', width: 200 },
        { field: 'return', headerName: 'Retorno', width: 150 },
        { field: 'user', headerName: 'Usuario', width: 150 },
        { field: 'cnjs', headerName: 'Quantidade de CNJ', width: 200 },
        { field: 'status', headerName: 'Status', width: 200 },
        { field: 'date', headerName: 'Data', width: 250 },
        {
            field: 'link',
            headerName: 'Link Arquivo',
            width: 150,
            renderCell: (params) => {
                if (params.row.link) {
                    return (
                        <a href={params.row.link} target="_blank" rel="noopener noreferrer">
                            <CloudDownloadIcon size={25} style={{ cursor: 'pointer' }} />
                        </a>
                    );
                }
                return null;
            },
        }

    ]

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                <div className="container-profile">
                    {reports &&
                        <div className="card p-fluid">
                            <Box sx={{ height: 650, width: '100%' }}>
                                <DataGridCustom
                                    column={columns}
                                    rows={reports}
                                />
                            </Box>
                        </div>
                    }
                </div>
            </div >
        </div >
    )
}


export default Relatorio;