import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'
import { toast } from 'react-toastify'
import { FilterMatchMode } from 'primereact/api';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';
import './index.css'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { ImportacaoContext } from '../../contexts/importacao';
import { readExcelFile, dateTemplete, dateTemplate } from '../../utils';
import DataGridCustom from "../../Components/DataGrid/DataGridCustom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import moment from 'moment'
import * as XLSX from 'xlsx';

const Higienizacao = () => {

    const { load } = useContext(ImportacaoContext);
    const { isHumburguerActive, user, getAllUsers, usuarios } = useContext(AuthContext);
    const { cadastrarNovoProcesso, higienizacaoAllProcess, higienizacaoAllProcessAdmin, higienizacaoProcess, formConteudo, deleteHigienização, cadastrarNovoMonitoramento,
        deleteMonitoramento } = useContext(CrudContext);
    const [dicionario, setdicionario] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const [newProductsDialog, setNewProductsDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);
    const [fileData, setFileData] = useState()
    const ref = useRef();
    const [isShowAndamento, setIsShowAndamentos] = useState(false)
    const [isShowDetalhes, setIsShowDetalhes] = useState(false)
    const [isShowAudiencias, setIsShowAudiencias] = useState(false)
    const [isShowProcessosRelacionados, setIsShowProcessosRelacionados] = useState(false)
    const [movimentacoes, setMovimentacoes] = useState(null)
    const [detalhes, setDetalhes] = useState(null)
    const [audiencias, setAudiencias] = useState(null)
    const [processosRelacionados, setProcessosRelacionados] = useState(null)

    useEffect(() => {
        getAllUsers(1);
        higienizacaoAllProcessAdmin();
        
        // if (user.tipoUsuario === 'Administrador Global' || user.tipoUsuario === 'Líder') {
        //     higienizacaoAllProcessAdmin();
        // } else {
        //     console.log(user)
        //     higienizacaoAllProcess(user.uid);
        // }

        initFilters1();
    }, [])

    useEffect(() => {

        const _capa = higienizacaoProcess.map((process) => {
            return {
                monitoramentoAtivo: process.isMonitored ? process.isMonitored : 'Não',
                ...process,
            };
        })

        setdicionario(_capa)

    }, [higienizacaoProcess])


    useEffect(() => { setSelectedCustomers(formConteudo) }, [formConteudo])

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

        });
        setGlobalFilterValue1('');
    }

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });


    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Pesquisar" />
                </span>
            </div>
        )
    }

    const header1 = renderHeader1();

    const novoMonitoramento = () => {


        let itemGrid = dicionario.filter(item => selectedCustomers.includes(item.id))

        let data = null

        for (let index = 0; index < itemGrid.length; index++) {
            const element = itemGrid[index];

            if (element.monitoramentoAtivo === 'Sim') {
                data = {
                    processos: selectedCustomers
                }
                deleteMonitoramento(data, user.uid, user.email, user.tipoUsuario)

            }
            else {
                data = {
                    codProcesso: element.numero,
                    baseCliente: element.baseCliente,
                    userID: user.uid,
                    user_email: user.email
                }

                cadastrarNovoMonitoramento(data, user.tipoUsuario)
            }

        }
    }

    const atualizar = () => {
        if (selectedCustomers) {
            console.log('falar sobre1')
        } else {
            console.log('falar sobre')
        }

        setImportDialog(true);
    }

    const deletar = () => {
        if (selectedCustomers) {
            const data = {
                processos: selectedCustomers
            }
            deleteHigienização(data, user.uid)
        }

    }

    const exportExcel = () => {
        if (selectedCustomers.length > 0) {
            let itemExcel = []
            let itemGrid = dicionario.filter(item => selectedCustomers.includes(item.id))


            itemGrid.map(item => {
                itemExcel.push({
                    numero: item.numero,
                    processoID: item.processoID,
                    baseCliente: item.baseCliente,
                    instancia: item.instancia,
                    nome_autor: item.nome_autor,
                    reclamada: item.reclamada,
                    juiz: item.juiz,
                    partes: item.partes,
                    area: item.area,
                    distribuicaoData: item.distribuicaoData ? moment(item.distribuicaoData).format('DD/MM/YYYY HH:MM') : '',
                    distribuicaoTipo: item.distribuicaoTipo,
                    processo_digital: item.processo_digital,
                    tribunal: item.tribunal,
                    sistema: item.sistema,
                    vara: item.vara,
                    orgao_julgador: item.orgao_julgador,
                    comarca_cnj: item.comarca_cnj,
                    foro_cnj: item.foro_cnj,
                    uf: item.uf,
                    valor: item.valor,
                    assunto_extra: item.assunto_extra,
                    natureza: item.natureza,
                    data_audiencia: item.data_audiencia ? moment(item.data_audiencia).format('DD/MM/YYYY HH:MM') : '',
                    local_audiencia: item.local_audiencia,
                    tipo_audiencia: item.tipo_audiencia,
                    todos_advogados: item.todos_advogados,
                    advogados_parte_ativa: item.advogados_parte_ativa,
                    oab_advogado_parte_ativa: item.oab_advogado_parte_ativa,
                    estado_advogado_parte_ativa: item.estado_advogado_parte_ativa,
                    advogados_parte_passiva: item.advogados_parte_passiva,
                    oab_advogado_parte_passiva: item.oab_advogado_parte_passiva,
                    estado_advogado_parte_passiva: item.estado_advogado_parte_passiva,
                    ultima_mov_juiz: item.ultima_mov_juiz,
                    total_movs: item.total_movs,
                    primeiro_andamento_mais_recente: item.primeiro_andamento_mais_recente,
                    segundo_andamento_mais_recente: item.segundo_andamento_mais_recente,
                    terceiro_andamento_mais_recente: item.terceiro_andamento_mais_recente,
                    quarto_andamento_mais_recente: item.quarto_andamento_mais_recente,
                    quinto_andamento_mais_recente: item.quinto_andamento_mais_recente,
                    sexto_andamento_mais_recente: item.sexto_andamento_mais_recente,
                    setimo_andamento_mais_recente: item.setimo_andamento_mais_recente,
                    oitavo_andamento_mais_recente: item.oitavo_andamento_mais_recente,
                    nono_andamento_mais_recente: item.nono_andamento_mais_recente,
                    decimo_andamento_mais_recente: item.decimo_andamento_mais_recente,
                    situacao: item.situacao,
                    citado: item.citado,
                    penhorado: item.penhorado,
                    suspenso: item.suspenso,
                    acordo: item.acordo,
                    sentenca: item.sentenca,
                    texto_sentenca: item.texto_sentenca,
                    sentencaData: item.sentencaData ? moment(item.sentencaData).format('DD/MM/YYYY HH:MM') : '',
                    classificacao_sentenca: item.classificacao_sentenca,
                    cumprimento_de_sentenca: item.cumprimento_de_sentenca,
                    recurso: item.recurso,
                    transitado_julg: item.transitado_julg,
                    arquivado: item.arquivado,
                    data_arquivamento: item.data_arquivamento
                })
            })

            import('xlsx').then(xlsx => {
                const headers = {
                    numero: 'Codigo do processo',
                    processoID: 'processoID',
                    baseCliente: 'Base Cliente',
                    instancia: 'instancia',
                    nome_autor: 'Nome autor',
                    reclamada: 'Reclamada',
                    juiz: 'Relator/Juiz',
                    partes: 'Todas partes',
                    area: 'Area',
                    distribuicaoData: 'Data distribuição',
                    distribuicaoTipo: 'Tipo distribuicao',
                    processo_digital: 'Processo digital',
                    tribunal: 'Tribunal',
                    sistema: 'Sistema',
                    vara: 'Vara',
                    orgao_julgador: 'Orgao Julgador',
                    comarca_cnj: 'Comarca CNJ',
                    foro_cnj: 'Foro CNJ',
                    uf: 'UF',
                    valor: 'Valor',
                    assunto_extra: 'Assunto extra',
                    natureza: 'Natureza',
                    data_audiencia: 'Data Audiência',
                    local_audiencia: 'Local Audiência',
                    tipo_audiencia: 'Tipo Audiência',
                    todos_advogados: 'Todos advogados',
                    advogados_parte_ativa: 'Advogados (parte ativa)',
                    oab_advogado_parte_ativa: 'OAB advogado (parte ativa)',
                    estado_advogado_parte_ativa: 'Estado advogado (parte ativa)',
                    advogados_parte_passiva: 'Advogados (parte passiva)',
                    oab_advogado_parte_passiva: 'OAB advogado (parte passiva)',
                    estado_advogado_parte_passiva: 'Estado advogado (parte passiva)',
                    ultima_mov_juiz: 'Ultima mov. juiz',
                    total_movs: 'Total movs.',
                    primeiro_andamento_mais_recente: '1o andamento mais recente',
                    segundo_andamento_mais_recente: '2o andamento mais recente',
                    terceiro_andamento_mais_recente: '3o andamento mais recente',
                    quarto_andamento_mais_recente: '4o andamento mais recente',
                    quinto_andamento_mais_recente: '5o andamento mais recente',
                    sexto_andamento_mais_recente: '6o andamento mais recente',
                    setimo_andamento_mais_recente: '7o andamento mais recente',
                    oitavo_andamento_mais_recente: '8o andamento mais recente',
                    nono_andamento_mais_recente: '9o andamento mais recente',
                    decimo_andamento_mais_recente: '10o andamento mais recente',
                    situacao: 'Situação',
                    citado: 'Citado',
                    penhorado: 'Penhorado',
                    suspenso: 'Suspenso',
                    acordo: 'Acordo',
                    sentenca: 'Sentença',
                    texto_sentenca: 'Data Sentença',
                    sentencaData: 'Texto Sentença',
                    classificacao_sentenca: 'Classificação sentença',
                    cumprimento_de_sentenca: 'Cumprimento de sentença',
                    recurso: 'Recurso',
                    transitado_julg: 'Transitado julg.',
                    arquivado: 'Arquivado',
                    data_arquivamento: 'Data arquivamento',
                };

                itemExcel.unshift(headers);
                const worksheet = XLSX.utils.json_to_sheet(itemExcel, { skipHeader: true });
                //  worksheet = xlsx.utils.json_to_sheet(productsFiltered);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'processos');
            });
        } else {
            toast.error('Selecione ao menos um item para exportar');
        }

    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                const date = new Date();
                let formatedDay = date.toLocaleDateString();
                module.default.saveAs(data, 'Higienizacao_' + formatedDay + EXCEL_EXTENSION);
            }
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button label="Ativar/Desativar monitoramento" icon="pi pi-eye" className="p-button-help mr-2" type="button" data-pr-tooltip="XLS" onClick={novoMonitoramento} /> */}
                <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success mr-2" type="button" data-pr-tooltip="XLS" onClick={exportExcel} />
                <Button label={"Importar"} icon={"pi pi-download"} className={"p-button-warning"} onClick={() => setNewProductsDialog(true)} />
                {/* <Button label="Atualizar" icon="pi pi-refresh" className="p-button-info" type="button" onClick={() => atualizar()} /> */}
                <Button label="Arquivar" icon="pi pi-trash" className="p-button-danger" type="button" onClick={() => deletar()} disabled={!selectedCustomers || !selectedCustomers.length} />
            </React.Fragment>
        )
    }

    const importarArquivo = () => {
        if (fileData) {

            const newArray = fileData.map(item => item.NCNJ);
            const element = fileData[0];
            const base = element.baseCliente
            cadastrarNovoProcesso(newArray, base, user, usuarios)


            setNewProductsDialog(false)
            setImportDialog(true)
        }
        else {
            toast.error("Necessário selecionar um arquivo")
        }
    }

    const hideDialog = () => {
        setNewProductsDialog(!newProductsDialog)
    };

    const handleFile = async (e, ref) => {
        const json = await readExcelFile(e, ref)
        setFileData(json)
    }

    const hideImportDialog = () => {
        setImportDialog(false);
    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="OK" icon="pi pi-check" className="p-button-text" onClick={hideImportDialog} />
        </React.Fragment>
    );

    const onHideLogMovs = () => {
        setIsShowAndamentos(false)
    }

    const onHideLogDetalhe = () => {
        setIsShowDetalhes(false)
    }

    const onHideLogAudiencias = () => {
        setIsShowAudiencias(false)
    }

    const onHideLogProcessosRelacionados = () => {
        setIsShowProcessosRelacionados(false)
    }

    const handleMovimentacoes = (data) => {

        const _movimentacoes = data.movs.map((movimento) => {
            return {
                data: movimento[0],
                tipo: movimento[1],
                texto: movimento[2],
                juiz: movimento[3],
                idInterno: movimento[4]
            };
        })

        setMovimentacoes(_movimentacoes)
        setIsShowAndamentos(true);

    }

    const handleProcessosRelacionados = (data) => {

        const _processos = data.processosRelacionados.map((movimento) => {
            return {
                tribunal: movimento.tribunal,
                natureza: movimento.natureza,
                distribuicao_data: movimento.distribuicao_data,
                numero: movimento.numero,
                instancia: movimento.instancia,
                codigo_identificador: movimento.codigo_identificador,
                id: movimento.id
            };
        })

        setProcessosRelacionados(_processos)
        setIsShowProcessosRelacionados(true);
    }

    const handleAudiencias = (data) => {

        const _audiencias = data.audiencias.map((movimento) => {
            return {
                data: movimento[0],
                local: movimento[1],
                tipo: movimento[2]
            };
        })

        setAudiencias(_audiencias)
        setIsShowAudiencias(true);

    }

    const handleDetalhes = (data) => {

        const _detalhes = [data]

        setDetalhes(_detalhes)
        setIsShowDetalhes(true);
    }

    const columns = [
        { field: 'numero', headerName: 'Codigo do Processo', width: 200 },
        { field: 'processoID', headerName: 'Process ID', width: 150 },
        { field: 'baseCliente', headerName: 'Base Cliente', width: 150 },
        { field: 'instancia', headerName: 'Instância', width: 200 },
        { field: 'nome_autor', headerName: 'Nome autor', width: 200 },
        { field: 'reclamada', headerName: 'Reclamada', width: 250 },
        { field: 'juiz', headerName: 'Relator/Juiz', width: 200 },
        { field: 'partes', headerName: 'Todas partes', width: 200 },
        { field: 'area', headerName: 'Area', width: 200 },
        { field: 'distribuicaoData', headerName: 'Data distribuição', width: 250 },
        { field: 'distribuicaoTipo', headerName: 'Tipo distribuicao', width: 250 },
        { field: 'processo_digital', headerName: 'Processo digital', width: 250 },
        { field: 'tribunal', headerName: 'Tribunal', width: 350 },
        { field: 'sistema', headerName: 'Sistema', width: 350 },
        { field: 'vara', headerName: 'Vara', width: 350 },
        { field: 'orgao_julgador', headerName: 'Orgao Julgador', width: 350 },
        { field: 'comarca_cnj', headerName: 'Comarca CNJ', width: 350 },
        { field: 'foro_cnj', headerName: 'Foro CNJ', width: 350 },
        { field: 'uf', headerName: 'UF', width: 350 },
        {
            field: 'valor', headerName: 'Valor', width: 250,
            valueFormatter: (params) => {
                const value = params.value ? currencyFormatter.format(params.value) : ''
                return value
            }
        },
        { field: 'assunto_extra', headerName: 'Assunto extra', width: 350 },
        { field: 'natureza', headerName: 'Natureza', width: 350 },
        { field: 'data_audiencia', headerName: 'Data Audiência', width: 350 },
        { field: 'local_audiencia', headerName: 'Local Audiência', width: 350 },
        { field: 'tipo_audiencia', headerName: 'Tipo Audiência', width: 350 },
        { field: 'todos_advogados', headerName: 'Todos advogados', width: 350 },
        { field: 'advogados_parte_ativa', headerName: 'Advogados (parte ativa)', width: 350 },
        { field: 'oab_advogado_parte_ativa', headerName: 'OAB advogado (parte ativa)', width: 350 },
        { field: 'estado_advogado_parte_ativa', headerName: 'Estado advogado (parte ativa)', width: 350 },
        { field: 'advogados_parte_passiva', headerName: 'Advogados (parte passiva)', width: 350 },
        { field: 'oab_advogado_parte_passiva', headerName: 'OAB advogado (parte passiva)', width: 350 },
        { field: 'estado_advogado_parte_passiva', headerName: 'Estado advogado (parte passiva)', width: 350 },
        { field: 'ultima_mov_juiz', headerName: 'Ultima mov. juiz', width: 350 },
        { field: 'total_movs', headerName: 'Total movs.', width: 350 },
        { field: 'primeiro_andamento_mais_recente', headerName: '1o andamento mais recente', width: 350 },
        { field: 'segundo_andamento_mais_recente', headerName: '2o andamento mais recente', width: 350 },
        { field: 'terceiro_andamento_mais_recente', headerName: '3o andamento mais recente', width: 350 },
        { field: 'quarto_andamento_mais_recente', headerName: '4o andamento mais recente', width: 350 },
        { field: 'quinto_andamento_mais_recente', headerName: '5o andamento mais recente', width: 350 },
        { field: 'sexto_andamento_mais_recente', headerName: '6o andamento mais recente', width: 350 },
        { field: 'setimo_andamento_mais_recente', headerName: '7o andamento mais recente', width: 350 },
        { field: 'oitavo_andamento_mais_recente', headerName: '8o andamento mais recente', width: 350 },
        { field: 'nono_andamento_mais_recente', headerName: '9o andamento mais recente', width: 350 },
        { field: 'decimo_andamento_mais_recente', headerName: '10o andamento mais recente', width: 350 },
        { field: 'situacao', headerName: 'Situação', width: 350 },
        { field: 'citado', headerName: 'Citado', width: 350 },
        { field: 'penhorado', headerName: 'Penhorado', width: 350 },
        { field: 'suspenso', headerName: 'Suspenso', width: 350 },
        { field: 'acordo', headerName: 'Acordo', width: 350 },
        { field: 'sentenca', headerName: 'Sentença', width: 350 },
        { field: 'texto_sentenca', headerName: 'Texto Sentença', width: 350 },
        { field: 'sentencaData', headerName: 'Data Sentença', width: 350 },
        { field: 'classificacao_sentenca', headerName: 'Classificação sentença', width: 350 },
        { field: 'cumprimento_de_sentenca', headerName: 'Cumprimento de sentença', width: 350 },
        { field: 'recurso', headerName: 'Recurso', width: 350 },
        { field: 'transitado_julg', headerName: 'Transitado julg.', width: 350 },
        { field: 'arquivado', headerName: 'Arquivado', width: 350 },
        { field: 'data_arquivamento', headerName: 'Data arquivamento', width: 350 },
        { field: 'monitoramentoAtivo', headerName: 'Monitoramento Ativo', width: 200 }
    ]

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                <div className="container-profile">
                    {dicionario &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                            <Box sx={{ height: 650, width: '100%' }}>
                                <DataGridCustom
                                    column={columns}
                                    rows={dicionario}
                                />
                            </Box>
                        </div>
                    }
                </div>

                {newProductsDialog &&
                    <Dialog
                        visible={newProductsDialog}
                        style={{ width: "450px" }}
                        header="Importação"
                        modal
                        className="card p-fluid"
                        onHide={hideDialog}
                    >
                        <div className="field">
                            <a className="arqModelo" href='https://pactfy.s3.sa-east-1.amazonaws.com/Modelo_Imporatacao_Higienizacao.xlsx'>Baixar Arquivo Modelo</a>
                        </div>

                        <div className="field">
                            <input id="arquivo-empresa" type="file" className="upload-box" onChange={(e) => handleFile(e, ref)} ref={ref} />
                        </div>

                        <div className="field button-right">
                            <Button
                                label={'Enviar'}
                                id={'button-right'}
                                className="p-button-success mr-2"
                                onClick={() => { importarArquivo() }}
                                style={{ width: '100px', margin: '1rem 0' }} disabled={load}
                            />
                        </div>
                    </Dialog>
                }

                {importDialog &&
                    <Dialog visible={importDialog} style={{ width: '450px' }} header="Atenção" modal footer={deleteProductDialogFooter} onHide={hideImportDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '1rem' }} />
                            {importDialog && <span> Sua base está sendo processada! As informações serão exibidas em 24 horas.</span>}
                        </div>
                    </Dialog>
                }


                {isShowAndamento &&
                    <div className="container-profile">

                        <Dialog
                            visible={isShowAndamento}
                            style={{ width: "auto" }}
                            header="Andamentos"
                            modal
                            className="card p-fluid"
                            onHide={onHideLogMovs}
                        >
                            <div className="card p-fluid">

                                <DataTable className="datatable-main" id="datatable-main" value={movimentacoes} editMode="row" dataKey="_id"
                                    globalFilterFields={['codAndamento', 'textoAndamento', 'codProcesso']} responsiveLayout="scroll"
                                    filters={filters1}
                                    header={header1}
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}" rows={100} rowsPerPageOptions={[50, 100, 150, 200]}
                                >
                                    <Column headerStyle={{ width: '1%' }}></Column>
                                    <Column field="idInterno" header="Id" style={{ width: '5%' }}></Column>
                                    <Column field="tipo" header="Tipo" style={{ width: '5%' }}></Column>
                                    <Column field="texto" header="Texto do Andamento" style={{ width: '3%' }}></Column>
                                    <Column field="juiz" header="Juíz" style={{ width: '5%' }}></Column>
                                    <Column field="data" sortable={true} body={(rowData) => dateTemplate(rowData, "data")} header="Data Andamento" style={{ width: '5%' }}></Column>
                                    <Column headerStyle={{ width: '1%', minWidth: '2rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                </DataTable>
                            </div>

                        </Dialog>
                    </div>
                }

                {isShowAudiencias &&
                    <div className="container-profile">

                        <Dialog
                            visible={isShowAudiencias}
                            style={{ width: "auto" }}
                            header="Audiências"
                            modal
                            className="card p-fluid"
                            onHide={onHideLogAudiencias}
                        >
                            <div className="card p-fluid">

                                <DataTable className="datatable-main" id="datatable-main" value={audiencias} editMode="row" dataKey="_id"
                                    globalFilterFields={['data', 'local', 'tipo']} responsiveLayout="scroll"
                                    filters={filters1}
                                    header={header1}
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}" rows={100} rowsPerPageOptions={[50, 100, 150, 200]}
                                >
                                    <Column headerStyle={{ width: '1%' }}></Column>
                                    <Column field="data" sortable={true} body={(rowData) => dateTemplete(rowData, "data")} header="Data" style={{ width: '5%' }}></Column>
                                    <Column field="local" header="Local" style={{ width: '5%' }}></Column>
                                    <Column field="tipo" header="Tipo" style={{ width: '5%' }}></Column>
                                    <Column headerStyle={{ width: '1%', minWidth: '2rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                </DataTable>
                            </div>

                        </Dialog>
                    </div>
                }

                {isShowProcessosRelacionados &&
                    <div className="container-profile">

                        <Dialog
                            visible={isShowProcessosRelacionados}
                            style={{ width: "auto" }}
                            header="Processos Relacionados"
                            modal
                            className="card p-fluid"
                            onHide={onHideLogProcessosRelacionados}
                        >
                            <div className="card p-fluid">

                                <DataTable className="datatable-main" id="datatable-main" value={processosRelacionados} editMode="row" dataKey="_id"
                                    responsiveLayout="scroll"
                                    filters={filters1}
                                    header={header1}
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}" rows={100} rowsPerPageOptions={[50, 100, 150, 200]}
                                >
                                    <Column headerStyle={{ width: '1%' }}></Column>
                                    <Column field="tribunal" header="Tribunal" style={{ width: '5%' }}></Column>
                                    <Column field="natureza" header="Natureza" style={{ width: '5%' }}></Column>
                                    <Column field="distribuicao_data" sortable={true} body={(rowData) => dateTemplete(rowData, "distribuicao_data")} header="Data Distribuição" style={{ width: '5%' }}></Column>
                                    <Column field="numero" header="Numero" style={{ width: '5%' }}></Column>
                                    <Column field="instancia" header="Instancia" style={{ width: '5%' }}></Column>
                                    <Column field="codigo_identificador" header="Codigo identificador" style={{ width: '5%' }}></Column>
                                    <Column field="id" header="ID" style={{ width: '5%' }}></Column>

                                    <Column headerStyle={{ width: '1%', minWidth: '2rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                </DataTable>
                            </div>

                        </Dialog>
                    </div>
                }

                {isShowDetalhes &&
                    <div className="container-profile">

                        <Dialog
                            visible={isShowDetalhes}
                            style={{ width: "70%" }}
                            header="Detalhes"
                            modal
                            className="card p-fluid"
                            onHide={onHideLogDetalhe}
                        >
                            <div className="card p-fluid">
                                {detalhes &&
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', m: 1, gap: 5 }}>

                                        <TextField id="outlined-basic" label="Regional CNJ" variant="outlined" value={detalhes[0].regional_cnj === 'true' ? "Sim" : detalhes[0].regional_cnj === 'false' ? 'Não' : ''} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Numero alternativo" variant="outlined" value={detalhes[0].numeroAlternativo} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Juiz" variant="outlined" value={detalhes[0].juiz} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Situação" variant="outlined" value={detalhes[0].situacao} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Distribuição Tipo" variant="outlined" value={detalhes[0].distribuicaoTipo} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Area" variant="outlined" value={detalhes[0].area} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField fullWidth id="outlined-basic" label="Assunto Extra" multiline variant="outlined" value={detalhes[0].assuntoExtra} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Liminar" variant="outlined" value={detalhes[0].liminar === 'true' ? "Sim" : detalhes[0].liminar === 'false' ? 'Não' : ''} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Vara Original" variant="outlined" value={detalhes[0].vara_original} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />
                                        <TextField id="outlined-basic" label="Extinto" variant="outlined" value={detalhes[0].extinto === "1" ? "Sim" : detalhes[0].extinto === "0" ? "Não" : 'Sim'} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Gratuita" variant="outlined" value={detalhes[0].gratuita === 'true' ? "Sim" : detalhes[0].gratuita === 'false' ? 'Não' : ''} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />
                                        <TextField id="outlined-basic" label="Alterado Em" variant="outlined" value={moment(detalhes[0].alteradoEm).format('DD/MM/YYYY HH:MM')} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Situação ID" variant="outlined" value={detalhes[0].situacao_situacaoID} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />
                                        <TextField id="outlined-basic" label="Sentença Data" variant="outlined" value={detalhes[0].sentencaData} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Instancia" variant="outlined" value={detalhes[0].instancia} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />
                                        <TextField id="outlined-basic" label="Flag" variant="outlined" value={detalhes[0].flag} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Comarca CNJ" variant="outlined" value={detalhes[0].comarca_cnj} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Tribunal ID" variant="outlined" value={detalhes[0].tribunalID} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Arquivado" variant="outlined" value={detalhes[0].arquivado === 'true' ? "Sim" : detalhes[0].arquivado === 'false' ? 'Não' : ''} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="UF" variant="outlined" value={detalhes[0].uf} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                        <TextField id="outlined-basic" label="Criado Em" variant="outlined" value={moment(detalhes[0].criadoEm).format('DD/MM/YYYY HH:MM')} InputProps={{
                                            readOnly: true,
                                            shrink: true,
                                        }} />

                                    </Box>
                                }
                            </div>

                        </Dialog>
                    </div>
                }
            </div >
        </div >
    )
}


export default Higienizacao;