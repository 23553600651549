import './pedidos.css';

import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'
import { toast } from 'react-toastify'
import { FilterMatchMode } from 'primereact/api';
import { MenuItem, TextField } from '@mui/material';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import DataGridCustom from '../../Components/DataGrid/DataGridCustom'
import Box from '@mui/material/Box'

function CadastroPedido() {

    const { user, isHumburguerActive } = useContext(AuthContext);
    const { 
        getFuncao, 
        funcao, 
        deleteFuncao,  
        getFuncaoDepartamento,
        funcaoDepartamento,
        alterFuncao,
        saveFuncao, formConteudo
     } = useContext(CrudContext);
    const [Funcao, setFuncao] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [newProductsDialog, setNewProductsDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [novaFuncao, setNovaFuncao] = useState();
    const [departamento, setDepartamento] = useState();

    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');

    const [productDialog, setProductDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        getFuncao();
        getFuncaoDepartamento()
        initFilters1();
    }, [])


    useEffect(() => {
        setFuncao(funcao)
    }, [funcao])

    useEffect(()=>{ 
        setSelectedCustomers(formConteudo)
      },[formConteudo])
      
    // --------------------------------------------------------------------------------------
    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveProduct(e)}
            />
        </React.Fragment>
    );

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setNewProductsDialog(!newProductsDialog)
    };

    const saveProduct = (e) => {
        setSubmitted(true);
        handleSavePedido(e)
        setNewProductsDialog(!newProductsDialog)
    }

    // ---------------------------------------------------------------------------------------

    async function handleSavePedido(e) {
        e.preventDefault();
        if (novaFuncao) {
            const funcaoDep = []
            funcaoDepartamento.filter( item => {
                if(item.label == departamento){
                    funcaoDep.push({
                        id: item._id, Descricao: item.label
                    })
                }
            }) 
            const payload = {
                Descricao: novaFuncao,
                Departamento: funcaoDep
            }
                        
            saveFuncao(payload);
            setNovaFuncao('');
            setDepartamento('');
        }
        else {
            toast.error('Preencha todos os campos')
        }
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const openNew = () => {
        setNewProductsDialog(true)
        setNovaFuncao()
        setDepartamento()
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProduct = () => {
        deleteFuncao(selectedCustomers);
        setDeleteProductsDialog(false);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

        });
        setGlobalFilterValue1('');
    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />
                <Button label="Editar" icon="pi pi-check" className="p-button-alert" onClick={() => confirmeEditar()} disabled={selectedCustomers?.length === 1 ? false : true} />
            </React.Fragment>
        )
    }

    const confirmeEditar = () => {
        setIsEdit(!isEdit)
        const filterFuncao = funcao.filter( item => item._id === selectedCustomers[0])[0]
        setNovaFuncao(filterFuncao.Descricao)
        setDepartamento(filterFuncao.Departamento)
        // setDataItemEdit()
    }
    const columns = [
        {field: 'id', headerName: 'id', width: 50 },
        {field: 'Descricao', headerName: 'Descrição', width: 250 },
        {field: 'Departamento', headerName: 'Departamento', width: 200 }
      ]

      const hideDialogAlter= ()=>{
        setIsEdit(!isEdit)
    }

    const productDialogFooterAlter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialogAlter()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => {
                    const _departamento = funcaoDepartamento.filter( item => item.label == departamento)
                    alterFuncao({
                        _id: selectedCustomers[0],
                        Departamento: [{ id: _departamento[0]._id, Descricao: _departamento[0].label}],
                        Descricao: novaFuncao
                    }); 
                    
                    setIsEdit(!isEdit)
                }}
            />
        </React.Fragment>
    );

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                {/* <Title nome="Cadastro de Pedido">
                </Title> */}

                <div className="container-profile">
                    {/* ---------------------------------------------------- */}
                    {newProductsDialog &&
                        <Dialog
                            visible={newProductsDialog}
                            style={{ width: "450px" }}
                            header="Nova Função"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Descrição"
                                    value={novaFuncao}
                                    onChange={(e) => setNovaFuncao(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Departamento"
                                    value={departamento}
                                    onChange={(e) => setDepartamento(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {funcaoDepartamento.map(option => (
                                        <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField> 
                            </div>
                        </Dialog>
                    }

                {isEdit &&
                        <Dialog
                            visible={isEdit}
                            style={{ width: "450px" }}
                            header="Editar Função"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooterAlter}
                            onHide={hideDialogAlter}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Descrição"
                                    value={novaFuncao}
                                    onChange={(e) => setNovaFuncao(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Departamento"
                                    value={departamento}
                                    onChange={(e) => setDepartamento(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {funcaoDepartamento.map(option => (
                                        <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Dialog>
                    }
                    {/* ---------------------------------------------------- */}

                    {Funcao &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                            <Box sx={{ height: 800, width: '100%' }}>
                                <DataGridCustom 
                                    column={columns} 
                                    rows={Funcao}                
                                />
                            </Box>
                        </div>
                    }
                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {deleteProductsDialog && <span>Tem certeza que gostaria de deletar os pedidos <b>selecionados</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div >
        </div >
    )
}


export default CadastroPedido;