import React, { useContext, useState, useEffect, useRef } from 'react'
import AWS from 'aws-sdk'

//context
import { AuthContext } from '../../contexts/auth'
import { ImportacaoContext } from '../../contexts/importacao'

import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { MenuItem } from '@mui/material'
import LoadingIcons from 'react-loading-icons'

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

//utils
import { extensoesPermitidas } from '../../utils/index'

//components primereact/material
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toolbar } from 'primereact/toolbar'
import Header from '../../Components/Header'
import { InputTextarea } from 'primereact/inputtextarea';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

//css
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';
import './arquivos.css'
import { CrudContext } from '../../contexts/crud'
import { tipoArquivo as tipoAqruivoData } from '../../utils/data'
import { FilterMatchMode } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import DataGridCustom from '../../Components/DataGrid/DataGridCustom'

window.Buffer = window.Buffer || require("buffer").Buffer;

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};


function Arquivos() {

  const { user, isHumburguerActive } = useContext(AuthContext);
  const {
    arquivos,
    setArquivos,
    getAllFiles,
    sendFile,
    deleteFiles,
    setTotalArquivosParaDeletar,
    gridHistorico,
    setGridHistorico,
    load, 
    salvarArquivoAzureStorageBlod,
    donwloadAzureStorageBlod
  } = useContext(ImportacaoContext);
  const { getEmpresas, empresas, formConteudo } = useContext(CrudContext);
  const [empresa, setEmpresa] = useState()

  const [filterEmpresaAdm, setFilterEmpresaAdm] = useState(null);

  const ref = useRef();
  const [fileName, setFileName] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [filesDocuments, setFilesDocuments] = useState(null)
  const [observacoes, setObservacoes] = useState('')
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState(null);


  const [newProductsDialog, setNewProductsDialog] = useState(false);
  const [productDialog, setProductDialog] = useState(false)
  const [submitted, setSubmitted] = useState(false);
  const [isOutros, setIsOutros] = useState(false)

  const [isAdm, setIsAdm] = useState(() => {
    const isAdm = user.tipoUsuario === "Administrador Global" || user.tipoUsuario === "Administrador Analytics" || user.tipoUsuario === "Analista Analytcs" ? true : false
    return isAdm
  });

  const [tipoArquivo, setTipoArquivo] = useState()
  const [funcao, setFuncao] = useState(null)
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState('');
  const [progress, setProgress] = useState(0);

  const S3_BUCKET = 'pactfy';
  const REGION = 'sa-east-1';

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
  ]

  AWS.config.update({
    accessKeyId: 'AKIA4WG3WIF7RD2QGTT2',
    secretAccessKey: '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'
  })

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  })


  useEffect(() => {
    getAllFiles(user.uid, isAdm)
    getEmpresas()
    setGridHistorico(arquivos);
    initFilters1();
  }, [])

  useEffect(() => {
    if (user?.tipoUsuario === "Cliente") {
      setFilterEmpresaAdm(user.cliente)
    } else {
      setFilterEmpresaAdm(empresas)
    }
  }, [empresas])

  useEffect(()=>{ 
    setSelectedCustomers(formConteudo)
  },[formConteudo])

  const initFilters1 = () => {
    setFilters1({
      'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

    });
    setGlobalFilterValue1('');
  }

  // --------------------------------------------------------------------------------------

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
    setNewProductsDialog(!newProductsDialog)
    setObservacoes('')
  };

  const renderHeader1 = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue1} onChange={(e) => onGlobalFilterChange1(e)} placeholder="Pesquisar" />
        </span>
      </div>
    )
  }

  const header1 = renderHeader1();

  // ---------------------------------------------------------------------------------------

  async function handleFile(e) {
    const file = e.target.files[0];
    const permitido = extensoesPermitidas(file.name)

    if (!permitido) {
      ref.current.value = ""
      toast.error("Arquivo não permitido")
    }

    if (permitido) {
      setFileName(file.name);
      setFileData(file)
    }
  };


  async function handleUpload() {
    const currentUid = user.uid;
    
    let payloadData = {
      arquivo: fileName,
      user: JSON.stringify([{ id: user.uid, name: user.nome }]),
      baseClient: empresa,
      fileType: tipoArquivo,
      occupation: funcao,
      comments: observacoes,
      url: '',
      company: empresa,
      key: fileName.replaceAll(' ', '+'),
      userType: user.tipoUsuario
    };

    await salvarArquivoAzureStorageBlod(fileData, payloadData)

      getAllFiles(user.uid, isAdm)
    
  }


  function importarArquivo() {
    if (!fileData || !empresa) {
      return toast.error('Necessário selecionar um arquivo e uma empresa')
    }


    if (fileData && empresa) {
      handleUpload()
      ref.current.value = "";
      setFileData(null)
      setFileName(null)
      setFuncao(null)
      hideDialog()
    }

  }

  const dataTamplateDownload = (rowData, column) => {
    return <div>
      <a href={rowData.urlDocumentoDownload} target={'_blank'}>Download</a>
    </div>
  }

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={() => hideDeleteProductDialog()} />
      <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={() => deleteProduct()} />
    </React.Fragment>
  );

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="Delete" icon="pi pi-trash" className="p-button-danger" style={{ width: '150px' }}
          onClick={() => { confirmDeleteSelected(); setTotalArquivosParaDeletar(selectedCustomers.length) }} disabled={!selectedCustomers || !selectedCustomers.length} />
        <Button label={"Importar"} icon={"pi pi-plus"} className={"p-button-new"} style={{ width: '150px' }} onClick={openNew} />
      </React.Fragment>
    )
  }

  const handledEmpresa = (event, values) => {

    let filesToFilter = [];
    let _arquivosCopyFilter = [...gridHistorico];

    values.forEach((item) => {
      filesToFilter.push(item.name)
    })


    if (filesToFilter.length === 0) {
      setArquivos(gridHistorico)
    }

    if (filesToFilter.length > 0) {
      let _arquivoFilter = _arquivosCopyFilter.filter(files => filesToFilter.includes(files.empresa));
      setArquivos(_arquivoFilter)
    }
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        {empresas &&
          <>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={empresas}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField {...params} label="Base Cliente" placeholder="Base Cliente" />
              )}
              onChange={handledEmpresa}
            />
          </>
        }
      </React.Fragment>
    )
  }

  const openNew = () => {
    setNewProductsDialog(true)
    setFuncao('')
    setEmpresa(empresas)
    setTipoArquivo(tipoAqruivoData)
  }
  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  }

  const hideDeleteProductDialog = () => {
    setDeleteProductsDialog(false);
  }

  const deleteProduct = () => {
    let deletar = [];
    let count = 1

    selectedCustomers.forEach(ids => {      
      deleteFiles(ids, count)
      count++
    })

    setSelectedCustomers([])
    setDeleteProductsDialog(false);

    setTimeout(() => {
      getAllFiles(user.uid, isAdm)
    }, 1000);
  }

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1['global'].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  }

  const columns = [
    {field: 'id', headerName: 'id', width: 50 },
    {field: 'arquivo', headerName: 'Arquivo', width: 250 },
    {field: 'userName', headerName: 'Usuário', width: 200 },
    {field: 'empresa', headerName: 'Base Cliente', width: 200 },
    {field: 'tipoArquivo', headerName: 'Tipo de arquivos', width: 250 },
    {field: 'funcao', headerName: 'Função', width: 250 },
    {field: 'data', headerName: 'Data', width: 200 },
    {field: 'observacoes', headerName: 'Observações', width: 250 },
    {field: 'urlDocumentoDownload', headerName: 'Download', width: 100, renderCell: (params)=> {
      const fileUrl = params.row.urlDocumentoDownload
      const fileName = 'Download'
      return ( <>
       {fileUrl? (<a href={fileUrl} target="_blank" download={true}> {fileName} </a>) : 
       fileUrl === '' && params.row.azureStorageBlod? (downloadArquivo(params)) : <></>}
     </>)
     } 
   }
  ]

  const downloadArquivo = (event) =>{       
    const { accountName, containerName, blobName } = event.row.azureStorageBlod
    return(<div className='download-arquivos'>
       <a className='download-ged' onClick={ async () => await download(accountName, containerName, blobName )} >Download</a>        
    </div>)        
  }

  const download = async (accountName, containerName, blobName) => {
    const linkDownload = await donwloadAzureStorageBlod(accountName, containerName, blobName)       
    const a = document.createElement('a');
    a.href = linkDownload;
    a.download = ''; 
    a.rel = 'noopener noreferrer';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  return (
    <div className="App">
      <Header />
      <div className={`content${isHumburguerActive ? '-active' : ''}`}>
        {/* <Title nome="Arquivos">
        </Title> */}
        <div className="container-profile">
          {load ?
            <div className='icon-loading'>
              <LoadingIcons.SpinningCircles stroke="#5499e9" />
            </div> : <>
              {arquivos &&
                <div className="card p-fluid">
                  <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                  <Box sx={{ height: 800, width: '100%' }}>
                                <DataGridCustom 
                                    column={columns} 
                                    rows={arquivos}                
                                />
                  </Box>
                  <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                    <div className="confirmation-content">
                      <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                      {deleteProductsDialog && <span>Tem certeza que gostaria de deletar esse arquivo <b>selecionado</b>?</span>}
                    </div>
                  </Dialog>
                </div>
              }

              {newProductsDialog &&
                <Dialog
                  visible={newProductsDialog}
                  style={{ width: "450px" }}
                  header="Importar aquivos"
                  modal
                  className="card p-fluid"
                  onHide={hideDialog}
                >
                  <div className="field">
                    <input id="arquivo-empresa" type="file" className="upload-box" onChange={(e) => handleFile(e)} ref={ref} />
                  </div>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Base Cliente"
                    value={empresa}
                    onChange={(e) => { setEmpresa(e.target.value) }}
                    margin="normal"
                    variant="outlined"
                  >
                    {filterEmpresaAdm?.map(option => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>


                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Tipo de arquivo"
                    value={tipoArquivo}
                    onChange={(e) => {
                      setTipoArquivo(e.target.value);
                      if (e.target.value === "Outros") {
                        setIsOutros(true)
                      } else (setIsOutros(false))
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {tipoAqruivoData?.map(option => (
                      <MenuItem key={option.value} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  {isOutros &&
                    <div className="field">
                      <label htmlFor="funcao">Função</label>
                      <InputTextarea id="funcao" value={funcao} onChange={(e) => setFuncao(e.target.value)} required rows={3} cols={20} />
                    </div>
                  }
                  <div className="field">
                    <label htmlFor="observacoes">Observações</label>
                    <InputTextarea id="observacoes" value={observacoes} onChange={(e) => setObservacoes(e.target.value)} required rows={3} cols={20} />
                  </div>


                  <div className="field button-right">
                    <Button
                      label={'Enviar'}
                      id={'button-right'}
                      className="p-button-success mr-2"
                      onClick={() => { importarArquivo() }}
                      style={{ width: '100px', margin: '1rem 0' }}
                    />
                  </div>
                </Dialog>
              }
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default Arquivos;
