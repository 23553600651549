import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { CrudContext } from '../../contexts/crud';
import './profile.css';
import { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import Header from '../../Components/Header'
import avatar from '../../assets/avatar.png'

import { toast } from 'react-toastify'
import { FiUpload } from 'react-icons/fi'
import { UserType } from '../../services/list';
import { MenuItem, TextField } from '@mui/material';
import { AutoComplete } from 'primereact/autocomplete';

import AWS from 'aws-sdk'

window.Buffer = window.Buffer || require("buffer").Buffer;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

function Profile() {
    const [value, setValue] = useState(0);
    const [isHidden, setIsHidden] = useState(true)
    const [btnDisabled, setBtnDisabled] = useState(true)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { user, updateMyUser, storageUser, isHumburguerActive, updateAvatar, updatePassword } = useContext(AuthContext);
    const [nome, setNome] = useState(user && user.nome);
    const [senhaAtual, setSenhaAtual] = useState('');
    const [novaSenha, setNovaSenha] = useState('');
    const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');
    const [tipoUsuario, setTipoUsuario] = useState(user && user.tipoUsuario);
    const [cliente, setCliente] = useState(user && user.cliente);

    const { getEmpresas, empresas, funcaoDepartamento, getFuncaoDepartamento, getFuncao, funcao, setFuncao } = useContext(CrudContext);

    const [email, setEmail] = useState(user && user.email);
    const [departamento, setDepartamento] = useState(user && user.departamento);
    const [efuncao, setEfuncao] = useState(user && user.funcao);
    const [funcaoFiltrada, setFuncaoFiltrada] = useState(null);
    const [avatarURL, setAvatarURL] = useState(user && user.avatarUrl);
    const [imageAvatar, setImageAvatar] = useState(null);
    const [filteredCompanies, setFilteredCompanies] = useState(null);
    const [isClient, setIsClient] = useState(false);
    const [isAlterUser, setIsAlterUser] = useState(true);
    const [loadSenha, setLoadSenha] = useState(false);
    const [filtroFuncao, setFiltroFuncao] = useState(null);

    const [selectedCompanies, setSelectedCompanies] = useState(user && user.cliente);

    const [progress, setProgress] = useState(0);

    const S3_BUCKET = 'pactfy';
    const REGION = 'sa-east-1';

    AWS.config.update({
        accessKeyId: 'AKIA4WG3WIF7RD2QGTT2',
        secretAccessKey: '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'
    })

    const myBucket = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    })

    const searchCompany = (event) => {
        setTimeout(() => {
            let _filteredCompanies;
            if (!event.query.trim().length) {
                _filteredCompanies = [...empresas];
            }
            else {
                _filteredCompanies = empresas.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredCompanies(_filteredCompanies);
        }, 250);
    }


    function HandleFile(e) {

        if (e.target.files[0]) {
            const image = e.target.files[0]

            if (image.type === 'image/jpeg' || image.type === 'image/png') {
                setImageAvatar(image);
                setAvatarURL(URL.createObjectURL(e.target.files[0]))
            }
            else {
                toast.error('Envie uma imagem do tipo PNG')
                setImageAvatar(null)
                return null
            }
        }
    }

    async function handleUpload() {
        const currentUid = user.uid;

        const params = {
            ACL: 'public-read',
            Body: imageAvatar,
            Bucket: S3_BUCKET,
            Key: 'images/' + imageAvatar.name,
            ContentType: imageAvatar.type
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .on('success', (evt) => {

                let fileNameReplaced = imageAvatar.name.replaceAll(' ', '+')
                let urlDocumentoDownload = 'https://pactfy.s3.sa-east-1.amazonaws.com/images/' + fileNameReplaced;

                let data = {
                    id: '636da408f73baaf0144d3a23',
                    avatarUrl: urlDocumentoDownload
                };

                updateAvatar(data);
            })
            .send((err) => {
                if (err) console.log(err)
            })
    }

    useEffect(() => {
        setIsHidden(false)
        if (user) {
            if (user.tipoUsuario === 'Cliente') {
                setIsHidden(true)
                setIsClient(true)
            }
        }

        getEmpresas();
        getFuncaoDepartamento();
        getFuncao();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setFiltroFuncao(funcao)
    }, [funcao])// eslint-disable-line react-hooks/exhaustive-deps



    async function handleSave(e) {
        e.preventDefault();
        const user = JSON.parse(localStorage.getItem("SistemaUser"))
        const token = user.token

        if (imageAvatar === null && nome !== '') {

            let myUser = {
                key: user.uid,
                nome: nome,
                tipoUsuario: tipoUsuario,
                departamento: departamento,
                funcao: efuncao,
                cliente: selectedCompanies,
                token: token
            }

            await updateMyUser(myUser)

        }
        if (imageAvatar !== null) {
            handleUpload()
        }
        setBtnDisabled(true)
    }

    async function AlterPassword() {
        setLoadSenha(true)
        try {
            if (senhaAtual === '' || novaSenha === '' || confirmarNovaSenha === '') {
                toast.error('Todos os campos devem ser preenchidos')
            }
            else {
                if (novaSenha !== confirmarNovaSenha) {
                    toast.error('Nova senha e confirmar senha não confere')
                }
                else {
                    let ret = await updatePassword(user.email, senhaAtual, novaSenha, confirmarNovaSenha)

                    if (!ret) {
                        setIsAlterUser(true)
                        setSenhaAtual('')
                        setNovaSenha('')
                        setConfirmarNovaSenha('')
                    }
                }
            }
        } catch (e) {

        }

        setLoadSenha(false)

    }

    function filterFuncao(depto) {
        let _filter = [...funcao]
        let filtrado = _filter.filter(item => item.Departamento === depto)
        setFiltroFuncao(filtrado)
    }

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>

                <div className="container-profile">
                    <form className="form-profile" onSubmit={handleSave} id="form-profile">
                        <div className='profile-avatar'>
                            <label className="label-p-avatar">
                                <span>
                                    <FiUpload color="#fff" size={25} />
                                </span>
                                <input type="file" accept="image/*" onChange={HandleFile} /><br />
                                {avatarURL === null ?
                                    <img src={avatar} width={250} height={250} alt="Foto Perfil" />
                                    :
                                    <img src={avatarURL} width={250} height={250} alt="Foto Perfil" />
                                }
                            </label>

                            <span className='avatar-name'>{user.nome}</span>
                            <span className='avatar-profi'>{user.tipoUsuario}</span>
                        </div>
                        <div className='profile-info'>
                            {!isAlterUser &&
                                <>
                                    <div className="form-box">
                                        <TextField
                                            id="outlined-name"
                                            label="Senha Atual"
                                            value={senhaAtual}
                                            onChange={(e) => setSenhaAtual(e.target.value)}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </div>

                                    <div className="form-box">
                                        <TextField
                                            id="outlined-name"
                                            label="Nova senha"
                                            value={novaSenha}
                                            onChange={(e) => setNovaSenha(e.target.value)}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </div>

                                    <div className="form-box">
                                        <TextField
                                            id="outlined-name"
                                            label="Confirmar nova senha"
                                            value={confirmarNovaSenha}
                                            onChange={(e) => setConfirmarNovaSenha(e.target.value)}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    </div>

                                    <button id='submit-profile-info' type="button" onClick={AlterPassword}>{!loadSenha ? 'Alterar' : 'Alterando...'}</button>
                                    <button id='submit-profile-info' type="button" onClick={() => setIsAlterUser(true)}>Cancelar</button>
                                </>
                            }

                            {isAlterUser && <>
                                <div className="form-box">
                                    <TextField
                                        id="outlined-name"
                                        label="Nome"
                                        value={nome}
                                        onChange={(e) => setNome(e.target.value)}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </div>

                                <div className="form-box">
                                    <TextField
                                        id="outlined-email"
                                        label="E-mail"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        margin="normal"
                                        variant="outlined"
                                        type={'email'}
                                        disabled={true}
                                    />
                                </div>

                                {funcaoDepartamento &&
                                    <div className="form-box">
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Departamento"
                                            fullWidth
                                            value={departamento}
                                            onChange={(e) => {
                                                setDepartamento(e.target.value)
                                                filterFuncao(e.target.value)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={btnDisabled}
                                        >
                                            {funcaoDepartamento.map(option => (
                                                <MenuItem key={option.value} value={option.label}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                }

                                {filtroFuncao &&
                                    <div className="form-box">
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Função"
                                            fullWidth
                                            value={efuncao}
                                            onChange={(e) => {
                                                setEfuncao(e.target.value)
                                            }}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={btnDisabled}
                                        >
                                            {
                                                filtroFuncao.map(option => (
                                                    <MenuItem key={option.Descricao} value={option.Descricao}>
                                                        {option.Descricao}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </div>
                                }

                                {!isClient &&
                                    <>
                                        <div className="form-box">
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Tipo de usuário"
                                                fullWidth
                                                value={tipoUsuario}
                                                onChange={(e) => {
                                                    if (e.target.value === 'Cliente') {
                                                        setIsHidden(true)
                                                    } else (
                                                        setIsHidden(false)
                                                    )
                                                    setTipoUsuario(e.target.value)
                                                }}
                                                margin="normal"
                                                variant="outlined"
                                                disabled={btnDisabled}
                                            >
                                                {UserType.map(option => (
                                                    <MenuItem key={option.value} value={option.label}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>


                                        {isHidden &&
                                            <div className="form-box">
                                                <AutoComplete
                                                    margin="normal"
                                                    variant="outlined"
                                                    placeholder="Base Cliente"
                                                    disabled={btnDisabled}
                                                    value={selectedCompanies}
                                                    suggestions={filteredCompanies}
                                                    fullWidth
                                                    id="outlined-profile-info"
                                                    completeMethod={searchCompany}
                                                    field="name" multiple
                                                    onChange={(e) => setSelectedCompanies(e.value)}
                                                    aria-label="Empresas"
                                                />
                                            </div>
                                        }
                                    </>
                                }

                                <div className='perfil-botoes'>
                                    <button id='submit-profile-info' type="submit">Salvar</button>
                                    {!isClient &&
                                        <button id='submit-profile-info' type="button" onClick={() => setBtnDisabled(false)}>Editar</button>
                                    }
                                    <button id='submit-profile-info' type="button" onClick={() => setIsAlterUser(false)}>Alterar Senha</button>
                                </div>
                            </>}
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export default Profile;