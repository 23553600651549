import { useState, createContext, useContext } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios';
import { AuthContext } from './auth'

export const CrudContext = createContext({})

function CrudProvider({ children }) {

    const { user } = useContext(AuthContext);
    const [empresas, setEmpresas] = useState([]);
    const [momentos, setMomentos] = useState(null);
    const [momentosExecucao, setMomentosExecucao] = useState(null);
    const [pedidos, setPedidos] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [advogados, setAdvogados] = useState([]);
    const [advogadosList, setAdvogadosList] = useState([]);
    const [filter, setFilter] = useState([])
    const [office, setOffice] = useState([])
    const [dicionary, setDicionary] = useState([])
    const [empresaGrid, setEmpresaGrid] = useState([])
    const [empresaUserAdm, setEmpresaUserAdm] = useState([])
    const [load, setLoad] = useState(false);
    const [departamento, setDepartamento] = useState([])
    const [funcao, setFuncao] = useState([])
    const [funcaoDepartamento, setFuncaoDepartamento] = useState([])
    const [atividades, setAtividades] = useState([])
    const [higienizacaoProcess, setHigienizacaoProcess] = useState([])
    const [processosMonitorados, setProcessosMonitorados] = useState([])
    const [processoAndamento, setProcessoAndamento] = useState([])
    const [formConteudo, setFormConteudo] = useState()
    const [reports, setReports] = useState(null)

    const urlBase = process.env.REACT_APP_PACT_API;
    const urlBaseDigesto = "https://be-digesto.azurewebsites.net";

    /**
     * @param {[{ name: string, oab:[] }]} payload
     */
    async function saveAdvogado(payload) {
        let formatData = {
            name: payload.name,
            oab: payload.oab,
            oabList: '',
            tipo: payload?.tipo,
            office: payload?.office
        }

        await axios.post(urlBase + '/lawyers/create',
            formatData,
            {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }
        )
            .then(function (response) {
                toast.success('Inserido com sucesso')
                getAdvogado();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function getFilters() {
        await axios.get(urlBase + '/filters/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then((response) => {
                let lista = [];
                if (response) {
                    response.data.forEach(item => {
                        lista.push({
                            id: item._id,
                            usuario: item.usuario,
                            descricao: item.descricao,
                            filtro: item.filtro
                        })
                    })
                    setFilter(lista)
                }
            })
            .catch(err => {
                console.log("getFilters_error: ", err)
            })
    }

    async function getFiltersByUserId(payload) {
        await axios.get(urlBase + "/filters/findByUserId/" + payload.id, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then((response) => {
                let lista = [];
                if (response) {
                    response.data.forEach(item => {
                        lista.push({
                            id: item._id,
                            usuario: item.usuario,
                            descricao: item.descricao,
                            filtro: item.filtro
                        })
                    })
                    setFilter(lista)
                }
            })
            .catch(err => {
                console.log("getFiltersByUserId: ", err)
            })
    }

    async function deleteFilter(filters) {
        await axios.delete(urlBase + '/filters/deleteById',
            {
                data: { filterDelete: JSON.parse(JSON.stringify(filters)) },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso')
                getFilters();
            })
            .catch(err => {
                console.log("getFilters_error: ", err)
                toast.error('Algo deu errado')
            })
    }

    async function deleteFilterUserClient(filters, payload) {
        await axios.delete(urlBase + '/filters/deleteById',
            {
                data: { filterDelete: JSON.parse(JSON.stringify(filters)) },

                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso')
                getFiltersByUserId(payload);
            })
            .catch(err => {
                console.log("getFilters_error: ", err)
                toast.error('Algo deu errado')
            })
    }

    //-----------------------------Office--------------------------------//
    //-------------------------------------------------------------------//
    //-------------------------------------------------------------------//
    async function getOffice() {
        await axios.get(urlBase + '/office/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then((response) => {
                let lista = [];
                if (response) {
                    response.data.forEach(item => {
                        lista.push({
                            id: item._id,
                            description: item.description
                        })
                    })
                    setOffice(lista)
                }
            })
            .catch(err => {
                console.log("getFilters_error: ", err)
            })
    }

    async function saveOffice(payload) {
        let formatData = {
            description: payload.description
        }

        await axios.post(urlBase + '/office/create',
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Inserido com sucesso')
                getOffice();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function deleteOffice(filters) {
        await axios.delete(urlBase + '/office/deleteById',
            {
                data: { officeDelete: JSON.parse(JSON.stringify(filters)) },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso')
                getOffice();
            })
            .catch(err => {
                console.log("deleteOffice: ", err)
                toast.error('Algo deu errado')
            })
    }

    async function alterOffice(payload) {
        let formatData = {
            description: payload.description
        }

        await axios.put(urlBase + '/office/' + payload.id,
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Registro alterado com sucesso')
                getOffice()
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }
    //---------------------------- Office-End ---------------------------//
    //-------------------------------------------------------------------//
    //---------------------------- Dicionary ----------------------------//
    async function getDicionary() {
        await axios.get(urlBase + '/dicionary/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then((response) => {
                let lista = [];
                if (response) {
                    response.data.forEach(item => {
                        lista.push({
                            id: item._id,
                            empresa: item.empresa,
                            tipo1: item.tipo1,
                            tipo2: item.tipo2,
                            tipo3: item.tipo3,
                            tipo4: item.tipo4,
                            tipo5: item.tipo5,
                            tipo6: item.tipo6,
                            tipo7: item.tipo7,
                            tipo8: item.tipo8,
                            tipo9: item.tipo9,
                            tipo10: item.tipo10
                        })
                    })
                    setDicionary(lista)
                }
            })
            .catch(err => {
                console.log("getDicionary: ", err)
            })
    }

    async function registrarAtividade(payload) {
        try {
            await axios.post(urlBase + '/atividades/registrar',
                payload, {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }).then(function (response) {
                toast.success('Inserido com sucesso')
            }).catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
        } catch (error) {
            toast.error('Algo deu errado.')
        }
    }

    async function saveDicionary(payload) {
        let formatData = {
            empresa: payload.empresa,
            tipo1: payload.tipo1,
            tipo2: payload.tipo2,
            tipo3: payload.tipo3,
            tipo4: payload.tipo4,
            tipo5: payload.tipo5,
            tipo6: payload.tipo6,
            tipo7: payload.tipo7,
            tipo8: payload.tipo8,
            tipo9: payload.tipo9,
            tipo10: payload.tipo10
        }

        await axios.post(urlBase + '/dicionary/create',
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Inserido com sucesso')
                getDicionary();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function deleteDicionary(filters) {
        await axios.delete(urlBase + '/dicionary/deleteById',
            {
                data: { dicionaryDelete: JSON.parse(JSON.stringify(filters)) },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso')
                getDicionary();
            })
            .catch(err => {
                console.log("deleteOffice: ", err)
                toast.error('Algo deu errado')
            })
    }

    async function alterDicionary(payload) {
        let formatData = {
            id: payload.id,
            empresa: payload.empresa,
            tipo1: payload.tipo1,
            tipo2: payload.tipo2,
            tipo3: payload.tipo3,
            tipo4: payload.tipo4,
            tipo5: payload.tipo5,
            tipo6: payload.tipo6,
            tipo7: payload.tipo7,
            tipo8: payload.tipo8,
            tipo9: payload.tipo9,
            tipo10: payload.tipo10
        }

        await axios.put(urlBase + '/dicionary/' + payload.id,
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Registro alterado com sucesso')
                getDicionary()
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }
    //---------------------------- Dicionary-End ------------------------//
    //-------------------------------------------------------------------//



    async function getAdvogado() {
        setAdvogados([])
        setLoad(true)

        await axios.get(urlBase + '/lawyers/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                updateAdvogados(response.data)
                setLoad(false)
            })
            .catch(err => {
                console.log("Erro", err)
                toast.error('Algo deu errado')
                setLoad(false)
            })
    }

    function updateAdvogados(snapshot) {
        const isCollectionEmpty = snapshot.size === 0;

        if (!isCollectionEmpty) {
            let lista = [];
            let listaadv = [];

            snapshot.forEach((doc) => {
                lista.push({
                    id: doc._id,
                    name: doc.name,
                    oab: doc.oab,
                    oabList: doc.oabList ? doc.oabList : '',
                    tipo: doc.tipo ? doc.tipo : '',
                    office: doc.office ? doc.office : ''
                })
            })

            snapshot.forEach((doc) => {
                listaadv.push({
                    id: doc._id,
                    label: doc.name
                })
            })

            lista.forEach((n, index) => {
                let oabFinal = '';

                if (n.oab.length > 0) {
                    n.oab?.forEach((oab) => {
                        if (oabFinal === '') {
                            oabFinal = oab.oab
                        }
                        else {
                            oabFinal = oabFinal + ', ' + oab.oab
                        }
                    })
                }
                lista[index].oabList = oabFinal
            })

            listaadv.push({
                id: 999,
                label: 'Todos'
            })

            setAdvogados(lista);
            setAdvogadosList(listaadv);
        } else {
            setIsEmpty(true);
        }
    }

    async function deleteAdvogado(advogado) {
        await axios.delete(urlBase + '/lawyers/deleteById',
            {
                data: { lawyersDelete: JSON.parse(JSON.stringify(advogado)) },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso')
                getAdvogado();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function alterAdvogado(payload) {
        let formatData = {
            name: payload.name,
            oab: payload.oab,
            oabList: payload.oabList,
            tipo: payload.tipo,
            office: payload.office
        }

        await axios.put(urlBase + '/lawyers/' + payload.id,
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Registro alterado com sucesso')
                getAdvogado()
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }



    async function saveEmpresa(payload) {
        await axios.post(urlBase + '/company/create',
            payload, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Inserido com sucesso')
                getEmpresas();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function getEmpresas() {
        setEmpresas([])
        setLoad(true)

        await axios.get(urlBase + '/company/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                updateEmpresa(response.data)
                setLoad(false)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
                setLoad(false)
            })
    }

    async function getEmpresasUserAdm(user) {
        setEmpresaUserAdm([])
        let lista = []

        if (user.tipoUsuario === 'Cliente') {
            user.cliente.forEach((doc) => {
                lista.push({
                    id: doc.id,
                    empresa: doc.name,
                    url: doc.url
                })
            })
            setEmpresaUserAdm(lista)

        } else {
            await axios.get(urlBase + '/company/all', {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
                .then(function (response) {

                    response.data.forEach((doc) => {

                        lista.push({
                            id: doc._id,
                            empresa: doc.name,
                            url: doc.url
                        })
                    })

                    setEmpresaUserAdm(lista)

                })
                .catch(err => {
                    console.log(err)
                    toast.error('Algo deu errado')
                })
        }
    }



    function updateEmpresa(snapshot) {
        const isCollectionEmpty = snapshot.size === 0;

        if (!isCollectionEmpty) {
            let lista = [];

            snapshot.forEach((doc) => {
                lista.push({
                    id: doc._id,
                    name: doc.name,
                    url: doc.url,
                    basico: doc?.basico === true ? "Sim" : "Não",
                    calculos: doc?.calculos === true ? "Sim" : "Não",
                    encerrados: doc?.encerrados === true ? "Sim" : "Não",
                    fase: doc?.fase === true ? "Sim" : "Não",
                    financeira: doc?.financeira === true ? "Sim" : "Não",
                    funcao: doc?.funcao === true ? "Sim" : "Não",
                    garantias: doc?.garantias === true ? "Sim" : "Não",
                    pedidos: doc?.pedidos === true ? "Sim" : "Não",
                    pericias: doc?.pericias === true ? "Sim" : "Não",
                    resersao: doc?.resersao === true ? "Sim" : "Não",
                    customizado: doc?.customizado === true ? "Sim" : "Não",
                    audiencias: doc?.audiencias === true ? "Sim" : "Não",
                    acordos: doc?.acordos === true ? "Sim" : "Não",
                    tipo1: doc?.tipo1 === true ? "Sim" : "Não",
                    tipo2: doc?.tipo2 === true ? "Sim" : "Não",
                    tipo3: doc?.tipo3 === true ? "Sim" : "Não",
                    tipo4: doc?.tipo4 === true ? "Sim" : "Não",
                    tipo5: doc?.tipo5 === true ? "Sim" : "Não",
                    tipo6: doc?.tipo6 === true ? "Sim" : "Não",
                    tipo7: doc?.tipo7 === true ? "Sim" : "Não",
                    tipo8: doc?.tipo8 === true ? "Sim" : "Não",
                    tipo9: doc?.tipo9 === true ? "Sim" : "Não",
                    tipo10: doc?.tipo10 === true ? "Sim" : "Não",

                })
            })

            setEmpresas(lista);
        } else {
            setIsEmpty(true);
        }
    }


    async function saveMomento(descricao, fase) {
        let formatData = {
            description: descricao,
            fase: fase
        }

        await axios.post(urlBase + '/moments/create',
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Inserido com sucesso');
                getMomentos();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function getMomentos() {
        setMomentos([])
        setMomentosExecucao([])

        await axios.get(urlBase + '/moments/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                updateMomento(response.data)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    function updateMomento(snapshot) {
        const isCollectionEmpty = snapshot.size === 0;

        if (!isCollectionEmpty) {
            let lista = [];

            snapshot.forEach((doc) => {
                lista.push({
                    id: doc._id,
                    description: doc.description,
                    fase: doc.fase
                })
            })
            setMomentos(lista);

            let listaFiltrada = [...lista]
            listaFiltrada = listaFiltrada.filter(momento => momento.fase === 'Execução')

            setMomentosExecucao(listaFiltrada)

        } else {
            setIsEmpty(true);
        }
    }



    async function savePedidos(descricao, tipoVerba) {
        let formatData = {
            description: descricao,
            tipoVerba: tipoVerba
        }

        await axios.post(urlBase + '/pedidos/create',
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Inserido com sucesso');
                getPedidos();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function getPedidos() {
        setPedidos([])

        await axios.get(urlBase + '/pedidos/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                updatePedido(response.data)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    function updatePedido(snapshot) {
        const isCollectionEmpty = snapshot.size === 0;

        if (!isCollectionEmpty) {
            let lista = [];

            snapshot.forEach((doc) => {
                lista.push({
                    id: doc._id,
                    description: doc.description,
                    tipoVerba: doc.tipoVerba
                })
            })
            setPedidos(lista);
        } else {
            setIsEmpty(true);
        }
    }

    async function alterMomento(moment) {
        let formatData = {
            description: moment.description,
            fase: moment.fase
        }

        await axios.put(urlBase + '/moments/' + moment.id,
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Alterado com sucesso')
                getMomentos()
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function alterEmpresa(empresa) {
        setLoad(true)
        await axios.put(urlBase + '/company/' + empresa.id,
            empresa, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Alterado com sucesso')
                getEmpresas();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
        setLoad(false)
    }

    async function alterPedido(pedido) {
        let formatData = {
            description: pedido.description,
            tipoVerba: pedido.tipoVerba
        }

        await axios.put(urlBase + '/pedidos/' + pedido.id,
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Alterado com sucesso')
                getPedidos()
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }



    async function deleteMomento(momentos) {
        await axios.delete(urlBase + '/moments/deleteById',
            {
                data: { momentsDelete: JSON.parse(JSON.stringify(momentos)) },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso')
                getMomentos();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function deleteEmpresa(companies) {

        await axios.delete(urlBase + '/company/deleteById',
            {
                data: { companyDelete: JSON.parse(JSON.stringify(companies)) },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso')
                getEmpresas();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function deletePedido(pedidos) {

        await axios.delete(urlBase + '/pedidos/deleteById',
            {
                data: { pedidosDelete: JSON.parse(JSON.stringify(pedidos)) },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso');
                getPedidos();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function getDepartamento() {
        setDepartamento([])
        let _departamentos = []
        await axios.get(urlBase + '/departamento/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                response.data.map(item => {
                    _departamentos.push({
                        Descricao: item.Descricao,
                        Lideres: item.Lider.join(', '),
                        _id: item._id,
                        id: item._id
                    })
                })
                setDepartamento(_departamentos)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function alterDepartamento(departamento) {
        let formatData = {
            Descricao: departamento.Descricao
        }

        await axios.put(urlBase + '/departamento/' + departamento.id,
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Alterado com sucesso')
                getDepartamento()
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function deleteDepartamento(departamento) {

        await axios.delete(urlBase + '/departamento/deleteById',
            {
                data: { departamentoDelete: JSON.parse(JSON.stringify(departamento)) },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso');
                getDepartamento();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function saveDepartamento(descricao, lider) {
        let formatData = {
            Descricao: descricao,
            Lider: lider
        }

        await axios.post(urlBase + '/departamento/create',
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Inserido com sucesso');
                getDepartamento();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function getFuncao() {
        setFuncao([])
        let _funcao = []

        await axios.get(urlBase + '/funcao/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {

                if (response.data) {
                    response.data.map(item => {
                        _funcao.push({
                            _id: item._id,
                            id: item._id,
                            Descricao: item.Descricao,
                            Departamento: item.Departamento[0].Descricao,
                            label: item.Descricao,
                            value: item.Descricao
                        })
                    })
                }
                setFuncao(_funcao)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function getFuncaoDepartamento() {
        setDepartamento([])
        let _funcaoDepartamento = []
        await axios.get(urlBase + '/departamento/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                if (response.data) {
                    response.data.map(item => {
                        _funcaoDepartamento.push({
                            label: item.Descricao,
                            value: item.Descricao,
                            Lider: item.LiderIds,
                            _id: item._id
                        })
                    })

                    _funcaoDepartamento.push({
                        label: 'N/A',
                        value: 'NA'
                    })

                    setFuncaoDepartamento(_funcaoDepartamento)
                }
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
        return _funcaoDepartamento
    }

    async function alterFuncao(funcao) {
        const formatData = {
            Descricao: funcao.Descricao,
            Departamento: funcao.Departamento
        }
        await axios.put(urlBase + '/funcao/' + funcao._id,
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                getFuncao()
                toast.success('Alterado com sucesso')
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function saveFuncao(payload) {
        let formatData = {
            Descricao: payload.Descricao,
            Departamento: payload.Departamento
        }

        await axios.post(urlBase + '/funcao/create',
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Inserido com sucesso');
                getFuncao();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function deleteFuncao(funcao) {

        await axios.delete(urlBase + '/funcao/deleteById',
            {
                data: { funcaoDelete: JSON.parse(JSON.stringify(funcao)) },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso');
                getFuncao();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function getAtividades() {
        setAtividades([])
        let _atividades = []

        await axios.get(urlBase + '/atividades/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                if (Array.isArray(response.data)) {
                    response.data.map(item => {
                        _atividades.push({
                            _id: item._id,
                            id: item._id,
                            Name: item.Name,
                            Department: item.Department,
                            Function: item.Function,
                            items: item.items
                        })
                    })
                }
                setAtividades(_atividades)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function alterAtividades(atividades) {
        const formatData = {
            Name: atividades.Name,
            Department: atividades.Department,
            Function: atividades.Function
        }
        await axios.put(urlBase + '/atividades/' + atividades._id,
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                getAtividades()
                toast.success('Alterado com sucesso')
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function moveAtividades(atividade) {

        await axios.post(urlBase + '/atividades/legalOpsUpdate',
            atividade, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                // getAtividades();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function saveAtividades(payload) {
        let formatData = {
            Name: payload.Name,
            Department: payload.Department,
            Function: payload.Function,
            items: []
        }

        await axios.post(urlBase + '/atividades/create',
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Inserido com sucesso');
                getAtividades();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function deleteAtividades(atividades) {

        await axios.delete(urlBase + '/atividades/deleteById',
            {
                data: { AtividadesDelete: JSON.parse(JSON.stringify(atividades)) },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso');
                getAtividades();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function cadastrarNovoProcesso(data, cliente, user, users) {
        const deptos = await getFuncaoDepartamento();
        const depto = deptos.find(data => data.value === user.departamento)
        const emailsEncontrados = depto.Lider.map(id => {
            const usuario = users.find(user => user.id === id);
            return usuario ? usuario.email : null;
        }).filter(email => email !== null);

        emailsEncontrados.push(user.email)

        const cnjsObject = {
            baseCliente: cliente,
            cnjs: data,
            user: user.nome,
            email: emailsEncontrados.join(', '),
        };

        await axios.post(urlBaseDigesto + '/process/createReport',
            cnjsObject)
            .then(function (response) {
                toast.success('Processos serao atuzalidos em 24h')
                // higienizacaoAllProcess(user.uid)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }



    const cadastrarNovoMonitoramento = (data, userRole) => {
        axios.post(urlBaseDigesto + `/process/incluiMonitoramento`, data)
            .then(response => {
                monitoramentoAllProcessPerUser(data.userID)
                if (userRole === 'Administrador Global') {
                    higienizacaoAllProcessAdmin()
                } else {
                    higienizacaoAllProcess(data.userID)
                }
                toast.success('Inlcuido novo monitoramento')
            })
            .catch(err => {
                console.log(err)
            })
    }

    const deleteHigienização = async (processo, userID) => {
        await axios.post(urlBaseDigesto + `/process/deleteProcess?userID=${userID}`, processo)
            .then(response => {
                toast.success('Monitoramento removido')
                higienizacaoAllProcess(userID)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const deleteMonitoramento = (processo, userID, user_email, userRole) => {
        axios.post(urlBaseDigesto + `/process/deleteMonitoredProcess?userID=${userID}&${user_email}`, processo)
            .then(response => {
                toast.success('Removendo monitoramento, volte em instantes')
                monitoramentoGetAllProcess()
                if (userRole === 'Administrador Global') {
                    higienizacaoAllProcessAdmin()
                } else {
                    higienizacaoAllProcess(user.uid)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }


    const higienizacaoAllProcess = async (userID) => {
        try {
            await axios.get(urlBaseDigesto + '/process/findAllProcess?userID=' + userID)
                .then(response => {
                    const data = response.data.map((item) => {
                        if (item.numero !== null && item.numero !== undefined) {
                            return {
                                ...item,
                                id: item.numero,
                            };
                        } else {
                            return null;
                        }
                    }).filter((item) => item !== null);

                    setHigienizacaoProcess(data)
                })
                .catch(error => {
                    console.log(error)
                    toast.error('Ocorreu um erro')
                })
        } catch (error) {
            toast.error("Algo deu errado")
        }
    }

    const higienizacaoAllProcessAdmin = async () => {
        try {
            await axios.get(urlBaseDigesto + '/process/GetAllProcess')
                .then(response => {
                    const data = response.data.map((item) => {
                        if (item.numero !== null && item.numero !== undefined) {
                            return {
                                ...item,
                                id: item.numero,
                            };
                        } else {
                            return null;
                        }
                    }).filter((item) => item !== null);
                    setHigienizacaoProcess(data)
                })
                .catch(error => { toast.error('Ocorreu um erro') })
        } catch (error) {
            toast.error("Algo deu errado")
        }
    }

    const getReports = async () => {
        try {
            await axios.get(urlBaseDigesto + '/process/getAllReports')
                .then(response => {
                    const data = response.data.map((doc) => {
                        const item = doc.data[0];
                        if (item.idReport !== null && item.idReport !== undefined) {
                            return {
                                id: item.idReport ? item.idReport : '',
                                idReport: item.idReport ? item.idReport : '',
                                return: item.return ? item.return : '',
                                user: item.user ? item.user : 'Administrador',
                                baseCliente: item.baseCliente ? item.baseCliente : '',
                                cnjs: item.cnjs ? item.cnjs : '',
                                status: item.status ? item.status : 'Finalizado',
                                date: item.date ? item.date : '',
                                link: item.link ? item.link : ''
                            };
                        } else {
                            return null;
                        }
                    });
                    setReports(data)
                })
                .catch(error => {
                    toast.error('Ocorreu um erro')
                    console.log(error)
                }
                )
        } catch (error) {
            toast.error("Algo deu errado")
        }
    }

    const monitoramentoAllProcessPerUser = async (userID) => {
        try {
            await axios.get(urlBaseDigesto + '/process/findAllMonitoredProcess?userID=' + userID)
                .then(response => {
                    const data =
                        response.data.map((item) => {
                            return {
                                ...item,
                                id: item.numero,
                            };
                        })
                    setProcessosMonitorados(data)
                })
                .catch(error => { toast.error('Ocorreu um erro') })
        } catch (error) {
            toast.error("Algo deu errado")
        }
    }

    const monitoramentoGetAllProcess = async () => {
        try {
            await axios.get(urlBaseDigesto + '/process/getAllMonitoredProcess')
                .then(response => {
                    const data =
                        response.data.map((item) => {
                            return {
                                ...item,
                                id: item.numero,
                            };
                        })
                    setProcessosMonitorados(data)
                })
                .catch(error => { toast.error('Ocorreu um erro') })
        } catch (error) {
            toast.error("Algo deu errado")
        }
    }

    return (
        <CrudContext.Provider value={{
            deleteFuncao,
            saveFuncao,
            alterFuncao,
            getFuncaoDepartamento,
            funcaoDepartamento,
            getFuncao,
            funcao,
            setFuncao,
            saveDepartamento,
            deleteDepartamento,
            alterDepartamento,
            getDepartamento,
            departamento,
            getEmpresas,
            empresas,
            saveEmpresa,
            getMomentos,
            momentos,
            momentosExecucao,
            saveMomento,
            getPedidos,
            pedidos,
            savePedidos,
            alterMomento,
            alterPedido,
            alterEmpresa,
            deleteEmpresa,
            deletePedido,
            deleteMomento,
            saveAdvogado,
            getAdvogado,
            advogados,
            advogadosList,
            deleteAdvogado,
            alterAdvogado,
            moveAtividades,

            //Atividades
            getAtividades,
            atividades,
            alterAtividades,
            saveAtividades,
            deleteAtividades,
            registrarAtividade,


            //Filters
            getFilters,
            setFilter,
            deleteFilter,
            getFiltersByUserId,
            deleteFilterUserClient,
            filter,

            //Office
            getOffice,
            alterOffice,
            saveOffice,
            deleteOffice,
            setOffice,
            office,

            //Dicionary              
            getDicionary,
            alterDicionary,
            saveDicionary,
            deleteDicionary,
            setDicionary,
            dicionary,

            //Empresa usuário adm
            getEmpresasUserAdm,
            empresaUserAdm,

            higienizacaoAllProcess,
            higienizacaoProcess,
            cadastrarNovoProcesso,
            processoAndamento,
            formConteudo,
            setFormConteudo,
            deleteHigienização,
            higienizacaoAllProcessAdmin,
            cadastrarNovoMonitoramento,
            monitoramentoAllProcessPerUser,
            monitoramentoGetAllProcess,
            processosMonitorados,
            deleteMonitoramento,
            getReports,
            reports
        }}>
            {children}
        </CrudContext.Provider>
    )
}

export default CrudProvider;