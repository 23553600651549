import { parseISO, isValid, format } from 'date-fns'
import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'
import { toast } from 'react-toastify'

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';
import './index.css'

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { ImportacaoContext } from '../../contexts/importacao';
import { readExcelFile } from '../../utils';
import DataGridCustom from "../../Components/DataGrid/DataGridCustom";
import Box from '@mui/material/Box';

const Monitoramento = () => {

    const { load } = useContext(ImportacaoContext);
    const { isHumburguerActive, user } = useContext(AuthContext);
    const { cadastrarNovoMonitoramento, monitoramentoAllProcessPerUser,
        monitoramentoGetAllProcess, processosMonitorados, formConteudo, deleteMonitoramento
    } = useContext(CrudContext);
    const [dicionario, setdicionario] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [newProductsDialog, setNewProductsDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);
    const [fileData, setFileData] = useState()
    const ref = useRef();

    useEffect(() => {
        if (user.tipoUsuario === 'Administrador Global') {
            monitoramentoGetAllProcess();
        } else {
            monitoramentoAllProcessPerUser(user.uid);
        }
    }, [])

    useEffect(() => {

        const _capa = processosMonitorados.map((process) => {
            let tipo = ''
            let detalhe1 = ''
            let detalhe2 = ''
            let detalhe3 = ''

            if (process.tipo === 'Movimentações') {
                tipo = process.tipo
                detalhe1 = process.detalhes[0]?.tipo
                detalhe2 = validarEFormatarData(process.detalhes[0].data, 'dd/MM/yyyy')
                detalhe3 = process.detalhes[0]?.texto
            }
            else if (process.tipo === 'Novas audiencias') {

                tipo = process.tipo
                detalhe1 = process.detalhes[0]?.$insert ? validarEFormatarData(process.detalhes[0].$insert[0][1][0], 'dd/MM/yyyy') : ''
                detalhe2 = process.detalhes[0]?.$insert ? validarEFormatarData(process.detalhes[0].$insert[0][1][1], 'dd/MM/yyyy') : ''
                detalhe3 = process.detalhes[0]?.$insert ? validarEFormatarData(process.detalhes[0].$insert[0][1][2], 'dd/MM/yyyy') : ''
            }
            else {
                tipo = tipo = process.tipo
                detalhe1 = ''
                detalhe2 = ''
                detalhe3 = ''
            }

            return {
                id: process.id,
                numero: process.numero,
                baseCliente: process.baseCliente,
                data: process.data,
                tipo: tipo,
                detalhe1: detalhe1,
                detalhe2: detalhe2,
                detalhe3: detalhe3
            };
        })

        const sortedRows = [..._capa].sort((a, b) => {
            const dateA = new Date(a.data);
            const dateB = new Date(b.data);

            if (isNaN(dateA) && isNaN(dateB)) {
                return 0;
            }

            if (isNaN(dateA)) {
                return 1;
            }

            if (isNaN(dateB)) {
                return -1;
            }

            return dateB - dateA;
        });

        setdicionario(sortedRows)
    }, [processosMonitorados])


    useEffect(() => { setSelectedCustomers(formConteudo) }, [formConteudo])

    function validarEFormatarData(dataStr, formatoSaida) {
        try {
            const data = parseISO(dataStr);

            if (isValid(data)) {
                return format(data, formatoSaida);
            } else {
                return dataStr;
            }
        } catch (error) {
            return 'Erro ao processar a data';
        }
    }


    const deletar = () => {
        if (selectedCustomers) {
            const data = {
                processos: selectedCustomers
            }
            deleteMonitoramento(data, user.uid, user.email, user.tipoUsuario)
        }

    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label={"Importar"} icon={"pi pi-download"} className={"p-button-warning"} onClick={() => setNewProductsDialog(true)} />
                <Button label="Excluir" icon="pi pi-trash" className="p-button-danger" type="button" onClick={() => deletar()} disabled={!selectedCustomers || !selectedCustomers.length} />
            </React.Fragment>
        )
    }

    const importarArquivo = () => {
        if (fileData) {
            for (let index = 0; index < fileData.length; index++) {
                const element = fileData[index];
                let data = {
                    codProcesso: element.NCNJ,
                    baseCliente: element.baseCliente,
                    userID: user.uid,
                    user_email: user.email
                }

                cadastrarNovoMonitoramento(data, user.tipoUsuario)
            }


            setNewProductsDialog(false)
            setImportDialog(true)
        }
        else {
            toast.error("Necessário selecionar um arquivo")
        }
    }

    const hideDialog = () => {
        setNewProductsDialog(!newProductsDialog)
    };

    const handleFile = async (e, ref) => {
        const json = await readExcelFile(e, ref)
        setFileData(json)
    }

    const hideImportDialog = () => {
        setImportDialog(false);
    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="OK" icon="pi pi-check" className="p-button-text" onClick={hideImportDialog} />
        </React.Fragment>
    );

    const columns = [
        { field: 'numero', headerName: 'Código do Processo', width: 300 },
        { field: 'baseCliente', headerName: 'Base Cliente', width: 150 },
        {
            field: 'data', headerName: 'Data Atualização', width: 150,
            valueGetter: params => {
                if (!params.value) {
                    return '';
                }

                const originalDate = new Date(params.value);
                const formattedDate = `${originalDate.getDate().toString().padStart(2, '0')}/${(originalDate.getMonth() + 1).toString().padStart(2, '0')}/${originalDate.getFullYear()}`;
                return formattedDate;
            },
        },
        { field: 'tipo', headerName: 'Tipo', width: 150 },
        { field: 'detalhe1', headerName: 'detalhe1', width: 150 },
        { field: 'detalhe2', headerName: 'detalhe2', width: 150 },
        { field: 'detalhe3', headerName: 'detalhe3', width: 150 }
    ]

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                <div className="container-profile">
                    {dicionario &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                            <Box sx={{ height: 650, width: '100%' }}>
                                <DataGridCustom
                                    column={columns}
                                    rows={dicionario}
                                />
                            </Box>
                        </div>
                    }
                </div>

                {newProductsDialog &&
                    <Dialog
                        visible={newProductsDialog}
                        style={{ width: "450px" }}
                        header="Importação"
                        modal
                        className="card p-fluid"
                        onHide={hideDialog}
                    >
                        <div className="field">
                            <a className="arqModelo" href='https://pactfy.s3.sa-east-1.amazonaws.com/Modelo_Imporatacao_Higienizacao.xlsx'>Baixar Arquivo Modelo</a>
                        </div>

                        <div className="field">
                            <input id="arquivo-empresa" type="file" className="upload-box" onChange={(e) => handleFile(e, ref)} ref={ref} />
                        </div>

                        <div className="field button-right">
                            <Button
                                label={'Enviar'}
                                id={'button-right'}
                                className="p-button-success mr-2"
                                onClick={() => { importarArquivo() }}
                                style={{ width: '100px', margin: '1rem 0' }} disabled={load}
                            />
                        </div>
                    </Dialog>
                }

                {importDialog &&
                    <Dialog visible={importDialog} style={{ width: '450px' }} header="Atenção" modal footer={deleteProductDialogFooter} onHide={hideImportDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '1rem' }} />
                            {importDialog && <span> Sua base está sendo processada! As informações serão exibidas em 24 horas.</span>}
                        </div>
                    </Dialog>
                }

            </div >
        </div >
    )
}


export default Monitoramento;