import './pedidos.css';
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'
import { toast } from 'react-toastify'
import { FilterMatchMode } from 'primereact/api';
import { MenuItem, TextField } from '@mui/material';
import LoadingIcons from 'react-loading-icons'

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import DataGridCustom from '../../Components/DataGrid/DataGridCustom';
import Box from '@mui/material/Box'

function ManutencaoFiltros() {

    const { user, isHumburguerActive } = useContext(AuthContext);
    const { getPedidos, pedidos, savePedidos, alterPedido, deletePedido, getFilters, filter, deleteFilter, getFiltersByUserId, deleteFilterUserClient
    ,formConteudo } = useContext(CrudContext);
    const [pedidos2, setPedidos2] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [newProductsDialog, setNewProductsDialog] = useState(false);

    const [novoPedido, setNovoPedido] = useState();
    const [tipoVerba, setTipoVerba] = useState();

    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');

    const [productDialog, setProductDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false);
    const [product, setProduct] = useState({
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
    });

    useEffect(() => {
        getPedidos();
        initFilters1();
        
        if(user?.tipoUsuario === 'Cliente'){
            getFiltersByUserId({ id: user.uid})
        }else{
            getFilters();
        }        

    }, [])


    useEffect(() => {
        setPedidos2(pedidos)
    }, [pedidos])

    useEffect(()=>{ 
        setSelectedCustomers(formConteudo)
      },[formConteudo])


    // --------------------------------------------------------------------------------------
    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveProduct(e)}
            />
        </React.Fragment>
    );

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setNewProductsDialog(!newProductsDialog)
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    };

    const saveProduct = (e) => {
        setSubmitted(true);
        handleSavePedido(e)
        setNewProductsDialog(!newProductsDialog)
    }

    async function handleSavePedido(e) {
        e.preventDefault();
        if (novoPedido) {
            await savePedidos(novoPedido, tipoVerba);
            setNovoPedido('');
            setTipoVerba('');
        }
        else {
            toast.error('Preencha todos os campos')
        }
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);        

    }

    const openNew = () => {
        setNewProductsDialog(true)
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProduct = () => {
        if(user?.tipoUsuario === 'Cliente'){
            deleteFilterUserClient(selectedCustomers, { id: user.uid })
        }else{
            deleteFilter(selectedCustomers);
        }
        
        setDeleteProductsDialog(false);

        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }

    const clearFilter1 = () => {
        initFilters1();
    }

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

        });
        setGlobalFilterValue1('');
    }

    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Pesquisar" />
                </span>
            </div>
        )
    }

    const header1 = renderHeader1();

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" style={{ width: "150px"}} onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />
            </React.Fragment>
        )
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case 'NA':
                return 'N/A';

            case 'verbasalarial':
                return 'Verba Salarial';

            case 'verbaindenizatoria':
                return 'Verba Indenizatória';

            default:
                return 'NA';
        }
    }

    const statuses = [
        { label: 'Verba Salarial', value: 'verbasalarial' },
        { label: 'Verba Indenizatória', value: 'verbaindenizatoria' },
        { label: 'N/A', value: 'NA' }
    ];

    const statusEditor = (options) => {
        return (
            <Dropdown value={options.value} options={statuses} optionLabel="label" optionValue="value"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Selecione Tipo Verba"
                itemTemplate={(option) => {
                    return <span className={`product-badge status-${option.value.toLowerCase()}`}>{option.label}</span>
                }} />
        );
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const onRowEditComplete1 = (e) => {
        let _products2 = [...pedidos2];
        let { newData, index } = e;

        _products2[index] = newData;
        alterPedido(newData);

        setPedidos2(_products2);
    }

    const statusBodyTemplate = (rowData) => {
        return getStatusLabel(rowData.tipoVerba);
    }

    const columns = [
        {field: 'id', headerName: 'id', width: 50 },
        {field: 'descricao', headerName: 'Descrição', width: 250 },        
      ]

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                {/* <Title nome="Manuteção de Filtros">
                </Title> */}

                <div className="container-profile">
                    {newProductsDialog &&
                        <Dialog
                            visible={newProductsDialog}
                            style={{ width: "450px" }}
                            header="Novo Pedido"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Descrição"
                                    value={novoPedido}
                                    onChange={(e) => setNovoPedido(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Tipo de Verba"
                                    value={tipoVerba}
                                    onChange={(e) => setTipoVerba(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {statuses.map(option => (
                                        <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Dialog>
                    }
                    {/* ---------------------------------------------------- */}
                    {filter &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                            <Box sx={{ height: 800, width: '100%' }}>
                                <DataGridCustom 
                                    column={columns} 
                                    rows={filter}                
                                />
                            </Box>
                            <DataTable value={filter} dataKey="id" onRowEditComplete={onRowEditComplete1}
                                globalFilterFields={['description', 'tipoVerba']} responsiveLayout="scroll"
                                filters={filters1}
                                header={header1}
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}" rows={10} rowsPerPageOptions={[5, 10, 20, 50]}
                                selection={selectedCustomers} onSelectionChange={e => setSelectedCustomers(e.value)}>
                                <Column selectionMode="multiple" headerStyle={{ width: '10%' }}></Column>
                                <Column field="descricao" header="Descrição" style={{ width: '20%' }}></Column>
                                <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>
                        </div>
                    }

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {deleteProductsDialog && <span>Tem certeza que gostaria de deletar os filtros <b>selecionados</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div >
        </div >
    )
}


export default ManutencaoFiltros;