import { useContext, useEffect, useState } from 'react'
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import { AuthContext } from '../../contexts/auth'
import { CrudContext } from '../../contexts/crud'

import Header from '../../Components/Header'
import { MenuItem, TextField } from '@mui/material'

import './dashboard.css'

function DashboardCustomizado() {

  const { user, getAllUsers, isHumburguerActive } = useContext(AuthContext);
  const { getEmpresas, empresas } = useContext(CrudContext);
  const [url, setUrl] = useState(null);
  const [empresaCliente, setEmpresaCliente] = useState('');
  const [exibeUsuario, setExibeUsuario] = useState(0);

  useEffect(() => {
    getEmpresas();
    getAllUsers(1);
    (user.tipoUsuario === 'Cliente') ? setExibeUsuario(1) : setExibeUsuario(2)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="App">
      <Header />
      <div className={`content${isHumburguerActive ? '-active' : ''}`}>
        {exibeUsuario > 0  &&
          <>
            {
              exibeUsuario === 1 &&
              <div className='dash-options'>
                <TextField
                  id="outlined-select-currency-base-cliente"
                  select
                  label="Selecionar base cliente"
                  value={empresaCliente}
                  fullWidth
                  onChange={(e) => {
                    setEmpresaCliente(e.target.value)
                    if (e.target.value !== 'NA' && e.target.value !== '') {
                      setUrl(true)
                    }
                    else {
                      setUrl(false);
                    }

                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {
                    user.cliente.map(option => (
                      <MenuItem id='base-cliente-itens' key={option.url} value={option.url}>
                        {option.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            }

            {exibeUsuario === 2 &&
              <>
                {empresas &&
                  <div className='dash-options'>
                    <TextField
                      id="outlined-select-currency-base-cliente"
                      select
                      fullWidth
                      label="Selecionar base cliente"
                      value={empresaCliente}
                      onChange={(e) => {
                        setEmpresaCliente(e.target.value)
                        setTimeout(() => {
                          setUrl(false);
                        }, 1000);

                        setTimeout(() => {
                          if (e.target.value !== 'NA' && e.target.value !== '') {
                            setUrl(true)
                          }
                          else {
                            setUrl(false);
                          }
                        }, 1000);
                      }}
                      margin="normal"
                      variant="outlined"
                    >
                      {
                        empresas.map(option => (
                          <MenuItem key={option.url} value={option.url}>
                            {option.name}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </div>
                }
              </>
            }

            {url &&
              <PowerBIEmbed
                embedConfig={{
                  type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                  id: '<Report Id>',
                  embedUrl: empresaCliente,
                  accessToken: '<Access Token>',
                  tokenType: models.TokenType.Embed,
                  settings: {
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false
                      }
                    },
                    background: models.BackgroundType.Transparent,
                  }
                }}

                eventHandlers={
                  new Map([
                    ['loaded', function () { console.log('Report loaded'); }],
                    ['rendered', function () { console.log('Report rendered'); }],
                    ['error', function (event) { console.log(event.detail); }]
                  ])
                }

                cssClassName={"report-style-class"}

                getEmbeddedComponent={(embeddedReport) => {
                  window.report = embeddedReport;
                }}
              />}
          </>
        }
      </div>
    </div>
  );
}

export default DashboardCustomizado;
