import { useState, useEffect, createContext } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios';

export const AuthContext = createContext({})


function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loadingAuth, setLoadingAuth] = useState(false);
    const [usuarios, setUsuarios] = useState(false);
    const [load, setLoad] = useState(true);
    const [errorEmail, setEmailError] = useState(false)
    const [isHumburguerActive, setIsHumburguerActive] = useState(false)
    const [isModel, setIsModel] = useState(false)
    const [isConfig, setIsConfig] = useState(false);
    const [isSelected, setIsSelected] = useState(0)
    const [isDash, setIsDash] = useState(false);
    const [isProject, setIsProject] = useState(false);
    const [isHigienizacao, setIsHigienizacao] = useState(false);
    const [showConfig, setShowConfig] = useState(false)
    const [token, setToken] = useState()

    // const urlBase = process.env.REACT_APP_MIDDLEWARE_API;
    const urlBase = process.env.REACT_APP_PACT_API;
    // let urlBase = window.document.domain == 'localhost'? process.env.REACT_APP_PACT_API : process.env.REACT_APP_MIDDLEWARE_API

    useEffect(() => {

        const storageUser = localStorage.getItem('SistemaUser');

        if (storageUser) {
            setUser(JSON.parse(storageUser));
            setLoad(false);
        }

        setLoad(false);
    }, [])


    async function signUp(data) {
        setLoadingAuth(true);

        await axios.post(urlBase + '/user/create',
            data, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                toast.success('Usuario cadastrado com sucesso');
            })
            .catch(err => {
                (err.response.data.erro) ? toast.error(err.response.data.erro) : toast.error('Algo deu errado')
            })
    }

    async function updateAvatar(data) {
        setLoadingAuth(true);

        await axios.post(urlBase + '/user/updateAvatar',
            data, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {

                let _data = response.data
                _data.token = user.token
                setUser(_data);
                storageUser(_data);
                toast.success('Usuario alterado com sucesso');
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function removeUser(id) {
        setLoadingAuth(true);

        await axios.put(urlBase + '/user/updatestatus',
            {
                id: id,
                status: 'Inativo'
            },
            {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }
        )
            .then(function (response) {
                getAllUsers(1);
                setLoadingAuth(false);
            })
            .catch((err) => {
                console.log(err)
                toast.error('Algo deu errado')
                setLoadingAuth(false);
            })
    }

    async function ativarUser(id) {
        setLoadingAuth(true);

        await axios.put(urlBase + '/user/updatestatus',
            {
                id: id,
                status: 'Ativo'
            },
            {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }
        )
            .then(function (response) {
                getAllUsers(0);
                setLoadingAuth(false);
            })
            .catch((err) => {
                console.log(err)
                toast.error('Algo deu errado')
                setLoadingAuth(false);
            })
    }


    function storageUser(data) {
        localStorage.removeItem('SistemaUser');
        localStorage.setItem('SistemaUser', JSON.stringify(data))
    }

    async function signOut() {
        localStorage.removeItem('SistemaUser');
        toast.success('Desconectado')
        setUser(null);
        setToken(null);
    }

    async function signIn(email, password) {

        setLoadingAuth(true);

        await axios.post(urlBase + '/user/auth',
            {
                email: email.toLowerCase(),
                password: password
            }
        )
            .then(function (response) {
                setUser(response.data);
                storageUser(response.data);
                setLoadingAuth(false);
                setToken(response.data.token)
                toast.success('Bem vindo de volta')
            })
            .catch((err) => {
                if (err.response.data.message) {
                    toast.error(err.response.data.message)
                    setLoadingAuth(false);
                }
                else {
                    toast.error('Algo deu errado')
                    setLoadingAuth(false);
                }
                setLoadingAuth(false);
            })
        setLoadingAuth(false);
    }

    async function forgot(email) {
        setLoad(true)

        await axios.put(urlBase + '/user/forgotPassword',
            {
                email: email,
            }
        )
            .then(function (response) {
                toast.success('Verifique seu e-mail para alterar a senha');
            })
            .catch((err) => {
                console.log("Update error", err);
                toast.error(err.response.data.messsage)
            })

        setLoad(false)

    }

    async function getAllUsers(status) {

        await axios.get(urlBase + '/user/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                updateState(response.data, status)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            }).finally(() => {
                setLoad(false)
            })

    }

    async function updateState(snapshot, status) {
        const isCollectionEmpty = snapshot.size === 0;
        if (!isCollectionEmpty) {
            let lista = [];

            snapshot.forEach((doc) => {
                lista.push({
                    key: (doc.uid) ? doc.uid : doc.uie,
                    id: (doc.uid) ? doc.uid : doc.uie,
                    nome: doc.nome,
                    tipoUsuario: doc.tipoUsuario,
                    departamento: doc.departamento ? doc.departamento : '',
                    funcao: doc.funcao ? doc.funcao : '',
                    email: doc.email,
                    cliente: doc.cliente,
                    empresa: doc.cliente,
                    status: doc.status
                })
            })

            lista.forEach((n, index) => {
                let cliFinal = '';

                if (n.cliente?.length > 0) {
                    n.cliente?.forEach((cli) => {
                        if (cliFinal === '') {
                            cliFinal = cli.name
                        }
                        else {
                            cliFinal = cliFinal + ', ' + cli.name
                        }
                    })
                }
                lista[index].cliente = cliFinal
            })

            let listaFinal = [...lista]

            if (status === 1) {
                listaFinal = lista.filter(item => item.status === "Ativo")
            }
            else {
                listaFinal = lista.filter(item => item.status === "Inativo")
            }

            setUsuarios(listaFinal)
        } else {

        }
    }

    async function updateUser(user) {
        const payloadUsuario = {
            uid: user.key,
            nome: user.nome,
            tipoUsuario: user.tipoUsuario,
            departamento: user.departamento,
            funcao: user.funcao,
            cliente: user.empresa,
            token: user.token
        }
        await axios.put(urlBase + '/user/alterUser',
            payloadUsuario,
            {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }
        )
            .then(function (response) {
                getAllUsers(1);
                toast.success('Alterado com sucesso')
            })
            .catch((err) => {
                console.log("Error auth.js updateUser: ", err);
                toast.error('Erro')
            })
    }

    async function updateMyUser(user) {
        await axios.put(urlBase + '/user/alterUser',
            {
                uid: user.key,
                nome: user.nome,
                tipoUsuario: user.tipoUsuario,
                departamento: user.departamento,
                funcao: user.funcao,
                cliente: user.cliente
            },
            {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }
        )
            .then(function (response) {
                response.data.token = user.token
                setUser(response.data)
                storageUser(response.data)
                toast.success('Alterado com sucesso')
            })
            .catch((err) => {
                console.log("Update error", err);
                toast.error('Erro')
            })
    }

    async function updatePassword(email, senhaAtual, novaSenha, confirmarNovaSenha) {
        let ret = null
        await axios.put(urlBase + '/user/alterPassword',
            {
                email: email,
                correntPassword: senhaAtual,
                newPassword: novaSenha,
                confirmPassword: confirmarNovaSenha
            },
            {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            }
        )
            .then(function (response) {
                toast.success('Senha Alterada com sucesso')
            })
            .catch((err) => {
                toast.error(err.response.data.messsage)
                ret = err.response.data.messsage
            })

        return ret;
    }

    return (
        <AuthContext.Provider value={{
            signed: !!user,
            user,
            load,
            signUp,
            signOut,
            signIn,
            loadingAuth,
            setUser,
            storageUser,
            getAllUsers,
            usuarios,
            isHumburguerActive,
            setIsHumburguerActive,
            removeUser,
            updateUser,
            updateMyUser,
            isModel,
            setIsModel,
            isConfig,
            setIsConfig,
            forgot,
            errorEmail,
            isSelected,
            setIsSelected,
            ativarUser,
            isDash,
            setIsDash,
            showConfig,
            setShowConfig,
            updateAvatar,
            updatePassword,
            token,
            isProject,
            setIsProject,
            isHigienizacao,
            setIsHigienizacao
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;