import './styled.css'
import { FaDownload, FaFileDownload } from 'react-icons/fa'
import { useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment';
import * as XLSX from 'xlsx';

const CardComponent = ({ title, onClick, modeloUm, filterItens }) =>{
    
    const [tipo1, setTipo1] = useState(false);
    const [tipo2, setTipo2] = useState(false);
    const [tipo3, setTipo3] = useState(false);
    const [tipo4, setTipo4] = useState(false);
    const [tipo5, setTipo5] = useState(false);
    const [tipo6, setTipo6] = useState(false);
    const [tipo7, setTipo7] = useState(false);
    const [tipo8, setTipo8] = useState(false);
    const [tipo9, setTipo9] = useState(false);
    const [tipo10, setTipo10] = useState(false);

    const filtro = filterItens.filtro[0]
    const filterModel = filtro.filterModel
    const filterModelItems = filterModel?.items

    let modeloUmEspelho = [...modeloUm]
    const modeloUmFilter = []

    for (let index = 0; index < filterModelItems?.length; index++){     
        let columName = filterModelItems[index].columnField
        let value = filterModelItems[index].value

        modeloUmEspelho.forEach((item) =>{ 
            if(item[columName] === value ){
                modeloUmFilter.push(item)
            }
        })
    }

    const exportExcel = () => {

        let infoLinha = [];
        let modeloUmListIds = [] 

        modeloUmFilter.forEach( item => {
            modeloUmListIds.push(item.id)
        })


        if (modeloUmFilter.length > 0) {
            let productsFiltered = [];

            modeloUmFilter.forEach((item, i) => {
                let index = modeloUmListIds.indexOf(item.NCNJ)

                if (index !== -1) {
                    infoLinha = [];
                    // if (_Basico) {
                        infoLinha.NCNJ = item.NCNJ
                        infoLinha.contrarioPrincipalNomeRazao = item.contrarioPrincipalNomeRazao
                        infoLinha.uf = item.uf
                        infoLinha.cidade = item.cidade
                        infoLinha.advogadoReverso_oab = item.advogadoReverso_oab
                        infoLinha.vara = item.vara
                        infoLinha.nVara = item.nVara
                        infoLinha.dtDistribuicao = item.dtDistribuicao ? moment(item.dtDistribuicao).format("DD/MM/YYYY") : ''
                        infoLinha.ecBase = item.ecBase
                        infoLinha.riscoDCliente = currencyFormatter.format(item.riscoDCliente.replace(',', '.'))
                        infoLinha.faixaRisco = item.faixaRisco
                        infoLinha.valorCausa = currencyFormatter.format(item.valorCausa.replace(',', '.'))
                        infoLinha.tipoProcesso = item.tipoProcesso
                        infoLinha.TipoAcao = item.tipoAcao
                        infoLinha.primeiraReclamada = item.primeiraReclamada
                        infoLinha.demaisReclamadas = item.demaisReclamadas
                    // }

                    // if (_Fase) {
                        infoLinha.status = item.status
                        infoLinha.fase = item.fase
                        infoLinha.momento = item.momento
                        infoLinha.dtMomento = item.dtMomento ? moment(item.dtMomento).format("DD/MM/YYYY") : ''
                        infoLinha.dtAudiencia = item.dtAudiencia ? moment(item.dtAudiencia).format("DD/MM/YYYY") : ''
                        infoLinha.dtEncerramento = item.dtEncerramento ? moment(item.dtEncerramento).format("DD/MM/YYYY") : ''
                    // }

                    // if (_Encerrados) {
                        infoLinha.motivoEncerramento = item.motivoEncerramento
                        infoLinha.ticketMedio = currencyFormatter.format(item.ticketMedio.replace(',', '.'))
                        infoLinha.valorAcordoCondenacao = currencyFormatter.format(item.valorAcordoCondenacao.replace(',', '.'))
                        infoLinha.formaPagamentoAcordo = item.formaPagamentoAcordo
                        infoLinha.quemPagouAcordo = item.quemPagouAcordo
                        infoLinha.momentoEmQueAcordoRealizado = item.momentoEmQueAcordoRealizado
                    // }

                    // if (_Pedidos) {
                        infoLinha.pedidosDaInicial = item.pedidosDaInicial
                    // }

                    // if (_Funcao) {
                        infoLinha.funcaoCompilada = item.funcaoCompilada
                        infoLinha.proprioTerceiro = item.proprioTerceiro
                        infoLinha.ultimoSalario = currencyFormatter.format(item.ultimoSalario.replace(',', '.'))
                        infoLinha.dtAdmissao = item.dtAdmissao ? moment(item.dtAdmissao).format("DD/MM/YYYY") : ''
                        infoLinha.dtDemissao = item.dtDemissao ? moment(item.dtDemissao).format("DD/MM/YYYY") : ''
                        infoLinha.tempoCasa = item.tempoCasa
                    // }

                    // if (_Resersao) {
                        infoLinha.sentenca = item.sentenca
                        infoLinha.dtSentenca = item.dtSentenca ? moment(item.dtSentenca).format("DD/MM/YYYY") : ''
                        infoLinha.nomeJuiz = item.nomeJuiz
                        infoLinha.houveReformaAcordaoRO = item.houveReformaAcordaoRO
                        infoLinha.pedidosIncluidosAcordao = item.pedidosIncluidosAcordao
                        infoLinha.pedidosExcluidosAcordao = item.pedidosExcluidosAcordao
                        infoLinha.dtAcordao = item.dtAcordao ? moment(item.dtAcordao).format("DD/MM/YYYY") : ''
                        infoLinha.acordaoReformouImprocedencia = item.acordaoReformouImprocedencia
                        infoLinha.rrAdmitido = item.rrAdmitido
                        infoLinha.seFoiAdmitidoQualMateria = item.seFoiAdmitidoQualMateria
                        infoLinha.houveReformaAcordaoRR = item.houveReformaAcordaoRR
                        infoLinha.dtAcordao2 = item.dtAcordao2 ? moment(item.dtAcordao2).format("DD/MM/YYYY") : ''
                    // }

                    // if (_acordos) {
                        infoLinha.cpf_reclamante = item.cpf_reclamante
                        infoLinha.projeto = item.projeto
                        infoLinha.status_pact = item.status_pact
                        infoLinha.cnj_execucao_provisoria = item.cnj_execucao_provisoria
                        infoLinha.momento_execucao_provisoria = item.momento_execucao_provisoria
                        infoLinha.data_cadastro_processo_base = item.data_cadastro_processo_base
                        infoLinha.status_pre = item.status_pre
                        infoLinha.data_provisao = item.data_provisao
                        infoLinha.provisao = item.provisao
                        infoLinha.data_atualizacao_risco = item.data_atualizacao_risco
                        infoLinha.risco_bruto = item.risco_bruto
                        infoLinha.risco_liquido = item.risco_liquido
                        infoLinha.risco_bruto_pact_net_atualizado = item.risco_bruto_pact_net_atualizado
                        infoLinha.risco_liquido_pact_net_atualizado = item.risco_liquido_pact_net_atualizado
                        infoLinha.depositos_recursais = item.depositos_recursais
                        infoLinha.faixa_risco = item.faixa_risco
                        infoLinha.gatilho_bruto = item.gatilho_bruto
                        infoLinha.status_negociacao = item.status_negociacao
                        infoLinha.termometro = item.termometro
                        infoLinha.data_primeiro_contato = item.data_primeiro_contato
                        infoLinha.data_ultimo_contato = item.data_ultimo_contato
                        infoLinha.proposta = item.proposta
                        infoLinha.ulitma_pretensao = item.ulitma_pretensao
                        infoLinha.data_acordo = item.data_acordo
                        infoLinha.data_protocolo = item.data_protocolo
                        infoLinha.data_homologacao = item.data_homologacao
                        infoLinha.acordo_bruto = item.acordo_bruto
                        infoLinha.acordo_liquido = item.acordo_liquido
                        infoLinha.status_minutas = item.status_minutas
                        infoLinha.inss_reclamada_pago = item.inss_reclamada_pago
                        infoLinha.inss_reclamante_pago = item.inss_reclamante_pago
                        infoLinha.ir = item.ir
                        infoLinha.honorarios_periciais = item.honorarios_periciais
                        infoLinha.economia = item.economia
                        infoLinha.desagio = item.desagio
                        infoLinha.honorario_pact = item.honorario_pact
                        infoLinha.economia_net = item.economia_net
                        infoLinha.desagio_net = item.desagio_net
                    // }

                    // if (_Financeira) {
                        infoLinha.juros = currencyFormatter.format(item.juros.replace(',', '.'))
                        infoLinha.hPericiais = currencyFormatter.format(item.hPericiais.replace(',', '.'))
                        infoLinha.honorariosAdvocaticios = currencyFormatter.format(item.honorariosAdvocaticios.replace(',', '.'))
                        infoLinha.custasProcessuais = currencyFormatter.format(item.custasProcessuais.replace(',', '.'))
                        infoLinha.estabilidade = currencyFormatter.format(item.estabilidade.replace(',', '.'))
                        infoLinha.bonus = currencyFormatter.format(item.bonus.replace(',', '.'))
                        infoLinha.equiparacaoSalarial = currencyFormatter.format(item.equiparacaoSalarial.replace(',', '.'))
                        infoLinha.acumuloDeFuncao = currencyFormatter.format(item.acumuloDeFuncao.replace(',', '.'))
                        infoLinha.dMoral = currencyFormatter.format(item.dMoral.replace(',', '.'))
                        infoLinha.dMaterial = currencyFormatter.format(item.dMaterial.replace(',', '.'))
                        infoLinha.pensao = currencyFormatter.format(item.pensao.replace(',', '.'))
                        infoLinha.he = currencyFormatter.format(item.he.replace(',', '.'))
                        infoLinha.horasInItinere = currencyFormatter.format(item.horasInItinere.replace(',', '.'))
                        infoLinha.sebreaviso = currencyFormatter.format(item.sebreaviso.replace(',', '.'))
                        infoLinha.intrajornada = currencyFormatter.format(item.intrajornada.replace(',', '.'))
                        infoLinha.diferencasSalariais = currencyFormatter.format(item.diferencasSalariais.replace(',', '.'))
                        infoLinha.adTransferencia = currencyFormatter.format(item.adTransferencia.replace(',', '.'))
                        infoLinha.insalubridade = currencyFormatter.format(item.insalubridade.replace(',', '.'))
                        infoLinha.periculosidade = currencyFormatter.format(item.periculosidade.replace(',', '.'))
                        infoLinha.ticketMedioporPedido = currencyFormatter.format(item.ticketMedioporPedido.replace(',', '.'))
                    // }

                    // if (_Garantias) {
                        infoLinha.garantia = item.garantia
                        infoLinha.possuiGarantia = item.possuiGarantia
                        infoLinha.garantiaMaiorouMenorqueRisco = item.garantiaMaiorouMenorqueRisco
                        infoLinha.contrarioPrincipalNomeRazao2 = item.contrarioPrincipalNomeRazao2
                        infoLinha.tipoGarantiaRecursalDepositoApolice = item.tipoGarantiaRecursalDepositoApolice
                        infoLinha.valorLiberado = currencyFormatter.format(item.valorLiberado.replace(',', '.'))
                    // }

                    // if (_Audiencias) {
                        infoLinha.revelia = item.revelia
                        infoLinha.reclamanteFoiOuvidaemAud = item.reclamanteFoiOuvidaemAud
                        infoLinha.testemunhaReclamada = item.testemunhaReclamada
                        infoLinha.testemunhaReclamante = item.testemunhaReclamante
                    // }

                    // if (_Calculos) {
                        infoLinha.dtTransitoemJulgado = item.dtTransitoemJulgado ? moment(item.dtTransitoemJulgado).format("DD/MM/YYYY") : ''
                        infoLinha.valorCalcApresentadoReclamada = currencyFormatter.format(item.valorCalcApresentadoReclamada.replace(',', '.'))
                        infoLinha.valorCalcApresentadoReclamante = currencyFormatter.format(item.valorCalcApresentadoReclamante.replace(',', '.'))
                        infoLinha.valorCalcApresentadoPerito = currencyFormatter.format(item.valorCalcApresentadoPerito.replace(',', '.'))
                        infoLinha.valorHomologado = currencyFormatter.format(item.valorHomologado.replace(',', '.'))
                        infoLinha.dtCalcHomologado = item.dtCalcHomologado ? moment(item.dtCalcHomologado).format("DD/MM/YYYY") : ''
                        infoLinha.deQuemfoiCalcHomologado = item.deQuemfoiCalcHomologado
                        infoLinha.houveEmbargosExecucao = item.houveEmbargosExecucao
                        infoLinha.materiaEe = item.materiaEe
                        infoLinha.formaDeGarantia = item.formaDeGarantia
                    // }

                    // if (_Pericias) {
                        infoLinha.houvePericia = item.houvePericia
                        infoLinha.nomePeritoPericulosidadeInsalubridade = item.nomePeritoPericulosidadeInsalubridade
                        infoLinha.nomePeritoAcidenteDoenca = item.nomePeritoAcidenteDoenca
                        infoLinha.periciaDeQue = item.periciaDeQue
                        infoLinha.resultadoInsalubridade = item.resultadoInsalubridade
                        infoLinha.fundamentoInsalubridade = item.fundamentoInsalubridade
                        infoLinha.resultadoPericulosidade = item.resultadoPericulosidade
                        infoLinha.agentePericulosidade = item.agentePericulosidade
                        infoLinha.fundamentoPericulosidade = item.fundamentoPericulosidade
                        infoLinha.agenteInsalubre = item.agenteInsalubre
                        infoLinha.resultadoDoenca = item.resultadoDoenca
                        infoLinha.resultadoAcidente = item.resultadoAcidente
                        infoLinha.dtLaudo = item.dtLaudo ? moment(item.dtLaudo).format("DD/MM/YYYY") : ''
                        infoLinha.compliadofunHe = item.compliadofunHe
                        infoLinha.agenteInsalubre2 = item.agenteInsalubre2
                        infoLinha.grauDaInsalubridade = item.grauDaInsalubridade
                        infoLinha.compiladofunPericulosidade = item.compiladofunPericulosidade
                        infoLinha.compiladofunAcidDoenca = item.compiladofunAcidDoenca
                        infoLinha.qualDoenca = item.qualDoenca
                        infoLinha.incapacidade = item.incapacidade
                        infoLinha.reducaoFuncional = item.reducaoFuncional
                        infoLinha.compiladoFundDanoMoral = item.compiladoFundDanoMoral
                    // }

                    // if (_Customizado) {
                        infoLinha.tipo1 = item.tipo1
                        infoLinha.tipo2 = item.tipo2
                        infoLinha.tipo3 = item.tipo3
                        infoLinha.tipo4 = item.tipo4
                        infoLinha.tipo5 = item.tipo5
                        infoLinha.tipo6 = item.tipo6
                        infoLinha.tipo7 = item.tipo7
                        infoLinha.tipo8 = item.tipo8
                        infoLinha.tipo9 = item.tipo9
                        infoLinha.tipo10 = item.tipo10
                    // }

                    productsFiltered.push(infoLinha)
                }
            });

            import('xlsx').then(xlsx => {
                const headers = {
                    NCNJ: 'Número Proc. De base',
                    contrarioPrincipalNomeRazao: 'Contrário principal / Nome/razão social',
                    uf: 'UF',
                    cidade: 'Cidade',
                    advogadoReverso_oab: 'Advogado Adverso / Oab',
                    vara: 'Vara',
                    nVara: 'n.º da Vara',
                    dtDistribuicao: 'Data de Distribuição',
                    ecBase: 'Escritório de Base (Dado Cliente)',
                    riscoDCliente: 'Risco (Dado Cliente)',
                    faixaRisco: 'Faixas de Risco',
                    valorCausa: 'Valor da Causa',
                    tipoProcesso: 'Tipo de Processo',
                    tipoAao: 'Tipo Ação',
                    primeiraReclamada: 'Primeira Reclamada',
                    demaisReclamadas: 'Demais Reclamadas (na ordem)',
                    status: 'Status ',
                    fase: 'Fase',
                    momento: 'Momento',
                    dtMomento: 'Data do Momento',
                    dtAudiencia: 'Data da Audiência',
                    dtEncerramento: 'Data do Encerramento',
                    motivoEncerramento: 'Motivo do Encerramento',
                    ticketMedio: '$ Ticket Médio (encerramento e ativo)',
                    valorAcordoCondenacao: 'Valor do Acordo ou Condenação',
                    formaPagamentoAcordo: 'Forma de Pagamento do Acordo',
                    quemPagouAcordo: 'Quem pagou o Acordo?',
                    momentoEmQueAcordoRealizado: 'Momento em que o acordo foi realizado?',
                    pedidosDaInicial: 'Pedidos da Inicial',
                    funcaoCompilada: 'Função Compilada',
                    proprioTerceiro: 'Próprio ou Terceiro?',
                    ultimoSalario: 'Último Salário',
                    dtAdmissao: 'Data de Admissão',
                    dtDemissao: 'Data de Demissão',
                    tempoCasa: 'Tempo de Casa (Anos)',
                    sentenca: 'Sentença (Pedidos Deferidos)',
                    dtSentenca: 'Data da Sentença',
                    nomeJuiz: 'Nome Juiz',
                    houveReformaAcordaoRO: 'Houve reforma no Acórdão de RO? ',
                    pedidosIncluidosAcordao: 'Pedidos Incluidos no Acórdão',
                    pedidosExcluidosAcordao: 'Pedidos Excluídos do Acórdão',
                    dtAcordao: 'Data do Acórdão ',
                    acordaoReformouImprocedencia: 'Acórdão reformou para improcedência?',
                    rrAdmitido: 'RR Admitido?',
                    seFoiAdmitidoQualMateria: 'Se foi admitido, qual a matéria?',
                    houveReformaAcordaoRR: 'Houve reforma no acórdão RR? Se sim, qual',
                    dtAcordao2: 'Data do Acórdão ',
                    juros: ' $ Juros',
                    hPericiais: '$ H. Periciais',
                    honorariosAdvocaticios: '$ Honorários advocatícios',
                    custasProcessuais: '$ Custas Processuais',
                    estabilidade: ' $ Estabilidade',
                    bonus: ' $ Bonus',
                    equiparacaoSalarial: ' $ Equiparação Salarial',
                    acumuloDeFuncao: ' $ Acumulo de Função',
                    dMoral: ' $ D. Moral',
                    dMaterial: ' $ D. Material',
                    pensao: ' $ Pensão',
                    he: ' $ HE',
                    horasInItinere: ' $ Horas in itinere',
                    sebreaviso: ' $ Sobreaviso',
                    intrajornada: ' $ Intrajornada',
                    diferencasSalariais: ' $ Diferenças salariais',
                    adTransferencia: ' $ Ad. Transferência',
                    insalubridade: ' $ Insalubridade',
                    periculosidade: ' $ Periculosidade',
                    ticketMedioporPedido: '$ Ticket Médio por Pedido (encerramento e ativo)',
                    garantia: 'Garantia',
                    possuiGarantia: 'Possui Guarantia',
                    garantiaMaiorouMenorqueRisco: 'Garantia maior ou menor que o risco?',
                    contrarioPrincipalNomeRazao2: 'Contrário principal / Nome/razão social',
                    tipoGarantiaRecursalDepositoApolice: 'Tipo de Garantia (Recursal/Depósito/Apólice)',
                    valorLiberado: 'Valor Liberado',
                    revelia: 'Revelia?',
                    reclamanteFoiOuvidaemAud: 'Reclamante foi ouvido em aud?',
                    testemunhaReclamada: 'Testemunha Reclamada?',
                    testemunhaReclamante: 'Testemunha Reclamante? ',
                    dtTransitoemJulgado: 'Data do Transito em Julgado',
                    valorCalcApresentadoReclamada: 'Valor Cálc. Apresentado pela Reclamada',
                    valorCalcApresentadoReclamante: 'Valor Cálc. Apresentado pelo Reclamante',
                    valorCalcApresentadoPerito: 'Valor Cálc. Apresentado pelo Perito',
                    valorHomologado: 'Valor Homologado',
                    dtCalcHomologado: 'Data Cálc. Homologado',
                    deQuemfoiCalcHomologado: 'De quem foi o cálc. Homologado',
                    houveEmbargosExecucao: 'Houve Embargos à Execução? (Sim ou não)',
                    materiaEe: 'Matéria dos EE',
                    formaDeGarantia: 'Forma de garantia (Dinheiro, seguro, etc)',
                    houvePericia: 'Houve perícia?',
                    nomePeritoPericulosidadeInsalubridade: 'Nome Perito Periculosidade/Insalubridade',
                    nomePeritoAcidenteDoenca: 'Nome Perito Acidente/Doença',
                    periciaDeQue: 'Pericia de que',
                    resultadoInsalubridade: 'Resultado Insalubridade ',
                    fundamentoInsalubridade: 'Fundamento da Insalubridade',
                    resultadoPericulosidade: 'Resultado Periculosidade',
                    agentePericulosidade: '# Agente Periculosidade',
                    fundamentoPericulosidade: 'Fundamento da Periculosidade',
                    agenteInsalubre: '# Agente Insalubre',
                    resultadoDoenca: 'Resultado Doença',
                    resultadoAcidente: 'Resultado Acidente',
                    dtLaudo: 'Data do Laudo',
                    compliadofunHe: 'Compilado Fun. HE',
                    agenteInsalubre2: 'Agente Insalubre',
                    grauDaInsalubridade: 'Grau da Insalubridade',
                    compiladofunPericulosidade: 'Compilado Fun. Periculosidade',
                    compiladofunAcidDoenca: 'Compilado Fun. Acid/Doença',
                    qualDoenca: 'Qual a Doença?',
                    incapacidade: 'Incapacidade?',
                    reducaoFuncional: 'Redução Funcional (%)',
                    compiladoFundDanoMoral: 'Compilado Fund. Dano Moral',
                    tipo1: (tipo1) ? tipo1 : 'tipo 1',
                    tipo2: (tipo2) ? tipo2 : 'tipo 2',
                    tipo3: (tipo3) ? tipo3 : 'tipo 3',
                    tipo4: (tipo4) ? tipo4 : 'tipo 4',
                    tipo5: (tipo5) ? tipo5 : 'tipo 5',
                    tipo6: (tipo6) ? tipo6 : 'tipo 6',
                    tipo7: (tipo7) ? tipo7 : 'tipo 7',
                    tipo8: (tipo8) ? tipo8 : 'tipo 8',
                    tipo9: (tipo9) ? tipo9 : 'tipo 9',
                    tipo10: (tipo10) ? tipo10 : 'tipo 10',
                    cpf_reclamante: 'CPF Reclamante',
                    projeto: 'Projeto',
                    status_pact: 'Status Pact',
                    cnj_execucao_provisoria: 'CNJ Execução Provisória',
                    momento_execucao_provisoria: 'Momento Execução Provisória',
                    data_cadastro_processo_base: 'Data do Cadastro do Processo na Base',
                    status_pre: 'Status pre',
                    data_provisao: 'Data Provisão',
                    provisao: 'Provisão',
                    data_atualizacao_risco: 'Data de Atualização do Risco',
                    risco_bruto: 'Risco Bruto',
                    risco_liquido: 'Risco líquido',
                    risco_bruto_pact_net_atualizado: 'Risco Bruto PACT NET',
                    risco_liquido_pact_net_atualizado: 'Risco Líquido PACT NET',
                    depositos_recursais: 'Depositos Recursais',
                    faixa_risco: 'Faixa de Risco',
                    gatilho_bruto: 'Gatilho Bruto',
                    status_negociacao: 'Status da Negociação',
                    termometro: 'Termometro',
                    data_primeiro_contato: 'Data Primeiro Contato',
                    data_ultimo_contato: 'Data Último Contato',
                    proposta: 'Proposta',
                    ulitma_pretensao: 'Última Pretensão',
                    data_acordo: 'Data do Acordo',
                    data_protocolo: 'Data do Protocolo',
                    data_homologacao: 'Data da Homologação',
                    acordo_bruto: 'Acordo Bruto',
                    acordo_liquido: 'Acordo Líquido',
                    status_minutas: 'Status das Minutas',
                    inss_reclamada_pago: 'INSS Reclamada (PAGO NO ACORDO)',
                    inss_reclamante_pago: 'INSS Reclamante (PAGO NO ACORDO)',
                    ir: 'IR',
                    honorarios_periciais: 'Homorários Periciais',
                    economia: 'Economia',
                    desagio: 'Deságio',
                    honorario_pact: 'Honorário PACT',
                    economia_net: 'Economia NET',
                    desagio_net: 'Desagio NET'
                };

                productsFiltered.unshift(headers); // if custom header, then make sure first row of data is custom header 
                const worksheet = XLSX.utils.json_to_sheet(productsFiltered, { skipHeader: true });
                //  worksheet = xlsx.utils.json_to_sheet(productsFiltered);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'processos');
            });
        }
        else {
            toast.warn(`O filtro "${title.toUpperCase()}" Não possui registro para download`);
        }
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                const date = new Date();
                let formatedDay = date.toLocaleDateString();
                module.default.saveAs(data, 'Lista_processos_' + formatedDay + EXCEL_EXTENSION);
            }
        });
    }

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    return(<>    
        <div className='card-component'>
            <div className='card-info'>
                <span>{title}</span>
                <p className="m-0" style={{lineHeight: '1'}}>{modeloUmFilter? modeloUmFilter.length : 0 } processos</p>
            </div>            
            <div className='card-button'>
                <FaDownload size={30} onClick={exportExcel}/>
            </div>
        </div>            
    </>)
}

export default CardComponent