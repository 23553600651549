import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/auth'
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import { Box, Paper, Typography } from "@mui/material";
import { Button } from 'primereact/button'
import avatar from '../../assets/avatar.png'
import { Toolbar } from 'primereact/toolbar';
import { TextField, MenuItem } from '@mui/material'
import moment from 'moment';

import useForm from '../../hooks/useForm'
import './style.css'
import { toast } from 'react-toastify';


const ToDos = () => {

  const { isHumburguerActive, user } = useContext(AuthContext);
  const {
    getAtividades,
    colunaAtividades,
    moveAtividades,
    departamento,
    getDepartamento,
    funcao,
    getFuncao,
  } = useContext(CrudContext);
  const [columns, setColumns] = useState(null);
  const [columnsHisto, setColumnsHisto] = useState(null);
  const [funcaoFilter, setFuncaoFilter] = useState([])
  const [atividadeFilter, setAtividadeFilter] = useState([])
  const [colunaAtividadePreFilter, setColunaAtividadePreFilter] = useState([])
  const [_funcao, _setFuncao] = useState('Todas')
  const [_atividade, _setAtividade] = useState('Todas')
  const [form, handleInputChange, clear] = useForm({
    atividade: 'Todas',
    funcao: 'Todas',
    departamento: 'Todas'
  })
  const [campoFuncao, setCampoFuncao] = useState()
  const [departamentoFilter, setDepartamentoFiler] = useState([])


  const [_departamento, _setDepartamento] = useState(() => {
    if (user.tipoUsuario === 'Líder') {
      let _filter = [...funcao]
      let filtrado = _filter.filter(item => item.Departamento === user.departamento)
      setFuncaoFilter(filtrado)
      let atividadeCopy = [...colunaAtividades]
      atividadeCopy = atividadeCopy.filter(ativ => ativ.Department === user.departamento)
      setAtividadeFilter(atividadeCopy)

      return user.departamento
    } else {
      return 'Todas'
    }
  })


  useEffect(() => {
    getAtividades();
    getDepartamento();
    getFuncao();
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (user?.tipoUsuario === 'Líder') {
      let departamentoCopy = [...departamento]
      departamentoCopy = departamentoCopy.filter(dep => dep.Descricao === user.departamento)
      setDepartamentoFiler(departamentoCopy)

      let event = {
        target: {
          name: 'departamento',
          value: departamentoCopy[0]?.Descricao
        }
      }

      if (event.target.value) {
        handleInputChange(event)
      }

    }
  }, [departamento])

  useEffect(() => {

    if (user?.tipoUsuario === 'Líder') {
      let colunaAtividadesCopy = [...colunaAtividades]
      colunaAtividadesCopy = colunaAtividadesCopy.filter(ativ => ativ.Department === user.departamento)
      setColumns(colunaAtividadesCopy)
      setColumnsHisto(colunaAtividadesCopy)
    }

    if (user?.tipoUsuario === 'Administrador Global') {
      setColumns(colunaAtividades)
      setColumnsHisto(colunaAtividades)
    }

    if (user?.tipoUsuario === 'Analista') {
      let colunaAtividadeCopy = [...colunaAtividades]
      colunaAtividadeCopy = colunaAtividadeCopy.filter(ativ => ativ.Department === user.departamento && ativ.Function === user.funcao)
      setColumns(colunaAtividadeCopy)
      setColumnsHisto(colunaAtividadeCopy)
    }

  }, [colunaAtividades])// eslint-disable-line react-hooks/exhaustive-deps


  const onDragEnd = (result) => {

    var sourceColumnItems = [];
    var destinationColumnItems = [];
    var draggedItem = {};

    var sourceColumnId = 0;
    var destinationColumnId = 0;

    for (var i in columns) {
      if (columns[i]._id == result.source.droppableId) {
        sourceColumnItems = columns[i].items;
        sourceColumnId = i;
      } else if (columns[i]._id == result.destination.droppableId) {
        destinationColumnItems = columns[i].items;
        destinationColumnId = i;
      }
    }

    for (var i in sourceColumnItems) {
      if (sourceColumnItems[i].id == result.draggableId) {
        draggedItem = sourceColumnItems[i];
      }
    }
    // Excluí o objeto arrastado.
    var filteredSourceColumnItems = sourceColumnItems.filter((item) => item.id != result.draggableId);

    // Adicionar o mesmo na nova posição.
    if (result.source.droppableId == result.destination.droppableId) {
      filteredSourceColumnItems.splice(result.destination.index, 0, draggedItem);

      // Mudar o state
      var columnsCopy = JSON.parse(JSON.stringify(columns));
      columnsCopy[sourceColumnId].items = filteredSourceColumnItems;
      setColumns(columnsCopy);
      setColumnsHisto(columnsCopy)
    } else {
      destinationColumnItems.splice(result.destination.index, 0, draggedItem);
      // Mudar o state
      var columnsCopy = JSON.parse(JSON.stringify(columns));
      columnsCopy[sourceColumnId].items = filteredSourceColumnItems;
      columnsCopy[destinationColumnId].items = destinationColumnItems;
      setColumns(columnsCopy);
      setColumnsHisto(columnsCopy)
    }

    let _idOrigem = result.source.droppableId
    let _idDestino = result.destination.droppableId
    let _idAtividade = result.draggableId
    let _processo = draggedItem.NCNJ

    let _data = {
      idAtividade: _idAtividade,
      idAtividadeOrigem: _idOrigem,
      idAtividadeDestino: _idDestino,
      idProcesso: _processo
    }

    moveAtividades(_data);
  };

  const clearOpcoes = () => {
    _setAtividade('Todas')
    _setFuncao('Todas')
  }

  const filterFuncao = (departamentoFilter) => {
    clearOpcoes()
    let _filter = [...funcao]
    let filtrado = _filter.filter(item => item.Departamento === departamentoFilter)
    setFuncaoFilter(filtrado)

    if (departamentoFilter != 'Todas') {
      let colunaAtividadesCopy = [...columnsHisto]
      colunaAtividadesCopy = colunaAtividadesCopy.filter(item => item.Department === departamentoFilter)
      setColunaAtividadePreFilter(colunaAtividadesCopy)
      setAtividadeFilter(colunaAtividadesCopy)

    }

    if (departamentoFilter === 'Todas') {
      setColunaAtividadePreFilter(columnsHisto)
      setAtividadeFilter(columnsHisto)
    }

  }

  const filterLegalOps = () => {
    if (!_departamento) {
      return toast.error("Necessário selecionar um Departamento")
    }

    let copyColumn = [...columnsHisto]

    if (_departamento !== 'Todas' && _funcao === 'Todas' && _atividade === 'Todas') {
      copyColumn = copyColumn.filter(item => item.Department === _departamento)
      setColumns(copyColumn)
    }

    if (_departamento !== 'Todas' && _funcao !== 'Todas' && _atividade === 'Todas') {
      copyColumn = copyColumn.filter(item => item.Department === _departamento && item.Function === _funcao)
      setColumns(copyColumn)
    }

    if (_departamento !== 'Todas' && _funcao !== 'Todas' && _atividade !== 'Todas') {
      copyColumn = copyColumn.filter(item => item.Department === _departamento && item.Function === _funcao && item.Name === _atividade)
      setColumns(copyColumn)
    }

    if (_departamento !== 'Todas' && _funcao === 'Todas' && _atividade != 'Todas') {
      copyColumn = copyColumn.filter(item => item.Department === _departamento && item.Name === _atividade)
      setColumns(copyColumn)
    }

    if (_departamento === 'Todas' && _funcao === 'Todas' && _atividade !== 'Todas') {
      copyColumn = copyColumn.filter(item => item.Name === _atividade)
      setColumns(copyColumn)
    }

    if (_departamento === 'Todas' && _funcao === 'Todas' && _atividade === 'Todas') {
      setColumns(columnsHisto)
    }
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>

        {user?.tipoUsuario === 'Líder' &&
          <TextField
            id="outlined-select-currency-empresa"
            select
            label="Departamento"
            value={_departamento}
            name={'departamento'}
            onChange={(e) => {
              // handleInputChange(e) 
              _setDepartamento(e.target.value)
              filterFuncao(e.target.value)
            }}
            margin="normal"
            variant="outlined"
            size="small"
          >
            {departamentoFilter?.map(item => {
              return <MenuItem key={item._id} value={item.Descricao}>{item.Descricao}</MenuItem>
            })
            }
          </TextField>
        }

        {user?.tipoUsuario === 'Administrador Global' &&
          <TextField
            id="outlined-select-currency-empresa"
            select
            label="Departamento"
            value={_departamento}
            name={'departamento'}
            onChange={(e) => {
              // handleInputChange(e) 
              _setDepartamento(e.target.value)
              filterFuncao(e.target.value)
            }}
            margin="normal"
            variant="outlined"
            size="small"
          >
            <MenuItem key={999} value={'Todas'}>
              Todas
            </MenuItem>
            {departamento?.map(item => {
              return <MenuItem key={item._id} value={item.Descricao}>{item.Descricao}</MenuItem>
            })
            }
          </TextField>
        }

        <TextField
          id="outlined-select-currency-empresa"
          select
          label="Função"
          value={_funcao}
          name={'funcao'}
          onChange={(e) => {
            // handleInputChange(e)
            _setFuncao(e.target.value)
            // filterAtividade(e.target.value)
          }}
          margin="normal"
          variant="outlined"
          size="small"
        >
          <MenuItem key={999} value={'Todas'}>
            Todas
          </MenuItem>
          {funcaoFilter && funcaoFilter?.map(item => {
            return <MenuItem key={item._id} value={item.label}>{item.label}</MenuItem>
          })}
        </TextField>

        <TextField
          id="outlined-select-currency-empresa"
          select
          label="Atividade"
          value={_atividade}
          name={'atividade'}
          onChange={(e) => {
            //handleInputChange(e) 
            _setAtividade(e.target.value)

          }}
          margin="normal"
          variant="outlined"
          size="small"
        >
          <MenuItem key={999} value={'Todas'}>
            Todas
          </MenuItem>
          {atividadeFilter &&
            atividadeFilter?.map(item => {
              return <MenuItem key={item._id} value={item.Name}>{item.Name}</MenuItem>
            })
          }
        </TextField>

        <Button label="Filtrar" icon="pi pi-filter-fill" className="p-button-new" type="button" onClick={filterLegalOps} />

      </React.Fragment>

    )
  }


  return (
    <div className="App">
      <Header />

      <div className={`content${isHumburguerActive ? '-active' : ''}`}>

        {(user.tipoUsuario === 'Administrador Global' || user.tipoUsuario === 'Líder') &&
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
        }

        <div className="container-todos">
          {columns && <Box>
            <Box display="flex" height="50vh">
              <DragDropContext onDragEnd={onDragEnd}>
                {columns.map((column) => (
                  <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }} key={column._id}>
                    <Droppable droppableId={column._id} key={column._id}>
                      {(provided) => (
                        <Box style={{ backgroundColor: "#ebebf1", width: 350, height: "fit-content", padding: 10, margin: 10, borderRadius: 10 }}>
                          <Typography variant="h5">{column.Name}</Typography>
                          <Box ref={provided.innerRef} width="100%" height="100%">
                            {column.items.map((item, index) => (
                              <Draggable draggableId={item.id} index={index} key={item.id}>
                                {(provided) => (
                                  <Paper
                                    onClick={() => { console.log('função de abrir popUp') }}
                                    elevation={2}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    style={{
                                      height: 60,
                                      marginTop: 10,
                                      padding: 5,
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }} >
                                      <div>
                                        <p>{item.content}</p>
                                        {/* <p><b>Prazo: </b> {moment(item.prazo).format("DD/MM/YYYY")}</p> */}
                                      </div>

                                      <img style={{ idth: 25, height: 25, borderRadius: 15 }} src={user.avatarUrl ? user.avatarUrl : avatar} title={user.nome} />

                                    </div>

                                  </Paper>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </Box>
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                ))}
              </DragDropContext>
            </Box>
          </Box>
          }

        </div >
      </div >
    </div >
  );
}

export default ToDos;
