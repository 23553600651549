import { useContext, useEffect } from 'react'

import { AuthContext } from '../../contexts/auth'
import { ImportacaoContext } from '../../contexts/importacao'
import { CrudContext } from '../../contexts/crud'
import Header from '../../Components/Header'
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DataGrid from '../../Components/GridModeloUm';
import LoadingIcons from 'react-loading-icons'
import './modeloum.css'

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};


function ModeloUm() {

  const { user, isHumburguerActive } = useContext(AuthContext);
  const { sendProcess, getImportacao, modeloUm, setProgress, isEmpty, load2 } = useContext(ImportacaoContext);
  const { getEmpresas, empresas } = useContext(CrudContext);

  useEffect(() => {
    setProgress(0);
    getImportacao();
    getEmpresas();
  }, [])

  return (
    <div className="App">
      <Header />
      <div className={`content${isHumburguerActive ? '-active' : ''}`}>
        <div className="container-dashboard">
          {load2 ?
            <div className='icon-loading'>
              <LoadingIcons.SpinningCircles stroke="#5499e9" />
            </div> : <>
              {isEmpty !== null &&
                <>
                  <DataGrid data={modeloUm} />
                </>
              }
            </>
          }
        </div>
      </div>
    </div>
  );
}

export default ModeloUm;



