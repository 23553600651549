import { useContext, useEffect } from 'react'
import { AuthContext } from '../../contexts/auth'
import CardComponent from '../../Components/Card';
import Header from '../../Components/Header'
import { Grid } from '@mui/material';
import LoadingIcons from 'react-loading-icons'

import './styled.css'
import { ImportacaoContext } from '../../contexts/importacao';
const Insights = () => {

  const { isHumburguerActive, user } = useContext(AuthContext);
  const { filterUser, getFilterByUserId, getFilter, modeloUm, getImportacao, load } = useContext(ImportacaoContext)

  useEffect(() => {
    getImportacao()
    if (user.tipoUsuario === "Cliente") {
      getFilterByUserId(user.uid)
    } else {
      getFilter()
    }
  }, [])

  return (
    <div className="App">
      <Header />
      <div className={`content${isHumburguerActive ? '-active' : ''}`}>
        {load ?
          <div className='icon-loading'>
            <LoadingIcons.SpinningCircles stroke="#5499e9" />
          </div> : <>
            <Grid className='grid-component' columnSpacing={1} container justifyContent={'space-evenly'}>

              {filterUser &&
                filterUser.map((filterItens, index) => {
                  return <CardComponent key={index} title={filterItens.descricao} modeloUm={modeloUm} filterItens={filterItens} />
                })
              }

            </Grid>
          </>
        }
      </div>
    </div>
  );
}

export default Insights;