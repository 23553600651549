import './grid.css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { CrudContext } from '../../contexts/crud';
import { ImportacaoContext } from '../../contexts/importacao';
import Box from '@mui/material/Box';
import { isExcel, readExcelFile } from '../../utils';
import { MenuItem } from '@mui/material';
import moment from 'moment';

import { TextField, FormControlLabel, FormGroup, Switch, Tooltip, Autocomplete } from '@mui/material'
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify'
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid'

import {
    DataGridPro,
    ptBR,
    GridLinkOperator, useGridApiContext
} from '@mui/x-data-grid-pro';

import { Button } from 'primereact/button';


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};


function DataGrids(props) {
    const {
        getMomentos,
        momentos,
        momentosExecucao,
        advogados,
        getAdvogado,
        colunaAtividades,
        getAtividades,
        registrarAtividade
    } = useContext(CrudContext);

    const {
        alterProcess,
        getFilter,
        filterUser,
        getFilterByUserId,
        deleteProcess,
        newImport,
        setNewImport,
        sendProcess,
        progress,
        setProgress,
        load,
        msg,
        saveFilter,
        getLabels,
        isEmpty,
        modeloUm
    } = useContext(ImportacaoContext);

    const [products2, setProducts2] = useState(null);
    const [gridHistorico, setGridHistorico] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const { getEmpresas, empresas } = useContext(CrudContext);
    const { user } = useContext(AuthContext);
    const [pageSize, setPageSize] = useState(50);
    const [nomeFiltro, setNomeFiltro] = useState('');
    const [newFilterDialog, setNewFiltersDialog] = useState(false);

    const [iniciarAtividadeDialog, setIniciarAtividadeDialog] = useState(false);
    const [atividade, setAtividade] = useState()
    const [atividadePrazo, setAtividadePrazo] = useState()
    const [atividadeDescricao, setAtividadeDescricao] = useState()

    const [newCamposDialog, setNewCamposDialog] = useState(false);
    const [listMoment, setListMoment] = useState(null);
    const [listMomentExecucao, setListMomentExecucao] = useState(null);
    const [listAdvogado, setListAdvogado] = useState(null);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [newProductsDialog, setNewProductsDialog] = useState(false);
    const [productDialog, setProductDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false);
    const [cliente, setCliente] = useState({});
    const [fileName, setFileName] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [queryOptions, setQueryOptions] = useState({});
    const [basico, setBasico] = useState(true);
    const [fase, setFase] = useState(false);
    const [encerrados, setEncerrados] = useState(false);
    const [pedidos, setPedidos] = useState(false);
    const [funcao, setFuncao] = useState(false);
    const [resersao, setResersao] = useState(false);
    const [financeira, setFinanceira] = useState(false);
    const [garantias, setGarantias] = useState(false);
    const [audiencias, setAudiencias] = useState(false);
    const [acordos, setAcordos] = useState(false);
    const [calculos, setCalculos] = useState(false);
    const [pericias, setPericias] = useState(false);
    const [customizado, setCustomizado] = useState(false);
    const [negociar, setNegociar] = useState(false)

    const [nbasico, setnBasico] = useState(true);
    const [nfase, setnFase] = useState(false);
    const [nencerrados, setnEncerrados] = useState(false);
    const [npedidos, setnPedidos] = useState(false);
    const [nfuncao, setnFuncao] = useState(false);
    const [nresersao, setnResersao] = useState(false);
    const [nfinanceira, setnFinanceira] = useState(false);
    const [ngarantias, setnGarantias] = useState(false);
    const [naudiencias, setnAudiencias] = useState(false);
    const [nacordos, setnAcordos] = useState(false);
    const [ncalculos, setnCalculos] = useState(false);
    const [npericias, setnPericias] = useState(false);
    const [ncustomizado, setnCustomizado] = useState(false);

    const [tipo1, setTipo1] = useState(false);
    const [tipo2, setTipo2] = useState(false);
    const [tipo3, setTipo3] = useState(false);
    const [tipo4, setTipo4] = useState(false);
    const [tipo5, setTipo5] = useState(false);
    const [tipo6, setTipo6] = useState(false);
    const [tipo7, setTipo7] = useState(false);
    const [tipo8, setTipo8] = useState(false);
    const [tipo9, setTipo9] = useState(false);
    const [tipo10, setTipo10] = useState(false);

    const [empresa, setEmpresa] = useState('');
    const [selFiltro, setSelFiltro] = useState('');
    const [exibeGrid, setExibeGrid] = useState(false);
    const [useFiltro, setUseFiltro] = useState(false);
    const [filterModel, setFilterModel] = useState({
        items: [

        ]
    });
    const [filterEmpresaAdm, setFilterEmpresaAdm] = useState(() => {
        if (user?.tipoUsuario === "Cliente") {
            return user.cliente
        } else {
            return empresas
        }
    });
    const ref = useRef();
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        // Hide columns status and traderName, the other columns will remain visible
        id: false,
        NCNJ: basico,
        contrarioPrincipalNomeRazao: basico,
        uf: basico,
        cidade: basico,
        advogadoReverso_oab: basico,
        vara: basico,
        nVara: basico,
        dtDistribuicao: basico,
        ecBase: basico,
        riscoDCliente: basico,
        faixaRisco: basico,
        valorCausa: basico,
        tipoProcesso: basico,
        tipoAcao: basico,
        primeiraReclamada: basico,
        demaisReclamadas: basico,
        status: fase,
        fase: fase,
        momento: fase,
        dtMomento: fase,
        dtAudiencia: fase,
        dtEncerramento: fase,
        motivoEncerramento: encerrados,
        ticketMedio: encerrados,
        valorAcordoCondenacao: encerrados,
        formaPagamentoAcordo: encerrados,
        quemPagouAcordo: encerrados,
        momentoEmQueAcordoRealizado: encerrados,
        pedidosDaInicial: pedidos,
        funcaoCompilada: funcao,
        proprioTerceiro: funcao,
        ultimoSalario: funcao,
        dtAdmissao: funcao,
        dtDemissao: funcao,
        tempoCasa: funcao,
        sentenca: resersao,
        dtSentenca: resersao,
        nomeJuiz: resersao,
        houveReformaAcordaoRO: resersao,
        pedidosIncluidosAcordao: resersao,
        pedidosExcluidosAcordao: resersao,
        dtAcordao: resersao,
        acordaoReformouImprocedencia: resersao,
        rrAdmitido: resersao,
        seFoiAdmitidoQualMateria: resersao,
        houveReformaAcordaoRR: resersao,
        dtAcordao2: resersao,
        juros: financeira,
        hPericiais: financeira,
        honorariosAdvocaticios: financeira,
        custasProcessuais: financeira,
        estabilidade: financeira,
        bonus: financeira,
        equiparacaoSalarial: financeira,
        acumuloDeFuncao: financeira,
        dMoral: financeira,
        dMaterial: financeira,
        pensao: financeira,
        he: financeira,
        horasInItinere: financeira,
        sebreaviso: financeira,
        intrajornada: financeira,
        diferencasSalariais: financeira,
        adTransferencia: financeira,
        insalubridade: financeira,
        periculosidade: financeira,
        ticketMedioporPedido: financeira,
        garantia: garantias,
        possuiGarantia: garantias,
        garantiaMaiorouMenorqueRisco: garantias,
        contrarioPrincipalNomeRazao2: garantias,
        tipoGarantiaRecursalDepositoApolice: garantias,
        valorLiberado: garantias,
        revelia: audiencias,
        reclamanteFoiOuvidaemAud: audiencias,
        testemunhaReclamada: audiencias,
        testemunhaReclamante: audiencias,
        dtTransitoemJulgado: calculos,
        valorCalcApresentadoReclamada: calculos,
        valorCalcApresentadoReclamante: calculos,
        valorCalcApresentadoPerito: calculos,
        valorHomologado: calculos,
        dtCalcHomologado: calculos,
        deQuemfoiCalcHomologado: calculos,
        houveEmbargosExecucao: calculos,
        materiaEe: calculos,
        formaDeGarantia: calculos,
        houvePericia: pericias,
        nomePeritoPericulosidadeInsalubridade: pericias,
        nomePeritoAcidenteDoenca: pericias,
        periciaDeQue: pericias,
        resultadoInsalubridade: pericias,
        fundamentoInsalubridade: pericias,
        resultadoPericulosidade: pericias,
        agentePericulosidade: pericias,
        fundamentoPericulosidade: pericias,
        agenteInsalubre: pericias,
        resultadoDoenca: pericias,
        resultadoAcidente: pericias,
        dtLaudo: pericias,
        compliadofunHe: pericias,
        agenteInsalubre2: pericias,
        grauDaInsalubridade: pericias,
        compiladofunPericulosidade: pericias,
        compiladofunAcidDoenca: pericias,
        qualDoenca: pericias,
        incapacidade: pericias,
        reducaoFuncional: pericias,
        compiladoFundDanoMoral: pericias,
        negociar: acordos

    });
    const uflist = [
        'AC',
        'AL',
        'AP',
        'AM',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MT',
        'MS',
        'MG',
        'PA',
        'PB',
        'PR',
        'PE',
        'PI',
        'RJ',
        'RN',
        'RS',
        'RO',
        'RR',
        'SC',
        'SP',
        'SE',
        'TO'
    ];

    const tipoAcao = [
        'Trabalhista',
        'Ambiental',
        'Tributário',
        'Administrativo'
    ];

    const statusPre = [
        'Desconhecido',
        '00.00 Intermediário - Forçar nova alteração de status',
        '00.02 Controle de Triagem - Indício de Restrição',
        '10.02 Cadastro Básico Realizado',
        '10.03 Cadastro Básico Realizado - Cópias já Solicitadas em lote',
        '10.04 Cadastro Básico Realizado - Cálculos já Solicitados em lote',
        '10.05 Cadastro Básico Realizado - Aguardando Cópia e Cálculo - Solicitação em lote',
        '10.06 Dados Inválidos',
        '21.00 Cópia Solicitada',
        '21.04 Nova Solicitação - Cópia - Erro',
        '25.01 Autos Indisponiveis - Carga',
        '25.02 Autos Indisponiveis - Concluso',
        '25.03 Autos Indisponiveis - Contadoria',
        '25.04 Autos Indisponiveis - Pendente Publicação',
        '25.05 Autos Indisponiveis - Processo em Trânsito',
        '25.06 Autos Indisponiveis - Segredo de Justiça',
        '25.07 Autos Indisponiveis - Processo não localizado',
        '25.08 Autos Indisponiveis - Retorno Cliente',
        '25.99 Autos Indisponiveis - Outros',
        '30.01 Cálculo Solicitado',
        '30.02 Cálculo em Validação',
        '30.03 Solicitação de Análise de Cálculo',
        '30.04 Cópia e Cálculo Solicitados',
        '30.05 Solicitação de Atualização de Cálculo',
        '30.06 Solicitação Interna - Adequação Interna de Cálculo',
        '30.07 Solicitação Interna - Dedução de Valor Liberado (Despacho - Sem Alvará)',
        '30.08 Retificação de Cálculo e Cópia Pendente',
        '30.09 Retificação Interna de Cálculo e Cópia Pendente',
        '30.90 Nova Solicitação Interna - Cálculo - Erro Interno',
        '30.98 Nova Solicitação - Cálculo - Erro',
        '40.00 Análise Processual',
        '40.02 Atualizar Momento Processual',
        '40.11 Diligência Recusada',
        '40.12 Diligência Recebida',
        '45.00 Lançar Cálculo Atualizado - Solicitado pela Negociação',
        '45.01 Atualização de Cálculo Realizada',
        '45.04 Cálculo Recusado - Cópia',
        '45.05 Cálculo Recusado - Improcedente',
        '45.06 Cálculo Recusado - Ausencia de Informação',
        '45.08 Cálculo Recebido - Verificar e Lançar',
        '45.10 Retorno da Solicitação de Análise',
        '45.11 Adequação Realizada',
        '45.12 Dedução Interna Realizada',
        '50.00 Validação Final',
        '50.04 Validação Final - Restrito a validar',
        '50.11 Inserir RLANet',
        '50.20 Projetos - Retorno Revalidar Nova Provisão',
        '50.30 Projetos - Retorno Aguardando Definição',
        '50.31 Projetos - Retorno Aguardando Autorização para Subir',
        '50.33 Projetos - Retorno Dados Inválidos',
        '50.35 Projetos - Retorno Provisão Inferior - Negociação Autorizada',
        '55.12 Inserido RLANet',
        '55.14 Acordo PACT',
        '55.15 Pendência - Pendente de tratamento',
        '55.16 Regularização - Pendente de tratamento',
        '60.01 Finalizado - Assessoria',
        '60.02 Finalizado - Provisão Inferior ao Risco',
        '60.03 Análise Suspensa',
        '60.05 Restrito - Contribuições Sindicais',
        '60.06 Finalizado - Ministério Público do Trabalho (MPT)',
        '60.07 Finalizado - Ministério Público do Trabalho e Emprego (MPTE)',
        '60.08 Finalizado - Risco Zerado',
        '60.50 Restrito - A Pedido do Cliente',
        '60.51 Restrito - Acordo em Cumprimento',
        '60.52 Restrito - Baixado',
        '60.53 Restrito - Encerrado',
        '60.54 Restrito - Contribuição Previdenciária e/ou Fiscal',
        '60.55 Restrito - Fase',
        '60.57 Restrito - Improcedência',
        '60.58 Restrito - Não Reclamação Trabalhista',
        '60.59 Restrito - Obrigação de Fazer',
        '60.60 Restrito - Terceiras',
        '60.61 Restrito - Valor',
        '60.62 Restrito - Duplicidade',
        '60.63 Restrito - Desdobramento',
        '60.65 Restrito - Reclamante com Multiplas Ações',
        '60.66 Restrito - Parcelamento da Execução',
        '60.70 Restrito - Inegociável',
        '60.98 Projetos - No Go Aguardando autorização',
        '60.99 Restrito - No Go',
        '70.01 Negociação Online - Solicitar Cálculo',
        '70.02 Negociação Online - Solicitar Atualização',
        '70.03 Negociação Online - Prosseguir',
        '99.01 Aguardando Definição',
        '99.02 Finalizado - Aguardando Autorização para subir',
        '99.03 Aguardando Definição Interna - Logística',
        '70.04 Em Negociação - Com Interação da Contraparte',
        '70.05 Em Negociação - Sem Interação da Contraparte',
        '70.06 Contraproposta Superior ao Gatilho - Validar com o Cliente',
        '70.07 No Go - Sem Interação na Plataforma',
        '70.08 No Go - Sem Interesse da Contraparte',
        '70.09 Acordo em Andamento - Pendente de Discriminação',
        '70.10 Acordo em Andamento - Pendente de Minuta',
        '70.11 Acordo em Andamento - Pendente de Protocolo',
        '70.12 Acordo Protocolado',
        '70.13 Acordo Faturado',
        '60.97 No Go - Inviabilidade - Cálculo de Risco Sugerido pelo Cliente'
    ];

    const statusNegociacao = [
        'A iniciar negociaçao',
        'Acordo em análise',
        'Acordo em negociaçao',
        'Mudança de Momento',
        'Acordo fechado, A discriminar',
        'Acordo discriminado, A subir Acordo',
        'No Go',
        'Aceito Contraparte, A Validar Gestor',
        'Validado Gestor, A Aprovar Escritório',
        'Aprovado Escritório, A Aprovar Cliente',
        'Aprovado Cliente, A enviar Minuta',
        'Minuta Enviada, a assinar ',
        'Minuta Assinada, a Protocolar',
        'Minuta Protocolada, a Homologar',
        'Homologado, A Faturar',
        'Acordo Faturado',
        'Recusado Contraparte, A Renegociar',
        'Retorno Gestor, a Revisar',
        'Desaprovado Pós Acordo, A Renegociar',
        'Recusado Escritorio, A Renegociar',
        'Recusado Cliente, A Renegociar',
        'Aguardando Regularização'
    ];

    const termometro = [
        'Alto',
        'Médio',
        'Baixo'
    ];



    const momento = [];
    const momentoExecucao = [];
    const advogado = [];
    const [collumns, setCollmuns] = useState([]);

    useEffect(() => {
        setProgress(0);
        getMomentos();
        getAdvogado();
        setProducts2(props.data);
        setGridHistorico(props.data);
        setNewImport(false);
        getEmpresas();
        // getAtividades();

        if (user.tipoUsuario === "Cliente") {
            getFilterByUserId(user.uid)
        } else {
            getFilter();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (momentos) {
            momentos.forEach(moment => {
                momento.push(moment.description)
            })
            setListMoment(momento);
        }
    }, [momentos]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (momentosExecucao) {
            momentosExecucao.forEach(moment => {
                momentoExecucao.push(moment.description)
            })
            setListMomentExecucao(momentoExecucao);
        }
    }, [momentosExecucao]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (load === false) {
            setNewProductsDialog(false)
        }
    }, [load])

    const renderLaywerEditInputCell = (params) => {
        return <LaywerEditInputCell {...params} />;
    };

    function LaywerEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleChange = (event, newValue) => {
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        const handleRef = (element) => {
            if (element) {
                const input = element.querySelector(`input[value="${value}"]`);
                input?.focus();
            }
        };

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
                <Autocomplete
                    disablePortal
                    freeSolo
                    ref={handleRef}
                    id="advogadoReverso_oab"
                    options={listAdvogado}
                    sx={{ width: 400 }}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Box>
        );
    }

    useEffect(() => {
        if (advogados) {
            advogados.forEach(adv => {
                advogado.push(adv.name + ' - ' + adv.oab[0].oab)
            })
            setListAdvogado(advogado);
        }
    }, [advogados]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCollmuns([{
            "field": "id",
            "hide": true
        },
        {
            "field": "empresa",
            "headerName": "Empresa",
            "width": 150,
            "editable": true
        },
        {
            "field": "status_atividade",
            "headerName": "Status Atividade",
            "width": 150,
            "editable": false
        },
        {
            "field": "NCNJ",
            "headerName": "Número Proc. De base",
            "width": 200,
            "hide": false
        },
        {
            "field": "contrarioPrincipalNomeRazao",
            "headerName": "Contrário principal / Nome/razão social",
            "width": 250,
            "editable": true
        },
        {
            "field": "uf",
            "headerName": "UF",
            "type": "singleSelect",
            "valueOptions": uflist,
            "width": 50,
            "editable": true
        },
        {
            "field": "cidade",
            "headerName": "Cidade",
            "width": 250,
            "editable": true
        },
        {
            "field": "advogadoReverso_oab",
            "headerName": "Advogado Adverso / oab",
            "width": 400,
            "editable": true,
            "renderEditCell": renderLaywerEditInputCell,
        },
        {
            "field": "vara",
            "headerName": "Vara",
            "width": 250,
            "editable": true
        },
        {
            "field": "nVara",
            "headerName": "n.º da Vara",
            "width": 250,
            "editable": true
        },
        {
            "field": "dtDistribuicao",
            "type": "date",
            "headerName": "Data de Distribuição",
            "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
            "width": 250,
            "editable": true
        },
        {
            "field": "ecBase",
            "headerName": "Escritório de Base (Dado Cliente)",
            "width": 150,
            "editable": true
        },
        {
            "field": "riscoDCliente",
            "headerName": "Risco (Dado Cliente)",
            "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
            "width": 150,
            "editable": true
        },
        {
            "field": "faixaRisco",
            "headerName": "Faixas de Risco",
            "width": 250,
            "editable": true
        },
        {
            "field": "valorCausa",
            "headerName": "Valor da Causa",
            "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
            "width": 250,
            "editable": true
        },
        {
            "field": "tipoProcesso",
            "headerName": "Tipo de Processo",
            "width": 250,
            "editable": true
        },
        {
            "field": "tipoAcao",
            "headerName": "Tipo Ação",
            "type": "singleSelect",
            "valueOptions": tipoAcao,
            "width": 250,
            "editable": true
        },
        {
            "field": "primeiraReclamada",
            "headerName": "Primeira Reclamada",
            "width": 250,
            "editable": true
        },
        {
            "field": "tribunal",
            "headerName": "Tribunal",
            "width": 250,
            "editable": true
        },
        {
            "field": "demaisReclamadas",
            "headerName": "Demais Reclamadas (na ordem)",
            "width": 250,
            "editable": true
        }])

    }, [listAdvogado]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setExibeGrid(true);
    }, [columnVisibilityModel]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setProducts2(props.data);
        setGridHistorico(props.data);
    }, [modeloUm]); // eslint-disable-line react-hooks/exhaustive-deps

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    const empresaCollumns = (linha, labels) => {

        let tipo1 = labels.tipo1
        let tipo2 = labels.tipo2
        let tipo3 = labels.tipo3
        let tipo4 = labels.tipo4
        let tipo5 = labels.tipo5
        let tipo6 = labels.tipo6
        let tipo7 = labels.tipo7
        let tipo8 = labels.tipo8
        let tipo9 = labels.tipo9
        let tipo10 = labels.tipo10

        setTipo1(tipo1)
        setTipo2(tipo2)
        setTipo3(tipo3)
        setTipo4(tipo4)
        setTipo5(tipo5)
        setTipo6(tipo6)
        setTipo7(tipo7)
        setTipo8(tipo8)
        setTipo9(tipo9)
        setTipo10(tipo10)

        let _basico = [
            {
                "field": "id",
                "hide": true
            },
            {
                "field": "empresa",
                "headerName": "Empresa",
                "width": 250,
                "editable": true
            },
            {
                "field": "status_atividade",
                "headerName": "Status Atividade",
                "width": 150,
                "editable": false
            },
            {
                "field": "NCNJ",
                "headerName": "Número Proc. De base",
                "width": 200,
                "hide": false
            },
            {
                "field": "contrarioPrincipalNomeRazao",
                "headerName": "Contrário principal / Nome/razão social",
                "width": 250,
                "editable": true
            },
            {
                "field": "uf",
                "headerName": "UF",
                "type": "singleSelect",
                "valueOptions": uflist,
                "width": 50,
                "editable": true
            },
            {
                "field": "cidade",
                "headerName": "Cidade",
                "width": 250,
                "editable": true
            },
            {
                "field": "advogadoReverso_oab",
                "headerName": "Advogado Adverso / oab",
                "width": 400,
                "editable": true,
                "renderEditCell": renderLaywerEditInputCell,
            },
            {
                "field": "vara",
                "headerName": "Vara",
                "width": 250,
                "editable": true
            },
            {
                "field": "nVara",
                "headerName": "n.º da Vara",
                "width": 250,
                "editable": true,

            },
            {
                "field": "dtDistribuicao",
                "type": "date",
                "headerName": "Data de Distribuição",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            },
            {
                "field": "ecBase",
                "headerName": "Escritório de Base (Dado Cliente)",
                "width": 150,
                "editable": true
            },
            {
                "field": "riscoDCliente",
                "headerName": "Risco (Dado Cliente)",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 150,
                "editable": true
            },
            {
                "field": "faixaRisco",
                "headerName": "Faixas de Risco",
                "width": 250,
                "editable": true
            },
            {
                "field": "valorCausa",
                "headerName": "Valor da Causa",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "tipoProcesso",
                "headerName": "Tipo de Processo",
                "width": 250,
                "editable": true
            },
            {
                "field": "tipoAcao",
                "headerName": "Tipo Ação",
                "type": "singleSelect",
                "valueOptions": tipoAcao,
                "width": 250,
                "editable": true
            },
            {
                "field": "primeiraReclamada",
                "headerName": "Primeira Reclamada",
                "width": 250,
                "editable": true
            },
            {
                "field": "demaisReclamadas",
                "headerName": "Demais Reclamadas (na ordem)",
                "width": 250,
                "editable": true
            },
            {
                "field": "tribunal",
                "headerName": "Tribunal",
                "width": 250,
                "editable": true
            }
        ]

        if (linha.fase === 'Sim') {
            _basico = [..._basico,
            {
                "field": "status",
                "headerName": "Status",
                "type": "singleSelect",
                "valueOptions": [
                    "Ativo",
                    "Baixado",
                    "Encerrado",
                    "Suspenso"
                ],
                "width": 150,
                "editable": true,
                "hide": false
            },
            {
                "field": "fase",
                "headerName": "Fase",
                "type": "singleSelect",
                "valueOptions": [
                    "1 - Conhecimento",
                    "2 - Recursal",
                    "3 - Execução"
                ],
                "width": 120,
                "editable": true
            },
            {
                "field": "momento",
                "headerName": "Momento",
                "type": "singleSelect",
                "valueOptions": listMoment,
                "width": 300,
                "editable": true
            },
            {
                "field": "dtMomento",
                "headerName": "Data do Momento",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 150,
                "editable": true
            },
            {
                "field": "dtAudiencia",
                "headerName": "Data da Audiência",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 140,
                "editable": true
            },
            {
                "field": "dtEncerramento",
                "headerName": "Data do Encerramento",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            }
            ]
        }

        if (linha.encerrados === 'Sim') {
            _basico = [..._basico,
            {
                "field": "motivoEncerramento",
                "headerName": "Motivo do Encerramento",
                "width": 250,
                "editable": true
            },
            {
                "field": "ticketMedio",
                "headerName": "$ Ticket Médio (encerramento e ativo)",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "valorAcordoCondenacao",
                "headerName": "Valor do Acordo ou Condenação",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "formaPagamentoAcordo",
                "headerName": "Forma de Pagamento do Acordo",
                "width": 250,
                "editable": true
            },
            {
                "field": "quemPagouAcordo",
                "headerName": "Quem pagou o Acordo?",
                "width": 250,
                "editable": true
            },
            {
                "field": "momentoEmQueAcordoRealizado",
                "headerName": "Momento em que o acordo foi realizado?",
                "width": 250,
                "editable": true
            }]
        }

        if (linha.pedidos === 'Sim') {
            _basico = [..._basico,
            {
                "field": "pedidosDaInicial",
                "headerName": "Pedidos da Inicial",
                "width": 250,
                "editable": true
            }]
        }

        if (linha.funcao === 'Sim') {
            _basico = [..._basico,
            {
                "field": "funcaoCompilada",
                "headerName": "Função Compilada",
                "width": 250,
                "editable": true
            },
            {
                "field": "proprioTerceiro",
                "headerName": "Próprio ou Terceiro?",
                "width": 250,
                "editable": true
            },
            {
                "field": "ultimoSalario",
                "headerName": "Último Salário",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "dtAdmissao",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "headerName": "Data de Admissão",
                "width": 250,
                "editable": true
            },
            {
                "field": "dtDemissao",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "headerName": "Data de Demissão",
                "width": 250,
                "editable": true
            },
            {
                "field": "tempoCasa",
                "headerName": "Tempo de Casa (Anos)",
                "width": 250,
                "editable": true
            }]
        }

        if (linha.resersao === 'Sim') {
            _basico = [..._basico,
            {
                "field": "sentenca",
                "headerName": "Sentença (Pedidos Deferidos)",
                "width": 250,
                "editable": true
            },
            {
                "field": "dtSentenca",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "headerName": "Data da Sentença",
                "width": 250,
                "editable": true
            },
            {
                "field": "nomeJuiz",
                "headerName": "Nome Juiz",
                "width": 250,
                "editable": true
            },
            {
                "field": "houveReformaAcordaoRO",
                "headerName": "Houve reforma no Acórdão de RO?",
                "width": 250,
                "editable": true
            },
            {
                "field": "pedidosIncluidosAcordao",
                "headerName": "Pedidos Incluidos no Acórdão",
                "width": 250,
                "editable": true
            },
            {
                "field": "pedidosExcluidosAcordao",
                "headerName": "Pedidos Excluídos do Acórdão",
                "width": 250,
                "editable": true
            },
            {
                "field": "dtAcordao",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "headerName": "Data do Acórdão",
                "width": 250,
                "editable": true
            },
            {
                "field": "acordaoReformouImprocedencia",
                "headerName": "Acórdão reformou para improcedência?",
                "width": 250,
                "editable": true
            },
            {
                "field": "rrAdmitido",
                "headerName": "RR Admitido?",
                "width": 250,
                "editable": true
            },
            {
                "field": "seFoiAdmitidoQualMateria",
                "headerName": "Se foi admitido, qual a matéria?",
                "width": 250,
                "editable": true
            },
            {
                "field": "houveReformaAcordaoRR",
                "headerName": "Houve reforma no acórdão RR? Se sim, qual",
                "width": 250,
                "editable": true
            },
            {
                "field": "dtAcordao2",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "headerName": "Data do Acórdão",
                "width": 250,
                "editable": true
            }]
        }

        if (linha.financeira === 'Sim') {
            _basico = [..._basico,
            {
                "field": "juros",
                "headerName": "$ Juros",
                "width": 250,
                "editable": true,
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
            },
            {
                "field": "hPericiais",
                "headerName": "$ H. Periciais",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "honorariosAdvocaticios",
                "headerName": "$ Honorários advocatícios",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "custasProcessuais",
                "headerName": "$ Custas Processuais",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "estabilidade",
                "headerName": "$ Estabilidade",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "bonus",
                "headerName": "$ Bonus",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "equiparacaoSalarial",
                "headerName": "$ Equiparação Salarial",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "acumuloDeFuncao",
                "headerName": "$ Acumulo de Função",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "dMoral",
                "headerName": "$ D. Moral",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "dMaterial",
                "headerName": "$ D. Material",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "pensao",
                "headerName": "$ Pensão",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "he",
                "headerName": "$ HE",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "horasInItinere",
                "headerName": "$ Horas in itinere",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "sebreaviso",
                "headerName": "$ Sobreaviso",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "intrajornada",
                "headerName": "$ Intrajornada",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "diferencasSalariais",
                "headerName": "$ Diferenças salariais",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "adTransferencia",
                "headerName": "$ Ad. Transferência",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "insalubridade",
                "headerName": "$ Insalubridade",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "periculosidade",
                "headerName": "$ Periculosidade",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "ticketMedioporPedido",
                "headerName": "$ Ticket Médio por Pedido (encerramento e ativo)",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            }]
        }

        if (linha.acordos === 'Sim') {
            _basico = [..._basico,
            {
                "field": "cpf_reclamante",
                "headerName": "CPF Reclamante",
                "width": 250,
                "editable": true
            },
            {
                "field": "projeto",
                "headerName": "Projeto",
                "width": 250,
                "editable": true
            },
            {
                "field": "status_pact",
                "headerName": "Status Pact",
                "width": 250,
                "editable": true
            },
            {
                "field": "cnj_execucao_provisoria",
                "headerName": "CNJ Execução Provisória",
                "width": 250,
                "editable": true
            },
            {
                "field": "momento_execucao_provisoria",
                "headerName": "Momento Execução Provisória",
                "type": "singleSelect",
                "valueOptions": listMomentExecucao,
                "width": 250,
                "editable": true
            },
            {
                "field": "data_cadastro_processo_base",
                "headerName": "Data do Cadastro do Processo na Base",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            },
            {
                "field": "status_pre",
                "headerName": "Status pre",
                "type": "singleSelect",
                "valueOptions": statusPre,
                "width": 250,
                "editable": true
            },
            {
                "field": "dataStatusPre",
                "headerName": "Data Status pre",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            },
            {
                "field": "observacao",
                "headerName": "Observação",
                "width": 250,
                "editable": true
            },
            {
                "field": "data_provisao",
                "headerName": "Data Provisão",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            },
            {
                "field": "provisao",
                "headerName": "Provisão",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "data_atualizacao_risco",
                "headerName": "Data de Atualização do Risco",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            },
            {
                "field": "risco_bruto",
                "headerName": "Risco Bruto",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            }, {
                "field": "risco_liquido",
                "headerName": "Risco líquido",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "risco_bruto_pact_net_atualizado",
                "headerName": "Risco Bruto PACT NET",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "risco_liquido_pact_net_atualizado",
                "headerName": "Risco Líquido PACT NET",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "depositos_recursais",
                "headerName": "Depositos Recursais",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "faixa_risco",
                "headerName": "Faixa de Risco",
                "width": 250,
                "editable": true
            },
            {
                "field": "gatilho_bruto",
                "headerName": "Gatilho Bruto",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "status_negociacao",
                "headerName": "Status da Negociação",
                "type": "singleSelect",
                "valueOptions": statusNegociacao,
                "width": 250,
                "editable": true
            },
            {
                "field": "termometro",
                "headerName": "Termometro",
                "type": "singleSelect",
                "valueOptions": termometro,
                "width": 250,
                "editable": true
            },
            {
                "field": "data_primeiro_contato",
                "headerName": "Data Primeiro Contato",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            },
            {
                "field": "data_ultimo_contato",
                "headerName": "Data Último Contato",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            },
            {
                "field": "proposta",
                "headerName": "Proposta",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "ulitma_pretensao",
                "headerName": "Última Pretensão",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "data_acordo",
                "headerName": "Data do Acordo",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            },
            {
                "field": "data_protocolo",
                "headerName": "Data do Protocolo",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            },
            {
                "field": "data_homologacao",
                "headerName": "Data da Homologação",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            },
            {
                "field": "acordo_bruto",
                "headerName": "Acordo Bruto",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "acordo_liquido",
                "headerName": "Acordo Líquido",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "status_minutas",
                "headerName": "Status das Minutas",
                "width": 250,
                "editable": true
            },
            {
                "field": "inss_reclamada_pago",
                "headerName": "INSS Reclamada (PAGO NO ACORDO)",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "inss_reclamante_pago",
                "headerName": "INSS Reclamante (PAGO NO ACORDO)",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "ir",
                "headerName": "IR",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "honorarios_periciais",
                "headerName": "Homorários Periciais",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "economia",
                "headerName": "Economia",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "desagio",
                "headerName": "Deságio",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "honorario_pact",
                "headerName": "Honorário PACT",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "economia_net",
                "headerName": "Economia NET",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "desagio_net",
                "headerName": "Desagio NET",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            }, {
                "field": "negociar",
                "headerName": "Negociador",
                "width": 250,
                "editable": true
            }, {
                "field": "descricaoSolicitacaoCopia",
                "headerName": "Descrição Solicitaão Cópia",
                "width": 250,
                "editable": true
            }, {
                "field": "descricaoSolicitacaoCalculo",
                "headerName": "Descrição Solicitação Cálculo",
                "width": 250,
                "editable": true
            }, {
                "field": "descricaoUltimaAcaoNegociar",
                "headerName": "Descrição Última Ação Negociar",
                "width": 250,
                "editable": true
            }, {
                "field": "statusPreSubTipo",
                "headerName": "Status Pre Sub Tipo",
                "width": 250,
                "editable": true
            }, {
                "field": "fluxoSaneamento",
                "headerName": "Fluxo Saneamento",
                "width": 250,
                "editable": true
            }, {
                "field": "RestricaoTipo",
                "headerName": "Restrição Tipo",
                "width": 250,
                "editable": true
            }]
        }

        if (linha.garantias === 'Sim') {
            _basico = [..._basico,
            {
                "field": "garantia",
                "headerName": "Garantia",
                "width": 250,
                "editable": true
            },
            {
                "field": "possuiGarantia",
                "headerName": "Possui Guarantia",
                "width": 250,
                "editable": true
            },
            {
                "field": "garantiaMaiorouMenorqueRisco",
                "headerName": "Garantia maior ou menor que o risco?",
                "width": 250,
                "editable": true
            },
            {
                "field": "contrarioPrincipalNomeRazao2",
                "headerName": "Contrário principal / Nome/razão social",
                "width": 250,
                "editable": true
            },
            {
                "field": "tipoGarantiaRecursalDepositoApolice",
                "headerName": "Tipo de Garantia (Recursal/Depósito/Apólice)",
                "width": 250,
                "editable": true
            },
            {
                "field": "valorLiberado",
                "headerName": "Valor Liberado",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            }]
        }

        if (linha.audiencias === 'Sim') {
            _basico = [..._basico,
            {
                "field": "revelia",
                "headerName": "Revelia?",
                "width": 250,
                "editable": true
            },
            {
                "field": "reclamanteFoiOuvidaemAud",
                "headerName": "Reclamante foi ouvido em aud?",
                "width": 250,
                "editable": true
            },
            {
                "field": "testemunhaReclamada",
                "headerName": "Testemunha Reclamada?",
                "width": 250,
                "editable": true
            },
            {
                "field": "testemunhaReclamante",
                "headerName": "Testemunha Reclamante? ",
                "width": 250,
                "editable": true
            }]
        }

        if (linha.calculos === 'Sim') {
            _basico = [..._basico,
            {
                "field": "dtTransitoemJulgado",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "headerName": "Data do Transito em Julgado",
                "width": 250,
                "editable": true
            },
            {
                "field": "valorCalcApresentadoReclamada",
                "headerName": "Valor Cálc. Apresentado pela Reclamada",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "valorCalcApresentadoReclamante",
                "headerName": "Valor Cálc. Apresentado pelo Reclamante",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "valorCalcApresentadoPerito",
                "headerName": "Valor Cálc. Apresentado pelo Perito",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "valorHomologado",
                "headerName": "Valor Homologado",
                "valueFormatter": ({ value }) => value && currencyFormatter.format(value.replace(',', '.')),
                "width": 250,
                "editable": true
            },
            {
                "field": "dtCalcHomologado",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "headerName": "Data Cálc. Homologado",
                "width": 250,
                "editable": true
            },
            {
                "field": "deQuemfoiCalcHomologado",
                "headerName": "De quem foi o cálc. Homologado",
                "width": 250,
                "editable": true
            },
            {
                "field": "houveEmbargosExecucao",
                "headerName": "Houve Embargos à Execução? (Sim ou não)",
                "width": 250,
                "editable": true
            },
            {
                "field": "materiaEe",
                "headerName": "Matéria dos EE",
                "width": 250,
                "editable": true
            },
            {
                "field": "formaDeGarantia",
                "headerName": "Forma de garantia (Dinheiro, seguro, etc)",
                "width": 250,
                "editable": true
            }]
        }

        if (linha.pericias === 'Sim') {
            _basico = [..._basico,
            {
                "field": "houvePericia",
                "headerName": "Houve perícia?",
                "width": 250,
                "editable": true
            },
            {
                "field": "nomePeritoPericulosidadeInsalubridade",
                "headerName": "Nome Perito Periculosidade/Insalubridade",
                "width": 250,
                "editable": true
            },
            {
                "field": "nomePeritoAcidenteDoenca",
                "headerName": "Nome Perito Acidente/Doença",
                "width": 250,
                "editable": true
            },
            {
                "field": "periciaDeQue",
                "headerName": "Pericia de que",
                "width": 250,
                "editable": true
            },
            {
                "field": "resultadoInsalubridade",
                "headerName": "Resultado Insalubridade ",
                "width": 250,
                "editable": true
            },
            {
                "field": "fundamentoInsalubridade",
                "headerName": "Fundamento da Insalubridade",
                "width": 250,
                "editable": true
            },
            {
                "field": "resultadoPericulosidade",
                "headerName": "Resultado Periculosidade",
                "width": 250,
                "editable": true
            },
            {
                "field": "agentePericulosidade",
                "headerName": "# Agente Periculosidade",
                "width": 250,
                "editable": true
            },
            {
                "field": "fundamentoPericulosidade",
                "headerName": "Fundamento da Periculosidade",
                "width": 250,
                "editable": true
            },
            {
                "field": "agenteInsalubre",
                "headerName": "# Agente Insalubre",
                "width": 250,
                "editable": true
            },
            {
                "field": "resultadoDoenca",
                "headerName": "Resultado Doença",
                "width": 250,
                "editable": true
            },
            {
                "field": "resultadoAcidente",
                "headerName": "Resultado Acidente",
                "width": 250,
                "editable": true
            },
            {
                "field": "dtLaudo",
                "headerName": "Data do Laudo",
                "type": "date",
                "valueGetter": ({ value }) => value && moment(value).format("DD/MM/YYYY"),
                "width": 250,
                "editable": true
            },
            {
                "field": "compliadofunHe",
                "headerName": "Compilado Fun. HE",
                "width": 250,
                "editable": true
            },
            {
                "field": "agenteInsalubre2",
                "headerName": "Agente Insalubre",
                "width": 250,
                "editable": true
            },
            {
                "field": "grauDaInsalubridade",
                "headerName": "Grau da Insalubridade",
                "width": 250,
                "editable": true
            },
            {
                "field": "compiladofunPericulosidade",
                "headerName": "Compilado Fun. Periculosidade",
                "width": 250,
                "editable": true
            },
            {
                "field": "compiladofunAcidDoenca",
                "headerName": "Compilado Fun. Acid/Doença",
                "width": 250,
                "editable": true
            },
            {
                "field": "qualDoenca",
                "headerName": "Qual a Doença?",
                "width": 250,
                "editable": true
            },
            {
                "field": "incapacidade",
                "headerName": "Incapacidade?",
                "width": 250,
                "editable": true
            },
            {
                "field": "reducaoFuncional",
                "headerName": "Redução Funcional (%)",
                "width": 250,
                "editable": true
            },
            {
                "field": "compiladoFundDanoMoral",
                "headerName": "Compilado Fund. Dano Moral",
                "width": 250,
                "editable": true
            }]
        }

        if (linha.customizado === 'Sim') {
            _basico = [..._basico,
            {
                "field": "tipo1",
                "headerName": tipo1,
                "width": 250,
                "editable": true
            },
            {
                "field": "tipo2",
                "headerName": tipo2,
                "width": 250,
                "editable": true
            },
            {
                "field": "tipo3",
                "headerName": tipo3,
                "width": 250,
                "editable": true
            },
            {
                "field": "tipo4",
                "headerName": tipo4,
                "width": 250,
                "editable": true
            },
            {
                "field": "tipo5",
                "headerName": tipo5,
                "width": 250,
                "editable": true
            },
            {
                "field": "tipo6",
                "headerName": tipo6,
                "width": 250,
                "editable": true
            },
            {
                "field": "tipo7",
                "headerName": tipo7,
                "width": 250,
                "editable": true
            },
            {
                "field": "tipo8",
                "headerName": tipo8,
                "width": 250,
                "editable": true
            },
            {
                "field": "tipo9",
                "headerName": tipo9,
                "width": 250,
                "editable": true
            },
            {
                "field": "tipo10",
                "headerName": tipo10,
                "width": 250,
                "editable": true
            }]
        }

        setCollmuns(_basico);

    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                const date = new Date();
                let formatedDay = date.toLocaleDateString();
                module.default.saveAs(data, 'Lista_processos_' + formatedDay + EXCEL_EXTENSION);
            }
        });
    }

    const exportExcel = () => {

        let _Basico = basico
        let _Fase = fase
        let _Encerrados = encerrados
        let _Pedidos = pedidos
        let _Funcao = funcao
        let _Resersao = resersao
        let _Financeira = financeira
        let _Garantias = garantias
        let _Audiencias = audiencias
        let _acordos = acordos
        let _Calculos = calculos
        let _Pericias = pericias
        let _Customizado = customizado

        let infoLinha = [];

        if (selectedCustomers.length > 0) {
            let productsFiltered = [];

            products2.forEach((item, i) => {
                let index = selectedCustomers.indexOf(item.NCNJ)

                if (index !== -1) {
                    infoLinha = [];
                    if (_Basico) {
                        infoLinha.NCNJ = item.NCNJ
                        infoLinha.contrarioPrincipalNomeRazao = item.contrarioPrincipalNomeRazao
                        infoLinha.uf = item.uf
                        infoLinha.cidade = item.cidade
                        infoLinha.advogadoReverso_oab = item.advogadoReverso_oab
                        infoLinha.vara = item.vara
                        infoLinha.nVara = item.nVara
                        infoLinha.dtDistribuicao = item.dtDistribuicao ? moment(item.dtDistribuicao).format("DD/MM/YYYY") : ''
                        infoLinha.ecBase = item.ecBase
                        infoLinha.riscoDCliente = item.riscoDCliente ? currencyFormatter.format(item.riscoDCliente.replace(',', '.')) : ''
                        infoLinha.faixaRisco = item.faixaRisco
                        infoLinha.valorCausa = item.valorCausa ? currencyFormatter.format(item.valorCausa.replace(',', '.')) : ''
                        infoLinha.tipoProcesso = item.tipoProcesso
                        infoLinha.tipoAcao = item.tipoAcao
                        infoLinha.primeiraReclamada = item.primeiraReclamada
                        infoLinha.demaisReclamadas = item.demaisReclamadas
                        infoLinha.tribunal = item.tribunal
                    }

                    if (_Fase) {
                        infoLinha.status = item.status
                        infoLinha.fase = item.fase
                        infoLinha.momento = item.momento
                        infoLinha.dtMomento = item.dtMomento ? moment(item.dtMomento).format("DD/MM/YYYY") : ''
                        infoLinha.dtAudiencia = item.dtAudiencia ? moment(item.dtAudiencia).format("DD/MM/YYYY") : ''
                        infoLinha.dtEncerramento = item.dtEncerramento ? moment(item.dtEncerramento).format("DD/MM/YYYY") : ''
                    }

                    if (_Encerrados) {
                        infoLinha.motivoEncerramento = item.motivoEncerramento
                        infoLinha.ticketMedio = item.ticketMedio ? currencyFormatter.format(item.ticketMedio.replace(',', '.')) : ''
                        infoLinha.valorAcordoCondenacao = item.valorAcordoCondenacao ? currencyFormatter.format(item.valorAcordoCondenacao.replace(',', '.')) : ''
                        infoLinha.formaPagamentoAcordo = item.formaPagamentoAcordo
                        infoLinha.quemPagouAcordo = item.quemPagouAcordo
                        infoLinha.momentoEmQueAcordoRealizado = item.momentoEmQueAcordoRealizado
                    }

                    if (_Pedidos) {
                        infoLinha.pedidosDaInicial = item.pedidosDaInicial
                    }

                    if (_Funcao) {
                        infoLinha.funcaoCompilada = item.funcaoCompilada
                        infoLinha.proprioTerceiro = item.proprioTerceiro
                        infoLinha.ultimoSalario = item.ultimoSalario ? currencyFormatter.format(item.ultimoSalario.replace(',', '.')) : ''
                        infoLinha.dtAdmissao = item.dtAdmissao ? moment(item.dtAdmissao).format("DD/MM/YYYY") : ''
                        infoLinha.dtDemissao = item.dtDemissao ? moment(item.dtDemissao).format("DD/MM/YYYY") : ''
                        infoLinha.tempoCasa = item.tempoCasa
                    }

                    if (_Resersao) {
                        infoLinha.sentenca = item.sentenca
                        infoLinha.dtSentenca = item.dtSentenca ? moment(item.dtSentenca).format("DD/MM/YYYY") : ''
                        infoLinha.nomeJuiz = item.nomeJuiz
                        infoLinha.houveReformaAcordaoRO = item.houveReformaAcordaoRO
                        infoLinha.pedidosIncluidosAcordao = item.pedidosIncluidosAcordao
                        infoLinha.pedidosExcluidosAcordao = item.pedidosExcluidosAcordao
                        infoLinha.dtAcordao = item.dtAcordao ? moment(item.dtAcordao).format("DD/MM/YYYY") : ''
                        infoLinha.acordaoReformouImprocedencia = item.acordaoReformouImprocedencia
                        infoLinha.rrAdmitido = item.rrAdmitido
                        infoLinha.seFoiAdmitidoQualMateria = item.seFoiAdmitidoQualMateria
                        infoLinha.houveReformaAcordaoRR = item.houveReformaAcordaoRR
                        infoLinha.dtAcordao2 = item.dtAcordao2 ? moment(item.dtAcordao2).format("DD/MM/YYYY") : ''
                    }

                    if (_acordos) {
                        infoLinha.cpf_reclamante = item.cpf_reclamante
                        infoLinha.projeto = item.projeto
                        infoLinha.status_pact = item.status_pact
                        infoLinha.cnj_execucao_provisoria = item.cnj_execucao_provisoria
                        infoLinha.momento_execucao_provisoria = item.momento_execucao_provisoria
                        infoLinha.data_cadastro_processo_base = item.data_cadastro_processo_base ? moment(item.data_cadastro_processo_base).format("DD/MM/YYYY") : ''
                        infoLinha.status_pre = item.status_pre
                        infoLinha.data_provisao = item.data_provisao ? moment(item.data_provisao).format("DD/MM/YYYY") : ''
                        infoLinha.provisao = item.provisao ? currencyFormatter.format(item.provisao.replace(',', '.')) : ''
                        infoLinha.data_atualizacao_risco = item.data_atualizacao_risco ? moment(item.data_atualizacao_risco).format("DD/MM/YYYY") : ''
                        infoLinha.risco_bruto = item.risco_bruto ? currencyFormatter.format(item.risco_bruto.replace(',', '.')) : ''
                        infoLinha.risco_liquido = item.risco_liquido ? currencyFormatter.format(item.risco_liquido.replace(',', '.')) : ''
                        infoLinha.risco_bruto_pact_net_atualizado = item.risco_bruto_pact_net_atualizado ? currencyFormatter.format(item.risco_bruto_pact_net_atualizado.replace(',', '.')) : ''
                        infoLinha.risco_liquido_pact_net_atualizado = item.risco_liquido_pact_net_atualizado ? currencyFormatter.format(item.risco_liquido_pact_net_atualizado.replace(',', '.')) : ''
                        infoLinha.depositos_recursais = item.depositos_recursais ? currencyFormatter.format(item.depositos_recursais.replace(',', '.')) : ''
                        infoLinha.faixa_risco = item.faixa_risco
                        infoLinha.gatilho_bruto = item.gatilho_bruto ? currencyFormatter.format(item.gatilho_bruto.replace(',', '.')) : ''
                        infoLinha.status_negociacao = item.status_negociacao
                        infoLinha.termometro = item.termometro
                        infoLinha.data_primeiro_contato = item.data_primeiro_contato ? moment(item.data_primeiro_contato).format("DD/MM/YYYY") : ''
                        infoLinha.data_ultimo_contato = item.data_ultimo_contato ? moment(item.data_ultimo_contato).format("DD/MM/YYYY") : ''
                        infoLinha.proposta = item.proposta ? currencyFormatter.format(item.proposta.replace(',', '.')) : ''
                        infoLinha.ulitma_pretensao = item.ulitma_pretensao ? currencyFormatter.format(item.ulitma_pretensao.replace(',', '.')) : ''
                        infoLinha.data_acordo = item.data_acordo ? moment(item.data_acordo).format("DD/MM/YYYY") : ''
                        infoLinha.data_protocolo = item.data_protocolo ? moment(item.data_protocolo).format("DD/MM/YYYY") : ''
                        infoLinha.data_homologacao = item.data_homologacao ? moment(item.data_homologacao).format("DD/MM/YYYY") : ''
                        infoLinha.acordo_bruto = item.acordo_bruto ? currencyFormatter.format(item.acordo_bruto.replace(',', '.')) : ''
                        infoLinha.acordo_liquido = item.acordo_liquido ? currencyFormatter.format(item.acordo_liquido.replace(',', '.')) : ''
                        infoLinha.status_minutas = item.status_minutas
                        infoLinha.inss_reclamada_pago = item.inss_reclamada_pago ? currencyFormatter.format(item.inss_reclamada_pago.replace(',', '.')) : ''
                        infoLinha.inss_reclamante_pago = item.inss_reclamante_pago ? currencyFormatter.format(item.inss_reclamante_pago.replace(',', '.')) : ''
                        infoLinha.ir = item.ir ? currencyFormatter.format(item.ir.replace(',', '.')) : ''
                        infoLinha.honorarios_periciais = item.honorarios_periciais ? currencyFormatter.format(item.honorarios_periciais.replace(',', '.')) : ''
                        infoLinha.economia = item.economia ? currencyFormatter.format(item.economia.replace(',', '.')) : ''
                        infoLinha.desagio = item.desagio ? currencyFormatter.format(item.desagio.replace(',', '.')) : ''
                        infoLinha.honorario_pact = item.honorario_pact ? currencyFormatter.format(item.honorario_pact.replace(',', '.')) : ''
                        infoLinha.economia_net = item.economia_net ? currencyFormatter.format(item.economia_net.replace(',', '.')) : ''
                        infoLinha.desagio_net = item.desagio_net ? currencyFormatter.format(item.desagio_net.replace(',', '.')) : ''
                        infoLinha.negociar = item.negociar
                        infoLinha.dataStatusPre = item.dataStatusPre
                        infoLinha.observacao = item.observacao
                        infoLinha.descricaoSolicitacaoCopia = item.descricaoSolicitacaoCopia
                        infoLinha.descricaoSolicitacaoCalculo = item.descricaoSolicitacaoCalculo
                        infoLinha.descricaoUltimaAcaoNegociar = item.descricaoUltimaAcaoNegociar
                        infoLinha.statusPreSubTipo = item.statusPreSubTipo
                        infoLinha.fluxoSaneamento = item.fluxoSaneamento
                        infoLinha.restricaoTipo = item.restricaoTipo

                    }

                    if (_Financeira) {
                        infoLinha.juros = item.juros ? currencyFormatter.format(item.juros.replace(',', '.')) : ''
                        infoLinha.hPericiais = item.hPericiais ? currencyFormatter.format(item.hPericiais.replace(',', '.')) : ''
                        infoLinha.honorariosAdvocaticios = item.honorariosAdvocaticios ? currencyFormatter.format(item.honorariosAdvocaticios.replace(',', '.')) : ''
                        infoLinha.custasProcessuais = item.custasProcessuais ? currencyFormatter.format(item.custasProcessuais.replace(',', '.')) : ''
                        infoLinha.estabilidade = item.estabilidade ? currencyFormatter.format(item.estabilidade.replace(',', '.')) : ''
                        infoLinha.bonus = item.bonus ? currencyFormatter.format(item.bonus.replace(',', '.')) : ''
                        infoLinha.equiparacaoSalarial = item.equiparacaoSalarial ? currencyFormatter.format(item.equiparacaoSalarial.replace(',', '.')) : ''
                        infoLinha.acumuloDeFuncao = item.acumuloDeFuncao ? currencyFormatter.format(item.acumuloDeFuncao.replace(',', '.')) : ''
                        infoLinha.dMoral = item.dMoral ? currencyFormatter.format(item.dMoral.replace(',', '.')) : ''
                        infoLinha.dMaterial = item.dMaterial ? currencyFormatter.format(item.dMaterial.replace(',', '.')) : ''
                        infoLinha.pensao = item.pensao ? currencyFormatter.format(item.pensao.replace(',', '.')) : ''
                        infoLinha.he = item.he ? currencyFormatter.format(item.he.replace(',', '.')) : ''
                        infoLinha.horasInItinere = item.horasInItinere ? currencyFormatter.format(item.horasInItinere.replace(',', '.')) : ''
                        infoLinha.sebreaviso = item.sebreaviso ? currencyFormatter.format(item.sebreaviso.replace(',', '.')) : ''
                        infoLinha.intrajornada = item.intrajornada ? currencyFormatter.format(item.intrajornada.replace(',', '.')) : ''
                        infoLinha.diferencasSalariais = item.diferencasSalariais ? currencyFormatter.format(item.diferencasSalariais.replace(',', '.')) : ''
                        infoLinha.adTransferencia = item.adTransferencia ? currencyFormatter.format(item.adTransferencia.replace(',', '.')) : ''
                        infoLinha.insalubridade = item.insalubridade ? currencyFormatter.format(item.insalubridade.replace(',', '.')) : ''
                        infoLinha.periculosidade = item.periculosidade ? currencyFormatter.format(item.periculosidade.replace(',', '.')) : ''
                        infoLinha.ticketMedioporPedido = item.ticketMedioporPedido ? currencyFormatter.format(item.ticketMedioporPedido.replace(',', '.')) : ''
                    }

                    if (_Garantias) {
                        infoLinha.garantia = item.garantia
                        infoLinha.possuiGarantia = item.possuiGarantia
                        infoLinha.garantiaMaiorouMenorqueRisco = item.garantiaMaiorouMenorqueRisco
                        infoLinha.contrarioPrincipalNomeRazao2 = item.contrarioPrincipalNomeRazao2
                        infoLinha.tipoGarantiaRecursalDepositoApolice = item.tipoGarantiaRecursalDepositoApolice
                        infoLinha.valorLiberado = item.valorLiberado ? currencyFormatter.format(item.valorLiberado.replace(',', '.')) : ''
                    }

                    if (_Audiencias) {
                        infoLinha.revelia = item.revelia
                        infoLinha.reclamanteFoiOuvidaemAud = item.reclamanteFoiOuvidaemAud
                        infoLinha.testemunhaReclamada = item.testemunhaReclamada
                        infoLinha.testemunhaReclamante = item.testemunhaReclamante
                    }

                    if (_Calculos) {
                        infoLinha.dtTransitoemJulgado = item.dtTransitoemJulgado ? moment(item.dtTransitoemJulgado).format("DD/MM/YYYY") : ''
                        infoLinha.valorCalcApresentadoReclamada = item.valorCalcApresentadoReclamada ? currencyFormatter.format(item.valorCalcApresentadoReclamada.replace(',', '.')) : ''
                        infoLinha.valorCalcApresentadoReclamante = item.valorCalcApresentadoReclamante ? currencyFormatter.format(item.valorCalcApresentadoReclamante.replace(',', '.')) : ''
                        infoLinha.valorCalcApresentadoPerito = item.valorCalcApresentadoPerito ? currencyFormatter.format(item.valorCalcApresentadoPerito.replace(',', '.')) : ''
                        infoLinha.valorHomologado = item.valorHomologado ? currencyFormatter.format(item.valorHomologado.replace(',', '.')) : ''
                        infoLinha.dtCalcHomologado = item.dtCalcHomologado ? moment(item.dtCalcHomologado).format("DD/MM/YYYY") : ''
                        infoLinha.deQuemfoiCalcHomologado = item.deQuemfoiCalcHomologado
                        infoLinha.houveEmbargosExecucao = item.houveEmbargosExecucao
                        infoLinha.materiaEe = item.materiaEe
                        infoLinha.formaDeGarantia = item.formaDeGarantia
                    }

                    if (_Pericias) {
                        infoLinha.houvePericia = item.houvePericia
                        infoLinha.nomePeritoPericulosidadeInsalubridade = item.nomePeritoPericulosidadeInsalubridade
                        infoLinha.nomePeritoAcidenteDoenca = item.nomePeritoAcidenteDoenca
                        infoLinha.periciaDeQue = item.periciaDeQue
                        infoLinha.resultadoInsalubridade = item.resultadoInsalubridade
                        infoLinha.fundamentoInsalubridade = item.fundamentoInsalubridade
                        infoLinha.resultadoPericulosidade = item.resultadoPericulosidade
                        infoLinha.agentePericulosidade = item.agentePericulosidade
                        infoLinha.fundamentoPericulosidade = item.fundamentoPericulosidade
                        infoLinha.agenteInsalubre = item.agenteInsalubre
                        infoLinha.resultadoDoenca = item.resultadoDoenca
                        infoLinha.resultadoAcidente = item.resultadoAcidente
                        infoLinha.dtLaudo = item.dtLaudo ? moment(item.dtLaudo).format("DD/MM/YYYY") : ''
                        infoLinha.compliadofunHe = item.compliadofunHe
                        infoLinha.agenteInsalubre2 = item.agenteInsalubre2
                        infoLinha.grauDaInsalubridade = item.grauDaInsalubridade
                        infoLinha.compiladofunPericulosidade = item.compiladofunPericulosidade
                        infoLinha.compiladofunAcidDoenca = item.compiladofunAcidDoenca
                        infoLinha.qualDoenca = item.qualDoenca
                        infoLinha.incapacidade = item.incapacidade
                        infoLinha.reducaoFuncional = item.reducaoFuncional
                        infoLinha.compiladoFundDanoMoral = item.compiladoFundDanoMoral
                    }

                    if (_Customizado) {
                        infoLinha.tipo1 = item.tipo1
                        infoLinha.tipo2 = item.tipo2
                        infoLinha.tipo3 = item.tipo3
                        infoLinha.tipo4 = item.tipo4
                        infoLinha.tipo5 = item.tipo5
                        infoLinha.tipo6 = item.tipo6
                        infoLinha.tipo7 = item.tipo7
                        infoLinha.tipo8 = item.tipo8
                        infoLinha.tipo9 = item.tipo9
                        infoLinha.tipo10 = item.tipo10
                    }

                    productsFiltered.push(infoLinha)
                }
            });

            import('xlsx').then(xlsx => {
                const headers = {
                    NCNJ: 'Número Proc. De base',
                    contrarioPrincipalNomeRazao: 'Contrário principal / Nome/razão social',
                    uf: 'UF',
                    cidade: 'Cidade',
                    advogadoReverso_oab: 'Advogado Adverso / Oab',
                    vara: 'Vara',
                    nVara: 'n.º da Vara',
                    dtDistribuicao: 'Data de Distribuição',
                    ecBase: 'Escritório de Base (Dado Cliente)',
                    riscoDCliente: 'Risco (Dado Cliente)',
                    faixaRisco: 'Faixas de Risco',
                    valorCausa: 'Valor da Causa',
                    tipoProcesso: 'Tipo de Processo',
                    tipoAcao: 'Tipo Ação',
                    primeiraReclamada: 'Primeira Reclamada',
                    demaisReclamadas: 'Demais Reclamadas (na ordem)',
                    status: 'Status ',
                    fase: 'Fase',
                    momento: 'Momento',
                    dtMomento: 'Data do Momento',
                    dtAudiencia: 'Data da Audiência',
                    dtEncerramento: 'Data do Encerramento',
                    motivoEncerramento: 'Motivo do Encerramento',
                    ticketMedio: '$ Ticket Médio (encerramento e ativo)',
                    valorAcordoCondenacao: 'Valor do Acordo ou Condenação',
                    formaPagamentoAcordo: 'Forma de Pagamento do Acordo',
                    quemPagouAcordo: 'Quem pagou o Acordo?',
                    momentoEmQueAcordoRealizado: 'Momento em que o acordo foi realizado?',
                    pedidosDaInicial: 'Pedidos da Inicial',
                    funcaoCompilada: 'Função Compilada',
                    proprioTerceiro: 'Próprio ou Terceiro?',
                    ultimoSalario: 'Último Salário',
                    dtAdmissao: 'Data de Admissão',
                    dtDemissao: 'Data de Demissão',
                    tempoCasa: 'Tempo de Casa (Anos)',
                    sentenca: 'Sentença (Pedidos Deferidos)',
                    dtSentenca: 'Data da Sentença',
                    nomeJuiz: 'Nome Juiz',
                    houveReformaAcordaoRO: 'Houve reforma no Acórdão de RO? ',
                    pedidosIncluidosAcordao: 'Pedidos Incluidos no Acórdão',
                    pedidosExcluidosAcordao: 'Pedidos Excluídos do Acórdão',
                    dtAcordao: 'Data do Acórdão ',
                    acordaoReformouImprocedencia: 'Acórdão reformou para improcedência?',
                    rrAdmitido: 'RR Admitido?',
                    seFoiAdmitidoQualMateria: 'Se foi admitido, qual a matéria?',
                    houveReformaAcordaoRR: 'Houve reforma no acórdão RR? Se sim, qual',
                    dtAcordao2: 'Data do Acórdão ',
                    juros: ' $ Juros',
                    hPericiais: '$ H. Periciais',
                    honorariosAdvocaticios: '$ Honorários advocatícios',
                    custasProcessuais: '$ Custas Processuais',
                    estabilidade: ' $ Estabilidade',
                    bonus: ' $ Bonus',
                    equiparacaoSalarial: ' $ Equiparação Salarial',
                    acumuloDeFuncao: ' $ Acumulo de Função',
                    dMoral: ' $ D. Moral',
                    dMaterial: ' $ D. Material',
                    pensao: ' $ Pensão',
                    he: ' $ HE',
                    horasInItinere: ' $ Horas in itinere',
                    sebreaviso: ' $ Sobreaviso',
                    intrajornada: ' $ Intrajornada',
                    diferencasSalariais: ' $ Diferenças salariais',
                    adTransferencia: ' $ Ad. Transferência',
                    insalubridade: ' $ Insalubridade',
                    periculosidade: ' $ Periculosidade',
                    ticketMedioporPedido: '$ Ticket Médio por Pedido (encerramento e ativo)',
                    garantia: 'Garantia',
                    possuiGarantia: 'Possui Guarantia',
                    garantiaMaiorouMenorqueRisco: 'Garantia maior ou menor que o risco?',
                    contrarioPrincipalNomeRazao2: 'Contrário principal / Nome/razão social',
                    tipoGarantiaRecursalDepositoApolice: 'Tipo de Garantia (Recursal/Depósito/Apólice)',
                    valorLiberado: 'Valor Liberado',
                    revelia: 'Revelia?',
                    reclamanteFoiOuvidaemAud: 'Reclamante foi ouvido em aud?',
                    testemunhaReclamada: 'Testemunha Reclamada?',
                    testemunhaReclamante: 'Testemunha Reclamante? ',
                    dtTransitoemJulgado: 'Data do Transito em Julgado',
                    valorCalcApresentadoReclamada: 'Valor Cálc. Apresentado pela Reclamada',
                    valorCalcApresentadoReclamante: 'Valor Cálc. Apresentado pelo Reclamante',
                    valorCalcApresentadoPerito: 'Valor Cálc. Apresentado pelo Perito',
                    valorHomologado: 'Valor Homologado',
                    dtCalcHomologado: 'Data Cálc. Homologado',
                    deQuemfoiCalcHomologado: 'De quem foi o cálc. Homologado',
                    houveEmbargosExecucao: 'Houve Embargos à Execução? (Sim ou não)',
                    materiaEe: 'Matéria dos EE',
                    formaDeGarantia: 'Forma de garantia (Dinheiro, seguro, etc)',
                    houvePericia: 'Houve perícia?',
                    nomePeritoPericulosidadeInsalubridade: 'Nome Perito Periculosidade/Insalubridade',
                    nomePeritoAcidenteDoenca: 'Nome Perito Acidente/Doença',
                    periciaDeQue: 'Pericia de que',
                    resultadoInsalubridade: 'Resultado Insalubridade ',
                    fundamentoInsalubridade: 'Fundamento da Insalubridade',
                    resultadoPericulosidade: 'Resultado Periculosidade',
                    agentePericulosidade: '# Agente Periculosidade',
                    fundamentoPericulosidade: 'Fundamento da Periculosidade',
                    agenteInsalubre: '# Agente Insalubre',
                    resultadoDoenca: 'Resultado Doença',
                    resultadoAcidente: 'Resultado Acidente',
                    dtLaudo: 'Data do Laudo',
                    compliadofunHe: 'Compilado Fun. HE',
                    agenteInsalubre2: 'Agente Insalubre',
                    grauDaInsalubridade: 'Grau da Insalubridade',
                    compiladofunPericulosidade: 'Compilado Fun. Periculosidade',
                    compiladofunAcidDoenca: 'Compilado Fun. Acid/Doença',
                    qualDoenca: 'Qual a Doença?',
                    incapacidade: 'Incapacidade?',
                    reducaoFuncional: 'Redução Funcional (%)',
                    compiladoFundDanoMoral: 'Compilado Fund. Dano Moral',
                    tipo1: (tipo1) ? tipo1 : 'tipo 1',
                    tipo2: (tipo2) ? tipo2 : 'tipo 2',
                    tipo3: (tipo3) ? tipo3 : 'tipo 3',
                    tipo4: (tipo4) ? tipo4 : 'tipo 4',
                    tipo5: (tipo5) ? tipo5 : 'tipo 5',
                    tipo6: (tipo6) ? tipo6 : 'tipo 6',
                    tipo7: (tipo7) ? tipo7 : 'tipo 7',
                    tipo8: (tipo8) ? tipo8 : 'tipo 8',
                    tipo9: (tipo9) ? tipo9 : 'tipo 9',
                    tipo10: (tipo10) ? tipo10 : 'tipo 10',
                    cpf_reclamante: 'CPF Reclamante',
                    projeto: 'Projeto',
                    status_pact: 'Status Pact',
                    cnj_execucao_provisoria: 'CNJ Execução Provisória',
                    momento_execucao_provisoria: 'Momento Execução Provisória',
                    data_cadastro_processo_base: 'Data do Cadastro do Processo na Base',
                    status_pre: 'Status pre',
                    data_provisao: 'Data Provisão',
                    provisao: 'Provisão',
                    data_atualizacao_risco: 'Data de Atualização do Risco',
                    risco_bruto: 'Risco Bruto',
                    risco_liquido: 'Risco líquido',
                    risco_bruto_pact_net_atualizado: 'Risco Bruto PACT NET',
                    risco_liquido_pact_net_atualizado: 'Risco Líquido PACT NET',
                    depositos_recursais: 'Depositos Recursais',
                    faixa_risco: 'Faixa de Risco',
                    gatilho_bruto: 'Gatilho Bruto',
                    status_negociacao: 'Status da Negociação',
                    termometro: 'Termometro',
                    data_primeiro_contato: 'Data Primeiro Contato',
                    data_ultimo_contato: 'Data Último Contato',
                    proposta: 'Proposta',
                    ulitma_pretensao: 'Última Pretensão',
                    data_acordo: 'Data do Acordo',
                    data_protocolo: 'Data do Protocolo',
                    data_homologacao: 'Data da Homologação',
                    acordo_bruto: 'Acordo Bruto',
                    acordo_liquido: 'Acordo Líquido',
                    status_minutas: 'Status das Minutas',
                    inss_reclamada_pago: 'INSS Reclamada (PAGO NO ACORDO)',
                    inss_reclamante_pago: 'INSS Reclamante (PAGO NO ACORDO)',
                    ir: 'IR',
                    honorarios_periciais: 'Homorários Periciais',
                    economia: 'Economia',
                    desagio: 'Deságio',
                    honorario_pact: 'Honorário PACT',
                    economia_net: 'Economia NET',
                    desagio_net: 'Desagio NET',
                    negociar: 'Negociador',
                    tribunal: 'Tribunal',
                    dataStatusPre: 'Data Status Pre',
                    Observacao: 'Observação',
                    descricaoSolicitacaoCopia: 'Descrição Solicitação Copia',
                    descricaoSolicitacaoCalculo: 'Descrição Solicitação Calculo',
                    descricaoUltimaAcaoNegociar: 'Descrição Última Ação Negociada',
                    statusPreSubTipo: 'Status Pre Sub Tipo',
                    fluxoSaneamento: 'Fluxo Saneamento',
                    restricaoTipo: 'Restrição de Tipo',
                };
                productsFiltered.unshift(headers); // if custom header, then make sure first row of data is custom header 
                const worksheet = XLSX.utils.json_to_sheet(productsFiltered, { skipHeader: true });
                //  worksheet = xlsx.utils.json_to_sheet(productsFiltered);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'processos');
            });
        }
        else {
            toast.error('Selecione ao menos um item para exportar');
        }
    };

    const saveFiltro = () => {
        setNewFiltersDialog(!newFilterDialog)
    };

    const showCampos = () => {
        setNewCamposDialog(!newCamposDialog)
    };

    const sendFilter = () => {
        if (nomeFiltro) {
            saveFilter(queryOptions, user.uid, nomeFiltro, user);
            setNewFiltersDialog(!newFilterDialog)
        }
        else {
            toast.error('Preencha todos os campos corretamente')
        }
    };

    const salvarAtividadeProcessos = () => {

        let now = moment();

        const atividadeFilter = colunaAtividades.filter(item => item.Name == atividade)
        let items = []

        if (!atividade) {
            toast.error("Necessário informar uma atividade")
        }

        if (selectedCustomers.length > 0 && atividade) {
            selectedCustomers.map(itemNCNJ => {
                items.push(
                    { id: uuidv4(), content: atividadeDescricao, atividade: atividade, prazo: now, responsavel: user.nome, NCNJ: itemNCNJ }
                )
            })

            const payload = {
                idAtividade: atividadeFilter[0]._id,
                items: items
            }

            registrarAtividade(payload)
            setIniciarAtividadeDialog(false)
        }


    }

    const createNewImport = () => {
        setNewImport(!newImport);
        setNewProductsDialog(!newProductsDialog)
    };

    async function handleFile(e) {
        const jsonData = await readExcelFile(e, ref)
        setFileData(jsonData);
    };

    function handledFiltro(e) {
        setSelFiltro(e.target.value);

        if (e.target.value === 'Nenhum') {
            setUseFiltro(false);
        }
        else {
            setUseFiltro(true);
            let _filterModel = e.target.value[0].filterModel;
            let _items = {
                items: [],
                linkOperator: GridLinkOperator.And
            };

            _filterModel.items.forEach(item => {
                let itemModel = { id: item.id, columnField: item.columnField, operatorValue: item.operatorValue, value: item.value }
                _items.items.push(itemModel)
            })
            setFilterModel(_items);
        }
    }

    async function handledEmpresa(e) {

        setExibeGrid(false);
        setEmpresa(e.target.value);

        let _labels = '';

        if (e.target.value === 'Todas') {
            _labels = {
                "tipo1": "tipo 1",
                "tipo2": "tipo 2",
                "tipo3": "tipo 3",
                "tipo4": "tipo 4",
                "tipo5": "tipo 5",
                "tipo6": "tipo 6",
                "tipo7": "tipo 7",
                "tipo8": "tipo 8",
                "tipo9": "tipo 9",
                "tipo10": "tipo 10"
            }
        }
        else {
            _labels = await getLabels(e.target.value);
        }


        let _empresas = [...empresas];

        let index = 0;
        _empresas.find((item, idx) => {
            if (item.name === e.target.value) {
                index = idx;
            }
        });

        let linha = _empresas[index]
        let newProducts = null;
        let _gridHistorica = [...gridHistorico];

        newProducts = _gridHistorica.filter(
            item => item.baseCliente === e.target.value
        );

        let _basico = null;
        let _fase = null;
        let _encerrados = null;
        let _pedidos = null;
        let _funcao = null;
        let _resersao = null;
        let _financeira = null;
        let _garantias = null;
        let _audiencias = null;
        let _calculos = null;
        let _acordos = null;
        let _pericias = null;
        let _customizado = null;


        if (e.target.value === 'Todas') {
            _basico = true;
            _fase = true;
            _encerrados = true;
            _pedidos = true;
            _funcao = true;
            _resersao = true;
            _financeira = true;
            _garantias = true;
            _audiencias = true;
            _calculos = true;
            _acordos = true;
            _pericias = true;
            _customizado = true;

            linha = {
                "id": "62f929028f45bc6f823be89c",
                "name": "Solar",
                "url": "NA",
                "basico": "Sim",
                "calculos": "Sim",
                "encerrados": "Sim",
                "fase": "Sim",
                "financeira": "Sim",
                "funcao": "Sim",
                "garantias": "Sim",
                "pedidos": "Sim",
                "pericias": "Sim",
                "resersao": "Sim",
                "acordos": "Sim",
                "customizado": "Sim",
                "audiencias": "Sim",
                "tipo1": "Sim",
                "tipo2": "Sim",
                "tipo3": "Sim",
                "tipo4": "Sim",
                "tipo5": "Sim",
                "tipo6": "Sim",
                "tipo7": "Sim",
                "tipo8": "Sim",
                "tipo9": "Sim",
                "tipo10": "Sim"
            }
            empresaCollumns(linha, _labels);
            setProducts2(_gridHistorica);
        }
        else {
            _basico = (linha.basico === "Sim") ? true : false;
            _fase = (linha.fase === "Sim") ? true : false;
            _encerrados = (linha.encerrados === "Sim") ? true : false;
            _pedidos = (linha.pedidos === "Sim") ? true : false;
            _funcao = (linha.funcao === "Sim") ? true : false;
            _resersao = (linha.resersao === "Sim") ? true : false;
            _financeira = (linha.financeira === "Sim") ? true : false;
            _garantias = (linha.garantias === "Sim") ? true : false;
            _audiencias = (linha.audiencias === "Sim") ? true : false;
            _calculos = (linha.calculos === "Sim") ? true : false;
            _pericias = (linha.pericias === "Sim") ? true : false;
            _acordos = (linha.acordos === "Sim") ? true : false;
            _customizado = (linha.customizado === "Sim") ? true : false;
            setProducts2(newProducts);
            empresaCollumns(linha, _labels);
        }

        setBasico(_basico);
        setFase(_fase);
        setEncerrados(_encerrados);
        setPedidos(_pedidos);
        setFuncao(_funcao);
        setResersao(_resersao);
        setFinanceira(_financeira);
        setGarantias(_garantias);
        setAudiencias(_audiencias);
        setAcordos(_acordos);
        setCalculos(_calculos);
        setPericias(_pericias);
        setCustomizado(_customizado);

        setnBasico(_basico);
        setnFase(_fase);
        setnEncerrados(_encerrados);
        setnPedidos(_pedidos);
        setnFuncao(_funcao);
        setnResersao(_resersao);
        setnFinanceira(_financeira);
        setnGarantias(_garantias);
        setnAudiencias(_audiencias);
        setnCalculos(_calculos);
        setnAcordos(_acordos);
        setnPericias(_pericias);
        setnCustomizado(_customizado);


        setColumnVisibilityModel({
            // Hide columns status and traderName, the other columns will remain visible
            id: false,
            empresa: _basico,
            NCNJ: _basico,
            contrarioPrincipalNomeRazao: _basico,
            uf: _basico,
            cidade: _basico,
            advogadoReverso_oab: _basico,
            vara: _basico,
            nVara: _basico,
            dtDistribuicao: _basico,
            ecBase: _basico,
            riscoDCliente: _basico,
            faixaRisco: _basico,
            valorCausa: _basico,
            tipoProcesso: _basico,
            tipoAcao: _basico,
            primeiraReclamada: _basico,
            demaisReclamadas: _basico,
            status: _fase,
            fase: _fase,
            momento: _fase,
            dtMomento: _fase,
            dtAudiencia: _fase,
            dtEncerramento: _fase,
            motivoEncerramento: _encerrados,
            ticketMedio: _encerrados,
            valorAcordoCondenacao: _encerrados,
            formaPagamentoAcordo: _encerrados,
            quemPagouAcordo: _encerrados,
            momentoEmQueAcordoRealizado: _encerrados,
            pedidosDaInicial: _pedidos,
            funcaoCompilada: _funcao,
            proprioTerceiro: _funcao,
            ultimoSalario: _funcao,
            dtAdmissao: _funcao,
            dtDemissao: _funcao,
            tempoCasa: _funcao,
            sentenca: _resersao,
            dtSentenca: _resersao,
            nomeJuiz: _resersao,
            houveReformaAcordaoRO: _resersao,
            pedidosIncluidosAcordao: _resersao,
            pedidosExcluidosAcordao: _resersao,
            dtAcordao: _resersao,
            acordaoReformouImprocedencia: _resersao,
            rrAdmitido: _resersao,
            seFoiAdmitidoQualMateria: _resersao,
            houveReformaAcordaoRR: _resersao,
            dtAcordao2: _resersao,
            juros: _financeira,
            hPericiais: _financeira,
            honorariosAdvocaticios: _financeira,
            custasProcessuais: _financeira,
            estabilidade: _financeira,
            bonus: _financeira,
            equiparacaoSalarial: _financeira,
            acumuloDeFuncao: _financeira,
            dMoral: _financeira,
            dMaterial: _financeira,
            pensao: _financeira,
            he: _financeira,
            horasInItinere: _financeira,
            sebreaviso: _financeira,
            intrajornada: _financeira,
            diferencasSalariais: _financeira,
            adTransferencia: _financeira,
            insalubridade: _financeira,
            periculosidade: _financeira,
            ticketMedioporPedido: _financeira,
            garantia: _garantias,
            possuiGarantia: _garantias,
            garantiaMaiorouMenorqueRisco: _garantias,
            contrarioPrincipalNomeRazao2: _garantias,
            tipoGarantiaRecursalDepositoApolice: _garantias,
            valorLiberado: _garantias,
            revelia: _audiencias,
            reclamanteFoiOuvidaemAud: _audiencias,
            testemunhaReclamada: _audiencias,
            testemunhaReclamante: _audiencias,
            dtTransitoemJulgado: _calculos,
            valorCalcApresentadoReclamada: _calculos,
            valorCalcApresentadoReclamante: _calculos,
            valorCalcApresentadoPerito: _calculos,
            valorHomologado: _calculos,
            dtCalcHomologado: _calculos,
            deQuemfoiCalcHomologado: _calculos,
            houveEmbargosExecucao: _calculos,
            materiaEe: _calculos,
            formaDeGarantia: _calculos,
            houvePericia: _pericias,
            nomePeritoPericulosidadeInsalubridade: _pericias,
            nomePeritoAcidenteDoenca: _pericias,
            periciaDeQue: _pericias,
            resultadoInsalubridade: _pericias,
            fundamentoInsalubridade: _pericias,
            resultadoPericulosidade: _pericias,
            agentePericulosidade: _pericias,
            fundamentoPericulosidade: _pericias,
            agenteInsalubre: _pericias,
            resultadoDoenca: _pericias,
            resultadoAcidente: _pericias,
            dtLaudo: _pericias,
            compliadofunHe: _pericias,
            agenteInsalubre2: _pericias,
            grauDaInsalubridade: _pericias,
            compiladofunPericulosidade: _pericias,
            compiladofunAcidDoenca: _pericias,
            qualDoenca: _pericias,
            incapacidade: _pericias,
            reducaoFuncional: _pericias,
            compiladoFundDanoMoral: _pericias,
            negociar: _acordos,//ok
            tribunal: _basico,//ok
            dataStatusPre: _acordos,
            Observacao: _acordos,//ok
            descricaoSolicitacaoCopia: _acordos,//ok
            descricaoSolicitacaoCalculo: _acordos,//ok
            descricaoUltimaAcaoNegociar: _acordos,//ok
            statusPreSubTipo: _acordos,
            fluxoSaneamento: _acordos,
            restricaoTipo: _acordos
        })
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setNewProductsDialog(!newProductsDialog)
    };


    const hideDialogFilter = () => {
        setNewFiltersDialog(!newFilterDialog)

    };

    const hideDialogAtividade = () => {
        setIniciarAtividadeDialog(!iniciarAtividade)

    };


    const hideDialogCampos = () => {
        setNewCamposDialog(!newCamposDialog)
    };

    const alterColumnVisibility = (model) => {
        setColumnVisibilityModel(model);
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const iniciarAtividade = () => {

        if (selectedCustomers.length === 0) {
            toast.error("É necessário selecionar um processo")
        }

        if (selectedCustomers.length > 0) {
            setAtividade('')
            setAtividadeDescricao('')
            setAtividadePrazo('')
            setIniciarAtividadeDialog(true)
        }

    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button label="Iniciar Atividade" icon="pi pi-caret-right" className="p-button-new" type="button" onClick={iniciarAtividade} /> */}
                <Button label="Salvar Filtro" icon="pi pi-filter-fill" className="p-button-new" type="button" onClick={saveFiltro} />
                <Button label="Campos" icon="pi pi-bars" className="p-button-info" type="button" onClick={showCampos} />
                <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success mr-2" type="button" data-pr-tooltip="XLS" onClick={exportExcel} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />
                <Button label={!newProductsDialog ? "Importar" : "Cancelar"} icon={!newProductsDialog ? "pi pi-download" : "pi pi-times-circle"} className={!newProductsDialog ? "p-button-warning" : "p-button-danger"} onClick={createNewImport} />
            </React.Fragment>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                {filterEmpresaAdm &&
                    <TextField
                        id="outlined-select-currency-empresa"
                        select
                        label="Base cliente"
                        value={empresa}
                        onChange={(e) => { handledEmpresa(e) }}
                        margin="normal"
                        variant="outlined"
                        size="small"
                    >
                        {user.tipoUsuario !== 'Cliente' &&
                            <MenuItem key={999} value={'Todas'}>
                                Todas
                            </MenuItem>
                        }
                        {filterEmpresaAdm?.map(option => (
                            <MenuItem key={option.id} value={option.name}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                }

                <TextField
                    id="outlined-select-currency-filtros"
                    select
                    label="Filtros favoritos"
                    value={selFiltro}
                    onChange={(e) => { handledFiltro(e) }}
                    margin="normal"
                    variant="outlined"
                    size="small"
                >
                    <MenuItem key={0} value={'Nenhum'}>
                        Nenhum
                    </MenuItem>
                    {filterUser?.map(option => (
                        <MenuItem key={option.id} value={option.filtro}>
                            {option.descricao}
                        </MenuItem>
                    ))}
                </TextField>
            </React.Fragment>

        )
    }

    function importarArquivo() {

        if (fileData && cliente) {
            sendProcess(fileData, user.nome, fileName, cliente);
        }
        else {
            toast.error("Necessário selecionar um arquivo")
        }
    }

    const handleSaveClick = (e) => {
        let _products2 = [...products2];
        let { id, value, field } = e;

        let index = 0;
        _products2.find((item, idx) => {
            if (item.NCNJ === id) {
                index = idx;
            }
        });

        let linha = _products2[index]
        linha = { ...linha, [field]: value };


        alterProcess(linha);
        _products2[index] = linha;
        setProducts2(_products2);
    };

    const handleChangeCampos = (campo) => {

        let _basico = '';
        let _calculos = '';
        let _encerrados = '';
        let _fase = '';
        let _financeira = '';
        let _funcao = '';
        let _garantias = '';
        let _pedidos = '';
        let _pericias = '';
        let _resersao = '';
        let _audiencias = '';
        let _acordos = '';
        let _customizado = '';

        if (campo.target.id === '1') {
            _basico = campo.target.checked;
            setnBasico(_basico);
        }
        if (campo.target.id === '2') {
            _calculos = campo.target.checked;
            setnCalculos(_calculos);
        }
        if (campo.target.id === '3') {
            _encerrados = campo.target.checked;
            setnEncerrados(_encerrados);
        }
        if (campo.target.id === '4') {
            _fase = campo.target.checked;
            setnFase(_fase);
        }
        if (campo.target.id === '5') {
            _financeira = campo.target.checked;
            setnFinanceira(_financeira);
        }
        if (campo.target.id === '6') {
            _funcao = campo.target.checked;
            setnFuncao(_funcao);
        }
        if (campo.target.id === '7') {
            _garantias = campo.target.checked;
            setnGarantias(_garantias);
        }
        if (campo.target.id === '8') {
            _pedidos = campo.target.checked;
            setnPedidos(_pedidos);
        }
        if (campo.target.id === '9') {
            _pericias = campo.target.checked;
            setnPericias(_pericias);
        }
        if (campo.target.id === '10') {
            _resersao = campo.target.checked;
            setnResersao(_resersao);
        }
        if (campo.target.id === '11') {
            _audiencias = campo.target.checked;
            setnAudiencias(_audiencias);
        }
        if (campo.target.id === '12') {
            _customizado = campo.target.checked;
            setnCustomizado(_customizado);
        }
        if (campo.target.id === '13') {
            _acordos = campo.target.checked;
            setnAcordos(_acordos);
        }
        setColumnVisibilityModel({
            // Hide columns status and traderName, the other columns will remain visible

            id: false,
            empresa: (campo.target.id === '1' ? _basico : nbasico),
            NCNJ: (campo.target.id === '1' ? _basico : nbasico),
            contrarioPrincipalNomeRazao: (campo.target.id === '1' ? _basico : nbasico),
            uf: (campo.target.id === '1' ? _basico : nbasico),
            cidade: (campo.target.id === '1' ? _basico : nbasico),
            advogadoReverso_oab: (campo.target.id === '1' ? _basico : nbasico),
            vara: (campo.target.id === '1' ? _basico : nbasico),
            nVara: (campo.target.id === '1' ? _basico : nbasico),
            dtDistribuicao: (campo.target.id === '1' ? _basico : nbasico),
            ecBase: (campo.target.id === '1' ? _basico : nbasico),
            riscoDCliente: (campo.target.id === '1' ? _basico : nbasico),
            faixaRisco: (campo.target.id === '1' ? _basico : nbasico),
            valorCausa: (campo.target.id === '1' ? _basico : nbasico),
            tipoProcesso: (campo.target.id === '1' ? _basico : nbasico),
            tipoAcao: (campo.target.id === '1' ? _basico : nbasico),
            primeiraReclamada: (campo.target.id === '1' ? _basico : nbasico),
            demaisReclamadas: (campo.target.id === '1' ? _basico : nbasico),
            status: (campo.target.id === '4' ? _fase : nfase),
            fase: (campo.target.id === '4' ? _fase : nfase),
            momento: (campo.target.id === '4' ? _fase : nfase),
            dtMomento: (campo.target.id === '4' ? _fase : nfase),
            dtAudiencia: (campo.target.id === '4' ? _fase : nfase),
            dtEncerramento: (campo.target.id === '4' ? _fase : nfase),
            motivoEncerramento: (campo.target.id === '3' ? _encerrados : nencerrados),
            ticketMedio: (campo.target.id === '3' ? _encerrados : nencerrados),
            valorAcordoCondenacao: (campo.target.id === '3' ? _encerrados : nencerrados),
            formaPagamentoAcordo: (campo.target.id === '3' ? _encerrados : nencerrados),
            quemPagouAcordo: (campo.target.id === '3' ? _encerrados : nencerrados),
            momentoEmQueAcordoRealizado: (campo.target.id === '3' ? _encerrados : nencerrados),
            pedidosDaInicial: (campo.target.id === '8' ? _pedidos : npedidos),
            funcaoCompilada: (campo.target.id === '6' ? _funcao : nfuncao),
            proprioTerceiro: (campo.target.id === '6' ? _funcao : nfuncao),
            ultimoSalario: (campo.target.id === '6' ? _funcao : nfuncao),
            dtAdmissao: (campo.target.id === '6' ? _funcao : nfuncao),
            dtDemissao: (campo.target.id === '6' ? _funcao : nfuncao),
            tempoCasa: (campo.target.id === '6' ? _funcao : nfuncao),
            sentenca: (campo.target.id === '10' ? _resersao : nresersao),
            dtSentenca: (campo.target.id === '10' ? _resersao : nresersao),
            nomeJuiz: (campo.target.id === '10' ? _resersao : nresersao),
            houveReformaAcordaoRO: (campo.target.id === '10' ? _resersao : nresersao),
            pedidosIncluidosAcordao: (campo.target.id === '10' ? _resersao : nresersao),
            pedidosExcluidosAcordao: (campo.target.id === '10' ? _resersao : nresersao),
            dtAcordao: (campo.target.id === '10' ? _resersao : nresersao),
            acordaoReformouImprocedencia: (campo.target.id === '10' ? _resersao : nresersao),
            rrAdmitido: (campo.target.id === '10' ? _resersao : nresersao),
            seFoiAdmitidoQualMateria: (campo.target.id === '10' ? _resersao : nresersao),
            houveReformaAcordaoRR: (campo.target.id === '10' ? _resersao : nresersao),
            dtAcordao2: (campo.target.id === '10' ? _resersao : nresersao),
            juros: (campo.target.id === '5' ? _financeira : nfinanceira),
            hPericiais: (campo.target.id === '5' ? _financeira : nfinanceira),
            honorariosAdvocaticios: (campo.target.id === '5' ? _financeira : nfinanceira),
            custasProcessuais: (campo.target.id === '5' ? _financeira : nfinanceira),
            estabilidade: (campo.target.id === '5' ? _financeira : nfinanceira),
            bonus: (campo.target.id === '5' ? _financeira : nfinanceira),
            equiparacaoSalarial: (campo.target.id === '5' ? _financeira : nfinanceira),
            acumuloDeFuncao: (campo.target.id === '5' ? _financeira : nfinanceira),
            dMoral: (campo.target.id === '5' ? _financeira : nfinanceira),
            dMaterial: (campo.target.id === '5' ? _financeira : nfinanceira),
            pensao: (campo.target.id === '5' ? _financeira : nfinanceira),
            he: (campo.target.id === '5' ? _financeira : nfinanceira),
            horasInItinere: (campo.target.id === '5' ? _financeira : nfinanceira),
            sebreaviso: (campo.target.id === '5' ? _financeira : nfinanceira),
            intrajornada: (campo.target.id === '5' ? _financeira : nfinanceira),
            diferencasSalariais: (campo.target.id === '5' ? _financeira : nfinanceira),
            adTransferencia: (campo.target.id === '5' ? _financeira : nfinanceira),
            insalubridade: (campo.target.id === '5' ? _financeira : nfinanceira),
            periculosidade: (campo.target.id === '5' ? _financeira : nfinanceira),
            ticketMedioporPedido: (campo.target.id === '5' ? _financeira : nfinanceira),
            garantia: (campo.target.id === '7' ? _garantias : ngarantias),
            possuiGarantia: (campo.target.id === '7' ? _garantias : ngarantias),
            garantiaMaiorouMenorqueRisco: (campo.target.id === '7' ? _garantias : ngarantias),
            contrarioPrincipalNomeRazao2: (campo.target.id === '7' ? _garantias : ngarantias),
            tipoGarantiaRecursalDepositoApolice: (campo.target.id === '7' ? _garantias : ngarantias),
            valorLiberado: (campo.target.id === '7' ? _garantias : ngarantias),
            revelia: (campo.target.id === '11' ? _audiencias : naudiencias),
            reclamanteFoiOuvidaemAud: (campo.target.id === '11' ? _audiencias : naudiencias),
            testemunhaReclamada: (campo.target.id === '11' ? _audiencias : naudiencias),
            testemunhaReclamante: (campo.target.id === '11' ? _audiencias : naudiencias),
            dtTransitoemJulgado: (campo.target.id === '2' ? _calculos : ncalculos),
            valorCalcApresentadoReclamada: (campo.target.id === '2' ? _calculos : ncalculos),
            valorCalcApresentadoReclamante: (campo.target.id === '2' ? _calculos : ncalculos),
            valorCalcApresentadoPerito: (campo.target.id === '2' ? _calculos : ncalculos),
            valorHomologado: (campo.target.id === '2' ? _calculos : ncalculos),
            dtCalcHomologado: (campo.target.id === '2' ? _calculos : ncalculos),
            deQuemfoiCalcHomologado: (campo.target.id === '2' ? _calculos : ncalculos),
            houveEmbargosExecucao: (campo.target.id === '2' ? _calculos : ncalculos),
            materiaEe: (campo.target.id === '2' ? _calculos : ncalculos),
            formaDeGarantia: (campo.target.id === '2' ? _calculos : ncalculos),
            houvePericia: (campo.target.id === '9' ? _pericias : npericias),
            nomePeritoPericulosidadeInsalubridade: (campo.target.id === '9' ? _pericias : npericias),
            nomePeritoAcidenteDoenca: (campo.target.id === '9' ? _pericias : npericias),
            periciaDeQue: (campo.target.id === '9' ? _pericias : npericias),
            resultadoInsalubridade: (campo.target.id === '9' ? _pericias : npericias),
            fundamentoInsalubridade: (campo.target.id === '9' ? _pericias : npericias),
            resultadoPericulosidade: (campo.target.id === '9' ? _pericias : npericias),
            agentePericulosidade: (campo.target.id === '9' ? _pericias : npericias),
            fundamentoPericulosidade: (campo.target.id === '9' ? _pericias : npericias),
            agenteInsalubre: (campo.target.id === '9' ? _pericias : npericias),
            resultadoDoenca: (campo.target.id === '9' ? _pericias : npericias),
            resultadoAcidente: (campo.target.id === '9' ? _pericias : npericias),
            dtLaudo: (campo.target.id === '9' ? _pericias : npericias),
            compliadofunHe: (campo.target.id === '9' ? _pericias : npericias),
            agenteInsalubre2: (campo.target.id === '9' ? _pericias : npericias),
            grauDaInsalubridade: (campo.target.id === '9' ? _pericias : npericias),
            compiladofunPericulosidade: (campo.target.id === '9' ? _pericias : npericias),
            compiladofunAcidDoenca: (campo.target.id === '9' ? _pericias : npericias),
            qualDoenca: (campo.target.id === '9' ? _pericias : npericias),
            incapacidade: (campo.target.id === '9' ? _pericias : npericias),
            reducaoFuncional: (campo.target.id === '9' ? _pericias : npericias),
            compiladoFundDanoMoral: (campo.target.id === '9' ? _pericias : npericias),
            tipo1: (campo.target.id === '12' ? _customizado : ncustomizado),
            tipo2: (campo.target.id === '12' ? _customizado : ncustomizado),
            tipo3: (campo.target.id === '12' ? _customizado : ncustomizado),
            tipo4: (campo.target.id === '12' ? _customizado : ncustomizado),
            tipo5: (campo.target.id === '12' ? _customizado : ncustomizado),
            tipo6: (campo.target.id === '12' ? _customizado : ncustomizado),
            tipo7: (campo.target.id === '12' ? _customizado : ncustomizado),
            tipo8: (campo.target.id === '12' ? _customizado : ncustomizado),
            tipo9: (campo.target.id === '12' ? _customizado : ncustomizado),
            tipo10: (campo.target.id === '12' ? _customizado : ncustomizado),
            cpf_reclamante: (campo.target.id === '13' ? _acordos : nacordos),
            projeto: (campo.target.id === '13' ? _acordos : nacordos),
            status_pact: (campo.target.id === '13' ? _acordos : nacordos),
            cnj_execucao_provisoria: (campo.target.id === '13' ? _acordos : nacordos),
            momento_execucao_provisoria: (campo.target.id === '13' ? _acordos : nacordos),
            data_cadastro_processo_base: (campo.target.id === '13' ? _acordos : nacordos),
            status_pre: (campo.target.id === '13' ? _acordos : nacordos),
            data_provisao: (campo.target.id === '13' ? _acordos : nacordos),
            provisao: (campo.target.id === '13' ? _acordos : nacordos),
            data_atualizacao_risco: (campo.target.id === '13' ? _acordos : nacordos),
            risco_bruto: (campo.target.id === '13' ? _acordos : nacordos),
            risco_liquido: (campo.target.id === '13' ? _acordos : nacordos),
            risco_bruto_pact_net_atualizado: (campo.target.id === '13' ? _acordos : nacordos),
            risco_liquido_pact_net_atualizado: (campo.target.id === '13' ? _acordos : nacordos),
            depositos_recursais: (campo.target.id === '13' ? _acordos : nacordos),
            faixa_risco: (campo.target.id === '13' ? _acordos : nacordos),
            gatilho_bruto: (campo.target.id === '13' ? _acordos : nacordos),
            status_negociacao: (campo.target.id === '13' ? _acordos : nacordos),
            termometro: (campo.target.id === '13' ? _acordos : nacordos),
            data_primeiro_contato: (campo.target.id === '13' ? _acordos : nacordos),
            data_ultimo_contato: (campo.target.id === '13' ? _acordos : nacordos),
            proposta: (campo.target.id === '13' ? _acordos : nacordos),
            ulitma_pretensao: (campo.target.id === '13' ? _acordos : nacordos),
            data_acordo: (campo.target.id === '13' ? _acordos : nacordos),
            data_protocolo: (campo.target.id === '13' ? _acordos : nacordos),
            data_homologacao: (campo.target.id === '13' ? _acordos : nacordos),
            acordo_bruto: (campo.target.id === '13' ? _acordos : nacordos),
            acordo_liquido: (campo.target.id === '13' ? _acordos : nacordos),
            status_minutas: (campo.target.id === '13' ? _acordos : nacordos),
            inss_reclamada_pago: (campo.target.id === '13' ? _acordos : nacordos),
            inss_reclamante_pago: (campo.target.id === '13' ? _acordos : nacordos),
            ir: (campo.target.id === '13' ? _acordos : nacordos),
            honorarios_periciais: (campo.target.id === '13' ? _acordos : nacordos),
            economia: (campo.target.id === '13' ? _acordos : nacordos),
            desagio: (campo.target.id === '13' ? _acordos : nacordos),
            honorario_pact: (campo.target.id === '13' ? _acordos : nacordos),
            economia_net: (campo.target.id === '13' ? _acordos : nacordos),
            desagio_net: (campo.target.id === '13' ? _acordos : nacordos),
            negociar: (campo.target.id === '13' ? _acordos : nacordos),
        })
    }

    const deleteProduct = () => {
        deleteProcess(selectedCustomers);
        setDeleteProductsDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    const onFilterChange = React.useCallback((filterModel) => {
        setQueryOptions({ filterModel: { ...filterModel } });
    }, []);

    return (
        <div className="datatable-editing-demo">

            <div className="card p-fluid">
                {exibeGrid &&
                    <>
                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        {useFiltro && !isEmpty && products2.length > 0 &&
                            <Box sx={{ height: 800, width: '100%' }}>

                                <DataGridPro id="data-Grid-Pro" className='data-Grid-Pro'
                                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                    columns={collumns}
                                    rows={products2}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel) =>
                                        alterColumnVisibility(newModel)
                                    }
                                    filterModel={filterModel}
                                    onFilterModelChange={onFilterChange}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[50, 100, 150, 200]}
                                    pageSize={pageSize}
                                    pagination
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectedCustomers(newSelectionModel)
                                    }}
                                    onCellEditCommit={handleSaveClick}
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                    }
                                />

                            </Box>
                        }
                        {!useFiltro && !isEmpty && products2.length > 0 &&
                            <Box sx={{ height: 800, width: '100%' }}>

                                <DataGridPro id="data-Grid-Pro" className='data-Grid-Pro'
                                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                    columns={collumns}
                                    rows={products2}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel) =>
                                        alterColumnVisibility(newModel)
                                    }
                                    onFilterModelChange={onFilterChange}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[50, 100, 150, 200]}
                                    pageSize={pageSize}
                                    pagination
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectedCustomers(newSelectionModel)
                                    }}
                                    onCellEditCommit={handleSaveClick}
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                    }
                                />
                            </Box>
                        }
                    </>
                }

                {newProductsDialog &&
                    <Dialog
                        visible={newProductsDialog}
                        style={{ width: "450px" }}
                        header="Importar Processos"
                        modal
                        className="card p-fluid"
                        onHide={hideDialog}
                    >
                        <div className="field">
                            <a className="arqModelo" href='https://pactfy.s3.sa-east-1.amazonaws.com/Layout_Pact_VF-Modelo.xlsx'>Baixar Arquivo Modelo</a>
                        </div>

                        <div className="field">
                            <input id="arquivo-empresa" type="file" className="upload-box" onChange={(e) => handleFile(e)} ref={ref} />
                        </div>

                        <div className="field">
                            {filterEmpresaAdm &&
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Base cliente"
                                    value={cliente}
                                    fullWidth
                                    onChange={(e) => { setCliente(e.target.value) }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {filterEmpresaAdm &&
                                        filterEmpresaAdm.map(option => (
                                            <MenuItem key={option.id} value={option.name}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            }
                        </div>

                        <div className="field button-right">
                            <Button
                                label={'Enviar'}
                                id={'button-right'}
                                className="p-button-success mr-2"
                                onClick={() => { importarArquivo() }}
                                style={{ width: '100px', margin: '1rem 0' }} disabled={load}
                            />
                        </div>

                        {load &&
                            <Box className="progress-bar" sx={{ width: '100%' }}>
                                <LinearProgressWithLabel value={progress} />
                            </Box>
                        }

                        {msg &&
                            (
                                <div className="msg-box">
                                    <h2>Arquivo importado com sucesso! </h2>
                                    <h3><p>FileName: <span>{fileName}</span></p></h3>
                                    <h3><p>Aguarde enquanto atualizamos a lista...</p></h3>
                                </div>
                            )
                        }

                    </Dialog>
                }


                {newFilterDialog &&
                    <Dialog
                        visible={newFilterDialog}
                        style={{ width: "450px" }}
                        header="Salvar Filtro"
                        modal
                        className="card p-fluid"
                        onHide={hideDialogFilter}
                    >

                        <div className="field">
                            <TextField
                                id="outlined-name"
                                label="Descrição"
                                value={nomeFiltro}
                                onChange={(e) => setNomeFiltro(e.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>

                        <div className="field button-right">
                            <Button
                                label={'Enviar'}
                                id={'button-right'}
                                className="p-button-success mr-2"
                                onClick={() => { sendFilter() }}
                                style={{ width: '100px', margin: '1rem 0' }} disabled={load}
                            />
                        </div>

                    </Dialog>
                }

                {/* Iniciar Atividade start */}
                {iniciarAtividadeDialog &&
                    <Dialog
                        visible={iniciarAtividadeDialog}
                        style={{ width: "450px" }}
                        header="Atividade"
                        modal
                        className="card p-fluid"
                        onHide={hideDialogAtividade}
                    >

                        <div className="field">
                            {colunaAtividades &&
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Atividade"
                                    value={atividade}
                                    fullWidth
                                    onChange={(e) => { setAtividade(e.target.value) }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {colunaAtividades &&
                                        colunaAtividades.map(option => (
                                            <MenuItem key={option._id} value={option.Name}>
                                                {option.Name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            }
                        </div>

                        {/* <div className="field">
                            <TextField
                                id="outlined-name"
                                label="Prazo"
                                // placeholder='Prazo'
                                value={atividadePrazo}
                                onChange={(e) => setAtividadePrazo(e.target.value)}
                                margin="normal"
                                variant="outlined"
                                type={'date'}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                            />
                        </div> */}

                        <div className="field">
                            <TextField
                                id="outlined-name"
                                label="Descrição"
                                value={atividadeDescricao}
                                onChange={(e) => setAtividadeDescricao(e.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>

                        <div className="field button-right">
                            <Button
                                label={'Enviar'}
                                id={'button-right'}
                                className="p-button-success mr-2"
                                onClick={() => { salvarAtividadeProcessos() }}
                                style={{ width: '100px', margin: '1rem 0' }} disabled={load}
                            />
                        </div>
                    </Dialog>
                }
                {/* Iniciar Atividade end */}

                {newCamposDialog &&
                    <Dialog
                        visible={newCamposDialog}
                        style={{ width: "450px" }}
                        header="Agrupamentos"
                        modal
                        className="card p-fluid"
                        onHide={hideDialogCampos}
                    >
                        <FormGroup>
                            <Tooltip title={basico ? "" : ""}>
                                <FormControlLabel disabled={basico ? false : true} control={<Switch id={1} checked={nbasico} onChange={(e) => handleChangeCampos(e)} />} label="Basicos" />
                            </Tooltip>
                            <Tooltip title={calculos ? "" : "Informações detalhadas sobre os cálculos apresentados (reclamante, reclamada e perito) e sobre a homologação"}>
                                <FormControlLabel disabled={calculos ? false : true} control={<Switch id={2} checked={ncalculos} onChange={(e) => handleChangeCampos(e)} />} label="Análise de Cálculos" />
                            </Tooltip>
                            <Tooltip title={encerrados ? "" : "Informações detalhadas sobre o encerramento do processo (forma, valor, encerramento)"}>
                                <FormControlLabel disabled={encerrados ? false : true} control={<Switch id={3} checked={nencerrados} onChange={(e) => handleChangeCampos(e)} />} label="Análise Casos Encerrados" />
                            </Tooltip>
                            <Tooltip title={fase ? "" : "Identificação se o processo está baixado, ativo e o exato momento/fase processual"}>
                                <FormControlLabel disabled={fase ? false : true} control={<Switch id={4} checked={nfase} onChange={(e) => handleChangeCampos(e)} />} label="Fase e Momento" />
                            </Tooltip>
                            <Tooltip title={financeira ? "" : "Validação dos valores envolvidos individualizado por cada pedido da inicial"}>
                                <FormControlLabel disabled={financeira ? false : true} control={<Switch id={5} checked={nfinanceira} onChange={(e) => handleChangeCampos(e)} />} label="Análise Financeira por pedido" />
                            </Tooltip>
                            <Tooltip title={funcao ? "" : "Informações detalhadas sobre a função/cargo do reclamante (próprio, terceirizado, salário, data de. Admissão, demissão)"}>
                                <FormControlLabel disabled={funcao ? false : true} control={<Switch id={6} checked={nfuncao} onChange={(e) => handleChangeCampos(e)} />} label="Função" />
                            </Tooltip>
                            <Tooltip title={garantias ? "" : "Informações detalhadas sobre a garantia existente no processo (tipo, valor, liberação, data)"}>
                                <FormControlLabel disabled={garantias ? false : true} control={<Switch id={7} checked={ngarantias} onChange={(e) => handleChangeCampos(e)} />} label="Análise de Garantias" />
                            </Tooltip>
                            <Tooltip title={pedidos ? "" : "Listagem de todos os pedidos da inicial"}>
                                <FormControlLabel disabled={pedidos ? false : true} control={<Switch id={8} checked={npedidos} onChange={(e) => handleChangeCampos(e)} />} label="Pedidos da Inicial" />
                            </Tooltip>
                            <Tooltip title={pericias ? "" : "Informações detalhadas sobre o resultado final das perícias, (tipo de perícia, nome do perito, fundamentos do laudo, fato gerador)"}>
                                <FormControlLabel disabled={pericias ? false : true} control={<Switch id={9} checked={npericias} onChange={(e) => handleChangeCampos(e)} />} label="Análise de Perícias" />
                            </Tooltip>
                            <Tooltip title={resersao ? "" : "Análise da reversão ou manutenção da condenação nas instâncias superiores"}>
                                <FormControlLabel disabled={resersao ? false : true} control={<Switch id={10} checked={nresersao} onChange={(e) => handleChangeCampos(e)} />} label="Índice de reversão" />
                            </Tooltip>
                            <Tooltip title={audiencias ? "" : "Análise sobre o comparecimento das partes e testemunhas"}>
                                <FormControlLabel disabled={audiencias ? false : true} control={<Switch id={11} checked={naudiencias} onChange={(e) => handleChangeCampos(e)} />} label="Análise Audiências" />
                            </Tooltip>
                            <Tooltip title={acordos ? "" : "Acordos"}>
                                <FormControlLabel disabled={acordos ? false : true} control={<Switch id={13} checked={nacordos} onChange={(e) => handleChangeCampos(e)} />} label="Acordos" />
                            </Tooltip>
                            <Tooltip title={garantias ? "" : "Campos extras"}>
                                <FormControlLabel disabled={customizado ? false : true} control={<Switch id={12} checked={ncustomizado} onChange={(e) => handleChangeCampos(e)} />} label="Customizado" />
                            </Tooltip>
                        </FormGroup>
                    </Dialog>
                }

                <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {deleteProductsDialog && <span>Tem certeza que gostaria de deletar os itens <b>selecionados</b>?</span>}
                    </div>
                </Dialog>
            </div>

        </div >
    );
}

export default DataGrids;