export const UserType = [
    {value: 1 , label: 'Administrador Global'},
    {value: 2 , label: 'Líder'},
    {value: 3 , label: 'Analista'},
    {value: 4 , label: 'Cliente'},
]

export const Client = [
    {value: 1, label: 'Cliente Um'},
    {value: 2, label: 'Cliente Dois'},
]