import { useState, useContext } from 'react'
import './signin.css'
import './main.css'
import './util.css'
import { AuthContext } from '../../contexts/auth'
import logo from '../../assets/logotipo@6x-8.png'
import { Link } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn, loadingAuth } = useContext(AuthContext);
  const [exibeSenha, setExibeSenha] = useState(false);

  function handledSubmit(e) {
    e.preventDefault();
    if (email !== '' && password !== '') {
      signIn(email, password)
    }
  }

  function alterType() {
    setExibeSenha(!exibeSenha)
  }



  return (
    <div className="container-center">
      <div className="login">
        <div className="logo-area">
          <img src={logo} alt="logo sistema" />
        </div>

        <form className='form-login-info' onSubmit={handledSubmit}>

          <div className="wrap-input100 validate-input">
            <input className="input100 has-val" placeholder='email@site.com.br' type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            {/* <span className="focus-input100" data-placeholder="Email"></span> */}
          </div>

          <div className="wrap-password">
            <input className="input200 has-val" placeholder='Senha' type={!exibeSenha ? "password" : "Text"} name="Senha" value={password} onChange={(e) => setPassword(e.target.value)} />

            {!exibeSenha &&
              <VisibilityIcon className="iconPassword" onClick={alterType} />}

            {exibeSenha &&
              <VisibilityOffIcon className="iconPassword" onClick={alterType} />}


            {/* <span className="focus-input100" data-placeholder="Senha"></span> */}
          </div>
          <button type="submit">{loadingAuth ? 'Carregando...' : 'Login'}</button>


          <Link className='forgot' to="/esqueceuSenha">Esqueceu a senha?</Link>

        </form>
      </div>
    </div>
  );
}

export default SignIn;
