import './escritorio.css';

import React,{ useState, useContext, useEffect} from 'react';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'
import { toast } from 'react-toastify'
import { FilterMatchMode } from 'primereact/api';
import { TextField } from '@mui/material';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import LoadingIcons from 'react-loading-icons'
import DataGridCustom from '../../Components/DataGrid/DataGridCustom'
import Box from '@mui/material/Box'

function CadastroEscritorio() {

    const { isHumburguerActive } = useContext(AuthContext);
    const { getMomentos,
        getOffice,
        alterOffice,
        saveOffice,
        deleteOffice,
        office,
        formConteudo
    } = useContext(CrudContext);

    const [office2, setOffice2] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [newProductsDialog, setNewProductsDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [novoOffice, setNovoOffice] = useState();
    const [fase, setFase] = useState();

    const [productDialog, setProductDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false);

    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');

    useEffect(() => {
        getMomentos();
        initFilters1();
        getOffice()
    }, [])

    useEffect(() => {
        setOffice2(office)
    }, [office])

    useEffect(()=>{ 
        if(formConteudo){
            setSelectedCustomers(formConteudo)
        }        
      },[formConteudo])

    // --------------------------------------------------------------------------------------
    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveProduct(e)}
            />
        </React.Fragment>
    );

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setNewProductsDialog(!newProductsDialog)
    };

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
        });
        setGlobalFilterValue1('');
    }

    const saveProduct = (e) => {
        setSubmitted(true);
        handleSaveOffice(e)
        setNewProductsDialog(!newProductsDialog)
    }

    // ---------------------------------------------------------------------------------------


    async function handleSaveOffice(e) {
        e.preventDefault();
        if (novoOffice) {
            await saveOffice({description: novoOffice });
            setNovoOffice('');
            setFase('');
        }
        else {
            toast.error('Preencha todos os campos')
        }
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const openNew = () => {
        setNewProductsDialog(true)
        setNovoOffice()
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProduct = () => {
        deleteOffice(selectedCustomers);
        setDeleteProductsDialog(false);
    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />
                <Button label="Editar" icon="pi pi-check" className="p-button-alert" onClick={() => confirmeEditar()} disabled={selectedCustomers?.length === 1 ? false : true} />
            </React.Fragment>
        )
    }

    const confirmeEditar = () => {
        setIsEdit(!isEdit)
        const filterEscritorio = office2.filter( item => item.id === selectedCustomers[0])[0]
       setNovoOffice(filterEscritorio.description)  
    }

    const columns = [
        {field: 'id', headerName: 'id', width: 50 },
        {field: 'description', headerName: 'Descrição', width: 250 }
      ]

      const productDialogFooterAlter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialogAlter()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => {
                    alterOffice({id: selectedCustomers[0], description: novoOffice});                                    
                    setIsEdit(!isEdit)
                }}
            />
        </React.Fragment>
    );

    
    const hideDialogAlter= ()=>{
        setIsEdit(!isEdit)
    }
    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                {/* <Title nome="Cadastro de Escritório">
                </Title> */}

                <div className="container-profile">
                    {/* ------------------------------------------ */}
                    {newProductsDialog &&
                        <Dialog
                            visible={newProductsDialog}
                            style={{ width: "450px" }}
                            header="Novo registro"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Descrição"
                                    value={novoOffice}
                                    onChange={(e) => setNovoOffice(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>                          
                        </Dialog>
                    }

                    {isEdit &&
                        <Dialog
                            visible={isEdit}
                            style={{ width: "450px" }}
                            header="Editar registro"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooterAlter}
                            onHide={hideDialogAlter}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Descrição"
                                    value={novoOffice}
                                    onChange={(e) => setNovoOffice(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>                          
                        </Dialog>
                    }

                    {office2 &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                            <Box sx={{ height: 800, width: '100%' }}>
                                <DataGridCustom 
                                    column={columns} 
                                    rows={office2}                
                                />
                            </Box>   
                        </div>
                    }

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {deleteProductsDialog && <span>Tem certeza que gostaria de deletar os escritórios <b>selecionados</b>?</span>}
                        </div>
                    </Dialog>

                </div>
            </div >
        </div >
    )
}


export default CadastroEscritorio;