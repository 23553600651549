import './pedidos.css';

import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'
import { toast } from 'react-toastify'
import { FilterMatchMode } from 'primereact/api';
import { MenuItem, TextField } from '@mui/material';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import LoadingIcons from 'react-loading-icons'
import Box from '@mui/material/Box'
import DataGridCustom from '../../Components/DataGrid/DataGridCustom'

function CadastroFuncao() {

    const { user, isHumburguerActive } = useContext(AuthContext);
    const { getPedidos, pedidos, savePedidos, alterPedido, deletePedido, formConteudo } = useContext(CrudContext);
    const [pedidos2, setPedidos2] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [newProductsDialog, setNewProductsDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [novoPedido, setNovoPedido] = useState();
    const [tipoVerba, setTipoVerba] = useState();

    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');

    const [productDialog, setProductDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        getPedidos();
        initFilters1();
    }, [])


    useEffect(() => {
        setPedidos2(pedidos)
    }, [pedidos])

    useEffect(()=>{ 
        if(formConteudo){
            setSelectedCustomers(formConteudo)
        }        
      },[formConteudo])
    // --------------------------------------------------------------------------------------
    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveProduct(e)}
            />
        </React.Fragment>
    );

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setNewProductsDialog(!newProductsDialog)
    };

    const saveProduct = (e) => {
        setSubmitted(true);
        handleSavePedido(e)
        setNewProductsDialog(!newProductsDialog)
    }

    // ---------------------------------------------------------------------------------------

    async function handleSavePedido(e) {
        e.preventDefault();
        if (novoPedido) {
            await savePedidos(novoPedido, tipoVerba);
            setNovoPedido('');
            setTipoVerba('');
        }
        else {
            toast.error('Preencha todos os campos')
        }
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const openNew = () => {
        setNewProductsDialog(true)
        setNovoPedido()
        setTipoVerba()
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProduct = () => {
        deletePedido(selectedCustomers);
        setDeleteProductsDialog(false);
    }

    const clearFilter1 = () => {
        initFilters1();
    }

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

        });
        setGlobalFilterValue1('');
    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />
                <Button label="Editar" icon="pi pi-check" className="p-button-alert" onClick={() => confirmeEditar()} disabled={selectedCustomers?.length === 1 ? false : true} />
            </React.Fragment>
        )
    }

    const confirmeEditar = () => {
        setIsEdit(!isEdit)
        const filterPedidos = pedidos.filter( item => item.id === selectedCustomers[0])[0]
       setNovoPedido(filterPedidos.description)  
       setTipoVerba(filterPedidos.tipoVerba)
    }

    const productDialogFooterAlter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialogAlter()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => {
                    alterPedido({  id: selectedCustomers[0], description: novoPedido, tipoVerba: tipoVerba });                                    
                    setIsEdit(!isEdit)
                }}
            />
        </React.Fragment>
    );

    
    const hideDialogAlter= ()=>{
        setIsEdit(!isEdit)
    }

    const statuses = [
        { label: 'Desconhecido', value: 'Desconhecido' },
        { label: 'Indenizatorio', value: 'Indenizatorio' },
        { label: 'Salarial', value: 'Salarial' },
        { label: 'N/A', value: 'NA' }
    ];

    const onRowEditComplete1 = (e) => {
        let _products2 = [...pedidos2];
        let { newData, index } = e;

        _products2[index] = newData;
        alterPedido(newData);

        setPedidos2(_products2);
    }

    const columns = [
        {field: 'id', headerName: 'id', width: 50 },
        {field: 'description', headerName: 'Descrição', width: 250 },
        {field: 'tipoVerba', headerName: 'Tipo Verba', width: 250 }
    ]

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                {/* <Title nome="Cadastro de Pedido">
                </Title> */}

                <div className="container-profile">
                    {/* ---------------------------------------------------- */}
                    {newProductsDialog &&
                        <Dialog
                            visible={newProductsDialog}
                            style={{ width: "450px" }}
                            header="Novo Pedido"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Descrição"
                                    value={novoPedido}
                                    onChange={(e) => setNovoPedido(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Tipo de Verba"
                                    value={tipoVerba}
                                    onChange={(e) => setTipoVerba(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {statuses.map(option => (
                                        <MenuItem key={option.label} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Dialog>
                    }

{isEdit &&
                        <Dialog
                            visible={isEdit}
                            style={{ width: "450px" }}
                            header="Editar Pedido"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooterAlter}
                            onHide={hideDialogAlter}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Descrição"
                                    value={novoPedido}
                                    onChange={(e) => setNovoPedido(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Tipo de Verba"
                                    value={tipoVerba}
                                    onChange={(e) => setTipoVerba(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    {statuses.map(option => (
                                        <MenuItem key={option.value} value={option.label}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </Dialog>
                    }
                    {/* ---------------------------------------------------- */}

                    {pedidos2 &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                            <Box sx={{ height: 800, width: '100%' }}>
                                <DataGridCustom 
                                    column={columns} 
                                    rows={pedidos2}                
                                />
                            </Box>
                        </div>
                    }

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {deleteProductsDialog && <span>Tem certeza que gostaria de deletar os pedidos <b>selecionados</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div >
        </div >
    )
}


export default CadastroFuncao;