
import { Routes, Route } from 'react-router-dom';

import SignIn from '../pages/SignIn'
import SignUp from '../pages/CadastroUsuario'
import DashPadrao from '../pages/DashPadrao'
import ModeloUm from '../pages/ModeloUm'
import Profile from '../pages/MeuPerfil'
import Empresa from '../pages/CadastroEmpresa'
import Pedido from '../pages/CadastroPedido'
import Momento from '../pages/CadastroMomento'
import Cadastro from '../pages/CadastroUsuario'
import EsqueceuSenha from '../pages/EsqueceuSenha'
import Arquivos from '../pages/Arquivos'
import RequireAuth from './requireAuth'
import CadastroAdvogado from '../pages/CadastroAdvogado';
import ManutencaoFiltros from '../pages/ManutencaoFiltros';
import CadastroEscritorio from '../pages/CadastroEscritorio';
import CadastroDicionario from '../pages/CadastroDicionario';
import DashboardCustomizado from '../pages/DashCustomizado';
import CadastroDepartamento from '../pages/CadastroDepartamento'
import Insights from '../pages/Insights';
import ToDos from '../pages/ToDos';
import CadastroFuncao from '../pages/CadastroFuncao'
import CadastroAtividade from '../pages/CadastroAtividade'
import Higienizacao from '../pages/Higienizacao';
import Tabelas from '../pages/TabelasDinamica';
import Pacotes from '../pages/Pacotes';
import Monitoramento from '../pages/Monitoramento';

import Relatorios from '../pages/Relatorios';


export default function Rotas() {

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth redirectTo="/higienizacao">
            <SignIn />
          </RequireAuth>
        }
      />

      <Route
        path="/register"
        element={
          <RequireAuth redirectTo="/higienizacao">
            <SignUp />
          </RequireAuth>
        }
      />

      <Route
        path="/dashboardPadrao"
        element={

          <RequireAuth redirectTo="/" isPrivate>
            <DashPadrao />
          </RequireAuth>
        }
      />

      <Route
        path="/dashboardCutomizado"
        element={

          <RequireAuth redirectTo="/" isPrivate>
            <DashboardCustomizado />
          </RequireAuth>
        }
      />

      <Route
        path="/processos"
        element={

          <RequireAuth redirectTo="/" isPrivate>
            <ModeloUm />
          </RequireAuth>
        }
      />

      <Route
        path="/meuperfil"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Profile />
          </RequireAuth>
        }
      />

      <Route
        path="/usuarios"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Cadastro />
          </RequireAuth>
        }
      />

      <Route
        path="/Todos"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <ToDos />
          </RequireAuth>
        }
      />

      <Route
        path="/empresas"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Empresa />
          </RequireAuth>
        }
      />

      <Route
        path="/pedidos"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Pedido />
          </RequireAuth>
        }
      />

      <Route
        path="/momentos"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Momento />
          </RequireAuth>
        }
      />

      <Route
        path="/esqueceuSenha"
        element={
          <RequireAuth redirectTo="/">
            <EsqueceuSenha />
          </RequireAuth>
        }
      />

      <Route
        path="/arquivos"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Arquivos />
          </RequireAuth>
        }
      />

      <Route
        path="/advogado"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <CadastroAdvogado />
          </RequireAuth>
        }
      />

      <Route
        path="/manutencaoFiltros"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <ManutencaoFiltros />
          </RequireAuth>
        }
      />

      <Route
        path="/escritorio"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <CadastroEscritorio />
          </RequireAuth>
        }
      />

      <Route
        path="/dicionario"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <CadastroDicionario />
          </RequireAuth>
        }
      />

      <Route
        path="/departamento"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <CadastroDepartamento />
          </RequireAuth>
        }
      />

      <Route
        path="/funcao"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <CadastroFuncao />
          </RequireAuth>
        }
      />

      <Route
        path="/atividades"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <CadastroAtividade />
          </RequireAuth>
        }
      />


      <Route
        path="/insights"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Insights />
          </RequireAuth>
        }
      />

      <Route
        path="/higienizacao"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Higienizacao />
          </RequireAuth>
        }
      />

      <Route
        path="/pacotes"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Pacotes />
          </RequireAuth>
        }
      />

      <Route
        path="/tabelas"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Tabelas />
          </RequireAuth>
        }
      />

      <Route
        path="/monitoramento"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Monitoramento />
          </RequireAuth>
        }
      />

      <Route
        path="/reports"
        element={
          <RequireAuth redirectTo="/" isPrivate>
            <Relatorios />
          </RequireAuth>
        }
      />



    </Routes>
  )
}