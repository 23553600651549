import './empresas.css';

import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/auth';
import { CrudContext } from '../../contexts/crud';
import Header from '../../Components/Header'

import { toast } from 'react-toastify'

import { TextField } from '@mui/material';
import { Checkbox } from 'primereact/checkbox';
import { FilterMatchMode} from 'primereact/api';
import LoadingIcons from 'react-loading-icons'

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import Box from '@mui/material/Box'
import DataGridCustom from '../../Components/DataGrid/DataGridCustom'

function CadastroEmpresa() {

    const { isHumburguerActive } = useContext(AuthContext);
    const { saveEmpresa, getEmpresas, empresas, alterEmpresa, deleteEmpresa, formConteudo } = useContext(CrudContext);
    const [empresas2, setEmpresas2] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [newProductsDialog, setNewProductsDialog] = useState(false);

    const [novaEmpresa, setNovaEmpresa] = useState();
    const [urlDash, setUrlDash] = useState();
    const [productDialog, setProductDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false);
    const [isEdit, setIsEdit] = useState(false)

    const [checkedBasico, setCheckedBasico] = useState(true)
    const [checkedCalculos, setCheckedCalculos] = useState(false)
    const [checkedEncerrados, setCheckedEncerrados] = useState(false)
    const [checkedFase, setCheckedFase] = useState(false)
    const [checkedFinanceira, setCheckedFinanceira] = useState(false)
    const [checkedFuncao, setCheckedFuncao] = useState(false)
    const [checkedGarantias, setCheckedGarantias] = useState(false)
    const [checkedPedidos, setCheckedPedidos] = useState(false)
    const [checkedPericias, setCheckedPericias] = useState(false)
    const [checkedResersao, setCheckedResersao] = useState(false)
    const [checkedAudiencias, setCheckedAudiencias] = useState(false)
    const [checkedAcordos, setCheckedAcordos] = useState(false)
    const [checkedCustomizado, setCheckedCustomizado] = useState(false)



    const [idEmpresa, setIdEmpresa] = useState('')
    const [isDeletedItem, setIsDeletedItem] = useState(false)
    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');

    const [product, setProduct] = useState({
        id: null,
        name: "",
        image: null,
        description: "",
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
    });


    useEffect(() => {
        getEmpresas();
        initFilters1();
    }, [])


    useEffect(() => {
        setEmpresas2(empresas)
    }, [empresas])

    useEffect(() => {
        let data = empresas?.filter((item) => item.id === idEmpresa)
        setNovaEmpresa(data[0]?.name)
        setUrlDash(data[0]?.url)
        setCheckedBasico(data[0]?.basico === "Sim" ? true : false)
        setCheckedCalculos(data[0]?.calculos === "Sim" ? true : false)
        setCheckedEncerrados(data[0]?.encerrados === "Sim" ? true : false)
        setCheckedFase(data[0]?.fase === "Sim" ? true : false)
        setCheckedFinanceira(data[0]?.financeira === "Sim" ? true : false)
        setCheckedFuncao(data[0]?.funcao === "Sim" ? true : false)
        setCheckedGarantias(data[0]?.garantias === "Sim" ? true : false)
        setCheckedPedidos(data[0]?.pedidos === "Sim" ? true : false)
        setCheckedPericias(data[0]?.pericias === "Sim" ? true : false)
        setCheckedResersao(data[0]?.resersao === "Sim" ? true : false)
        setCheckedAudiencias(data[0]?.audiencias === "Sim" ? true : false)
        setCheckedAcordos(data[0]?.customizado === "Sim" ? true : false)
        setCheckedCustomizado(data[0]?.customizado === "Sim" ? true : false)

    }, [idEmpresa])

    useEffect(()=>{ 
        if(formConteudo){
            setSelectedCustomers(formConteudo)
        }        
      },[formConteudo])

    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialog()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={(e) => saveProduct(e)}
            />
        </React.Fragment>
    );

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setNewProductsDialog(!newProductsDialog)
    };


    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

        });
        setGlobalFilterValue1('');
    }

    const saveProduct = (e) => {
        setSubmitted(true);
        handleSaveEmpresa(e)
        setNewProductsDialog(!newProductsDialog)
    }

    // ---------------------------------------------------------------------------------------


    async function handleSaveEmpresa(e) {
        e.preventDefault();
        if (novaEmpresa) {
            await saveEmpresa(dataEmpresa());
            setNovaEmpresa('');
            setUrlDash('');

            clearAllFields()
        }
        else {
            toast.error('É necessário informar o nome da empresa')
        }
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const openNew = () => {
        setNewProductsDialog(true)
        clearAllFields()
    }

    const clearAllFields = () => {
        setNovaEmpresa('')
        setUrlDash('')
        setIdEmpresa('')
        setCheckedCalculos(false)
        setCheckedEncerrados(false)
        setCheckedFase(false)
        setCheckedFinanceira(false)
        setCheckedFuncao(false)
        setCheckedGarantias(false)
        setCheckedPedidos(false)
        setCheckedPericias(false)
        setCheckedResersao(false)
        setCheckedAudiencias(false)
        setCheckedAcordos(false)
        setCheckedCustomizado(false)
    }

    const confirmeEditar = () => {
        setIsEdit(true)
        setDataItemEdit()
    }

    const validaBolean = (valorBoolean) => {
        return valorBoolean === "Sim" ? true : false
    }

    const setDataItemEdit = () => {

        let data = empresas.filter((item) => item.id === selectedCustomers[0])

        setIdEmpresa(idEmpresa ? data[0].id : data[0].id)
        setNovaEmpresa(data[0].name)
        setUrlDash(idEmpresa ? data[0].url : data[0].url)
        setCheckedBasico(idEmpresa ? validaBolean(data[0].basico) : data[0].basico === "Sim" ? true : false)
        setCheckedCalculos(idEmpresa ? validaBolean(data[0].calculos) : data[0].calculos === "Sim" ? true : false)
        setCheckedEncerrados(idEmpresa ? validaBolean(data[0].encerrados) : data[0].encerrados === "Sim" ? true : false)
        setCheckedFase(idEmpresa ? validaBolean(data[0].fase) : data[0].fase === "Sim" ? true : false)
        setCheckedFinanceira(idEmpresa ? validaBolean(data[0].financeira) : data[0].financeira === "Sim" ? true : false)
        setCheckedFuncao(idEmpresa ? validaBolean(data[0].funcao) : data[0].funcao === "Sim" ? true : false)
        setCheckedGarantias(idEmpresa ? validaBolean(data[0].garantias) : data[0].garantias === "Sim" ? true : false)
        setCheckedPedidos(idEmpresa ? validaBolean(data[0].pedidos) : data[0].pedidos === "Sim" ? true : false)
        setCheckedPericias(idEmpresa ? validaBolean(data[0].pericias) : data[0].pericias === "Sim" ? true : false)
        setCheckedResersao(idEmpresa ? validaBolean(data[0].resersao) : data[0].resersao === "Sim" ? true : false)
        setCheckedAudiencias(idEmpresa ? validaBolean(data[0].audiencias) : data[0].audiencias === "Sim" ? true : false)
        setCheckedAcordos(idEmpresa ? validaBolean(data[0].acordos) : data[0].acordos === "Sim" ? true : false)
        setCheckedCustomizado(idEmpresa? validaBolean(data[0].customizado) :  data[0].customizado === "Sim"? true : false )

    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomers || !selectedCustomers.length} />
                <Button label="Editar" icon="pi pi-check" className="p-button-alert" onClick={() => confirmeEditar()} disabled={selectedCustomers?.length === 1 ? false : true} />
            </React.Fragment>
        )
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProduct = () => {
        deleteEmpresa(selectedCustomers);
        setDeleteProductsDialog(false);
        setIsDeletedItem(true)

    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );

    const hideDialogEdit = () => {
        setSubmitted(false);
        setIsEdit(false)
    };

    const editDialogFooter = (
        <React.Fragment>
            <Button
                label="Cancelar"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideDialogEdit()}
            />
            <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => updateEmpresa()}
            />
        </React.Fragment>
    );

    const dataEmpresa = () => {
        let dataEmpresa = {
            id: idEmpresa,
            name: novaEmpresa,
            url: urlDash ? urlDash : 'NA',
            basico: true,
            calculos: checkedCalculos,
            encerrados: checkedEncerrados,
            fase: checkedFase,
            financeira: checkedFinanceira,
            funcao: checkedFuncao,
            garantias: checkedGarantias,
            pedidos: checkedPedidos,
            pericias: checkedPericias,
            resersao: checkedResersao,
            audiencias: checkedAudiencias,
            acordos: checkedAcordos,
            customizado: checkedCustomizado
        }

        return dataEmpresa
    }

    const updateEmpresa = () => {
        alterEmpresa(dataEmpresa());
        setIsEdit(false)
    }
    const widthColumn = 100
    const columns = [
        {field: 'id', headerName: 'id', width: widthColumn },
        {field: 'name', headerName: 'Nome', width: widthColumn },
        {field: 'url', headerName: 'Url dashboard', width: widthColumn },
        {field: 'basico', headerName: 'Básico', width: widthColumn },
        {field: 'calculos', headerName: 'Cálculos', width: widthColumn },
        {field: 'encerrados', headerName: 'Encerrados', width: widthColumn },
        {field: 'fase', headerName: 'Fase', width: widthColumn },
        {field: 'financeira', headerName: 'Financeiro', width: widthColumn },
        {field: 'funcao', headerName: 'Função', width: widthColumn },
        {field: 'garantias', headerName: 'Garantias', width: widthColumn },
        {field: 'pedidos', headerName: 'Pedidos', width: widthColumn },
        {field: 'pericias', headerName: 'Pericias', width: widthColumn },
        {field: 'resersao', headerName: 'Reversão', width: widthColumn },
        {field: 'audiencias', headerName: 'Audiencias', width: widthColumn },
        {field: 'acordos', headerName: 'Acordos', width: widthColumn },
        {field: 'customizado', headerName: 'Customizado', width: widthColumn }
      ]

    return (
        <div >
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                {/* <Title nome="Cadastro de base cliente">
                </Title> */}

                <div className="container-profile">
                    {newProductsDialog &&
                        <Dialog
                            visible={newProductsDialog}
                            style={{ width: "450px" }}
                            header="Nova Base Cliente"
                            modal
                            className="card p-fluid"
                            footer={productDialogFooter}
                            onHide={hideDialog}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="Base cliente"
                                    value={novaEmpresa}
                                    onChange={(e) => setNovaEmpresa(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    id="outlined-name"
                                    label="URL"
                                    value={urlDash}
                                    onChange={(e) => setUrlDash(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="checkbomain">
                                <div className='checkbox'>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb1" readOnly={true} value={true} checked={true}></Checkbox>
                                        <label htmlFor="cb1" className="p-checkbox-label">Básico</label>
                                    </div>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb3" value={checkedCalculos} onChange={() => setCheckedCalculos(!checkedCalculos)} checked={checkedCalculos}></Checkbox>
                                        <label htmlFor="cb2" className="p-checkbox-label">Cálculos</label>
                                    </div>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb5" value={checkedEncerrados} onChange={() => setCheckedEncerrados(!checkedEncerrados)} checked={checkedEncerrados}></Checkbox>
                                        <label htmlFor="cb4" className="p-checkbox-label">Encerrados</label>
                                    </div>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb7" value={checkedFase} onChange={() => setCheckedFase(!checkedFase)} checked={checkedFase}></Checkbox>
                                        <label htmlFor="cb6" className="p-checkbox-label">Fase</label>
                                    </div>
                                </div>

                                <div className='checkbox'>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb9" value={checkedFinanceira} onChange={() => setCheckedFinanceira(!checkedFinanceira)} checked={checkedFinanceira}></Checkbox>
                                        <label htmlFor="cb8" className="p-checkbox-label">Financeira</label>
                                    </div>

                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb1" value={checkedFuncao} onChange={() => setCheckedFuncao(!checkedFuncao)} checked={checkedFuncao}></Checkbox>
                                        <label htmlFor="cb10" className="p-checkbox-label">Função</label>
                                    </div>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb12" value={checkedGarantias} onChange={() => setCheckedGarantias(!checkedGarantias)} checked={checkedGarantias}></Checkbox>
                                        <label htmlFor="cb11" className="p-checkbox-label">Garantias</label>
                                    </div>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb14" value={checkedPedidos} onChange={() => setCheckedPedidos(!checkedPedidos)} checked={checkedPedidos}></Checkbox>
                                        <label htmlFor="cb13" className="p-checkbox-label">Pedidos</label>
                                    </div>
                                </div>

                                <div className='checkbox'>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb16" value={checkedPericias} onChange={() => setCheckedPericias(!checkedPericias)} checked={checkedPericias}></Checkbox>
                                        <label htmlFor="cb15" className="p-checkbox-label">Pericias</label>
                                    </div>

                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb18" value={checkedResersao} onChange={() => setCheckedResersao(!checkedResersao)} checked={checkedResersao}></Checkbox>
                                        <label htmlFor="cb17" className="p-checkbox-label">Reversão</label>
                                    </div>

                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb18" value={checkedAudiencias} onChange={() => setCheckedAudiencias(!checkedAudiencias)} checked={checkedAudiencias}></Checkbox>
                                        <label htmlFor="cb17" className="p-checkbox-label">Audiencias</label>
                                    </div>

                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb18" value={checkedAcordos} onChange={() => setCheckedAcordos(!checkedAcordos)} checked={checkedAcordos}></Checkbox>
                                        <label htmlFor="cb17" className="p-checkbox-label">Acordos</label>
                                    </div>

                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb18" value={checkedCustomizado} onChange={() => setCheckedCustomizado(!checkedCustomizado)} checked={checkedCustomizado}></Checkbox>
                                        <label htmlFor="cb17" className="p-checkbox-label">Customizado</label>
                                    </div>
                                </div>

                            </div>

                        </Dialog>
                    }

                    {isEdit &&
                        <Dialog
                            visible={isEdit}
                            style={{ width: "450px" }}
                            header="Editar registro"
                            modal
                            className="card p-fluid"
                            footer={editDialogFooter}
                            onHide={hideDialogEdit}
                        >
                            <div className="field">
                                <TextField
                                    id="outlined-name-edit"
                                    label="Nome"
                                    value={novaEmpresa}
                                    onChange={(e) => setNovaEmpresa(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    id="outlined-name-edit"
                                    label="Url dashboard"
                                    value={urlDash}
                                    onChange={(e) => setUrlDash(e.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>


                            <div className="checkbomain">
                                <div className='checkbox'>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb1" readOnly={true} value={checkedBasico} onChange={() => setCheckedBasico(!checkedBasico)} checked={checkedBasico}></Checkbox>
                                        <label htmlFor="cb1" className="p-checkbox-label">Básico</label>
                                    </div>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb3" value={checkedCalculos} onChange={() => setCheckedCalculos(!checkedCalculos)} checked={checkedCalculos}></Checkbox>
                                        <label htmlFor="cb2" className="p-checkbox-label">Cálculos</label>
                                    </div>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb5" value={checkedEncerrados} onChange={() => setCheckedEncerrados(!checkedEncerrados)} checked={checkedEncerrados}></Checkbox>
                                        <label htmlFor="cb4" className="p-checkbox-label">Encerrados</label>
                                    </div>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb7" value={checkedFase} onChange={() => setCheckedFase(!checkedFase)} checked={checkedFase}></Checkbox>
                                        <label htmlFor="cb6" className="p-checkbox-label">Fase</label>
                                    </div>
                                </div>

                                <div className='checkbox'>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb9" value={checkedFinanceira} onChange={() => setCheckedFinanceira(!checkedFinanceira)} checked={checkedFinanceira}></Checkbox>
                                        <label htmlFor="cb8" className="p-checkbox-label">Financeira</label>
                                    </div>

                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb1" value={checkedFuncao} onChange={() => setCheckedFuncao(!checkedFuncao)} checked={checkedFuncao}></Checkbox>
                                        <label htmlFor="cb10" className="p-checkbox-label">Função</label>
                                    </div>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb12" value={checkedGarantias} onChange={() => setCheckedGarantias(!checkedGarantias)} checked={checkedGarantias}></Checkbox>
                                        <label htmlFor="cb11" className="p-checkbox-label">Garantias</label>
                                    </div>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb14" value={checkedPedidos} onChange={() => setCheckedPedidos(!checkedPedidos)} checked={checkedPedidos}></Checkbox>
                                        <label htmlFor="cb13" className="p-checkbox-label">Pedidos</label>
                                    </div>
                                </div>

                                <div className='checkbox'>
                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb16" value={checkedPericias} onChange={() => setCheckedPericias(!checkedPericias)} checked={checkedPericias}></Checkbox>
                                        <label htmlFor="cb15" className="p-checkbox-label">Pericias</label>
                                    </div>

                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb18" value={checkedResersao} onChange={() => setCheckedResersao(!checkedResersao)} checked={checkedResersao}></Checkbox>
                                        <label htmlFor="cb17" className="p-checkbox-label">Reversão</label>
                                    </div>

                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb18" value={checkedAudiencias} onChange={() => setCheckedAudiencias(!checkedAudiencias)} checked={checkedAudiencias}></Checkbox>
                                        <label htmlFor="cb17" className="p-checkbox-label">Audiencias</label>
                                    </div>

                                    <div className='checkbox-container'>
                                        <Checkbox inputId="cb18" value={checkedAcordos} onChange={() => setCheckedAudiencias(!checkedAcordos)} checked={checkedAcordos}></Checkbox>
                                        <label htmlFor="cb17" className="p-checkbox-label">Acordos</label>
                                    </div>
                                    
                                    <div className='checkbox-container'>
                                    <Checkbox inputId="cb18" value={checkedCustomizado} onChange={()=> setCheckedCustomizado(!checkedCustomizado)} checked={checkedCustomizado}></Checkbox>   
                                    <label htmlFor="cb17" className="p-checkbox-label">Customizado</label>                            
                                </div>
                                </div>

                            </div>


                        </Dialog>
                    }


                    {
                        empresas2 &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                            <Box sx={{ height: 800, width: '100%' }}>
                                <DataGridCustom 
                                    column={columns} 
                                    rows={empresas2}                
                                />
                            </Box>
                        </div>
                    }

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {deleteProductsDialog && <span>Tem certeza que gostaria de deletar as empresas <b>selecionadas</b>?</span>}
                        </div>
                    </Dialog>
                </div >
            </div >
        </div >
    )
}


export default CadastroEmpresa;