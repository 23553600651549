import { useState, createContext, useContext } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import moment from 'moment'
import { AuthContext } from './auth'

export const ImportacaoContext = createContext({})

function ImportacaoProvider({ children }) {
    const { user } = useContext(AuthContext);
    const [modeloUm, setModeloUm] = useState([])
    const [arquivos, setArquivos] = useState([])
    const [gridHistorico, setGridHistorico] = useState([]);
    const [totalArquivosParaDeletar, setTotalArquivosParaDeletar] = useState(0)

    const [newImport, setNewImport] = useState(false);

    const [isEmpty, setIsEmpty] = useState(null);
    const [progress, setProgress] = useState(0);
    const [load, setLoad] = useState(false);
    const [load2, setLoad2] = useState(false);
    const [msg, setMSG] = useState(false);
    const [filterUser, setFilterUser] = useState([]);
    const [labels, setLabels] = useState([]);

    const urlBase = process.env.REACT_APP_PACT_API;

    async function sendProcess(data, usuario, filename, cliente) {
        setLoad(true);
        setMSG(true)
        let tamanho = data.length;
        let item = 0;
        data.forEach((d, index) => {
            item = item + 1
            imprimirPercent(tamanho, item, d, usuario, filename, cliente)
        });
    }

    async function getLabels(empresa) {
        setLabels([])

        let _labels = ''

        await axios.get(urlBase + '/dicionary/companyName/' + empresa, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                setLabels(response.data)
                _labels = response.data
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })

        return _labels
    }

    async function imprimirPercent(tamanho, index, d, usuario, filename, cliente) {
        let percentual = 0;
        await saveProcess(d, usuario, filename, cliente)
            .then((result) => {
                if (index < tamanho - 1) {
                    percentual = ((100 * (index + 1)) / tamanho)
                    setProgress(percentual)
                }
                else {
                    percentual = 100
                    setProgress(percentual)
                    setMSG(true)
                }

                if (percentual >= 100) {
                    setLoad(false);
                    setMSG(false);
                    getImportacao();
                }
            })
    }


    async function saveProcess(data, usuario, filename, cliente) {
        let now = new Date()
        let formatData = {
            id: data.NCNJ,
            NCNJ: (data.NCNJ) ? data.NCNJ : '',
            contrarioPrincipalNomeRazao: (data.contrarioPrincipalNomeRazao) ? data.contrarioPrincipalNomeRazao : '',
            uf: (data.uf) ? data.uf : '',
            cidade: (data.cidade) ? data.cidade : '',
            advogadoReverso: (data.advogadoReverso) ? data.advogadoReverso : '',
            oab: (data.oab) ? data.oab : '',
            vara: (data.vara) ? data.vara : '',
            nVara: (data.nVara) ? data.nVara : '',
            dtDistribuicao: (data.dtDistribuicao) ? data.dtDistribuicao : '',
            ecBase: (data.ecBase) ? data.ecBase : '',
            riscoDCliente: (data.riscoDCliente) ? data.riscoDCliente : '',
            faixaRisco: (data.faixaRisco) ? data.faixaRisco : '',
            valorCausa: (data.valorCausa) ? data.valorCausa : '',
            tipoProcesso: (data.tipoProcesso) ? data.tipoProcesso : '',
            tipoAcao: (data.tipoAcao) ? data.tipoAcao : '',
            primeiraReclamada: (data.primeiraReclamada) ? data.primeiraReclamada : '',
            demaisReclamadas: (data.demaisReclamadas) ? data.demaisReclamadas : '',
            status: (data.status) ? data.status : '',
            fase: (data.fase) ? data.fase : '',
            momento: (data.momento) ? data.momento : '',
            dtMomento: (data.dtMomento) ? data.dtMomento : '',
            dtAudiencia: (data.dtAudiencia) ? data.dtAudiencia : '',
            dtEncerramento: (data.dtEncerramento) ? data.dtEncerramento : '',
            motivoEncerramento: (data.motivoEncerramento) ? data.motivoEncerramento : '',
            ticketMedio: (data.ticketMedio) ? data.ticketMedio : '',
            valorAcordoCondenacao: (data.valorAcordoCondenacao) ? data.valorAcordoCondenacao : '',
            formaPagamentoAcordo: (data.formaPagamentoAcordo) ? data.formaPagamentoAcordo : '',
            quemPagouAcordo: (data.quemPagouAcordo) ? data.quemPagouAcordo : '',
            momentoEmQueAcordoRealizado: (data.momentoEmQueAcordoRealizado) ? data.momentoEmQueAcordoRealizado : '',
            pedidosDaInicial: (data.pedidosDaInicial) ? data.pedidosDaInicial : '',
            funcaoCompilada: (data.funcaoCompilada) ? data.funcaoCompilada : '',
            proprioTerceiro: (data.proprioTerceiro) ? data.proprioTerceiro : '',
            ultimoSalario: (data.ultimoSalario) ? data.ultimoSalario : '',
            dtAdmissao: (data.dtAdmissao) ? data.dtAdmissao : '',
            dtDemissao: (data.dtDemissao) ? data.dtDemissao : '',
            tempoCasa: (data.tempoCasa) ? data.tempoCasa : '',
            sentenca: (data.sentenca) ? data.sentenca : '',
            dtSentenca: (data.dtSentenca) ? data.dtSentenca : '',
            nomeJuiz: (data.nomeJuiz) ? data.nomeJuiz : '',
            houveReformaAcordaoRO: (data.houveReformaAcordaoRO) ? data.houveReformaAcordaoRO : '',
            pedidosIncluidosAcordao: (data.pedidosIncluidosAcordao) ? data.pedidosIncluidosAcordao : '',
            pedidosExcluidosAcordao: (data.pedidosExcluidosAcordao) ? data.pedidosExcluidosAcordao : '',
            dtAcordao: (data.dtAcordao) ? data.dtAcordao : '',
            acordaoReformouImprocedencia: (data.acordaoReformouImprocedencia) ? data.acordaoReformouImprocedencia : '',
            rrAdmitido: (data.rrAdmitido) ? data.rrAdmitido : '',
            seFoiAdmitidoQualMateria: (data.seFoiAdmitidoQualMateria) ? data.seFoiAdmitidoQualMateria : '',
            houveReformaAcordaoRR: (data.houveReformaAcordaoRR) ? data.houveReformaAcordaoRR : '',
            dtAcordao2: (data.dtAcordao2) ? data.dtAcordao2 : '',
            juros: (data.juros) ? data.juros : '',
            hPericiais: (data.hPericiais) ? data.hPericiais : '',
            honorariosAdvocaticios: (data.honorariosAdvocaticios) ? data.honorariosAdvocaticios : '',
            custasProcessuais: (data.custasProcessuais) ? data.custasProcessuais : '',
            estabilidade: (data.estabilidade) ? data.estabilidade : '',
            bonus: (data.bonus) ? data.bonus : '',
            equiparacaoSalarial: (data.equiparacaoSalarial) ? data.equiparacaoSalarial : '',
            acumuloDeFuncao: (data.acumuloDeFuncao) ? data.acumuloDeFuncao : '',
            dMoral: (data.dMoral) ? data.dMoral : '',
            dMaterial: (data.dMaterial) ? data.dMaterial : '',
            pensao: (data.pensao) ? data.pensao : '',
            he: (data.he) ? data.he : '',
            horasInItinere: (data.horasInItinere) ? data.horasInItinere : '',
            sebreaviso: (data.sebreaviso) ? data.sebreaviso : '',
            intrajornada: (data.intrajornada) ? data.intrajornada : '',
            diferencasSalariais: (data.diferencasSalariais) ? data.diferencasSalariais : '',
            adTransferencia: (data.adTransferencia) ? data.adTransferencia : '',
            insalubridade: (data.insalubridade) ? data.insalubridade : '',
            periculosidade: (data.periculosidade) ? data.periculosidade : '',
            ticketMedioporPedido: (data.ticketMedioporPedido) ? data.ticketMedioporPedido : '',
            garantia: (data.garantia) ? data.garantia : '',
            possuiGarantia: (data.possuiGarantia) ? data.possuiGarantia : '',
            garantiaMaiorouMenorqueRisco: (data.garantiaMaiorouMenorqueRisco) ? data.garantiaMaiorouMenorqueRisco : '',
            contrarioPrincipalNomeRazao2: (data.contrarioPrincipalNomeRazao2) ? data.contrarioPrincipalNomeRazao2 : '',
            tipoGarantiaRecursalDepositoApolice: (data.tipoGarantiaRecursalDepositoApolice) ? data.tipoGarantiaRecursalDepositoApolice : '',
            valorLiberado: (data.valorLiberado) ? data.valorLiberado : '',
            revelia: (data.revelia) ? data.revelia : '',
            reclamanteFoiOuvidaemAud: (data.reclamanteFoiOuvidaemAud) ? data.reclamanteFoiOuvidaemAud : '',
            testemunhaReclamada: (data.testemunhaReclamada) ? data.testemunhaReclamada : '',
            testemunhaReclamante: (data.testemunhaReclamante) ? data.testemunhaReclamante : '',
            dtTransitoemJulgado: (data.dtTransitoemJulgado) ? data.dtTransitoemJulgado : '',
            valorCalcApresentadoReclamada: (data.valorCalcApresentadoReclamada) ? data.valorCalcApresentadoReclamada : '',
            valorCalcApresentadoReclamante: (data.valorCalcApresentadoReclamante) ? data.valorCalcApresentadoReclamante : '',
            valorCalcApresentadoPerito: (data.valorCalcApresentadoPerito) ? data.valorCalcApresentadoPerito : '',
            valorHomologado: (data.valorHomologado) ? data.valorHomologado : '',
            dtCalcHomologado: (data.dtCalcHomologado) ? data.dtCalcHomologado : '',
            deQuemfoiCalcHomologado: (data.deQuemfoiCalcHomologado) ? data.deQuemfoiCalcHomologado : '',
            houveEmbargosExecucao: (data.houveEmbargosExecucao) ? data.houveEmbargosExecucao : '',
            materiaEe: (data.materiaEe) ? data.materiaEe : '',
            formaDeGarantia: (data.formaDeGarantia) ? data.formaDeGarantia : '',
            houvePericia: (data.houvePericia) ? data.houvePericia : '',
            nomePeritoPericulosidadeInsalubridade: (data.nomePeritoPericulosidadeInsalubridade) ? data.nomePeritoPericulosidadeInsalubridade : '',
            nomePeritoAcidenteDoenca: (data.nomePeritoAcidenteDoenca) ? data.nomePeritoAcidenteDoenca : '',
            periciaDeQue: (data.periciaDeQue) ? data.periciaDeQue : '',
            resultadoInsalubridade: (data.resultadoInsalubridade) ? data.resultadoInsalubridade : '',
            fundamentoInsalubridade: (data.fundamentoInsalubridade) ? data.fundamentoInsalubridade : '',
            resultadoPericulosidade: (data.resultadoPericulosidade) ? data.resultadoPericulosidade : '',
            agentePericulosidade: (data.agentePericulosidade) ? data.agentePericulosidade : '',
            fundamentoPericulosidade: (data.fundamentoPericulosidade) ? data.fundamentoPericulosidade : '',
            agenteInsalubre: (data.agenteInsalubre) ? data.agenteInsalubre : '',
            resultadoDoenca: (data.resultadoDoenca) ? data.resultadoDoenca : '',
            resultadoAcidente: (data.resultadoAcidente) ? data.resultadoAcidente : '',
            dtLaudo: (data.dtLaudo) ? data.dtLaudo : '',
            compliadofunHe: (data.compliadofunHe) ? data.compliadofunHe : '',
            agenteInsalubre2: (data.agenteInsalubre2) ? data.agenteInsalubre2 : '',
            grauDaInsalubridade: (data.grauDaInsalubridade) ? data.grauDaInsalubridade : '',
            compiladofunPericulosidade: (data.compiladofunPericulosidade) ? data.compiladofunPericulosidade : '',
            compiladofunAcidDoenca: (data.compiladofunAcidDoenca) ? data.compiladofunAcidDoenca : '',
            qualDoenca: (data.qualDoenca) ? data.qualDoenca : '',
            incapacidade: (data.incapacidade) ? data.incapacidade : '',
            reducaoFuncional: (data.reducaoFuncional) ? data.reducaoFuncional : '',
            compiladoFundDanoMoral: (data.compiladoFundDanoMoral) ? data.compiladoFundDanoMoral : '',
            usuario: (usuario),
            dtInclusao: now,
            dtAtualizacao: now,
            filename: filename,
            empresa: (data.empresa) ? data.empresa : '',
            usuarioAtualizacao: usuario,
            tipo1: (data.tipo1) ? (data.tipo1) : '',
            tipo2: (data.tipo2) ? (data.tipo2) : '',
            tipo3: (data.tipo3) ? (data.tipo3) : '',
            tipo4: (data.tipo4) ? (data.tipo4) : '',
            tipo5: (data.tipo5) ? (data.tipo5) : '',
            tipo6: (data.tipo6) ? (data.tipo6) : '',
            tipo7: (data.tipo7) ? (data.tipo7) : '',
            tipo8: (data.tipo8) ? (data.tipo8) : '',
            tipo9: (data.tipo9) ? (data.tipo9) : '',
            tipo10: (data.tipo10) ? (data.tipo10) : '',
            baseCliente: cliente,
            cpf_reclamante: (data.cpf_reclamante) ? (data.cpf_reclamante) : '',
            projeto: (data.projeto) ? (data.projeto) : '',
            status_pact: (data.status_pact) ? (data.status_pact) : '',
            cnj_execucao_provisoria: (data.cnj_execucao_provisoria) ? (data.cnj_execucao_provisoria) : '',
            momento_execucao_provisoria: (data.momento_execucao_provisoria) ? (data.momento_execucao_provisoria) : '',
            data_cadastro_processo_base: (data.data_cadastro_processo_base) ? (data.data_cadastro_processo_base) : '',
            status_pre: (data.status_pre) ? (data.status_pre) : '',
            data_provisao: (data.data_provisao) ? (data.data_provisao) : '',
            provisao: (data.provisao) ? (data.provisao) : '',
            data_atualizacao_risco: (data.data_atualizacao_risco) ? (data.data_atualizacao_risco) : '',
            risco_bruto: (data.risco_bruto) ? (data.risco_bruto) : '',
            risco_liquido: (data.risco_liquido) ? (data.risco_liquido) : '',
            risco_bruto_pact_net_atualizado: (data.risco_bruto_pact_net_atualizado) ? (data.risco_bruto_pact_net_atualizado) : '',
            risco_liquido_pact_net_atualizado: (data.risco_liquido_pact_net_atualizado) ? (data.risco_liquido_pact_net_atualizado) : '',
            risco_bruto_pact_net: (data.risco_bruto_pact_net) ? (data.risco_bruto_pact_net) : '',
            risco_liquido_pact_net: (data.risco_liquido_pact_net) ? (data.risco_liquido_pact_net) : '',
            depositos_recursais: (data.depositos_recursais) ? (data.depositos_recursais) : '',
            faixa_risco: (data.faixa_risco) ? (data.faixa_risco) : '',
            gatilho_bruto: (data.gatilho_bruto) ? (data.gatilho_bruto) : '',
            status_negociacao: (data.status_negociacao) ? (data.status_negociacao) : '',
            termometro: (data.termometro) ? (data.termometro) : '',
            data_primeiro_contato: (data.data_primeiro_contato) ? (data.data_primeiro_contato) : '',
            data_ultimo_contato: (data.data_ultimo_contato) ? (data.data_ultimo_contato) : '',
            proposta: (data.proposta) ? (data.proposta) : '',
            ulitma_pretensao: (data.ulitma_pretensao) ? (data.ulitma_pretensao) : '',
            data_acordo: (data.data_acordo) ? (data.data_acordo) : '',
            data_protocolo: (data.data_protocolo) ? (data.data_protocolo) : '',
            data_homologacao: (data.data_homologacao) ? (data.data_homologacao) : '',
            acordo_bruto: (data.acordo_bruto) ? (data.acordo_bruto) : '',
            acordo_liquido: (data.acordo_liquido) ? (data.acordo_liquido) : '',
            status_minutas: (data.status_minutas) ? (data.status_minutas) : '',
            inss_reclamada_pago: (data.inss_reclamada_pago) ? (data.inss_reclamada_pago) : '',
            inss_reclamante_pago: (data.inss_reclamante_pago) ? (data.inss_reclamante_pago) : '',
            ir: (data.ir) ? (data.ir) : '',
            honorarios_periciais: (data.honorarios_periciais) ? (data.honorarios_periciais) : '',
            economia: (data.economia) ? (data.economia) : '',
            desagio: (data.desagio) ? (data.desagio) : '',
            honorario_pact: (data.honorario_pact) ? (data.honorario_pact) : '',
            economia_net: (data.economia_net) ? (data.economia_net) : '',
            desagio_net: (data.desagio_net) ? (data.desagio_net) : '',
            status_atividade: 'Não iniciado',
            negociar: (data.negociar) ? (data.negociar) : '',
            tribunal: (data.tribunal) ? (data.tribunal) : '',
            dataStatusPre: (data.dataStatusPre) ? (data.dataStatusPre) : '',
            Observacao: (data.Observacao) ? (data.Observacao) : '',
            descricaoSolicitacaoCopia: (data.descricaoSolicitacaoCopia) ? (data.descricaoSolicitacaoCopia) : '',
            descricaoSolicitacaoCalculo: (data.descricaoSolicitacaoCalculo) ? (data.descricaoSolicitacaoCalculo) : '',
            descricaoUltimaAcaoNegociar: (data.descricaoUltimaAcaoNegociar) ? (data.descricaoUltimaAcaoNegociar) : '',
            statusPreSubTipo: (data.statusPreSubTipo) ? (data.statusPreSubTipo) : '',
            fluxoSaneamento: (data.fluxoSaneamento) ? (data.fluxoSaneamento) : '',
            restricaoTipo: (data.restricaoTipo) ? (data.restricaoTipo) : '',
        }

        await axios.post(urlBase + '/process/create',
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {

            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    async function saveFilter(data, usuario, descricao, user) {
        let now = new Date()
        let formatData = {
            usuario: usuario,
            descricao: descricao,
            filtro: data
        }

        await axios.post(urlBase + '/filters/create',
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Filtro Salvo');

                if (user.tipoUsuario === 'Cliente') {
                    getFilterByUserId(usuario)
                } else {
                    getFilter();
                }

            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    };

    async function getImportacao() {
        setModeloUm([])
        setLoad(true)
        setLoad2(true)
        await axios.get(urlBase + '/process/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                updateState(response.data)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            }).finally(() => {
                setLoad(false)
                setLoad2(false)
            })
    }

    async function updateState(snapshot) {
        const isCollectionEmpty = snapshot.size === 0;

        if (!isCollectionEmpty) {
            let lista = [];
            snapshot.forEach((doc) => {
                if (doc.id != undefined && doc.id != "" && doc.id != null) {
                    lista.push({
                        id: doc.id,
                        NCNJ: doc.NCNJ,
                        contrarioPrincipalNomeRazao: doc.contrarioPrincipalNomeRazao,
                        uf: doc.uf,
                        cidade: doc.cidade,
                        advogadoReverso_oab: doc.advogadoReverso + ' - ' + (doc.oab ? doc.oab : 0),
                        advogado: doc.advogadoReverso,
                        oab: doc.oab,
                        vara: doc.vara,
                        nVara: doc.nVara,
                        dtDistribuicao: doc.dtDistribuicao,
                        ecBase: doc.ecBase,
                        riscoDCliente: doc.riscoDCliente,
                        faixaRisco: doc.faixaRisco,
                        valorCausa: doc.valorCausa,
                        tipoProcesso: doc.tipoProcesso,
                        tipoAcao: doc.tipoAcao,
                        primeiraReclamada: doc.primeiraReclamada,
                        demaisReclamadas: doc.demaisReclamadas,
                        status: doc.status,
                        fase: doc.fase,
                        momento: doc.momento,
                        dtMomento: doc.dtMomento,
                        dtAudiencia: doc.dtAudiencia,
                        dtEncerramento: doc.dtEncerramento,
                        motivoEncerramento: doc.motivoEncerramento,
                        ticketMedio: doc.ticketMedio,
                        valorAcordoCondenacao: doc.valorAcordoCondenacao,
                        formaPagamentoAcordo: doc.formaPagamentoAcordo,
                        quemPagouAcordo: doc.quemPagouAcordo,
                        momentoEmQueAcordoRealizado: doc.momentoEmQueAcordoRealizado,
                        pedidosDaInicial: doc.pedidosDaInicial,
                        funcaoCompilada: doc.funcaoCompilada,
                        proprioTerceiro: doc.proprioTerceiro,
                        ultimoSalario: doc.ultimoSalario,
                        dtAdmissao: doc.dtAdmissao,
                        dtDemissao: doc.dtDemissao,
                        tempoCasa: doc.tempoCasa,
                        sentenca: doc.sentenca,
                        dtSentenca: doc.dtSentenca,
                        nomeJuiz: doc.nomeJuiz,
                        houveReformaAcordaoRO: doc.houveReformaAcordaoRO,
                        pedidosIncluidosAcordao: doc.pedidosIncluidosAcordao,
                        pedidosExcluidosAcordao: doc.pedidosExcluidosAcordao,
                        dtAcordao: doc.dtAcordao,
                        acordaoReformouImprocedencia: doc.acordaoReformouImprocedencia,
                        rrAdmitido: doc.rrAdmitido,
                        seFoiAdmitidoQualMateria: doc.seFoiAdmitidoQualMateria,
                        houveReformaAcordaoRR: doc.houveReformaAcordaoRR,
                        dtAcordao2: doc.dtAcordao2,
                        juros: doc.juros,
                        hPericiais: doc.hPericiais,
                        honorariosAdvocaticios: doc.honorariosAdvocaticios,
                        custasProcessuais: doc.custasProcessuais,
                        estabilidade: doc.estabilidade,
                        bonus: doc.bonus,
                        equiparacaoSalarial: doc.equiparacaoSalarial,
                        acumuloDeFuncao: doc.acumuloDeFuncao,
                        dMoral: doc.dMoral,
                        dMaterial: doc.dMaterial,
                        pensao: doc.pensao,
                        he: doc.he,
                        horasInItinere: doc.horasInItinere,
                        sebreaviso: doc.sebreaviso,
                        intrajornada: doc.intrajornada,
                        diferencasSalariais: doc.diferencasSalariais,
                        adTransferencia: doc.adTransferencia,
                        insalubridade: doc.insalubridade,
                        periculosidade: doc.periculosidade,
                        ticketMedioporPedido: doc.ticketMedioporPedido,
                        garantia: doc.garantia,
                        possuiGarantia: doc.possuiGarantia,
                        garantiaMaiorouMenorqueRisco: doc.garantiaMaiorouMenorqueRisco,
                        contrarioPrincipalNomeRazao2: doc.contrarioPrincipalNomeRazao2,
                        tipoGarantiaRecursalDepositoApolice: doc.tipoGarantiaRecursalDepositoApolice,
                        valorLiberado: doc.valorLiberado,
                        revelia: doc.revelia,
                        reclamanteFoiOuvidaemAud: doc.reclamanteFoiOuvidaemAud,
                        testemunhaReclamada: doc.testemunhaReclamada,
                        testemunhaReclamante: doc.testemunhaReclamante,
                        dtTransitoemJulgado: doc.dtTransitoemJulgado,
                        valorCalcApresentadoReclamada: doc.valorCalcApresentadoReclamada,
                        valorCalcApresentadoReclamante: doc.valorCalcApresentadoReclamante,
                        valorCalcApresentadoPerito: doc.valorCalcApresentadoPerito,
                        valorHomologado: doc.valorHomologado,
                        dtCalcHomologado: doc.dtCalcHomologado,
                        deQuemfoiCalcHomologado: doc.deQuemfoiCalcHomologado,
                        houveEmbargosExecucao: doc.houveEmbargosExecucao,
                        materiaEe: doc.materiaEe,
                        formaDeGarantia: doc.formaDeGarantia,
                        houvePericia: doc.houvePericia,
                        nomePeritoPericulosidadeInsalubridade: doc.nomePeritoPericulosidadeInsalubridade,
                        nomePeritoAcidenteDoenca: doc.nomePeritoAcidenteDoenca,
                        periciaDeQue: doc.periciaDeQue,
                        resultadoInsalubridade: doc.resultadoInsalubridade,
                        fundamentoInsalubridade: doc.fundamentoInsalubridade,
                        resultadoPericulosidade: doc.resultadoPericulosidade,
                        agentePericulosidade: doc.agentePericulosidade,
                        fundamentoPericulosidade: doc.fundamentoPericulosidade,
                        agenteInsalubre: doc.agenteInsalubre,
                        resultadoDoenca: doc.resultadoDoenca,
                        resultadoAcidente: doc.resultadoAcidente,
                        dtLaudo: doc.dtLaudo,
                        compliadofunHe: doc.compliadofunHe,
                        agenteInsalubre2: doc.agenteInsalubre2,
                        grauDaInsalubridade: doc.grauDaInsalubridade,
                        compiladofunPericulosidade: doc.compiladofunPericulosidade,
                        compiladofunAcidDoenca: doc.compiladofunAcidDoenca,
                        qualDoenca: doc.qualDoenca,
                        incapacidade: doc.incapacidade,
                        reducaoFuncional: doc.reducaoFuncional,
                        compiladoFundDanoMoral: doc.compiladoFundDanoMoral,
                        empresa: doc.empresa,
                        // usuario: doc.usuario,
                        // dtInclusao: doc.dtInclusao,
                        // dtAtualizacao: doc.dtAtualizacao
                        tipo1: doc.tipo1 ? doc.tipo1 : '',
                        tipo2: doc.tipo2 ? doc.tipo2 : '',
                        tipo3: doc.tipo3 ? doc.tipo3 : '',
                        tipo4: doc.tipo4 ? doc.tipo4 : '',
                        tipo5: doc.tipo5 ? doc.tipo5 : '',
                        tipo6: doc.tipo6 ? doc.tipo6 : '',
                        tipo7: doc.tipo7 ? doc.tipo7 : '',
                        tipo8: doc.tipo8 ? doc.tipo8 : '',
                        tipo9: doc.tipo9 ? doc.tipo9 : '',
                        tipo10: doc.tipo10 ? doc.tipo10 : '',
                        baseCliente: doc.baseCliente,
                        cpf_reclamante: doc.cpf_reclamante,
                        projeto: doc.projeto,
                        status_pact: doc.status_pact,
                        cnj_execucao_provisoria: doc.cnj_execucao_provisoria,
                        momento_execucao_provisoria: doc.momento_execucao_provisoria,
                        data_cadastro_processo_base: doc.data_cadastro_processo_base,
                        status_pre: doc.status_pre,
                        data_provisao: doc.data_provisao,
                        provisao: doc.provisao,
                        data_atualizacao_risco: doc.data_atualizacao_risco,
                        risco_bruto: doc.risco_bruto,
                        risco_liquido: doc.risco_liquido,
                        risco_bruto_pact_net_atualizado: doc.risco_bruto_pact_net_atualizado,
                        risco_liquido_pact_net_atualizado: doc.risco_liquido_pact_net_atualizado,
                        depositos_recursais: doc.depositos_recursais,
                        faixa_risco: doc.faixa_risco,
                        gatilho_bruto: doc.gatilho_bruto,
                        status_negociacao: doc.status_negociacao,
                        termometro: doc.termometro,
                        data_primeiro_contato: doc.data_primeiro_contato,
                        data_ultimo_contato: doc.data_ultimo_contato,
                        proposta: doc.proposta,
                        ulitma_pretensao: doc.ulitma_pretensao,
                        data_acordo: doc.data_acordo,
                        data_protocolo: doc.data_protocolo,
                        data_homologacao: doc.data_homologacao,
                        acordo_bruto: doc.acordo_bruto,
                        acordo_liquido: doc.acordo_liquido,
                        status_minutas: doc.status_minutas,
                        inss_reclamada_pago: doc.inss_reclamada_pago,
                        inss_reclamante_pago: doc.inss_reclamante_pago,
                        ir: doc.ir,
                        honorarios_periciais: doc.honorarios_periciais,
                        economia: doc.economia,
                        desagio: doc.desagio,
                        honorario_pact: doc.honorario_pact,
                        economia_net: doc.economia_net,
                        desagio_net: doc.desagio_net,
                        status_atividade: doc.status_atividade,
                        negociar: doc.negociar,
                        tribunal: doc.tribunal,
                        dataStatusPre: doc.dataStatusPre,
                        Observacao: doc.Observacao,
                        descricaoSolicitacaoCopia: doc.descricaoSolicitacaoCopia,
                        descricaoSolicitacaoCalculo: doc.descricaoSolicitacaoCalculo,
                        descricaoUltimaAcaoNegociar: doc.descricaoUltimaAcaoNegociar,
                        statusPreSubTipo: doc.statusPreSubTipo,
                        fluxoSaneamento: doc.fluxoSaneamento,
                        restricaoTipo: doc.restricaoTipo,
                    })
                }
            })
            setIsEmpty(false)
            setModeloUm(lista);
        } else {
            setIsEmpty(true);
        }
    }

    //**----------------------------------FILTER----------------------------------- */
    async function getFilter() {
        setFilterUser([])

        await axios.get(urlBase + '/filters/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                updateFilterState(response.data)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    function updateFilterState(snapshot) {
        const isCollectionEmpty = snapshot.size === 0;

        if (!isCollectionEmpty) {
            let lista = [];

            snapshot.forEach((doc) => {
                if (doc._id != undefined && doc._id != "" && doc._id != null) {
                    lista.push({
                        usuario: doc.usuario,
                        descricao: doc.descricao,
                        filtro: doc.filtro
                    })
                }
            })
            setFilterUser(lista);
        } else {
            setIsEmpty(true);
        }
    }


    async function getFilterByUserId(id) {
        setFilterUser([])

        await axios.get(urlBase + '/filters/findByUserId/' + id, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                updateFilterByUserIdState(response.data)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    function updateFilterByUserIdState(snapshot) {
        const isCollectionEmpty = snapshot.size === 0;

        if (!isCollectionEmpty) {
            let lista = [];

            snapshot.forEach((doc) => {
                if (doc._id != undefined && doc._id != "" && doc._id != null) {
                    lista.push({
                        usuario: doc.usuario,
                        descricao: doc.descricao,
                        filtro: doc.filtro
                    })
                }
            })
            setFilterUser(lista);
        } else {
            setIsEmpty(true);
        }
    }
    //**----------------------------------FILTER----------------------------------- */
    async function alterProcess(process) {

        let advogado_oab = process.advogadoReverso_oab.split(" - ")

        let dtAudiencia = moment(process.dtAudiencia).format("DD/MM/YYYY")
        let dtMomento = moment(process.dtMomento).format("DD/MM/YYYY")

        if (dtAudiencia == 'Invalid date') {
            dtAudiencia = process.dtAudiencia
        }
        else {
            dtAudiencia = moment(process.dtAudiencia).format("DD/MM/YYYY")
        }

        if (dtMomento == 'Invalid date') {
            dtMomento = process.dtMomento
        }
        else {
            dtMomento = moment(process.dtMomento).format("DD/MM/YYYY")
        }

        let now = new Date();

        let formatData = {
            NCNJ: process.NCNJ,
            contrarioPrincipalNomeRazao: process.contrarioPrincipalNomeRazao,
            uf: process.uf,
            cidade: process.cidade,
            advogadoReverso: advogado_oab[0],
            oab: advogado_oab[1],
            vara: process.vara,
            nVara: process.nVara,
            dtDistribuicao: process.dtDistribuicao,
            ecBase: process.ecBase,
            riscoDCliente: process.riscoDCliente,
            faixaRisco: process.faixaRisco,
            valorCausa: process.valorCausa,
            tipoProcesso: process.tipoProcesso,
            tipoAcao: process.tipoAcao,
            primeiraReclamada: process.primeiraReclamada,
            demaisReclamadas: process.demaisReclamadas,
            status: process.status,
            fase: process.fase,
            momento: process.momento,
            dtMomento: process.dtMomento,
            dtAudiencia: process.dtAudiencia,
            dtEncerramento: process.dtEncerramento,
            motivoEncerramento: process.motivoEncerramento,
            ticketMedio: process.ticketMedio,
            valorAcordoCondenacao: process.valorAcordoCondenacao,
            formaPagamentoAcordo: process.formaPagamentoAcordo,
            quemPagouAcordo: process.quemPagouAcordo,
            momentoEmQueAcordoRealizado: process.momentoEmQueAcordoRealizado,
            pedidosDaInicial: process.pedidosDaInicial,
            funcaoCompilada: process.funcaoCompilada,
            proprioTerceiro: process.proprioTerceiro,
            ultimoSalario: process.ultimoSalario,
            dtAdmissao: process.dtAdmissao,
            dtDemissao: process.dtDemissao,
            tempoCasa: process.tempoCasa,
            sentenca: process.sentenca,
            dtSentenca: process.dtSentenca,
            nomeJuiz: process.nomeJuiz,
            houveReformaAcordaoRO: process.houveReformaAcordaoRO,
            pedidosIncluidosAcordao: process.pedidosIncluidosAcordao,
            pedidosExcluidosAcordao: process.pedidosExcluidosAcordao,
            dtAcordao: process.dtAcordao,
            acordaoReformouImprocedencia: process.acordaoReformouImprocedencia,
            rrAdmitido: process.rrAdmitido,
            seFoiAdmitidoQualMateria: process.seFoiAdmitidoQualMateria,
            houveReformaAcordaoRR: process.houveReformaAcordaoRR,
            dtAcordao2: process.dtAcordao2,
            juros: process.juros,
            hPericiais: process.hPericiais,
            honorariosAdvocaticios: process.honorariosAdvocaticios,
            custasProcessuais: process.custasProcessuais,
            estabilidade: process.estabilidade,
            bonus: process.bonus,
            equiparacaoSalarial: process.equiparacaoSalarial,
            acumuloDeFuncao: process.acumuloDeFuncao,
            dMoral: process.dMoral,
            dMaterial: process.dMaterial,
            pensao: process.pensao,
            he: process.he,
            horasInItinere: process.horasInItinere,
            sebreaviso: process.sebreaviso,
            intrajornada: process.intrajornada,
            diferencasSalariais: process.diferencasSalariais,
            adTransferencia: process.adTransferencia,
            insalubridade: process.insalubridade,
            periculosidade: process.periculosidade,
            ticketMedioporPedido: process.ticketMedioporPedido,
            garantia: process.garantia,
            possuiGarantia: process.possuiGarantia,
            garantiaMaiorouMenorqueRisco: process.garantiaMaiorouMenorqueRisco,
            contrarioPrincipalNomeRazao2: process.contrarioPrincipalNomeRazao2,
            tipoGarantiaRecursalDepositoApolice: process.tipoGarantiaRecursalDepositoApolice,
            valorLiberado: process.valorLiberado,
            revelia: process.revelia,
            reclamanteFoiOuvidaemAud: process.reclamanteFoiOuvidaemAud,
            testemunhaReclamada: process.testemunhaReclamada,
            testemunhaReclamante: process.testemunhaReclamante,
            dtTransitoemJulgado: process.dtTransitoemJulgado,
            valorCalcApresentadoReclamada: process.valorCalcApresentadoReclamada,
            valorCalcApresentadoReclamante: process.valorCalcApresentadoReclamante,
            valorCalcApresentadoPerito: process.valorCalcApresentadoPerito,
            valorHomologado: process.valorHomologado,
            dtCalcHomologado: process.dtCalcHomologado,
            deQuemfoiCalcHomologado: process.deQuemfoiCalcHomologado,
            houveEmbargosExecucao: process.houveEmbargosExecucao,
            materiaEe: process.materiaEe,
            formaDeGarantia: process.formaDeGarantia,
            houvePericia: process.houvePericia,
            nomePeritoPericulosidadeInsalubridade: process.nomePeritoPericulosidadeInsalubridade,
            nomePeritoAcidenteDoenca: process.nomePeritoAcidenteDoenca,
            periciaDeQue: process.periciaDeQue,
            resultadoInsalubridade: process.resultadoInsalubridade,
            fundamentoInsalubridade: process.fundamentoInsalubridade,
            resultadoPericulosidade: process.resultadoPericulosidade,
            agentePericulosidade: process.agentePericulosidade,
            fundamentoPericulosidade: process.fundamentoPericulosidade,
            agenteInsalubre: process.agenteInsalubre,
            resultadoDoenca: process.resultadoDoenca,
            resultadoAcidente: process.resultadoAcidente,
            dtLaudo: process.dtLaudo,
            compliadofunHe: process.compliadofunHe,
            agenteInsalubre2: process.agenteInsalubre2,
            grauDaInsalubridade: process.grauDaInsalubridade,
            compiladofunPericulosidade: process.compiladofunPericulosidade,
            compiladofunAcidDoenca: process.compiladofunAcidDoenca,
            qualDoenca: process.qualDoenca,
            incapacidade: process.incapacidade,
            reducaoFuncional: process.reducaoFuncional,
            compiladoFundDanoMoral: process.compiladoFundDanoMoral,
            dtAtualizacao: now,
            tipo1: process.tipo1 ? process.tipo1 : '',
            tipo2: process.tipo2 ? process.tipo2 : '',
            tipo3: process.tipo3 ? process.tipo3 : '',
            tipo4: process.tipo4 ? process.tipo4 : '',
            tipo5: process.tipo5 ? process.tipo5 : '',
            tipo6: process.tipo6 ? process.tipo6 : '',
            tipo7: process.tipo7 ? process.tipo7 : '',
            tipo8: process.tipo8 ? process.tipo8 : '',
            tipo9: process.tipo9 ? process.tipo9 : '',
            tipo10: process.tipo10 ? process.tipo10 : '',
            empresa: process.empresa,
            baseCliente: process.baseCliente,
            cpf_reclamante: process.cpf_reclamante,
            projeto: process.projeto,
            status_pact: process.status_pact,
            cnj_execucao_provisoria: process.cnj_execucao_provisoria,
            momento_execucao_provisoria: process.momento_execucao_provisoria,
            data_cadastro_processo_base: process.data_cadastro_processo_base,
            status_pre: process.status_pre,
            data_provisao: process.data_provisao,
            provisao: process.provisao,
            data_atualizacao_risco: process.data_atualizacao_risco,
            risco_bruto: process.risco_bruto,
            risco_liquido: process.risco_liquido,
            risco_bruto_pact_net_atualizado: process.risco_bruto_pact_net_atualizado,
            risco_liquido_pact_net_atualizado: process.risco_liquido_pact_net_atualizado,
            depositos_recursais: process.depositos_recursais,
            faixa_risco: process.faixa_risco,
            gatilho_bruto: process.gatilho_bruto,
            status_negociacao: process.status_negociacao,
            termometro: process.termometro,
            data_primeiro_contato: process.data_primeiro_contato,
            data_ultimo_contato: process.data_ultimo_contato,
            proposta: process.proposta,
            ulitma_pretensao: process.ulitma_pretensao,
            data_acordo: process.data_acordo,
            data_protocolo: process.data_protocolo,
            data_homologacao: process.data_homologacao,
            acordo_bruto: process.acordo_bruto,
            acordo_liquido: process.acordo_liquido,
            status_minutas: process.status_minutas,
            inss_reclamada_pago: process.inss_reclamada_pago,
            inss_reclamante_pago: process.inss_reclamante_pago,
            ir: process.ir,
            honorarios_periciais: process.honorarios_periciais,
            economia: process.economia,
            desagio: process.desagio,
            honorario_pact: process.honorario_pact,
            economia_net: process.economia_net,
            desagio_net: process.desagio_net,
            status_atividade: process.status_atividade,
            negociar: (process.negociar) ? (process.negociar) : '',
            tribunal: (process.tribunal) ? (process.tribunal) : '',
            dataStatusPre: (process.dataStatusPre) ? (process.dataStatusPre) : '',
            Observacao: (process.Observacao) ? (process.Observacao) : '',
            descricaoSolicitacaoCopia: (process.descricaoSolicitacaoCopia) ? (process.descricaoSolicitacaoCopia) : '',
            descricaoSolicitacaoCalculo: (process.descricaoSolicitacaoCalculo) ? (process.descricaoSolicitacaoCalculo) : '',
            descricaoUltimaAcaoNegociar: (process.descricaoUltimaAcaoNegociar) ? (process.descricaoUltimaAcaoNegociar) : '',
            statusPreSubTipo: (process.statusPreSubTipo) ? (process.statusPreSubTipo) : '',
            fluxoSaneamento: (process.fluxoSaneamento) ? (process.fluxoSaneamento) : '',
            restricaoTipo: (process.restricaoTipo) ? (process.restricaoTipo) : ''
        }

        await axios.put(urlBase + '/process/' + process.id,
            formatData, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Alterado com sucesso');
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function deleteProcess(process) {

        await axios.delete(urlBase + '/process/deleteById',
            {
                data: { processDelete: JSON.parse(JSON.stringify(process)) },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso');
                getImportacao();
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }

    async function sendFile(data) {

        await axios.post(urlBase + '/file/create',
            data, {
            headers: {
                'Authorization': `token ${user.token}`
            }
        }
        )
            .then(function (response) {
                toast.success('Arquivo enviado com sucesso');
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })

    }

    const salvarArquivoAzureStorageBlod = async (formData, payloadData) => {
        try{
            const _formData = new FormData()
            _formData.append('file', formData)
            _formData.append('arquivo', payloadData.arquivo)
            _formData.append('user', payloadData.user)
            _formData.append('baseClient', payloadData.baseClient)
            _formData.append('fileType', payloadData.fileType)
            _formData.append('occupation', payloadData.occupation)
            _formData.append('comments', payloadData.comments)
            _formData.append('url', payloadData.url)
            _formData.append('company', payloadData.company)
            _formData.append('key', payloadData.key)
            _formData.append('userType', payloadData.userType)

            const response = await axios.post(`${urlBase}/arquivos/criarArquivo`, _formData, {
                headers: {
                    'Authorization': `token ${user.token}`,
                    'Content-Type': 'multipart/form-data'
                }
            })   
            return response        
        }catch(error){
            console.log(`salvarArquivoGED`, error)
        }
    }

    const donwloadAzureStorageBlod = async (accountName, containerName, blobName) => {
        try {
            const payload ={
                accountName: accountName,
                containerName: containerName,
                blobName: blobName
            }

            const response = await axios.post(`${urlBase}/arquivos/donwloadAzureStorageBlod`, payload, {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })   

            if(response.status === 200){
                return response.data
            }

            if(response.status != 200){
                console.log(`donwloadAzureStorageBlod `)
            }
             
        } catch (error) {
            console.log(`donwloadAzureStorageBlod `, error)
        }
    }


    async function getAllFiles(userID, isAdm) {
        setLoad(true);
        await axios.get(urlBase + '/file/all', {
            headers: {
                'Authorization': `token ${user.token}`
            }
        })
            .then(function (response) {
                updateFiles(response.data)
                setLoad(false);

            })
            .catch(err => {
                setLoad(false);
                console.log(err)
                toast.error('Algo deu errado')
            })

    }

    async function updateFiles(snapshot) {
        const isCollectionEmpty = snapshot.size === 0;

        if (!isCollectionEmpty) {
            let lista = [];

            snapshot.forEach((doc) => {
                if (doc._id != undefined) {
                    lista.push({
                        key: doc._id,
                        id: doc._id,
                        userUid: doc.user[0].id,
                        userName: doc.user[0].name,
                        tipoUsuario: doc.userType,
                        cliente: doc.baseClient,
                        arquivo: doc.arquivo,
                        urlDocumentoDownload: doc.url,
                        observacoes: doc.comments,
                        data: doc.date,
                        ativo: doc.status,
                        empresa: (doc.company) ? doc.company : '',
                        funcao: (doc.occupation) ? doc.occupation : '',
                        tipoArquivo: (doc.fileType) ? doc.fileType : '',
                        azureStorageBlod: doc.azureStorageBlod ? doc.azureStorageBlod : ''
                    })
                }
            })
            setArquivos(lista)
            setGridHistorico(lista)
        } else {
            setArquivos([])
            setGridHistorico([])
        }


    }

    async function deleteFiles(id, qtdFiles) {

        await axios.delete(urlBase + '/file/deleteById',
            {
                data: { fileDelete: [id] },
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                toast.success('Deletado com sucesso');
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            })
    }


    return (
        <ImportacaoContext.Provider value={{
            sendProcess,
            getImportacao,
            modeloUm,
            progress,
            setProgress,
            load,
            msg,
            alterProcess,
            deleteProcess,
            newImport,
            setNewImport,
            arquivos,
            gridHistorico,
            setArquivos,
            setGridHistorico,
            getAllFiles,
            deleteFiles,
            totalArquivosParaDeletar,
            setTotalArquivosParaDeletar,
            saveFilter,
            getFilter,
            filterUser,
            getFilterByUserId,
            getLabels,
            isEmpty,
            sendFile,
            setLoad,
            load2,
            salvarArquivoAzureStorageBlod,
            donwloadAzureStorageBlod
        }}>
            {children}
        </ImportacaoContext.Provider>
    )
}

export default ImportacaoProvider;